export enum ProdOrderState {
    Preview = 1,
    Planned = 2,
    Released = 3,
    Started = 4,
    Stopped = 5,
    Reported = 6,
    Completed = 7,
    Closed = 8,
    Archived = 9
}
