import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamMaterialType } from './beam-material-type.model';

export class BeamMaterial {
  public TemplateID: number;
  public beamMaterialLines: BeamMaterialLine[];

  constructor(
    public beamMaterialID: number,
    public layer: number,
    public layerOrder: number,
    public numberOfLamminas: number,
    public locked: boolean,
    public length : number,
    public productionNote: string,
    public planedThickness: number,
    public BeamMaterialType: BeamMaterialType,
    public materialIndex: number,
    public width: number,
    public height: number,
    public affectedByTemplate: BeamMaterialType = null,
    public guid: string = null,
    public takeTestPsc: boolean = false,
    public testPscLenght: number = 0,
    public isSplit:boolean = false,
    public overlength: number = 0,
    public cutWidth:number = 0
  ) {
    this.guid = guid;
    if (BeamMaterialType != null) {
      this.TemplateID = BeamMaterialType.BeamMaterialTypeID;
    }
    if (this.IsTemplate) {
      affectedByTemplate = this.BeamMaterialType;
    }
    this.beamMaterialLines = new Array<BeamMaterialLine>();
  }

  public AddNewLine(bml: BeamMaterialLine) {
    bml.BeamMaterialLineID = null;
    this.beamMaterialLines.push(bml);
    this.FixIndexInCut(bml.Cut);
  }

  public SetAffectedByTemplate(template: BeamMaterialType) {
    this.beamMaterialLines.forEach(
      (bml) => (bml.affectedByTemplate = template)
    );
  }

  public get IsTemplate(): boolean {
    return this.BeamMaterialType.TypeGroupCode === BeamMaterialTypeeEnum.Template;
  }

  public RemoveLineFromIndexInLayer1(bmlIndex: number): BeamMaterialLine {
    const bmlToRemove = this.beamMaterialLines.find(
      (bml) => bml.Cut === 1 && bml.IndexInCut === bmlIndex
    );

    const bmLine = this.RemoveLine(bmlToRemove);

    return bmLine;
  }

  public RemoveLine(bml: BeamMaterialLine): BeamMaterialLine {
    const index = this.beamMaterialLines.findIndex(
      (x) => x.Cut === bml.Cut && x.IndexInCut === bml.IndexInCut
    );
    const bmLine = this.beamMaterialLines.splice(index, 1)[0];

    this.FixIndexInCut(bml.Cut);
    return bmLine;
  }

  private FixIndexInCut(cut: number) {
    const lines = this.beamMaterialLines.filter((bml) => bml.Cut === cut);

    lines.forEach((a, index) => {
      a.IndexInCut = index;
    });
  }

  public GetBmlFromIndex(bmlIndex: number): BeamMaterialLine {
    return this.beamMaterialLines[bmlIndex];
  }

  public getLength(): number {
    return this.length ?? 0;
  }

  public getHight(): number {
    return this.planedThickness * this.numberOfLamminas ?? this.height;
  }

  public GetTotalAssignedLength(): number {
    let sum = 0;

    this.beamMaterialLines.filter(f => f.Cut === 1).forEach((line) => {
      sum += line.length;
    });

    return sum;
  }

  public getColor(): any {
    return { fill: this.BeamMaterialType.Color, 'stroke-width': 1, stroke: 'rgb(0,0,0)' };
  }
}

export enum BeamMaterialTypeeEnum {
  Normal = 1,
  Template = 2,
  Edit = 3,
  Manual = 4,
}
