import { Component, ViewChild } from '@angular/core';
import { ProdOrderCreateModelService } from '@app/core/services/http-services/operative/prod-order-create-model.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IProdOrderCreateInput } from '@app/core/models/prod-order-create-input.model';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { ProdOrderGridComponent } from '@app/modules/operative/controls/prod-order-grid/prod-order-grid.component';
import { ProdOrderCalendarService } from '@app/modules/operative/services/prod-order-calendar.service';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { SelectEntityForm, ProdorderFilterForm } from '@app/core/models/forms/production-orders/prod-order-component-form.model';
import { Guid } from 'guid-typescript';

marker('Operative.SelectMaterial');

@Component({
  selector: 'app-prod-order',
  templateUrl: './prod-order.component.html',
  styleUrls: ['./prod-order.component.css']
})
export class ProdOrderComponent {
  selectEntityForm: FormGroup<SelectEntityForm> = new FormGroup<SelectEntityForm>({
    materialIndex: new FormControl<number | null>(null, [Validators.required])
  });

  // Typed FormGroup for prodorderFilterForm
  prodorderFilterForm: FormGroup<ProdorderFilterForm> = new FormGroup<ProdorderFilterForm>({
    siteId: new FormControl<Guid | null>(null, [Validators.required])
  });

  @ViewChild(ProdOrderGridComponent) grid: ProdOrderGridComponent;
  deniedRole = [UserRoleEnum.Operator];
  private dialogRef: DialogRef;

  constructor(
      private readonly prodOrderCreateModelService: ProdOrderCreateModelService
    , private readonly prodOrderCalendarService: ProdOrderCalendarService
  ) { }

  openProdOrderMaterialDialog() {
    this.dialogRef =  this.prodOrderCreateModelService.openDialog(<IProdOrderCreateInput>{
      productIndex: this.selectEntityForm.get('materialIndex').value,
      startDateTime: new Date(),
      quantity: '',
      unitCode: '',
      siteId: this.prodorderFilterForm.get('siteId').value
    });
    this.prodOrderCreateModelService.handleClose(this.dialogRef).subscribe((result) => {
      if (result) {
        this.grid.loadProdOrder();
      }
    });
  }

  reloadProdOrderGrid() {
    this.grid.loadProdOrder();
  }

  openAutoSchedulerPopup() {
    let dialogRef = this.prodOrderCalendarService.openAutoSchedulerPopup();
    dialogRef.result.subscribe((d) => {
      if (d instanceof DialogCloseResult) {
        dialogRef.close();
      } else {
        this.grid.loadProdOrder();
      }
    });
  }
}
