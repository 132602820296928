export class GlusetStateHelper {
  public static getStateText(code: number) {
    switch (code) {
      case 1:
        return 'GluePlan.GENERATED';
      case 2:
        return 'GluePlan.PLANNED';
      case 3:
        return 'GluePlan.STARTED';
      case 4:
        return 'GluePlan.PAUSED';
      case 5:
        return 'GluePlan.COMPLETED';
      case 6:
        return 'App.Cancel';
      case 7:
        return 'GluePlan.SENT_BY_PLANNER';
      case 8:
        return 'GluePlan.RECIVED_BY_PROD_SYSTEM';
      case 9:
        return 'GluePlan.FAIL_TO_RECEIVE_BY_PROD_SYSTEM';
      case 10:
        return 'GluePlan.RELEASED';
    }
  }
}
