<kendo-grid
            [kendoGridBinding]="scheduleData"
            class="crud-grid"
            [selectable]="true"
            [kendoGridSelectBy]="'id'"
            [reorderable]="true"
            [filterable]="true"
            [sortable]="true"
            [filter]="state.filter"
            [skip]="state.skip"
            [pageSize]="state.take"
            [pageable]="true"
            [reorderable]="true"
            [loading]="scheduleDataLoading"
            [resizable]="true"
            (remove)="removeHandler($event)"
            [size]="'small'"
  >
    <kendo-grid-column field="code"
                       title="{{ 'App.Code' | translate }}">
      <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name"
                       title="{{ 'App.Name' | translate }}">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <kendo-grid-string-filter-cell  [showOperators]="false"
                                            [column]="column"
                                            [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}"
                              [width]="400">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <button  themeColor="primary"
                     kendoGridEditCommand
                     (click)="editSchedule(dataItem.scheduleId)">
                        {{ 'App.Edit' | translate  }}
            </button>
            <button kendoButton themeColor="warning"
                     kendoGridRemoveCommand>
                        {{ 'App.Remove' | translate  }}
            </button>
            <button kendoButton
                    themeColor="primary"
                    (click)="openCalender(dataItem.scheduleLines)">
                        {{ 'App.View' | translate }}
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<app-confirmation-dialog *ngIf="itemToRemoveId"
                         (conformationDialogCloseEvent) = "confirmRemove($event)"
>
</app-confirmation-dialog>
