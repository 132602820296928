// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvDef } from './env-def';

export const environment = <EnvDef>{
  production: true,
  environment: '#{appEnviroment}#',
  apiUrl: 'https://#{appHostname}#/api/',
  authRedirectUrl: 'https://#{appHostname}#/dashboard',
  azureADauthClient: 'fbb8261c-25ec-4e10-905e-284340fa5b4f',
  azureADauthTennant: '96fba627-e880-46cb-ad80-0dc424121a1d',
  azureADauthAuthority: 'https://login.microsoftonline.com/96fba627-e880-46cb-ad80-0dc424121a1d',
  authLogoutRedirectUrl: 'https://#{appHostname}#/',
  offCutLenght: 60,
  maxHight: 1300,
  maxLenght: 24150,
  appInsightsKey: '#{appInsightsKey}#',
  useAutoRefreshDemand: false
};
