import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { DataSet } from 'vis-data';
import { Network, Data, Node, Edge } from 'vis-network';
import { EG, GraphConfig } from '../models/graph-config.model';
import { EntityGroupService, IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { UserService } from '@app/core/services/http-services/common/user.service';
import { GraphExplorerService } from '@app/core/services/http-services/model/graph-explorer.service';
import { ITenantInformationDTO } from '@app/core/models/tenantInformationDTO.model';

@Component({
  selector: 'app-graph-visualizer',
  template: `
  <div style="padding: 20px;">
  <div #networkLegend tabindex="0" style="width: 20vw; height: 75vh;float: left">
    <canvas width="20vw" height="100vh"></canvas>
  </div>
  <div #network tabindex="0" style="width: 70vw; height: 75vh;float: left;">
    <canvas width="70vw" height="100vh"></canvas>
  </div>
  </div>
  `,
  styleUrls: ['./graph-visualizer.component.css']
})
export class GraphVisualizerComponent implements OnInit {
  @ViewChild('network', { static: false }) el: ElementRef;
  private networkInstance: Network;
  private networkData: Data;

  @ViewChild('networkLegend', { static: false }) elLegend: ElementRef;
  private networkInstanceLegend: Network;

  public event: string;
  private _entity:IEntity;
  private graphConfigs = new GraphConfig();
  public entityGroups: IEntityGroup[];
  public tenant : ITenantInformationDTO = null;
  public egLevelMap: EG[];

  @Input() set selectedRootEntity(entity:IEntity) {
    this._entity = entity;

    if (this._entity) {
      this.loadAll();
      this.loadAllLegend();
    }
  };

  get selectedRootEntity():IEntity {
    return this._entity;
  }

  constructor(private graphExplorerService : GraphExplorerService,
    private entityGroupService : EntityGroupService,
    private userService : UserService) { }

  ngOnInit(): void {
    this.entityGroupService.query().subscribe(eg => {
      this.entityGroups = eg;
    });
    this.getTenant();
  }

  ngAfterViewInit() {
    const container = this.el.nativeElement;
    const containerLegend = this.elLegend.nativeElement;
    const data = {};
    const dataLegend = {};
    const groups = {
      material: { },
      activity: { shape: 'triangle' },
      materialExpanded: { },
      activityExpanded: { shape: 'triangle' }
    };
    const options = {
      nodes: {
        shape: 'dot',
        size: 30
      },
      edges: {
        smooth: {
          type: 'cubicBezier',
          forceDirection: 'horizontal',
          roundness: 0.4,
          enabled: true
        }
      },
      layout: {
        hierarchical: {
          direction: 'LR'
        }
      },
      groups,
      physics: true
    };
    this.networkData = data;
    this.networkInstance = new Network(container, data, options);
    this.networkInstanceLegend = new Network(containerLegend, dataLegend, options);
    this.loadAll();
    this.networkInstance.on('doubleClick', e => {
      if (e.nodes[0]) {
        this.graphExplorerService.getGraphRuntime(e.nodes[0]).subscribe(r => {
          const nodeDS = this.networkData.nodes as DataSet<Node>;
          r.nodes.forEach(element => {
            const currentEntityGroup = this.egLevelMap.find(x => x.entityGroupIndex === element.entityGroupIndex);
            if (element != null) {
              nodeDS.update({
                id: element.id,
                label: element.code,
                group: element.type,
                level: currentEntityGroup.level,
                color: currentEntityGroup.color
              });
            }
          });
          const edgeDS = this.networkData.edges as DataSet<Edge>;
          edgeDS.remove(e.edges);
          r.relations.forEach(element => {
            if (element != null) {
              edgeDS.update({
                id: element.id,
                from: element.from,
                to: element.to,
                label: element.rel.toFixed(3) + ' ' + element.unitCode
              });
            }
          });
        });
      }
      this.event = e;
    });

    this.networkInstance.on('oncontext', e => {
      if (e.nodes[0]) {
        const nodeDS = this.networkData.nodes as DataSet<Node>;
        nodeDS.remove(e.nodes[0]);
        const edgeDS = this.networkData.edges as DataSet<Edge>;
        edgeDS.remove(e.edges);
      }
      this.event = e;
    });
  }

  getTenant(): void {
    this.userService.getTenant().subscribe(t => {
      this.tenant = t;
    });
  }

  setEgLevelMap(): void {
    if (this.tenant) {
      this.egLevelMap = this.tenant.id === 'Rubner' ? this.graphConfigs.egRubner : this.graphConfigs.egOther;
    }
  }

  loadAll(): void {
    if (this._entity) {
      this.setEgLevelMap();
      this.graphExplorerService.getGraphRuntime(this._entity.index).subscribe(r => {
        const nodes = new DataSet<any>();
        r.nodes.forEach(element => {
          if (element != null) {
            const currentEntityGroup = this.egLevelMap.find(x => x.entityGroupIndex === element.entityGroupIndex);
            nodes.add({
              id: element.id,
              label: element.code,
              group: element.type,
              level: currentEntityGroup.level,
              color: currentEntityGroup.color
            });
          }
        });
        const edges = new DataSet<any>();
        r.relations.forEach(element => {
          if (element != null) {
            edges.add({
              from: element.from,
              to: element.to,
              label: element.rel.toFixed(3) + ' ' + element.unitCode
            });
          }
        });
        const data = { nodes, edges };
        this.networkData = data;
        this.networkInstance.setData(data);
      });
    }
  }

  loadAllLegend(): void {
    if (this._entity) {
      const edges = new DataSet<any>();
      const nodes = new DataSet<any>();
      let i = 0;
      this.egLevelMap.forEach(e => {
        const currentGroup = this.entityGroups.find(x => x.index === e.entityGroupIndex);
        nodes.add({
          id: 1000000 + i,
          label: currentGroup.code,
          group: currentGroup.entityTypeCode.toLowerCase() === 'ACTIVITY'.toLowerCase() ? 'activity' : 'material',
          value: 1,
          fixed: true,
          physics: true,
          color: e.color
        });
        i++;
      });
      const dataLegend = { nodes, edges };
      this.networkInstanceLegend.setData(dataLegend);
    }
  }
}
