import { AppControlsModule } from '../app-controls/app-controls.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GraphExplorerComponent } from './graph-explorer/graph-explorer.component';
import { GraphService } from './service/graph.service';
import { GraphVisualizerComponent } from './graph-visualizer/graph-visualizer.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { ModelRoutingModule } from './model-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '@progress/kendo-angular-grid';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GraphExplorerComponent, GraphVisualizerComponent],
  imports: [
    CommonModule,
    ModelRoutingModule,
    TranslateModule,
    FormsModule,
    KendoImportModule,
    AppControlsModule,
    SharedModule
  ],
  providers: [GraphService]
})
export class ModelModule {}
