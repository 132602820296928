import { Component, OnInit } from '@angular/core';

import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { TypedGridResult, RunLogService } from '@app/core/services/http-services/run-job/run-log.service';
import { IRunLogDTO } from '@app/core/models/run-log-dto';

@Component({
  selector: 'app-optimization-run-admin',
  templateUrl: './optimization-run-admin.component.html',
  styles: [
  ]
})
export class OptimizationRunAdminComponent implements OnInit {
  gridData:Observable<TypedGridResult<IRunLogDTO>>;

  public state: State = {
    skip: 0,
    take: 30
  };

  constructor(private runLogService:RunLogService) { }

  ngOnInit(): void {
    this.gridData = this.runLogService.querySubject;

    this.runLogService.query(this.state.skip, this.state.take);
  }

  filter(ev:DataStateChangeEvent) {
    this.state.skip = ev.skip;
    this.runLogService.query(ev.skip, ev.take);
  }

  getDataSetText(id:number):string {
    switch (id) {
      case 0:
        return 'Glulam';
      case 1:
        return 'Sequencer';
      case 2:
        return 'Tactical';
      case 3:
        return 'AutoSheduler';
      case 4:
        return 'GlulamStatical';
      default:
        return 'Unknown';
    }
  }
}
