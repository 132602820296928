import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

@Injectable()
export class GlueSetStateService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(http, conf, notificationService);
  }

  getGlusetStatesForPlan(planid: number): Observable<IBeamMaterialGlueSetState[]> {
    const url = `${this.configurations.baseApiUrl}GlueSetState/${planid}`;
    return this.http.get<IBeamMaterialGlueSetState[]>(url);
  }

  getGlusetStates(): Observable<IBeamMaterialGlueSetState[]> {
    const url = `${this.configurations.baseApiUrl}GlueSetState`;
    return this.http.get<IBeamMaterialGlueSetState[]>(url);
  }
}

export interface IBeamMaterialGlueSetState {
  glueSetStateId: number;
  description: string;
  isGlobal: boolean;
  name: string;
  isValidForGluePlan: boolean;
  canChangeToState: boolean;
}
export enum GlueSetState {
  GENERATED = 1,
  PLANNED = 2,
  STARTED = 3,
  PAUSED = 4,
  COMPLETED = 5,
  CANCELED = 6,
  SENT_BY_PLANNER = 7,
  RECIVED_BY_PROD_SYSTEM = 8,
  FAIL_TO_RECEIVE_BY_PROD_SYSTEM = 9,
  RELEASED = 10
}
