import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';
import { EntityPropertyService, IEntityProperty, IEntityPropertyValueValid } from '@app/core/services/http-services/model/entity-property.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.UnitCode');

@Component({
  selector: 'app-select-valid-value',
  templateUrl: './select-valid-value.component.html',
  styleUrls: ['./select-valid-value.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectValidValueComponent
    }
  ]
})
export class SelectValidValueComponent implements OnInit, ControlValueAccessor {
  validValues: IEntityPropertyValueValid[] = [];
  selectedValidValue: any; // the reason to declare this var as any is that it can hold a string or an int or a double value
  loading = true;
  public textField = '';
  public selectedValidValueText: any = '';
  public showDropdown = true;

  @Input() label = 'Valid Values';
  @Input() showLable = false;
  @Input() propertyCode = '';
  @Input() property: IEntityProperty;

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private readonly entityPropertyService: EntityPropertyService) { }

  ngOnInit(): void {
    if (this.property === null || this.property === undefined) {
      this.loadValidValues();
    } else {
      this.validValues = this.property.validValues;
      if (this.property.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_STRING) {
        this.textField = 'valueString';
      } else if (this.property.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_INT) {
        this.textField = 'valueInt';
      } else if (this.property.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE) {
        this.textField = 'valueDouble';
      } else {
        this.textField = null;
      }
      this.showDropdown = this.property.areValuesValidated;
    }
  }

  private loadValidValues() {
    this.entityPropertyService.getValidValues(this.propertyCode).subscribe(result => {
      if (result.length > 0) {
        this.showDropdown = true;
        this.validValues = result;
        this.textField = result[0].valueString !== null ? 'valueString' : (result[0].valueInt !== null ? 'valueInt' : (result[0].valueDouble !== null ? 'valueDouble' : null));
        this.loading = false;

        if (this.selectedValidValueText != null) {
          // if textField is of type string then assign valueString which is bind to selectedValidValueText
          // otherwise convert string value bind to selectedValidValueText to its respective type
          // for example, int or double
          this.selectedValidValue = this.textField === 'valueString' ? this.selectedValidValueText : +this.selectedValidValueText;
        }
      } else {
        this.showDropdown = false;
        this.validValues = [];
        this.loading = false;
      }
    });
  }

  onChangedVal(validValue: IEntityPropertyValueValid) {
    this.selectedValidValue = validValue.valueString !== null ? validValue.valueString : (validValue.valueInt !== null ? validValue.valueInt : (validValue.valueDouble !== null ? validValue.valueDouble : null));
    this.onTouched();
    this.onChanged(this.selectedValidValue);
  }

  writeValue(validValue: string): void {
    this.selectedValidValueText = validValue;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  onChange(value) {
    this.selectedValidValue = value;
    this.onTouched();
    this.onChanged(this.selectedValidValue);
  }
}
