import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class KendoGridService {
  getStateFilter(state: State) {
    const filter = <StateFilters>{};
    filter.skip = state.skip;
    filter.take = state.take;

    if (state.filter) {
      filter.filter = [];
      state.filter.filters.forEach((x: any) => {
        const f = <FilterParams>{};
        f.code = x.field;
        f.value = x.value.toString();
        f.isDynamicProperty = ((x.field === x.field.toUpperCase()));
        filter.filter.push(f);
      });
    }

    if (state.sort && state.sort.length > 0) {
      const sortInfo = <sort> { };
      const requestedSorting = state.sort[0]; // always takes first selected column to sort result
      sortInfo.sortColumn = requestedSorting.field;
      sortInfo.sortDirection = requestedSorting.dir;
      filter.sort = sortInfo;
    }

    return filter;
  }

  getHttpParamsFilter(state: State):HttpParams {
    let params = new HttpParams();

    params = params.appendAll({
      skip: state.skip,
      take: state.take
    });

    if (state.filter) {
      state.filter.filters.forEach((x: any) => {
        params = params.append(x.field, x.value.toString());
      });
    }

    if (state.sort && state.sort.length > 0) {
      const requestedSorting = state.sort[0]; // always takes first selected column to sort result
      params = params.append('sortColumn', requestedSorting?.field?.toString());
      params = params.append('sortDirection', requestedSorting?.dir?.toString());
    }

    return params;
  }
}

export interface StateFilters {
    skip: number;
    take: number;
    filter: FilterParams[];
    id: number;
    searchTermCode?: string;
    sort: sort;
  }

export interface FilterParams {
    value: string;
    field:string;
    code: string;
    isDynamicProperty: boolean;
    operator:string;
  }

export interface sort {
    sortDirection: string;
    sortColumn: string;
  }
