import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { IMachineSearchCriteriaDTO, IMachineDTO } from '@app/core/models/machineDTO';
import { IShiftAllocationDTO } from '@app/core/models/shift';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class MachineGroupService {
  conf: any;
  data: any[] = [];
  _machinesDataResult:GridDataResult = null;

  constructor(
    public http: HttpClient
    , private kendoGridService: KendoGridService
    , private localStorageService: LocalStorageService) {
  }

  get(state: State, criteria: IMachineSearchCriteriaDTO): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter?.skip ?? 0)
      .set('take', stateFilter?.take ?? 40);

    criteria.machines.forEach(m => {
      params = params.append('machineIds', m.machineId?.toString());
    });

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http.get<GridDataResult>(`${environment.apiUrl}MachineGroup`, { params });
  }

  getAll(): Observable<GridDataResult> {
    return this.http.get<GridDataResult>(`${environment.apiUrl}MachineGroup`);
  }

  query(mashineGroupId:number = null, isStaticalPressGroup: boolean | null = null, isContinuousPressGroup: boolean | null = null): Observable<GridDataResult> {
    let params = new HttpParams();
    params = params.append('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId));

    if (mashineGroupId) {
      params = params.append('machineGroupId', mashineGroupId.toString());
    }

    if (isContinuousPressGroup) {
      params = params.append('isContinuousPressGroup', isContinuousPressGroup);
    }

    if (isStaticalPressGroup) {
      params = params.append('isStaticalPressGroup', isStaticalPressGroup);
    }

    return this.http.get<GridDataResult>(`${environment.apiUrl}MachineGroup`, { params })
      .pipe(tap(m => {
        this._machinesDataResult = m;
      }));
  }

  queryv2(mashineGroupId:number = null, isStaticalPressGroup: boolean | null = null, isContinuousPressGroup: boolean | null = null, siteId: string | null): Observable<GridDataResult> {
    let params = new HttpParams();

    if (siteId) {
      params = params.append('siteId', siteId);
    }
    if (mashineGroupId) {
      params = params.append('machineGroupId', mashineGroupId.toString());
    }

    if (isContinuousPressGroup) {
      params = params.append('isContinuousPressGroup', isContinuousPressGroup);
    }

    if (isStaticalPressGroup) {
      params = params.append('isStaticalPressGroup', isStaticalPressGroup);
    }

    return this.http.get<GridDataResult>(`${environment.apiUrl}MachineGroup/query`, { params })
      .pipe(tap(m => {
        this._machinesDataResult = m;
      }));
  }

  update(machine: IMachineGroupDTO): Observable<IMachineGroupDTO> {
    return this.http.put<IMachineGroupDTO>(`${environment.apiUrl}MachineGroup/${machine.machineGroupId}`, JSON.stringify(machine), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(entity: IMachineGroupDTO): Observable<IMachineGroupDTO> {
    return this.http.post<IMachineGroupDTO>(`${environment.apiUrl}MachineGroup`, JSON.stringify(entity), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  remove(data: IMachineGroupDTO): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}MachineGroup/${data.machineGroupId}`);
  }

  resetItem(dataItem: any) {
    if (!dataItem) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.data.find(item => item.machineId === dataItem.machineId);

    // revert changes
    Object.assign(originalDataItem, dataItem);
  }
}

export interface IMachineGroupDTO {
  machineGroupId: number;
  name:string;
  siteId: string;
  siteCode: string;
  isDisabledForAutoSheduler: boolean;
  preserveOrderAutoSheduler: boolean;
  isStaticalPressGroup: boolean;
  isContinuousPressGroup: boolean;
  shiftAllocations:IShiftAllocationDTO[];
  machines:IMachineDTO[];
  machineId: number; // Only for UI purpose - This field is to support MachineGroup multi selection of Machines - Value field
}
