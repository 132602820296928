import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

@Component({
  selector: 'app-mbsc-calendar-slot-selection',
  templateUrl: './mbsc-calendar-slot-selection.component.html',
  styleUrls: ['./mbsc-calendar-slot-selection.component.css']
})
export class MbscCalendarSlotSelectionComponent implements OnInit {
  public slots = [
    { text: '15 minutes', value: 15 },
    { text: '30 minutes', value: 30 },
    { text: '1 hour', value: 60 },
    { text: '2 hours', value: 2 * 60 },
    { text: '4 hours', value: 4 * 60 },
    { text: '8 hours', value: 8 * 60 },
    { text: '24 hours', value: 24 * 60 }
  ];

  public slotDuration:number;
  @Output() slotTime = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  constructor(public localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.slotDuration = Number(this.localStorageService.getItem(LocalStorageService.sheduleDurationKey)) ?? this.slots[4].value; // default 4h
    this.slotTime.emit(this.slotDuration);
  }

  onChangeSlot(s:any) {
    this.localStorageService.setItem(LocalStorageService.sheduleDurationKey, s.value);
    this.slotTime.emit(s.value);
  }
}
