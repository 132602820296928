import { Component, OnInit } from '@angular/core';
import { EntityService, IEntity, IEntityRelation, IPropertyValue } from '@app/core/services/http-services/model/entity.service';
import { ColumnReorderEvent, ColumnResizeArgs, GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, Subject, takeUntil } from 'rxjs';
import { getStringValue } from '../entity-form/entity-form.component';
import { State } from '@progress/kendo-data-query';
import { IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { FormControl, Validators } from '@angular/forms';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ActivatedRoute } from '@angular/router';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { ISearchEntityCriteria } from '@app/core/models/searchEntityCriteria.model';
import { IUnitConversion } from '@app/core/models/unitConversion.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@environment/environment';

marker('EntityTranslation.EntityAdded');
marker('EntityTranslation.EntityUpdated');

@Component({
  selector: 'app-entity-editor',
  templateUrl: './entity-editor.component.html',
  styleUrls: ['./../entity-editor.component.css']
})
export class EntityEditorComponent implements OnInit {
  entity: IEntity;
  group: IEntityGroup;
  entityRelation: IEntityRelation;
  groups: IEntityGroup[];
  isCollapsed: boolean = true;
  isNew: boolean = false;
  gridData: Observable<GridDataResult>;
  loading: any;
  state: State = {
    skip: 0,
    take: 25,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };

  entitygroup = new FormControl<string>('', [Validators.required]);
  inputEntity = <ISearchEntityCriteria>{};
  entityTypeCode: string = 'RESOURCE';
  showExportToExcel: boolean = false;
  opened: boolean = false;
  svgPattern: SafeHtml;

  private destroy$ = new Subject<void>();

  constructor(
    public readonly entityService: EntityService,
    private readonly notificationService: AppNotificationService,
    private readonly activeRoute: ActivatedRoute,
    private readonly unitService: UnitService,
    private sanitizer: DomSanitizer
    ) {
    this.gridData = this.entityService;
    // Commented because causing issue
    // this.entityGroupService.query({}).subscribe(data => {
    //   this.groups = data;
    //   this.group = this.groups[0];
    // });
  }

  ngOnInit(): void {
    const data = this.activeRoute.data;
      data
      .pipe(takeUntil(this.destroy$))
      .subscribe((d) => {
        this.entityTypeCode = d['entityTypeCode'] ?? this.entityTypeCode;
        this.showExportToExcel = this.entityTypeCode === 'RESOURCE';
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isCuttingPattern(row: IEntity): boolean {
    return row.entityGroup.code.startsWith('SAW_PATTERN');
  }

  viewPattern(row: IEntity) {
    window.open(`${environment.authLogoutRedirectUrl}saw-pattern/${row.index}`)
  }

  loadSelectedGroupEntity(g) {
    console.warn(g);
    if (g != null) {
      this.group = g;
      this.entityService.setSelectedGroup(this.group.index);
      this.loadData();
    }
  }

  loadData() {
    this.entityService.query(this.state);
  }

  dataStateChange(state) {
    this.state = state;
    this.loadData();
  }

  onEditEntity({ dataItem }) {
    this.entity = dataItem;
    this.addNewlyAddedPVtoEntityPropertyValue();
    this.entity.machineId = this.entity.machineId === 0 ? undefined : this.entity.machineId;
    const dr = this.entityService.openDialog(this.entity, this.group, false, this.entityTypeCode);

    dr.result.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response instanceof DialogCloseResult) {
        dr.close();
      } else if (response) {
        this.loadData();
        this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityUpdated');
      }
    });
  }

  private addNewlyAddedPVtoEntityPropertyValue() {
    const newPropertyAddedInEGP = this.group.entityGroupProperties.filter(item1 => !this.entity.propertyValues.some(item2 => item1.propertyCode === item2.propertyCode));
    if (newPropertyAddedInEGP.length > 0) {
      const newPropertyValues = newPropertyAddedInEGP.map<IPropertyValue>((p) => {
        return <IPropertyValue>{
          id: null,
          propertyCode: p.propertyCode,
          valueString: p.defaultValueString,
          changeDate: p.changeDate,
          entityIndex: 0,
          valueDouble: p.defaultValueDouble,
          valueInt: p.defaultValueInt,
          userCode: '',
          dataType: p.property == null ? null : p.property.datatype
        };
      });
      newPropertyValues.forEach(element => {
        if (!this.entity.propertyValues.includes(element)) {
          this.entity.propertyValues.push(element);
        }
      });
    }
  }

  onNewEntity() {
    const result = this.unitService.query({});

    result
    .pipe(takeUntil(this.destroy$))
    .subscribe((u) => {
      this.isNew = true;
      this.entity = <IEntity>{
        index: -1,
        entityGroupCode: this.group.code,
        propertyValues: this.group.entityGroupProperties.map<IPropertyValue>((p) => {
          return <IPropertyValue>{
            id: -1,
            propertyCode: p.propertyCode,
            valueString: p.defaultValueString,
            changeDate: null,
            entityIndex: -1,
            valueDouble: p.defaultValueDouble,
            valueInt: p.defaultValueInt,
            userCode: '',
            dataType: p.property == null ? null : p.property.datatype
          };
        }),
        entityRelations: [],
        unitConversions:
          this.entityTypeCode === 'ACTIVITY'
            ? []
            : u
              .filter((a) => a.mandatoryUnitRelation)
              .map<IUnitConversion>((unit) => {
                return <IUnitConversion>{
                  unitCode: unit.unitCode
                };
              })
      };

      this.isCollapsed = false;
      const dr = this.entityService.openDialog(this.entity, this.group, true, this.entityTypeCode);

      dr.result.pipe(takeUntil(this.destroy$)).subscribe((response) => {
        if (response instanceof DialogCloseResult) {
          dr.close();
        } else if (response) {
          this.loadData();
          this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityAdded');
        }
        this.isCollapsed = true;
      })
    });
  }

  getPropertyValue(dataItem: IEntity, propertyCode: string) {
    const propertyValue = dataItem.propertyValues.find((p) => p.propertyCode === propertyCode);
    return propertyValue ? getStringValue(propertyValue) : 'n/a';
  }

  public columnReorder(args: ColumnReorderEvent): void {
    console.log(`column ${args.column['field']} moved from index ${args.oldIndex} to index ${args.newIndex}`);
  }

  public columnResize(args: ColumnResizeArgs[]): void {
    const arg = args[0][0];
    console.log(`column ${arg.column['field']} width changed from ${arg.oldWidth} to ${arg.newWidth} pixels`);
  }

  async downloadCSV(entityTypeCode: string) {
    this.loading = true;
    await this.entityService.getEntityReport(entityTypeCode.toUpperCase()).then((x) => {
      this.loading = false;
      DownloadFileHelper.downloadFile(x, `EntityReport-${new Date(Date.now()).toISOString().split('T')[0]}`);
    });
  }
}
