<kendo-formfield *ngIf="showLable">
  <kendo-label translate>{{label}}</kendo-label>
  <kendo-dropdownlist
  [data]="entityStatus"
  [valueField]="'value'"
  [textField]="'key'"
  [valuePrimitive]="true"
  [(value)]="selectedStatus"
  (selectionChange)="onChangedVal($event)">
</kendo-dropdownlist>
</kendo-formfield>
<kendo-dropdownlist
[data]="entityStatus"
[valueField]="'value'"
[textField]="'key'"
[valuePrimitive]="true"
[(value)]="selectedStatus"
(selectionChange)="onChangedVal($event)"
*ngIf="!showLable"
class="controlWidth">
</kendo-dropdownlist>
