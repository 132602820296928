import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { GraphExplorerComponent } from './graph-explorer/graph-explorer.component';
import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { EntityEditorComponent } from '@app/modules/entity-admin/entity-editor/entity-editor.component';
import { EntityGroupEditorComponent } from '@app/modules/entity-admin/entity-group-editor/entity-group-editor.component';
import { EntityPropertyEditorComponent } from '@app/modules/entity-admin/entity-property-editor/entity-property-editor.component';

const routes: Routes = [
  { path: 'model', component: GraphExplorerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'entity/activity', component: EntityEditorComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true, entityTypeCode: 'ACTIVITY' } },
  { path: 'entity/material', component: EntityEditorComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true, entityTypeCode: 'RESOURCE' } },
  { path: 'entity-group', component: EntityGroupEditorComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true } },
  { path: 'entity-property', component: EntityPropertyEditorComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true } },
  { path: 'entity/:id', component: EntityEditorComponent, canActivate: [MsalGuard, AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModelRoutingModule { }
