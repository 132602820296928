import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { RoleBasedAccessHelper, UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router,
              private localStorageService:LocalStorageService,
              private roleBasedAccessHelper: RoleBasedAccessHelper) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userRoles = JSON.parse(this.localStorageService.getItem(LocalStorageService.userRoles));
    if (userRoles && this.roleBasedAccessHelper.hasOnlyInputRole([UserRoleEnum.Operator])) {
      const expectedRoles: string[] = next ? next.data['expectedRoles'] : [];
      if (typeof (expectedRoles) === 'undefined' || expectedRoles.length === 0) {
        this.router.navigate(['/page-not-found']);
        return false;
      }
    }

    return true;
  }
}
