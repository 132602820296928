import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-kendo-grid-multiselect-drop-down-filter',
  template: `
    <kendo-multiselect
      [data]="data"
      (valueChange)="onChange($event)"
      [valuePrimitive]="true"
      [textField]="textField"
      [valueField]="valueField"
      [itemDisabled]="itemDisabled"
      [(ngModel)]="selectedValues"
      [tagMapper]="tagMapper"
    >
      <ng-template kendoDropDownListItemTemplate let-dataItem>
          {{ dataItem[textField] | translate}}
      </ng-template>
    </kendo-multiselect>
  `,
  styles: [
  ]
})
export class KendoGridMultiselectDropDownFilterComponent extends BaseFilterCellComponent {
  selectedValues: any[];

  @Input() public override filter: any = null;
  @Input() public data: any[];
  @Input() set defaultSelectedValues(values: any[]) {
    this.selectedValues = values;
    if (this.selectedValues !== undefined) {
      this.onChange(this.selectedValues);
    }
  };

  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public isValueTypeString: boolean = false;
  @Input() public filterCode: string;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: any): void {
    // update the root filter
    this.applyFilter(
      (value === null || value.length === 0)
        ? this.removeFilter(this.filterCode ?? this.valueField)
        : this.updateFilter({
          field: this.filterCode ?? this.valueField,
          operator: 'eq',
          value: this.isValueTypeString ? value.toString() : value
        })
    );
  }

  public itemDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    return itemArgs.dataItem.isDisabled;
  }

  public tagMapper(tags: any[]) {
    return tags.length < 3 ? tags : [tags];
  }
}
