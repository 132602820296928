import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Parameter } from '@app/core/models/parameter.model';
import { ITenantInformationDTO } from '@app/core/models/tenantInformationDTO.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: OptinexUserDTO;
  public _tenants : ITenantInformationDTO[] = null;
  public _tenant : ITenantInformationDTO = null;

  private _locales:AppLocale[] = [
    <AppLocale> { id: 'us', language: 'en', flag: 'us', locale: 'de-AT' },
    <AppLocale> { id: 'sv', language: 'sv', flag: 'se', locale: 'sv-SE' },
    <AppLocale> { id: 'de', language: 'de', flag: 'at', locale: 'de-AT' },
    <AppLocale> { id: 'in', language: 'hi', flag: 'in', locale: 'hi-IN' },
    <AppLocale> { id: 'mt', language: 'empty', flag: '', locale: 'em-mt' }
  ];

  public get locales():AppLocale[] {
    return this._locales;
  }

  public set locales(l:AppLocale[]) {
    this._locales = l;
  }

  constructor(public http: HttpClient) { }

  search(query: string): Observable<{ Code: string, Name: string }[]> {
    return this.http.get<{ Code: string, Name: string }[]>('/api/user/search', {
      params: {
        query
      }
    });
  }

  private userPromise:Promise<OptinexUserDTO>;

  async getUser(): Promise<OptinexUserDTO> {
    if (this._user) {
      return Promise.resolve(this._user);
    } else if (this.userPromise != null) {
      return this.userPromise.then(() => this._user);
    } else {
      this.userPromise = lastValueFrom(this.http.get<OptinexUserDTO>(`${environment.apiUrl}User`).pipe(
        tap(u => { this._user = u; })
      ));

      return await this.userPromise;
    }
  }

  public getTenants(): Observable<Array<ITenantInformationDTO>> {
    if (this._tenants) {
      return of(this._tenants);
    } else {
      const endpointUrl = `${environment.apiUrl}User/tennats`;
      return this.http.get<ITenantInformationDTO[]>(endpointUrl)
        .pipe(tap(t => {
          this._tenants = t;
        }));
    }
  }

  public getTenant(): Observable<ITenantInformationDTO> {
    if (this._tenant) {
      return of(this._tenant);
    } else {
      const endpointUrl = `${environment.apiUrl}User/tennatInfo`;
      return this.http.get<ITenantInformationDTO>(endpointUrl)
        .pipe(tap(t => {
          this._tenant = t;
        }));
    }
  }

  public changeTennat(newTennat: string): Observable<any> {
    const endpointUrl = `${environment.apiUrl}User/changeTennat?newTennat=${newTennat}`;
    return this.http.post<any>(endpointUrl, newTennat);
  }

  saveUser(userInfo: OptinexUserDTO): Observable<{}> {
    return this.http.put(`${environment.apiUrl}User`, userInfo);
  }

  getPofileImage(): Observable<Blob> {
    return this.http
      .get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' });
  }

  getLocaleStr():string {
    return this.getLocale().locale;
  }

  getLocale():AppLocale {
    const c = localStorage.getItem(LocalStorageService.selectedLocale);
    return this.getLocaleByCode(c);
  }

  setLocale(locale:string) {
    localStorage.setItem(LocalStorageService.selectedLocale, locale);
  }

  getLocaleByCode(code:string):AppLocale {
    const l = this._locales.find(k => k.id === code);
    if (l) {
      return l;
    } else {
      console.warn(`Using Default Locale: ${this._locales[0].id}`);
      return this._locales[0];
    }
  }

  getDefaultSite(userSites: UserSiteDTO[]) : UserSiteDTO {
    let defaultSite = userSites.find(x => x.isDefaultSite);

    if (!defaultSite) {
      defaultSite = userSites[0];
    }

    return defaultSite;
  }
}

export interface OptinexUserDTO {
  userId: string;
  userName: string;
  userCodeMasterUser: string;
  changeDate: string | null;
  isMenuExpanded: boolean;
  email: string;
  languageCode: string;
  parameters: Parameter[];
  groups: string[];
  tennatId: string;
  isGlobalAdmin: boolean;
  userSites:UserSiteDTO[]
  isDeveloper:boolean
  isPlanner:boolean
  isOperator:boolean
}

export interface AppLocale{
  id:string;
  language:string;
  locale:string;
  flag:string;
}

export interface UserSiteDTO {
siteId: string;
siteName: string;
timeZoneId: string;
address:string;
zipCode:string;
country:string;
isDefaultSite:number;
}
