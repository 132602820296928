<h1>{{'Shift.AdminShift' | translate}}</h1>

<section class="app-button-row">
  <div style="flex-grow: 1;"></div>
  <button kendoButton class="k-mb-1" themeColor="primary" (click)="addShiftRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

<kendo-grid #shiftEditorGrid
    [data]="shifts"
    class="crud-grid"
    [pageSize]="state.take"
    [skip]="state.skip"
    [pageable]="true"
    [size]="'small'"
    (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    [filterable]="true"
    [filter]="state.filter"
    [loading]="loadshiftdata"
    (dataStateChange)="dataStateChange($event)"
    [resizable]="true"
    [size]="'small'">
    <kendo-grid-column
      field="siteId"
      title="{{ 'App.Site' | translate  }}">
      <ng-template
          kendoGridEditTemplate
          let-formGroup="formGroup">
          <app-site-selector-v2 [formControl]="formGroup.get('siteId')"></app-site-selector-v2>
      </ng-template>
      <ng-template
          kendoGridCellTemplate
          let-dataItem>
          <span>{{ dataItem.siteName }}</span>
      </ng-template>
      <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <app-site-filter [filter]="filter"></app-site-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="code"
        title="{{ 'App.Code'|translate  }}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-textbox [formControl]="formGroup.get('code')"> </kendo-textbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="externalReference"
        title="{{ 'Shift.ExternalReference'|translate  }}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-textbox [formControl]="formGroup.get('externalReference')"> </kendo-textbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="name"
        title="{{ 'Shift.Name'|translate  }}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-textbox [formControl]="formGroup.get('name')"> </kendo-textbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="scheduleName"
        title="{{ 'Shift.ScheduleName' |translate }}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-dropdownlist
                textField="name"
                valueField="scheduleId"
                [data]="schedules"
                [valuePrimitive]="true"
                [formControl]="formGroup.get('scheduleId')">
            </kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="fte"
        title="{{ 'Shift.FTE'| translate  }}" [style]="{'text-align': 'right'}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-textbox [formControl]="formGroup.get('fte')" [ngModel]="formGroup.get('fte').value | ReplaceDotToCommaPipe"> </kendo-textbox>
        </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem=dataItem >
           {{dataItem.fte | number}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        filter="boolean"
        field="overtime"
        title="{{'Shift.Overtime' | translate}}">
        <kendo-grid-messages filterIsTrue="{{'App.Yes' | translate}}" filterIsFalse="{{ 'App.No' | translate}}">
        </kendo-grid-messages>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.overtime ? ('App.Yes' | translate) : ('App.No' | translate)}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <input [formControl]="formGroup.get('overtime')" type="checkbox" />
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="validFrom"
        title="{{ 'Operative.validFrom' | translate }}"
        [filterable]="false">
        <ng-template
              kendoGridCellTemplate
              let-dataItem>
              {{ dataItem.validFrom | siteDateFormat }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-datepicker [formControl]="formGroup.get('validFrom')"></kendo-datepicker>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="validTo"
        title="{{ 'Operative.validTo' | translate }}"
        [filterable]="false"
        >
        <ng-template
              kendoGridCellTemplate
              let-dataItem>
              {{ (dataItem.validTo | siteDateFormat ) }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-datepicker [formControl]="formGroup.get('validTo')"></kendo-datepicker>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="machineGroup"
        title="{{ 'Shift.MachineGroup'| translate  }}"
        [editable]="false" [filterable]="false">
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'App.Action' | translate }}">
        <ng-template
            kendoGridCellTemplate
            let-isNew="isNew"
            let-dataItem>
            <button
                kendoGridEditCommand
                [primary]="true">
                {{ 'App.Edit' | translate }}
            </button>
            <button
                kendoGridRemoveCommand
                themeColor="error">
                {{ 'App.Remove' | translate }}
            </button>
            <button
                kendoGridSaveCommand themeColor="primary"
                [disabled]="formGroup?.invalid">
                {{ isNew ?  ('App.Add' | translate) : ('App.Update' | translate) }}
            </button>
            <button kendoGridCancelCommand themeColor="error">
                {{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}
            </button>
            <button
                kendoButton
                themeColor="primary"
                *ngIf="dataItem.shiftId > 0"
                (click)="openCalender(dataItem.scheduleLines,dataItem.shiftId,dataItem.validFrom,dataItem.validTo)">
                {{ ('App.View' | translate)}}
            </button>
        </ng-template>
    </kendo-grid-command-column>


</kendo-grid>


<app-confirmation-dialog
    *ngIf="itemToRemove"
    (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>
<div kendoDialogContainer></div>
