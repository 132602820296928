export enum NotificationLevel{
    SUCCESS = 'Sucsess',
    INFO = 'Info',
    WARNING = 'Warning',
    ERROR = 'Error'
}

export abstract class AppNotification {
  public toastId:number = -1;

  constructor(
    public ID:number,
    public chanelID:number,
    public message:string,
    public title:string,
    public issued:Date = new Date(),
    public validto:Date = null,
    public level:NotificationLevel = NotificationLevel.INFO,
    public sticky:boolean = false) {
  }
}

export class SimpelNotification extends AppNotification {
  constructor(message:string, title:string = null, level:NotificationLevel = NotificationLevel.INFO, sticky:boolean = false) {
    super(-1, 0, message, title, new Date(), null, level, sticky);
  }
}
