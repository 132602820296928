import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, AbstractControl, FormControl, FormGroup, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntityRelationDirection, EntityService, IEntity } from '@app/core/services/http-services/model/entity.service';
import { DatePipe } from '@angular/common';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ProdOrderReportService } from '@app/core/services/http-services/operative/prod-order-report.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IAdditionalInstructionLineDetailsDTO } from '@app/core/models/additional-instruction-line-details.model';
import { FileDownloadMode } from '@app/core/models/file-download-mode-enum';
import { IInstructionLineDTO } from '@app/core/models/instruction-line.model';
import { IProdOrderEventSearchCriteriaDTO } from '@app/core/models/prod-order-event-search-criteria';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { IProdOrderDTO, IProdOrderLineDTO, DirtyComponent, IProdOrderLineActivityRelDTO } from '@app/core/models/prod-order';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { AggregateDescriptor, AggregateResult, aggregateBy } from '@progress/kendo-data-query';
import { CellClickEvent, CellCloseEvent, GridComponent } from '@progress/kendo-angular-grid';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { ProdOrderEventComponent } from '@app/modules/operative/controls/prod-order-event/prod-order-event.component';
import { IUserSettingDTO } from '@app/core/models/userSettingDTO';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { IGridSetting } from '@app/core/models/gridSetting';
import { ProdOrderCustomService } from '@app/core/services/custom-services/prod-order-custom.service';
import { InstructionLineService } from '@app/core/services/http-services/operative/instruction-line.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { BeamMaterialGlusetStaticalDialogComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/beam-material-gluset-statical-dialog/beam-material-gluset-statical-dialog.component';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { DefaultInstructionLinesEnum } from '@app/core/models/default-instruction-lines.enum';
import { Guid } from 'guid-typescript';
import { CreateProdOrderForm } from '@app/core/models/forms/production-orders/create-prod-order-form';
import { CreateProdOrderLineForm } from '@app/core/models/forms/production-orders/create-prod-order-line-form';
import { CreateProdOrderLineInstructionsForm } from '@app/core/models/forms/production-orders/create-prod-order-line-instructions-form';
import { RoleBasedAccessHelper, UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { DialogService } from '@progress/kendo-angular-dialog';
import { environment } from '@environment/environment';

marker('Operative.SaveSuccessMessage');
marker('Operative.UpdateSuccessMessage');
marker('Operative.SingleRecordWithMainTrueErrMsg');
marker('Operative.CreateProdOrderErrMsg');
marker('Operative.UpdateProdOrderErrMsg');
marker('Operative.ChangeStateBackToReleaseMsg');

const createProdOrderForm = (p: IProdOrderDTO) => new FormGroup<CreateProdOrderForm>({
  prodOrderId: new FormControl<number>(p.prodOrderId, [Validators.required]),
  name: new FormControl<string>(p.name, [Validators.required]),
  externalProdOrderId: new FormControl<string>({ value: p.externalProdOrderId, disabled: true }),
  machineId: new FormControl<number>(p.machineId, [Validators.required]),
  startDateTime: new FormControl<Date>(p.plannedExecutionStartDate, [Validators.required]),
  endDateTime: new FormControl<Date>(p.plannedExecutionEndDate, [Validators.required]),
  duration: new FormControl<number>(p.plannedExecutionDuration, [Validators.required]),
  instruction: new FormControl<string>({ value: p.instruction, disabled: true }),
  stateId: new FormControl<number>(p.stateId, [Validators.required]),
  activityRelationLocked: new FormControl<boolean>(p.activityRelationLocked, [Validators.required]),
  prodOrderLines: new FormControl<IProdOrderLineDTO[]>(p.prodOrderLines),
  actualStartDateTime: new FormControl<Date>({ value: p.actualExecutionStartDate, disabled: true }),
  actualEndDateTime: new FormControl<Date>({ value: p.actualExecutionEndDate, disabled: true }),
  actualDuration: new FormControl<number>({ value: p.actualExecutionDuration, disabled: true }),
  operatorComment: new FormControl<string>(p.operatorComment),
  siteId: new FormControl<Guid>(p.siteId),
  firstActivityGuid: new FormControl<string>(p.firstActivityGuid)
});

const createProdOrderLineForm = (p: IProdOrderLineDTO, isAddRequest: boolean, instructionLines: IInstructionLineDTO[]) => new FormGroup<CreateProdOrderLineForm>({
  lineNo: new FormControl<number>(p.lineNo, Validators.required),
  isMain: new FormControl<boolean>(p.mainReportingLine, Validators.required),
  isEmptyStock: new FormControl<boolean>(p.isEmptyStock),
  materialIndex: new FormControl<number>(p.materialIndex, Validators.required),
  activityCode: new FormControl<string>(p.prodOrderLineActivityRel.length > 0 ? p.prodOrderLineActivityRel[0].activity.code : ''),
  relation: new FormControl<number>(p.materialRelationSum),
  unitCode: new FormControl<string>(p.planedQtyUOM, Validators.required),
  direction: new FormControl<number>(p.direction, Validators.required),
  stockLocationId: new FormControl<number>(p.stockLocationId, Validators.required),
  quantity: new FormControl<number>(p.planedQty, Validators.compose([Validators.required, Validators.pattern(RegexHelper.positiveNegativeZeroWithDotComma)])),
  isAddRequest: new FormControl<boolean>(isAddRequest),
  plannedWidth: new FormControl<number>(p.plannedWidth),
  plannedThickness: new FormControl<number>(p.plannedThickness),
  plannedLength: new FormControl<number>(p.plannedLength),
  plannedQuality: new FormControl<string>(p.plannedQuality),
  additionalInstructions: createAdditionalInstructions(p.additionalInstructions, instructionLines)
});

const createProdOrderLineInstructionsForm = (p: IProdOrderLineDTO, instructionLines: IInstructionLineDTO[]) => new FormGroup<CreateProdOrderLineInstructionsForm>({
  plannedWidth: new FormControl<number>(p.plannedWidth),
  plannedThickness: new FormControl<number>(p.plannedThickness),
  plannedLength: new FormControl<number>(p.plannedLength),
  plannedQuality: new FormControl<string>(p.plannedQuality),
  additionalInstructions: createAdditionalInstructions(p.additionalInstructions, instructionLines)
});

const createAdditionalInstructions = (additionalInstruction: IAdditionalInstructionLineDetailsDTO[], instructionLines: IInstructionLineDTO[]) => {
  const instructionFormArray: AbstractControl[] = [];
  instructionLines.forEach(x => {
    const instructionMapWithMachine = additionalInstruction.find(i => i.instructionId === x.instructionID);
    instructionFormArray.push(new FormControl({
      instructionId: new FormControl<number>(instructionMapWithMachine?.instructionId ?? x.instructionID),
      valueString: new FormControl<string>(instructionMapWithMachine?.valueString ?? '')
    })
    );
  });
  return new FormArray(instructionFormArray);
};

@Component({
  selector: 'app-prod-order-details',
  templateUrl: './prod-order-details.component.html',
  styleUrls: ['./prod-order-details.component.css'],
  providers: [DatePipe]
})
export class ProdOrderDetailsComponent implements OnInit, DirtyComponent, AfterViewInit, OnDestroy {
  isNew = false;
  isEditable = false;
  isSubmitClicked = true;
  prodOrderForm: FormGroup<CreateProdOrderForm>;
  prodOrderLineForm: FormGroup<CreateProdOrderLineForm>;
  prodOrderLinesGrid: IProdOrderLineDTO[];
  itemToRemoveLineNo: number = undefined;
  entityTypeCode = 'RESOURCE';

  // State
  loadProdOrderStates = false;
  prodOrderStateId: number;

  // report
  prodOrderState = ProdOrderState;
  isGlueSetFound = false;
  isDemandBindToPO = true;
  previousPlanedQty: number;
  settings: IUserSettingDTO;
  changeStateBackToReleaseMsg: string;
  confirmStateUpdate = false;
  prodOrder: IProdOrderDTO = null;
  fileDownloadMode = FileDownloadMode;
  aggregates: AggregateDescriptor[] = [{ field: 'planedQty', aggregate: 'sum' }];
  total: AggregateResult;
  defaultSelectedinstructionConfigIds: any[] = [];

  defaultInstructionLines = DefaultInstructionLinesEnum;
  // Instruction Lines
  instructionLines: IInstructionLineDTO[] = [];

  private destroy$ = new Subject<void>();
  private editedRowIndex: number;
  private selectedMaterialCode: string;
  private selectedMaterialDescription: string;
  private selectedMaterial: IEntity;
  private selectedDirectionText: string;
  private selectedStockLocationName: string;
  private settingsId = UserSettingkeyEnum.grid_prodOrder_prodOrderdetailList;

  @ViewChild('grdProdOrderdetails')
  private grdProdOrderdetails: GridComponent;

  constructor(private readonly poService: ProdOrderService
    , private readonly activeRoute: ActivatedRoute
    , private readonly router: Router
    , private readonly spinner: NgxSpinnerService
    , private readonly appNotificationService: AppNotificationService
    , private readonly poReportService: ProdOrderReportService
    , private dialog: DialogService
    , private readonly beamOrderRowService: DeamndService
    , private readonly localStorageService: LocalStorageService
    , private readonly entityService: EntityService
    , private readonly userSettingService: UserSettingService
    , private readonly prodOrderCustomService: ProdOrderCustomService
    , private readonly instructionLineService: InstructionLineService
    , private readonly glueSetService: GlueSetService
    , private readonly gluePlanService: GluePlanService
    , private readonly machineService: MachineService
    , private readonly roleBasedAccessHelper: RoleBasedAccessHelper
  ) { }

  ngOnInit() {
    this.prodOrderForm = createProdOrderForm(<IProdOrderDTO>{
      activityRelationLocked: true,
      prodOrderLines: []
    });
    this.prodOrderForm.get('endDateTime').disable();

    this.activeRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(routhData => {
        if (routhData['isNew'] === true) {
          this.isSubmitClicked = false;
          this.isNew = true;

          const newPODetails = JSON.parse(this.localStorageService.getItem(LocalStorageService.newPODetails));
          this.spinner.show('createProdOrderdetailsLoader');

          this.poService.createProdOrder(
            newPODetails.machineId,
            newPODetails.activityIndex,
            newPODetails.productIndex,
            newPODetails.start,
            newPODetails.end,
            newPODetails.quantity,
            newPODetails.duration,
            newPODetails.isEmptyStock,
            newPODetails.instruction,
            newPODetails.siteId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              po => this.prodOrderSuccess(po, newPODetails.activityRelationLocked),
              _ => this.hideLoader()
            );
        } else {
          this.activeRoute.params.subscribe(params => {
            const id = params['id'] ? +params['id'] : null;

            if (routhData['isCopy'] ?? false) {
              this.isNew = true;
              this.poService.copyProdOrder(id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  po => this.prodOrderSuccess(po),
                  _ => this.hideLoader()
                );
            } else {
              this.spinner.show('createProdOrderdetailsLoader');
              this.poService.getProdOrder(id)
                .pipe(takeUntil(this.destroy$))
                .subscribe((po) => {
                  this.prodOrderSuccess(po)
                });
            }
          });
        }
      });

    this.prodOrderForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        if (this.prodOrderForm.dirty) {
          this.isSubmitClicked = false;
        }
      });

    this.prodOrderForm.get('stateId').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(poStateId => {
        if (this.prodOrder.stateId > poStateId && poStateId === ProdOrderState.Released) {
          this.confirmStateUpdate = true;
          this.changeStateBackToReleaseMsg = this.prodOrderCustomService.getPOBackToReleaseMsg(this.prodOrder.stateId);
        }
        if (poStateId >= ProdOrderState.Started) {
          this.prodOrderForm.get('startDateTime').disable();
          this.prodOrderForm.get('endDateTime').disable();
          this.prodOrderForm.get('duration').disable();
        }

        this.toggleInstruction();
        this.toggleOperatorComment(poStateId);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isInEditableStatus(poStateId: number): boolean {
    switch (poStateId) {
      case ProdOrderState.Released:
      case ProdOrderState.Completed:
      case ProdOrderState.Stopped:
      case ProdOrderState.Started:
        return true;
      default:
        return false;
    }
  }

  ngAfterViewInit(): void {
    this.getUserSettings();
  }

  calculatePlanedQty(event: Event): void {
    const inputValue = Number((event.target as HTMLInputElement).value.replace(',', '.'));
    if (isNaN(inputValue)) {
      return;
    }
    this.calculateTotalQty();
    this.total.planedQty.sum = ((inputValue - this.previousPlanedQty) + this.total.planedQty.sum);
  }

  calculateTotalQty() {
    this.total = aggregateBy(this.prodOrderLinesGrid, this.aggregates);
  }

  canDeactivate() {
    return this.prodOrderForm.touched && this.prodOrderForm.dirty;
  }

  unlockRelation() {
    if (this.prodOrderForm.get('activityRelationLocked').value) {
      this.prodOrderForm.get('activityRelationLocked').setValue(false);
    }
  }

  bindProdOrderLinesToGrid() {
    this.prodOrderLinesGrid = this.prodOrderForm.get('prodOrderLines').value.filter(d => d.isRemoved === false);
    this.calculateTotalQty();
  }

  closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.prodOrderLineForm = undefined;
  }

  cancelHandler({ sender, rowIndex }) {
    this.calculateTotalQty();
    this.isEditable = false;
    this.closeEditor(sender, rowIndex);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.previousPlanedQty = dataItem.planedQty;
    const prodOrderLineActivityRels: IProdOrderLineActivityRelDTO[] = [];

    if (dataItem.prodOrderLineActivityRel.length > 0) {
      const prodOrderLineActivityRel = <IProdOrderLineActivityRelDTO>{
        activity: dataItem.prodOrderLineActivityRel[0].activity
      };
      prodOrderLineActivityRels.push(prodOrderLineActivityRel);
    }
    this.prodOrderLineForm = createProdOrderLineForm(dataItem, false, this.instructionLines);
    this.isEditable = (dataItem.prodOrderLineActivityRel.length <= 0);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.prodOrderLineForm);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const prodOrderLine = formGroup.value;

    if (prodOrderLine.isMain) {
      this.setProdOrderLineIsMain(false);
    }

    if (isNew) {
      const newProdOrderLine = this.createNewProdOrderLineModel(prodOrderLine);
      this.prodOrderForm.get('prodOrderLines').value.push(newProdOrderLine);
    } else {
      const existingProdOrderLine = this.getProdOrderLine(prodOrderLine.lineNo);
      if (existingProdOrderLine !== undefined) {

        const shouldRecalculateDuration = (existingProdOrderLine.planedQty !== Number(CommonHelper.replaceCommawithDot(prodOrderLine.quantity)))
                                          && prodOrderLine.isMain;

        existingProdOrderLine.mainReportingLine = prodOrderLine.isMain;
        existingProdOrderLine.isEmptyStock = prodOrderLine.isMain && prodOrderLine.direction === EntityRelationDirection.CONSUMES ? prodOrderLine.isEmptyStock : false;
        existingProdOrderLine.planedQtyUOM = prodOrderLine.unitCode;
        existingProdOrderLine.planedQty = this.poService.validateQuantityAsPerDirection(Number(CommonHelper.replaceCommawithDot(prodOrderLine.quantity)), Number(prodOrderLine.direction));
        existingProdOrderLine.plannedWidth = Number(CommonHelper.replaceCommawithDot(prodOrderLine.plannedWidth));
        existingProdOrderLine.plannedThickness = Number(CommonHelper.replaceCommawithDot(prodOrderLine.plannedThickness));
        existingProdOrderLine.plannedLength = Number(CommonHelper.replaceCommawithDot(prodOrderLine.plannedLength));
        existingProdOrderLine.plannedQuality = prodOrderLine.plannedQuality;

        if (existingProdOrderLine.direction !== prodOrderLine.direction) {
          existingProdOrderLine.direction = prodOrderLine.direction;
          existingProdOrderLine.directionText = this.selectedDirectionText;
        }

        if (existingProdOrderLine.stockLocationId !== prodOrderLine.stockLocationId) {
          existingProdOrderLine.stockLocationId = prodOrderLine.stockLocationId;
          existingProdOrderLine.stockLocationName = this.selectedStockLocationName;
        }

        if (existingProdOrderLine.materialIndex !== prodOrderLine.materialIndex) {
          existingProdOrderLine.materialIndex = prodOrderLine.materialIndex;
          existingProdOrderLine.material.code = this.selectedMaterialCode;
          existingProdOrderLine.material.description = this.selectedMaterialDescription;
        }

        if (shouldRecalculateDuration) {
          this.calculateDuration();
        }
      }

      existingProdOrderLine.additionalInstructions = this.setAdditionalInstruction(prodOrderLine.additionalInstructions);

      // Modify Quantity Of Existing ProdLines On Change Of Main ProdLine Quantity
      if (this.prodOrderForm.get('activityRelationLocked').value && (existingProdOrderLine.prodOrderLineActivityRel.length > 0)) {
        const prodOrderLines = this.poService.calculateProdOrderLineQuantity(
          Number(existingProdOrderLine.planedQty)
          , this.prodOrderForm.get('prodOrderLines').value);

        this.prodOrderForm.patchValue({
          prodOrderLines
        });
      }
    }

    sender.closeRow(rowIndex);
    this.isEditable = false;
    this.bindProdOrderLinesToGrid();
  }

  addHandler({ sender }) {
    this.closeEditor(sender);

    this.onSelectDirection(undefined);

    const prodOrderLineActivityRels: IProdOrderLineActivityRelDTO[] = [];

    this.prodOrderLineForm = createProdOrderLineForm(<IProdOrderLineDTO>{
      lineNo: (this.prodOrderForm.get('prodOrderLines').value.length + 1),
      mainReportingLine: false,
      materialIndex: null,
      materialRelationSum: 0,
      planedQtyUOM: '',
      direction: null,
      stockLocationId: null,
      planedQty: 0,
      prodOrderLineActivityRel: prodOrderLineActivityRels,
      isNew: true,
      plannedWidth: null,
      plannedThickness: null,
      plannedLength: null,
      plannedQuality: null,
      additionalInstructions: []
    }, true, this.instructionLines);

    this.isEditable = true;
    sender.addRow(this.prodOrderLineForm);
  }

  cellClickHandler(args: CellClickEvent) {
    if (!args.isEdited && this.isCellEditable(args.column.field)) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        createProdOrderLineInstructionsForm(args.dataItem, this.instructionLines)
      );
    }
  }

  cellCloseHandler(args: CellCloseEvent): void {
    const additionalInstructionsData = args.formGroup.value;
    const existingProdOrderLine = this.getProdOrderLine(args.rowIndex + 1);
    existingProdOrderLine.plannedWidth = Number(CommonHelper.replaceCommawithDot(additionalInstructionsData.plannedWidth));
    existingProdOrderLine.plannedThickness = Number(CommonHelper.replaceCommawithDot(additionalInstructionsData.plannedThickness));
    existingProdOrderLine.plannedLength = Number(CommonHelper.replaceCommawithDot(additionalInstructionsData.plannedLength));
    existingProdOrderLine.plannedQuality = additionalInstructionsData.plannedQuality;
    existingProdOrderLine.additionalInstructions = this.setAdditionalInstruction(additionalInstructionsData.additionalInstructions);
    this.bindProdOrderLinesToGrid();
  }

  isCellEditable(fieldName): boolean {
    if (this.instructionLines.some(line => line.instructionID.toString() === fieldName) || Object.values(this.defaultInstructionLines).includes(fieldName)) {
      return true;
    }
    return false;
  }

  removeHandler({ dataItem }): void {
    this.itemToRemoveLineNo = dataItem.lineNo;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      const selectedProdOrderLine = this.prodOrderForm.get('prodOrderLines').value.find(d => d.lineNo === this.itemToRemoveLineNo);

      if (selectedProdOrderLine !== undefined) {
        selectedProdOrderLine.isRemoved = true;
      }
      this.bindProdOrderLinesToGrid();
    }
    this.itemToRemoveLineNo = undefined;
  }

  onClose() {
    this.localStorageService.removeItem(LocalStorageService.newPODetails);
    this.localStorageService.removeItem(LocalStorageService.newPODetailLine);
  }

  onSubmit() {
    this.isSubmitClicked = true;
    this.localStorageService.removeItem(LocalStorageService.newPODetails);
    this.localStorageService.removeItem(LocalStorageService.newPODetailLine);
    const prodOrderFormValue = this.prodOrderForm.value;

    const prodOrder = <IProdOrderDTO>{
      prodOrderId: prodOrderFormValue.prodOrderId,
      externalProdOrderId: prodOrderFormValue.externalProdOrderId,
      name: prodOrderFormValue.name,
      instruction: prodOrderFormValue.instruction,
      plannedExecutionStartDate: this.prodOrderForm.get('startDateTime').value,
      plannedExecutionEndDate: prodOrderFormValue.endDateTime,
      machineId: prodOrderFormValue.machineId,
      activityRelationLocked: this.prodOrderForm.get('activityRelationLocked').value,
      stateId: prodOrderFormValue.stateId,
      prodOrderLines: this.prodOrderForm.get('prodOrderLines').value,
      plannedExecutionDuration: this.prodOrderForm.get('duration').value,
      siteId: prodOrderFormValue.siteId,
      operatorComment: prodOrderFormValue.operatorComment
    };

    // set prodorder report property to null as it not required during save/update
    this.prodOrder.prodOrderLines.forEach(pol => {
      pol.prodOrderReport = [];
      pol.plannedWidth = pol.plannedWidth ? +pol.plannedWidth : null;
      pol.plannedThickness = pol.plannedThickness ? +pol.plannedThickness : null;
      pol.plannedLength = pol.plannedLength ? +pol.plannedLength : null;
    });

    if (this.validate()) {
      this.spinner.show('createProdOrderdetailsLoader');
      if (this.isNew) {
        this.poService.saveProdOrder(prodOrder)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            prodOrderId => this.saveResponse(prodOrderId, 'Operative.SaveSuccessMessage')
            , err => this.handleResponseError(err)
          );
      } else {
        this.poService.updateProdOrder(prodOrder)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            res => this.updateResponse(res, 'Operative.UpdateSuccessMessage')
            , err => this.handleResponseError(err)
          );
      }
    }
  }

  isSawingPattern(): boolean {
    const main = this.getProdOrderMainLine();
    return main.prodOrderLineActivityRel[0].activity.entityGroup.isSawingPattern;
  }

  showSawPattern(): void {
    const main = this.getProdOrderMainLine();
    const id = main.prodOrderLineActivityRel[0].activityIndex;
    window.open(`${environment.authLogoutRedirectUrl}/saw-pattern/${id}`);
  }
  disableSubmitButton() {
    return !(this.prodOrderForm.valid && this.getProdOrderMainLine() !== undefined);
  }

  setSelectedMaterial(entity: IEntity) {
    this.selectedMaterialCode = entity.code;
    this.selectedMaterialDescription = entity.description;
    this.selectedMaterial = entity;
  }

  onSelectDirection(direction: IEnumKeyValue) {
    this.selectedDirectionText = (direction === undefined ? '' : direction.key);
  }

  setSelectedStockLocationText(stockLocationName: string) {
    this.selectedStockLocationName = stockLocationName;
  }

  isActivityRelationLocked() {
    return this.prodOrderForm.get('activityRelationLocked').value;
  }

  isEmptyStockEditable() {
    return this.prodOrderLineForm?.controls['isMain'].value && this.prodOrderLineForm?.controls['direction'].value === EntityRelationDirection.CONSUMES;
  }

  enableProdOrderLineEdit(dataItem: any) {
    return dataItem.isAddRequest
      ? true
      : (dataItem.mainReportingLine || dataItem.prodOrderLineActivityRel === null || dataItem.prodOrderLineActivityRel.length === 0 || !this.isActivityRelationLocked());
  }

  enableProdOrderLineRemove(dataItem: any) {
    return dataItem.isAddRequest
      ? true
      : (dataItem.prodOrderLineActivityRel === null || dataItem.prodOrderLineActivityRel.length === 0 || !this.isActivityRelationLocked());
  }

  createReport() {
    const prodOrderLineDBData = this.prodOrderForm.get('prodOrderLines').value.filter(d => d.isNew === false);
    this.poReportService.openDialog(prodOrderLineDBData, this.prodOrderForm.get('prodOrderId').value);
  }

  showDemandInfo(demandGUID: string) {
    this.beamOrderRowService.openDialogForDemandInfo(demandGUID);
  }

  viewEventDetails() {
    const dialogRef = this.dialog.open({
      content: ProdOrderEventComponent,
      width: '900px',
      height: 'auto',
    });

    const content = dialogRef.content.instance as ProdOrderEventComponent;
    content.data = {
      input: <IProdOrderEventSearchCriteriaDTO>{
        prodOrderID: this.prodOrder.prodOrderId
      }
    }
  }

  showGlueset() {
    forkJoin({
      glueSet: this.glueSetService.getGluesetbyId(this.prodOrder.glueSetID),
      gluePlan: this.gluePlanService.getGluePlansByGluesetId(this.prodOrder.glueSetID),
      poMachine: this.machineService.getMachinesByID(this.prodOrder.machineId)
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ glueSet, gluePlan, poMachine }) => {
        if (glueSet !== null && gluePlan !== null && poMachine !== null) {

          const dialogRef = this.dialog.open({
            content: BeamMaterialGlusetStaticalDialogComponent,
            width: '800px',
            height: 'auto',
          });

          const content = dialogRef.content.instance as BeamMaterialGlusetStaticalDialogComponent;
          content.data = {
              bmgs: glueSet,
              plan: gluePlan,
              machine: poMachine
          }
        }
      });
  }

  showReportGrid(dataItem: IProdOrderLineDTO): boolean {
    return dataItem.prodOrderReport?.length > 0;
  }

  calculateDuration() {
    this.spinner.show('createProdOrderdetailsLoader');
    const mainProdOrderLine = this.getProdOrderMainLine();
    this.poService.calculateDuration(
      mainProdOrderLine.prodOrderLineActivityRel[0].activityIndex,
      mainProdOrderLine.materialIndex,
      mainProdOrderLine.planedQty)
      .pipe(takeUntil(this.destroy$))
      .subscribe(d => {
        this.prodOrderForm.get('duration').setValue(d);
        this.spinner.hide('createProdOrderdetailsLoader');
      });
  }

  getActivityText(line: IProdOrderLineDTO): string {
    return ProdOrderService.GetActivityText(line);
  }

  getAdditionalInstructionValue(line: IProdOrderLineDTO, instructionId: number): string {
    if (line.additionalInstructions.length > 0) {
      return line.additionalInstructions.find(x => x.instructionId === instructionId)?.valueString;
    }
    return null;
  }

  getAdditionalInstructionformControl(formGroup: FormGroup, instructionId: number): FormControl {
    const instructionFormArray = formGroup.get('additionalInstructions') as FormArray;
    const instructionFormControl = instructionFormArray.controls.find(x => x.value.instructionId.value === instructionId);
    return instructionFormControl.value.valueString;
  }

  recreateChain() {
    const activityIndex = this.prodOrder.prodOrderLines[0].prodOrderLineActivityRel[0].activityIndex;

    this.entityService.get(activityIndex)
      .pipe(takeUntil(this.destroy$))
      .subscribe(activity => {
        for (const entityRelation of activity.entityRelations) {
          const prodOrderLine = this.prodOrder.prodOrderLines.find(l => l.materialIndex === entityRelation.productIndex);

          entityRelation.plannedQty = prodOrderLine?.planedQty;
          entityRelation.reportedQty = prodOrderLine?.totalReportedQty;
        }

        const dr = this.poService.openDialogForMultiStepExistingPO(
          this.prodOrder.prodOrderId,
          activity.entityRelations
        );

        dr.result
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            dr.close();
          })
      });
  }

  onGridStateChange(): void {
    if (!this.settingsId) {
      return;
    }

    setTimeout(() => {
      const settings = this.setGridSettings();
      this.convertGridSettingsToDTO(settings);
      this.saveOrUpdateUserSetting();
    });
  }

  confirmUpdate(isUpdate: boolean) {
    if (!isUpdate) {
      this.prodOrderForm.get('stateId').setValue(this.prodOrder.stateId);
    }
    this.confirmStateUpdate = false;
  }

  isShowGluesetDisabled() {
    return !this.prodOrder.glueSetID || !this.prodOrder.machine.machineGroup?.isStaticalPressGroup;
  }

  private toggleInstruction() {
    if (this.roleBasedAccessHelper.hasOnlyInputRole([UserRoleEnum.Planner, UserRoleEnum.Developer])) {
      this.prodOrderForm.get('instruction').enable();
    }
  }

  private toggleOperatorComment(poStateId: number) {
    if (this.isInEditableStatus(poStateId)) {
      this.prodOrderForm.get('operatorComment').enable();
    }
    else {
      this.prodOrderForm.get('operatorComment').disable();
    }
  }

  private setAdditionalInstruction(additionalInstructions: any[]): IAdditionalInstructionLineDetailsDTO[] {
    const instructions: IAdditionalInstructionLineDetailsDTO[] = [];
    for (const ai of additionalInstructions) {
      if (ai.valueString.value !== null && ai.valueString.value !== '') {
        instructions.push(<IAdditionalInstructionLineDetailsDTO>{
          instructionId: ai.instructionId.value,
          valueString: ai.valueString.value
        });
      }
    }
    return instructions;
  }

  private validate() {
    const isMainProdOrderLines = this.prodOrderLinesGrid.filter(x => x.mainReportingLine === true);
    if (isMainProdOrderLines.length !== 1) {
      this.appNotificationService.notifyErrorAppChanel('Operative.SingleRecordWithMainTrueErrMsg');
      return false;
    }
    return true;
  }

  private getProdOrderMainLine() {
    return this.prodOrderLinesGrid.find(x => x.mainReportingLine === true);
  }

  private saveResponse(prodOrderId: number, successMsgKey: string) {
    this.hideLoader();
    if (prodOrderId !== -1) {
      this.appNotificationService.notifySucsessAppChanel(successMsgKey);
      this.prodOrderForm.reset();
      this.router.navigate(['/prod-order']);
    } else {
      this.appNotificationService.notifyErrorAppChanel('Operative.CreateProdOrderErrMsg');
    }
  }

  private updateResponse(response: boolean, successMsgKey: string) {
    this.hideLoader();
    if (response) {
      this.appNotificationService.notifySucsessAppChanel(successMsgKey);
      this.prodOrderForm.reset();
      this.router.navigate(['/prod-order']);
    } else {
      this.appNotificationService.notifyErrorAppChanel('Operative.UpdateProdOrderErrMsg');
    }
  }

  private handleResponseError(error: any) {
    this.hideLoader();
    console.error(error);
  }

  private prodOrderSuccess(po, isActivityRelationLocked = true) {
    this.prodOrder = po;
    po.activityRelationLocked = isActivityRelationLocked;
    this.isDemandBindToPO = !(this.prodOrder.demandId);
    this.hideLoader();
    if (po) {
      this.instructionLineService.getInstructionForMachine(po.machineId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((instructionLines) => {
          this.instructionLines = instructionLines;
        });
      this.setUpProdOrderForm(po);
    } else {
      this.router.navigate(['/page-not-found']);
    }
  }

  private hideLoader() {
    this.spinner.hide('createProdOrderdetailsLoader');
  }

  private setUpProdOrderForm(prodOrder: IProdOrderDTO) {
    prodOrder = this.setProdOrderLineAdditionalProperty(prodOrder);
    if (this.isNew) {
      const newPODetailLine = JSON.parse(this.localStorageService.getItem(LocalStorageService.newPODetailLine));
      newPODetailLine?.forEach(element => {
        const poLine = prodOrder.prodOrderLines.find(x => x.materialIndex === element.productIndex);
        if (poLine) {
          if (element.isUnlockQtyRelation) {
            poLine.planedQty = Number(element.plannedQty);
          }
          if (element.isUnlockUOMRelation) {
            poLine.planedQtyUOM = element.unitCode;
          }
        }
      });
    }
    this.prodOrderForm.patchValue({
      prodOrderId: prodOrder.prodOrderId,
      externalProdOrderId: prodOrder.externalProdOrderId,
      name: prodOrder.name,
      instruction: prodOrder.instruction,
      startDateTime: new Date(prodOrder.plannedExecutionStartDate),
      endDateTime: new Date(prodOrder.plannedExecutionEndDate),
      machineId: prodOrder.machineId,
      stateId: prodOrder.stateId === 0 ? undefined : prodOrder.stateId,
      activityRelationLocked: prodOrder.activityRelationLocked,
      prodOrderLines: prodOrder.prodOrderLines,
      duration: prodOrder.plannedExecutionDuration,
      actualStartDateTime: prodOrder.actualExecutionStartDate !== null ? new Date(prodOrder.actualExecutionStartDate) : null,
      actualEndDateTime: prodOrder.actualExecutionEndDate !== null ? new Date(prodOrder.actualExecutionEndDate) : null,
      actualDuration: prodOrder.actualExecutionDuration,
      firstActivityGuid: prodOrder.firstActivityGuid,
      operatorComment: prodOrder.operatorComment,
      siteId: prodOrder.siteId
    });
    this.prodOrderStateId = prodOrder.stateId;
    this.bindProdOrderLinesToGrid();
  }

  private setProdOrderLineAdditionalProperty(prodOrder: IProdOrderDTO): IProdOrderDTO {
    let lineNo = 0;
    prodOrder.prodOrderLines.forEach(d => {
      lineNo += 1;
      d.lineNo = lineNo;
      d.isRemoved = false;
      d.isNew = !(d.prodOrderLineId > 0);
      if (d.mainReportingLine && prodOrder.mainProductUnitConversions.length > 0) {
        d.material.unitConversions = prodOrder.mainProductUnitConversions;
      }
      d.prodOrderReport.forEach(r => {
        r.reportDate = new Date(r.reportDate);
        r.eventDate = new Date(r.eventDate);
      });
    });

    return prodOrder;
  }

  private setProdOrderLineIsMain(value: boolean) {
    this.prodOrderForm.get('prodOrderLines').value.forEach(d => {
      d.mainReportingLine = value;
      d.isEmptyStock = !value ? value : d.isEmptyStock; // if setting isMain false then set isEmpty stock to false as well
    });
  }

  private createNewProdOrderLineModel(prodOrderLine: any) {
    const prodOrderLineActivityRels: IProdOrderLineActivityRelDTO[] = [];

    const newProdOrderLine = <IProdOrderLineDTO>{
      isNew: true,
      lineNo: prodOrderLine.lineNo,
      mainReportingLine: prodOrderLine.isMain,
      materialIndex: prodOrderLine.materialIndex,
      planedQty: this.poService.validateQuantityAsPerDirection(Number(CommonHelper.replaceCommawithDot(prodOrderLine.quantity)), Number(prodOrderLine.direction)),
      planedQtyUOM: prodOrderLine.unitCode,
      direction: prodOrderLine.direction,
      directionText: this.selectedDirectionText,
      stockLocationId: prodOrderLine.stockLocationId,
      stockLocationName: this.selectedStockLocationName,
      prodOrderLineActivityRel: prodOrderLineActivityRels,
      isRemoved: false,
      prodOrderLineId: 0,
      materialRelationSum: 0,
      planedQtyConvFactor: 0,
      totalReportedQty: 0,
      material: this.selectedMaterial,
      additionalInstructions: this.setAdditionalInstruction(prodOrderLine.additionalInstructions)
    };

    return newProdOrderLine;
  }

  private getProdOrderLine(lineNo: number) {
    return this.prodOrderForm.get('prodOrderLines').value.find(x => x.lineNo === lineNo);
  }

  private getUserSettings(): void {
    this.userSettingService.getUserSetting(this.settingsId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings) => {
        if (settings) {
          this.convertGridSettingsToDTO(settings);

          this.grdProdOrderdetails.columns.forEach((column: any, index) => {
            column.width = settings.columnSettings[index].width;
          });
        }
      });
  }

  private setGridSettings() {
    const settings: IGridSetting = {
      columnSettings: this.grdProdOrderdetails.columns
        .toArray()
        .map(item => <any>item)
        .map(item => ({
          columnName: item.field,
          columnType: '',
          width: item.width ?? item.implicitWidth,
          sortBy: '',
          filterValue: '',
          filterOperator: '',
          group: ''
        })),
      filterSettings: []
    };
    return settings;
  }

  private convertGridSettingsToDTO(newSettings: any): void {
    this.settings = {
      key: this.settingsId,
      value: JSON.stringify(newSettings)
    };
  }

  private saveOrUpdateUserSetting(): void {
    this.userSettingService.saveUserSetting(this.settings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          console.log(result);
        }
      });
  }
}
