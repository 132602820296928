import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DirtyComponent } from '@app/core/models/prod-order';

marker('App.ClosePageWithoutSavingErrorMessage');

@Injectable({
  providedIn: 'root'
})
export class DirtyCheckGuard {
  closePageWithoutSavingMessage : string = '';

  constructor(private translateService: TranslateService) {
    this.translateService.get('App.ClosePageWithoutSavingErrorMessage').subscribe(d => {
      this.closePageWithoutSavingMessage = d;
    });
  }

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return confirm(this.closePageWithoutSavingMessage);
    } else {
      return true;
    }
  }
}
