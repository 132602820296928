import {
  Component,
  Input,
  Output,
  EventEmitter,
  Injectable
} from '@angular/core';
import { CordHelper } from './CordHelper';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { StateCategory } from '@app/core/models/state-category-enum';
import { GlusetMenuEvent } from './beam-material-glueset.component';
import { BeamMaterialLineEditorService } from './beam-material-line-menu.service';

@Component({
  selector: '[app-beam-material-line]',
  templateUrl: './beam-material-line.component.html',
  styleUrls: ['./beam-material-glueset.component.css']
})
@Injectable()
export class BeamMaterialLineEditor {
  public _bml: BeamMaterialLine = null;
  public _ch: CordHelper = null;
  public selectedLaminaDimensionWidth: number;
  @Input() set bml(bml: BeamMaterialLine) {
    this._bml = bml;
    if (this._ch) {
      this.hightPX = this.ch.getHight(this.bml.transportPackageDemand.hight);
      this.widthPX = this.ch.getWidth(this.bml.transportPackageDemand.length);
    }
  };

  get bml(): BeamMaterialLine {
    return this._bml;
  };

  @Input() set ch(ch: CordHelper) {
    this._ch = ch;

    if (this._bml) {
      this.selectedLaminaDimensionWidth = this.bml.transportPackageDemand.LamminaPlaneWidth;
      this.hightPX = this.ch.getHight(this.bml.transportPackageDemand.hight);
      this.widthPX = this.ch.getWidth(this.bml.transportPackageDemand.length);
    }
  };

  @Input() planedThickness: number;
  @Input() defaultNoOfBeams: number;
  get ch(): CordHelper {
    return this._ch;
  };

  @Input() addTestPsc: number;
  @Input() testPscLenght: number;
  @Input() colorLight: string;

  public hightPX: number;
  public widthPX: number;

  public withDisplayBreakPoint = 170;

  @Output() openBeamMaterialLineMenu: EventEmitter<GlusetMenuEvent> = new EventEmitter();
  StateCategoryEnum = StateCategory;

  constructor(public bmlDialogService: BeamMaterialLineEditorService) {
  }

  public grab(evt: any) {
    this.openBeamMaterialLineMenu.emit(<GlusetMenuEvent>{ bml: this.bml, mouseEvent: evt });
    return false;
  }

  public getStateColor(code: number) {
    if (code === 0) {
      return '#6c757d';
    } else if (code === 1) {
      return '#007bff';
    } else if (code === 2) {
      return '#28a745';
    } else if (code === 3) {
      return '#dc3545';
    }
  }
}
