import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuItems: IMenuItem[] = null;

  constructor(private http: HttpClient) { }

  private menuItemsPromise:Promise<IMenuItem[]>;

  async getItems(): Promise<IMenuItem[]> {
    if (this.menuItems) {
      return this.menuItems;
    } else if (this.menuItemsPromise != null) {
      return this.menuItemsPromise.then(() => this.menuItems);
    } else {
      this.menuItemsPromise = this.http.get<IMenuItem[]>(`${environment.apiUrl}Menu`).pipe(tap(m => {
        this.menuItems = m;
      })).toPromise();
      return await this.menuItemsPromise;
    }
  }

  toggleFavorite(menuItemId: number, isFavorite: boolean): Observable<{}> {
    return this.http.post(`${environment.apiUrl}Menu/Favorites`, null, {
      params: {
        menuItemId: menuItemId.toString(),
        isFavorite: isFavorite ? 'true' : 'false'
      }
    });
  }
}

export interface IMenuItem {
  childItems: IMenuItem[];
  icon: string;
  id: number;
  isFavorite: boolean;
  isRelative: boolean;
  name: string;
  order: number;
  parentId: number | null;
  tooltip: string;
  url: string;
  selected: boolean;
}
