import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GraphExplorerService {
  constructor(public http: HttpClient) { }

  getGraphRuntime(activityIndex:number) : Observable<GraphResponceDTO> {
    const url = `${environment.apiUrl}Graph?Index=${activityIndex}`;
    return this.http.get<GraphResponceDTO>(url);
  }
}

export interface ExpandNodeRequestDTO {
  index: number;
}

export interface GraphNodeDTO {
  id: number;
  type: string;
  entityGroupIndex: number;
  code: string;
}

export interface GraphRelationDTO {
  id: number;
  from: number;
  to: number;
  rel: number;
  unitCode: string;
}

export interface GraphResponceDTO {
  nodes: GraphNodeDTO[];
  relations: GraphRelationDTO[];
}
