import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('AoT.SalesOrder');
marker('AoT.ForcastOrder');

export enum DemandTypeEnum {
        SalesOrder = 1,
        ForcastOrder = 2,
        StockOrder = 3
}

export function getDemmandTypeValues() {
  return [
    {
      value: DemandTypeEnum.SalesOrder,
      text: 'AoT.SalesOrder'
    },
    {
      value: DemandTypeEnum.ForcastOrder,
      text: 'AoT.ForcastOrder'
    },
    {
      value: DemandTypeEnum.StockOrder,
      text: 'AoT.StockOrder'
    }
  ];
}
