import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlueLamSchedulerResultDTO } from '@app/core/models/glue-lam-scheduler-result-dto';
import { IGlueLamSchedulerSearchCriteriaDTO } from '@app/core/models/glue-lam-scheduler-search-criteria-dto.model';
import { environment } from '@environment/environment';

@Injectable()
export class GlueLamSchedulerService {
  private gluplanUri = `${environment.apiUrl}GlueLamScheduler`;

  constructor(
    public http: HttpClient
  ) {}

  getHeatMapConfiguration(searchCriteria: IGlueLamSchedulerSearchCriteriaDTO) {
    return this.http.post<GlueLamSchedulerResultDTO>(this.gluplanUri, searchCriteria);
  }
}
