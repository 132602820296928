import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { IPrintBeamMaterial } from '@app/core/models/print-beammaterial.model';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BeamMaterialService extends BaseService {
  private gluplanUri = `${environment.apiUrl}BeamMaterial`;

  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(http, conf, notificationService);
  }

  getBeamMaterialLength(beamMaterial: BeamMaterial, machineId: number, lenghtOffset: number): Observable<number> {
    return this.http.post<number>(`${this.gluplanUri}/${machineId}/${lenghtOffset}`, beamMaterial);
  }

  getBeamMaterialByGlueplanId(gluePlanId: number): Observable<IPrintBeamMaterial[]> {
    return this.http
      .get<IPrintBeamMaterial[]>(`${this.gluplanUri}/GetBeamMaterialForPrint/${gluePlanId}`);
  }

  calculateHeight(lamminaPlaneThicknes: number, numberOfLaminas: number) {
    return lamminaPlaneThicknes * numberOfLaminas;
  }
}
