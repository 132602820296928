import { Observable, Subject, Subscription, forkJoin } from 'rxjs';

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppNotification, NotificationLevel, SimpelNotification } from '@app/core/models/app-notification';
@Injectable({
  providedIn: 'root'
})

export class AppNotificationService {
  public static readonly APP_CHANEL: number = 0;
  public static readonly APP_OPERATIONAL: number = 1;
  public static readonly APP_OPERATIONAL_OPS: number = 2;

  private readonly onNotification: Subject<AppNotification> = new Subject<AppNotification>();

  private _s: Subscription;
  ViewProdOrderText:string;
  SuccessProdOrderText: string;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly ts: TranslateService

  ) {
    this._s = this.onNotification.subscribe(n => {
      let type = this.toastrService.toastrConfig.iconClasses.info;

      switch (n.level) {
        case NotificationLevel.SUCCESS:
          type = this.toastrService.toastrConfig.iconClasses.success;
          break;
        case NotificationLevel.INFO:
          type = this.toastrService.toastrConfig.iconClasses.info;
          break;
        case NotificationLevel.WARNING:
          type = this.toastrService.toastrConfig.iconClasses.warning;
          break;
        case NotificationLevel.ERROR:
          type = this.toastrService.toastrConfig.iconClasses.error;
          break;
      }

      const r = this.toastrService.show(n.message, n.title, { timeOut: 5000, disableTimeOut: n.sticky, enableHtml: true }
        , type);
      n.toastId = r.toastId;
    });
  }

  private getTranslations(msg: string, title: string, values: any = {}): Observable<{ msg: string, title: string }> {
    return forkJoin({
      msg: this.ts.get(msg, values),
      title: this.ts.get(title, values)
    }
    );
  }

  public notifySucsessAppChanel(message: string, title: string = 'Sucsess', values: any = {}) {
    this.showMessage(message, title, values, NotificationLevel.SUCCESS, false);
  }

  public notifyInfoAppChanel(message: string, title: string = 'Info', values: any = {}) {
    this.showMessage(message, title, values, NotificationLevel.INFO, false);
  }

  public notifyErrorAppChanel(message: string, title: string = 'Error', values: any = {}) {
    this.showMessage(message, title, values, NotificationLevel.ERROR, false);
  }

  public notifyWarningAppChanel(message: string, title: string = 'Warning', values: any = {}) {
    this.showMessage(message, title, values, NotificationLevel.WARNING, false);
  }

  public notifySucsessAppChanelWithoutTimeout(message: string, title: string = 'Sucsess', values: any = {}) {
    this.showMessage(message, title, values, NotificationLevel.SUCCESS, true);
  }

  showMessage(message: string, title: string, values: any = {}, notificationLevel: NotificationLevel, timeout: boolean) {
    this.getTranslations(message, title, values).subscribe(d => {
      const n = new SimpelNotification(d.msg, d.title, notificationLevel, timeout);
      this.onNotification.next(n);
    });
  }
}
