import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { ScheduleEditorComponent } from '@app/modules/operative/controls/schedule-editor/schedule-editor.component';
import { ShiftCalendarComponent } from '@app/modules/operative/shift-calendar/shift-calendar.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

marker('Operative.ScheduleCodeCanNotBeBlank');
marker('Operative.ScheduleCodeAlreadyExists');

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
     private readonly http: HttpClient,
     private readonly dialogService: DialogService) {  }

  query() : Observable<GridDataResult> {
    return this.http.get<GridDataResult>(`${environment.apiUrl}Schedule`);
  }

  openDialog(scheduleId: number) : DialogRef {
    const dialogRef = this.dialogService.open({
      content: ScheduleEditorComponent,
      width: '1500px',
      height: '800px'
    });

    const content = dialogRef.content.instance as ScheduleEditorComponent;
    content.data = { scheduleId }
    return dialogRef;
  }

  getSchedule(scheduleId: number) : Observable<IScheduleDTO> {
    return this.http.get<IScheduleDTO>(`${environment.apiUrl}Schedule/${scheduleId}`);
  }

  saveSchedule(schedule: IScheduleDTO) : Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}Schedule`, schedule);
  }

  removeSchedule(scheduleId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}Schedule/${scheduleId}`);
  }

  getScheduleLinesbyMachinegroupId(machineGroupId:number):Observable<IScheduleDTO> {
    return this.http.get<IScheduleDTO>(`${environment.apiUrl}Schedule/machineGroup/${machineGroupId}`);
  }

  showCalender(criteria:ICalendarLinesCriteriaInputDTO, title:string = null) {
    const dialogRef = this.dialogService.open({
      content: ShiftCalendarComponent,
      width: '1500px',
      height: '900px'
    });
    const content = dialogRef.content.instance as ShiftCalendarComponent;
    content.criteria = criteria;
    content.label = title;
    return dialogRef;
  }
}
