import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BeamHistoryComponent } from './beam-history/beam-history.component';
import { BMGSprintComponent } from './controls/print/bmgs-print.component';
import { GlueplanPrintFirstComponent } from './glueplan-print-first/glueplan-print-first.component';
import { GlueplanPrintSecondComponent } from './glueplan-print-second/glueplan-print-second.component';
import { GluesetsViewUrlComponent } from './gluesets-view-modal/gluesets-view.component';
import { GluesetsViewModelComponent } from './gluesets-view-model-component/gluesets-view-model.component';
import { OperatorComponent } from './operator/operator.component';
import { PlannerComponent } from './planner/planner.component';
import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { StaticalPlannerComponent } from './statical-planner/statical-planner.component';
import { GluePlansHistoryComponent } from './glue-plans-history/glue-plans-history.component';
import { StaticalSchedulerComponent } from './statical-scheduler/statical-scheduler.component';
import { GlueplanPrintThirdComponent } from './glueplan-print-third/glueplan-print-third.component';

const appRoutes: Routes = [
  {
    path: 'glulam/beams',
    component: BeamHistoryComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { forceSelect: true }
  },
  {
    path: 'glulam/operator',
    component: OperatorComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { forceSelect: true }
  },

  { path: 'glulam/plan-print-first/:id', component: GlueplanPrintFirstComponent, canActivate: [MsalGuard, AuthGuard] },
  {
    path: 'glulam/plan-print-second/:id',
    component: GlueplanPrintSecondComponent,
    canActivate: [MsalGuard, AuthGuard]
  },
  { path: 'glulam/gluset-print/:id', component: BMGSprintComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/gluplam-view/:id', component: GluesetsViewUrlComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glulam-view-model/', component: GluesetsViewModelComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/solution/:gluePlanId/:solutionId', component: PlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glueplans', component: GluePlansHistoryComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true } },
  { path: 'glulam/statical', component: StaticalPlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/:id', component: PlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/statical/scheduler', component: StaticalSchedulerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glueplan-print-third/:id', component: GlueplanPrintThirdComponent, canActivate: [MsalGuard, AuthGuard] },
  {
    path: 'glulam',
    component: PlannerComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: {
      forceSelect: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class GluelamRoutingModule {}
