import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IStockLocation, IstockQuery, StockLocationService } from '@app/core/services/http-services/stock/stocklocation.service';

marker('App.StockLocation');

@Component({
  selector: 'app-stock-location-selector',
  template: `
<kendo-floatinglabel *ngIf="showLable" [text]="label | translate">
   <kendo-combobox
      [data]="stockLocations"
      [filterable]="true"
      [valuePrimitive]="true"
      [valueField]="'stockLocationId'"
      [textField]="'name'"
      [disabled]="isDisabled"
      (filterChange)="handleFilter($event)"
      (valueChange)="onChangedVal($event)">
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        {{ dataItem.code }} - {{ dataItem.name }}
      </ng-template>
    </kendo-combobox>
</kendo-floatinglabel>
<kendo-combobox
      *ngIf="!showLable"
      [data]="stockLocations"
      [value]="selectedStockLocationId"
      [filterable]="true"
      [valuePrimitive]="true"
      [valueField]="'stockLocationId'"
      [textField]="'name'"
      [disabled]="isDisabled"
      (filterChange)="handleFilter($event)"
      (valueChange)="onChangedVal($event)"
    >
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        {{ dataItem.code }} - {{ dataItem.name }}
      </ng-template>
    </kendo-combobox>
`,
  styleUrls: ['./stock-location-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StockLocationSelectorComponent,
      multi: true
    }
  ]
})
export class StockLocationSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {
  stockLocations: IStockLocation[];
  selectedStockLocationId: number;
  selectedName:string;
  isDisabled : boolean = false;
  loading: boolean = true;

  @Input() isEdge: boolean = null;
  @Input() siteId: string = null;

  @Output() selectedStockLocationText = new EventEmitter();

  @Input() label: string = 'App.StockLocation';
  @Input() showLable: boolean = false;

  private onChanged!: Function;
  private onTouched!: Function;

  constructor(private stockLocationService: StockLocationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['siteId'];

    if (!change.firstChange) {
      this.stockLocations = [];
    }
  }

  ngOnInit(): void {
  }

  onChangedVal(stockLocationId: number) {
    const stockLocation = this.stockLocations.find(s => s.stockLocationId === stockLocationId);

    this.onTouched();
    if (stockLocation != null) {
      this.selectedStockLocationId = stockLocation.stockLocationId;
      this.selectedStockLocationText.emit(stockLocation.name);
    } else {
      this.selectedStockLocationId = null;
      this.selectedStockLocationText.emit('');
    }
    this.onChanged(this.selectedStockLocationId);
  }

  writeValue(stockLocationId: number): void {
    this.selectedStockLocationId = stockLocationId;
    if(stockLocationId)
    {
      this.stockLocationService.get(stockLocationId).subscribe(sl => {
        this.stockLocations = [sl];
      });
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public async handleFilter(searchString:string) {
    if(searchString.length >= 3){
      this.stockLocationService.queryData(<IstockQuery>{ siteId: this.siteId, isEdge: this.isEdge, searchString, take: 10 }).subscribe(stockLocations => {
        this.stockLocations = stockLocations.data;
      });
    }
  }
}
