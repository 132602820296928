export class DownloadFileHelper {
  public static downloadFile(blob:Blob, file:string): void {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = file;
    a.click();
    URL.revokeObjectURL(objectUrl);

    /* if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    } */
  }
}
