import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { RunLogService } from '@app/core/services/http-services/run-job/run-log.service';
import { IRunLogDTO } from '@app/core/models/run-log-dto';

@Component({
  selector: 'app-optimization-run-detail',
  templateUrl: './optimization-run-detail.component.html',
  styles: [
    ':host {display: flex; flex-direction: column;}',
    '.row { display: flex; flex-direction: row}'
  ]
})
export class OptimizationRunDetailComponent implements OnInit {
  logItem: IRunLogDTO;
  outputData:any;
  inputData:any;

  constructor(
    private runLogService:RunLogService,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(p => {
      if (p.id) {
        this.runLogService.get(p.id).subscribe(log => {
          this.logItem = log;
          this.outputData = JSON.parse(log.outputData);
          this.inputData = JSON.parse(log.inputDataFileJson);
        });
      }
    });
  }
}
