<h1>
  {{ 'EntityTranslation.EntityGroupProperties' | translate }}
</h1>
<div class="app-button-row">
  <div style="flex-grow: 1;"></div>
  <button
      kendoButton
      class="k-mb-1"
      themeColor='primary'
      [disabled]="!isCollapsed"
      (click)="onNewEntity()">
      {{'App.New' | translate}}
  </button>
</div>
<kendo-grid
  [kendoGridBinding]="result"
  class="crud-grid"
  [selectable]="true"
  [kendoGridSelectBy]="'BeamOrderRowID'"
  [reorderable]="true"
  [columnMenu]="true"
  [filterable]="true"
  [filter]="state.filter"
  [skip]="state.skip"
  [sortable]="true"
  [pageSize]="state.take"
  [pageable]="true"
  (edit)="onEditEntity($event)"
  (pageChange)="pageChange($event)"
  (columnResize)="columnResize($event)"
  (columnReorder)="columnReorder($event)"
  [resizable]="true"
  [size]="'small'">

  <kendo-grid-column
      field="code"
      title="{{ 'EntityTranslation.Code' | translate }}"
      [width]="25">
      <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column">
      <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column
      field="description"
      title="{{ 'EntityTranslation.Description' | translate }}"
      [width]="25">
      <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column">
      <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column
      field="names"
      title="{{ 'EntityTranslation.Names' | translate }}"
      [width]="25">
      <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column">
      <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column
      field="propertyGroupCode"
      title="{{ 'EntityTranslation.PropertyGroupCode' | translate }}"
      [width]="25">
      <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column">
      <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column
      field="areValuesValidated"
      title="{{ 'EntityTranslation.AreValuesValidated' | translate }}"
      [width]="25" [filterable]="false"></kendo-grid-column>
  <kendo-grid-column
      field="dataType"
      title="{{ 'EntityTranslation.DataType' | translate }}"
      [width]="25" [filterable]="false">
      <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
      let-dataItem>
    {{ getDataTypeString(dataItem.datatype) }}
    </ng-template>
    </kendo-grid-column>
  <kendo-grid-column
      field="sortOrderIndex"
      title="{{ 'EntityTranslation.SortOrderIndex' | translate }}"
      [width]="10" [filterable]="false"></kendo-grid-column>
  <kendo-grid-column
      field="userCode"
      title="{{ 'EntityTranslation.UserCode' | translate }}"
      [width]="15" [filterable]="false"></kendo-grid-column>
  <kendo-grid-column
      field="changeDate"
      title="{{ 'EntityTranslation.ChangeDate' | translate }}"
      [width]="25"
      [filterable]="false"
      format="d">
    </kendo-grid-column>
  <kendo-grid-command-column
      title="command"
      [width]="20">
      <ng-template
          kendoGridCellTemplate
          let-isNew="isNew">
          <button
              kendoGridEditCommand
              [primary]="true">
              {{ 'App.Edit' | translate }}
          </button>
          <button kendoGridCancelCommand>
              {{ isNew ? 'App.Discard' : 'App.Cancel' | translate }}
          </button>
      </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
