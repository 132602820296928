import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';

@Directive(
  {
    selector: '[draggable]'
  }
)
export class DraggableDirective {
  @Output() xAttributeChange = new EventEmitter<{id:number, offset:number}>();
  private isDragging = false;
  private displacement:number = 0;
  private mousemoveUnlistener: () => void;
  private mouseupUnlistener: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: any): void {
    event.preventDefault();
    this.isDragging = true;
    const svgRect = this.el.nativeElement.parentElement.getBoundingClientRect();
    const svgX = svgRect.left;
    this.displacement = (event.clientX - svgX) - event.target?.attributes?.x?.value;

    if (event instanceof MouseEvent) {
      this.mousemoveUnlistener = this.renderer.listen(this.el.nativeElement.parentElement, 'mousemove', this.onMouseMove.bind(this));
      this.mouseupUnlistener = this.renderer.listen(this.el.nativeElement.parentElement, 'mouseup', this.onMouseUp.bind(this));
    } else if (event instanceof TouchEvent) {
      this.mousemoveUnlistener = this.renderer.listen(this.el.nativeElement.parentElement, 'touchmove', this.onTouchMove.bind(this));
      this.mouseupUnlistener = this.renderer.listen(this.el.nativeElement.parentElement, 'touchend', this.onTouchEnd.bind(this));
    }
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      const svgRect = this.el.nativeElement.parentElement.getBoundingClientRect();
      const svgX = svgRect.left;
      const x = event.clientX - svgX;

      if ((x >= 0 && (x - this.displacement) >= 0) && (((x - this.displacement) + this.el.nativeElement.width.animVal.value) <= svgRect.width)) {
        this.el.nativeElement.setAttribute('x', x - this.displacement);
        document.getElementById('clip_' + this.el.nativeElement.id + '_rect').setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('name_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('state_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('date_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));

        this.el.nativeElement.setAttribute('x', x - this.displacement);
      }
    }
  }

  onTouchMove(event: TouchEvent): void {
    if (this.isDragging) {
      const svgRect = this.el.nativeElement.parentElement.getBoundingClientRect();
      const svgX = svgRect.left;
      const x = event.touches[0].clientX - svgX;

      if ((x >= 0 && (x - this.displacement) >= 0) && (((x - this.displacement) + this.el.nativeElement.width.animVal.value) <= svgRect.width)) {
        this.el.nativeElement.setAttribute('x', x - this.displacement);
        document.getElementById('clip_' + this.el.nativeElement.id + '_rect').setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('name_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('state_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));
        document.getElementById('date_' + this.el.nativeElement.id).setAttribute('x', '' + (x - this.displacement + 10));
      }
    }
  }

  onMouseUp(event): void {
    event.stopPropagation();
    this.isDragging = false;
    this.mousemoveUnlistener();
    this.mouseupUnlistener();
    this.xAttributeChange.emit({ id: this.el.nativeElement.id, offset: this.el.nativeElement.x.animVal.value });
  }

  onTouchEnd(event): void {
    event.stopPropagation();
    this.isDragging = false;
    this.mousemoveUnlistener();
    this.mouseupUnlistener();
    this.xAttributeChange.emit({ id: this.el.nativeElement.id, offset: this.el.nativeElement.x.animVal.value });
  }
}
