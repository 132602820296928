export class RegexHelper {
  // use below regex when user must enter negative/positive value. Scale is upto 4 decimal place. 0 is not allowed
  public static positiveNegativeWithDotComma:RegExp = /^-?(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,4}$/;

  // use below regex when user must enter only positive value. Scale is upto 4 decimal place. 0 is not allowed
  public static onlyPostiveWithDotComma:RegExp = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,4}$/;

  // use below regex when user must enter negative/positive value. No scale limit. 0 is not allowed
  public static positiveNegativeWithDotCommaWithNoScaleNoZero :RegExp = /^-?(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d*$/;

  // use below regex when user must enter only positive value. No scale limit. 0 is not allowed
  public static onlyPostiveWithDotCommaWithNoScaleNoZero :RegExp = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d*$/;

  // use below regex when user must enter negative/positive value. No scale limit. 0 is allowed
  public static positiveNegativeWithDotCommaWithNoScale :RegExp = /^-?(?!0*[.,]0*$|[.,]0*$)\d+[,.]?\d*$/;

  // use below regex when user must enter only positive value.  No scale limit. 0 is allowed
  public static onlyPostiveWithDotCommaWithNoScale :RegExp = /^(?!0*[.,]0*$|[.,]0*$)\d+[,.]?\d*$/;

  // use below regex when user must enter negative/positive value. Scale is upto 4 decimal place. 0 is allowed
  public static positiveNegativeZeroWithDotComma:RegExp = /^-?(?!0*[.,]0*$|[.,]0*$)\d+[,.]?\d{0,4}$/;
}
