<div class="main">
  <kendo-card width="500px">
    <kendo-card-body>
      <h1  class="title">Welcome to</h1>
      <div class="logoImg"></div>
      <div class="sub-title">Sawmill Optimization</div>
      <p class="card-content">A comprehensive planning and calculation solution for sawmills and wood processing industries, Optinex allows you to create a model of your entire organisation – from raw materials to the market. Based on this model, an optimised plan is prepared to maximise the total contribution margin.
      </p>
    </kendo-card-body>
    <kendo-card-actions>
      <button
        mat-raised-button
        (click)="login()"
        cdkFocusInitial
        color="primary"
        *ngIf="!isUserLoggedIn">
        Login
      </button>
    </kendo-card-actions>
  </kendo-card>
</div>
