import { RouterModule, Routes } from '@angular/router';

import { ApplicationKey } from '@app/core/models/application.constant';
import { AuthGuard } from './core/services/auth-service/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { LoginRedirectComponent } from './core/components/login-redirect/login-redirect.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { UserProfileComponent } from './modules/app-controls/user-profile/user-profile.component';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';

const appRoutes: Routes = [
  { path: 'profile', component: UserProfileComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'state', component: LoginRedirectComponent },
  { path: 'error', component: LoginRedirectComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'aot', loadChildren: () => import('./modules/aot/aot.module').then((m) => m.AotModule) },
  { path: 'model', loadChildren: () => import('./modules/model/model.module').then((m) => m.ModelModule) },
  { path: 'clt', loadChildren: () => import('./modules/clt/clt.module').then((m) => m.CLTModule) },
  { path: 'stock', loadChildren: () => import('./modules/stock/stock.module').then((m) => m.StockModule) },
  { path: 'tactical', loadChildren: () => import('./modules/tactical/tactical.module').then((m) => m.TacticalModule) },

  {
    path: 'saw-pattern',
    loadChildren: () => import('./modules/saw-pattern/saw-pattern.module').then((m) => m.SawPatternModule)
  },
  { path: 'report', loadChildren: () => import('./modules/reports/report.module').then((m) => m.ReportModule) },
  {
    path: '', component: HomeComponent, canActivate: [MsalGuard, AuthGuard], data: {
      [ApplicationKey.ExpectedRoles]: [
        UserRoleEnum.Operator
      ]
    }

  }
];

@NgModule({
  providers: [MsalGuard, AuthGuard],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        initialNavigation: 'enabledNonBlocking',
        enableTracing: false
      }
      // { useHash: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
