import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviationService } from '@app/core/services/http-services/operative/deviation.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ShiftService } from '@app/core/services/http-services/operative/shift.service';
import { IDeviationDTO } from '@app/core/models/deviation.model';
import { IShiftDTO } from '@app/core/models/shift';
import { IShiftDeviationDTO } from '@app/core/models/shift-deviation.model';
import { SiteService } from '@app/core/services/http-services/app/site.service';
import { CreateDeviationForm } from '@app/core/models/forms/operative/deviation/deviation-form.model';
import { Guid } from 'guid-typescript';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

const createDeviationForm = (d: IDeviationDTO, shifts: IShiftDTO[]) => new FormGroup<CreateDeviationForm>({
  deviationId: new FormControl<number>(d.deviationId),
  code: new FormControl<string>(d.code, [Validators.required]),
  description: new FormControl<string>(d.description),
  startDate: new FormControl<Date>(d.startDate, Validators.required),
  endDate: new FormControl<Date>(d.endDate, Validators.required),
  duration: new FormControl<number>(d.duration, Validators.required),
  shifts: new FormControl<IShiftDTO[]>(shifts, Validators.required),
  siteId: new FormControl<Guid>(d.siteId, [Validators.required]),
});

@Component({
  selector: 'app-deviation',
  templateUrl: './deviation.component.html',
  styleUrls: ['./deviation.component.css']
})
export class DeviationComponent implements OnInit {
  constructor(private deviationService: DeviationService
    , private appNotificationService: AppNotificationService
    , private shiftService: ShiftService
    , private siteService: SiteService
    , private localStorageService: LocalStorageService) { }

  public gridDeviationData: GridDataResult;
  public loader: boolean = false;
  public deviationForm: FormGroup;
  public shiftData: IShiftDTO[] = [];
  public deleteDeviationId: number = undefined;

  private editedRowIndex: number;

  @ViewChild('deviationEditorGrid')
  private deviationEditorGrid: GridComponent;

  public gridState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  ngOnInit(): void {
    this.loadShifts();
    this.loadData();
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(this.deviationEditorGrid);

    const shifts: IShiftDTO[] = [];
    dataItem.shiftDeviation.forEach(x => {
      shifts.push(<IShiftDTO>{ shiftId: x.shiftId, name: x.shiftName });
    });
    this.deviationForm = createDeviationForm(dataItem, shifts);
    this.loadShifts();
    sender.editRow(rowIndex, this.deviationForm);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public async saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): Promise<void> {
    const siteName = await this.siteService.query(formGroup.value.siteId);
    if (this.validate(formGroup.value)) {
      const deviation = <IDeviationDTO>{
        deviationId: formGroup.value.deviationId,
        startDate: formGroup.value.startDate,
        endDate: formGroup.value.endDate,
        duration: formGroup.value.duration,
        description: formGroup.value.description,
        code: formGroup.value.code,
        siteId: formGroup.value.siteId,
        shiftDeviation: []
      };

      formGroup.value.shifts.forEach(x => {
        deviation.shiftDeviation.push(<IShiftDeviationDTO>{ shiftId: x.shiftId, shiftName: x.name });
      });

      if (isNew) {
        this.deviationService.save(deviation)
          .subscribe(() => {
            this.loadData();
            sender.closeRow(rowIndex);
            this.appNotificationService.notifySucsessAppChanel('Operative.DeviationCreatedSuccessfully');
          });
      } else {
        this.deviationService.update(deviation)
          .subscribe(() => {
            dataItem.deviationId = deviation.deviationId;
            dataItem.startDate = deviation.startDate;
            dataItem.endDate = deviation.endDate;
            dataItem.duration = deviation.duration;
            dataItem.shiftDeviation = deviation.shiftDeviation;
            dataItem.description = deviation.description;
            dataItem.code = deviation.code;
            dataItem.siteId = deviation.siteId;
            dataItem.siteName = siteName;
            sender.closeRow(rowIndex);
            this.appNotificationService.notifySucsessAppChanel('Operative.DeviationUpdatedSuccessfully');
          });
      }
    }
  }

  public removeHandler({ dataItem, isNew, rowIndex }): void {
    this.deleteDeviationId = dataItem.deviationId;
  }

  public addDeviationRow() {
    this.closeEditor(this.deviationEditorGrid);
    const newDeviation = <IDeviationDTO>{
      deviationId: 0,
      startDate: null,
      endDate: null,
      duration: undefined,
      code: '',
      siteId: null
    };
    this.deviationForm = createDeviationForm(newDeviation, null);
    this.deviationEditorGrid.addRow(this.deviationForm);
    this.shiftData = [];
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.deviationForm = undefined;
  }

  public dataStateChange(state: State) {
    this.gridState = state;
    this.loadData();
  }

  public onDateTimeChange(d: any, type: string) {
    let startDateTime = this.deviationForm.get('startDate').value;
    let endDateTime = this.deviationForm.get('endDate').value;

    if (startDateTime !== null && endDateTime !== null) {
      startDateTime = new Date(startDateTime);
      endDateTime = new Date(endDateTime);

      if (endDateTime <= startDateTime) {
        this.deviationForm.patchValue({
          duration: undefined
        });
        this.appNotificationService.notifyErrorAppChanel('Operative.DeviationEndDateCanNotLessThenStartDate');
        return;
      }

      this.deviationForm.patchValue({
        duration: Math.floor((Math.abs(endDateTime - startDateTime) / 1000) / 60)
      });
    }
  }

  public async loadData() {
    this.loader = true;
    this.deviationService.getDeviation(this.gridState)
      .subscribe({
        next: async (d) => {
          this.gridDeviationData = await this.siteService.mapSiteIdsToNames(d);
          this.loader = false;
        },
        error: (error:any) => {
          this.appNotificationService.notifyErrorAppChanel(error.message);
          this.loader = false;
        }
      });
  }

  public getShiftsName(shiftDeviations: IShiftDeviationDTO[]) {
    return shiftDeviations.length === 0 ? '' : shiftDeviations.map(a => a.shiftName).join(',');
  }

  private loadShifts() {
    let selectedSiteId = this.localStorageService.getItem(LocalStorageService.selectedSiteId);
    if (this.deviationForm) {
      selectedSiteId = this.deviationForm?.get('siteId').value;
    }

    this.shiftService.query()
      .subscribe(d => {
        this.shiftData = selectedSiteId ? d.data.filter(x => x.siteId === selectedSiteId) : d.data;
      });
  }

  private validate(deviation: any) {
    if (deviation.startDate === null) {
      this.appNotificationService.notifyErrorAppChanel('App.StartDateCanNotBeBlank');
      return false;
    }

    if (deviation.endDate === null) {
      this.appNotificationService.notifyErrorAppChanel('App.EndDateCanNotBeBlank');
      return false;
    }

    if (deviation.duration <= 0) {
      this.appNotificationService.notifyErrorAppChanel('Operative.DeviationDurationCanNotBeLessThenZero');
      return false;
    }

    if (deviation.shifts === undefined || deviation.shifts.length === 0) {
      this.appNotificationService.notifyErrorAppChanel('Operative.ShiftDeviationCanNotBeNull');
      return false;
    }

    return true;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      this.deviationService.delete(this.deleteDeviationId)
        .subscribe(d => {
          if (d) {
            this.appNotificationService.notifySucsessAppChanel('Operative.DeviationDeletedSuccessfully');
            this.loadData();
          } else {
            this.appNotificationService.notifyErrorAppChanel('Operative.ErrorWhileDeletingDeviation');
          }
        },
          err => {
            this.appNotificationService.notifyErrorAppChanel(err.message);
          });
    }
    this.deleteDeviationId = undefined;
  }

  onSiteSelected(){
    this.deviationForm.get('shifts').patchValue('');
    this.loadShifts();
  }
}
