import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { ProdOrderCalendarNgComponent } from '@app/modules/operative/prod-order-calendar-ng/prod-order-calendar-ng.component';

import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { ApplicationKey } from '@app/core/models/application.constant';
import { DirtyCheckGuard } from '@app/core/services/custom-services/dirty-check.guard';
import { DeviationComponent } from '@app/modules/operative/deviation/deviation.component';
import { InstructionLineComponent } from '@app/modules/operative/instruction-line/instruction-line.component';
import { InstructionComponent } from '@app/modules/operative/instruction/instruction.component';
import { MachineGroupComponent } from '@app/modules/operative/machine-group/machine-group.component';
import { MachineComponent } from '@app/modules/operative/machine/machine.component';
import { ProdOrderDetailsComponent } from '@app/modules/operative/prod-order-details/prod-order-details.component';
import { ProdOrderShedulerNgComponent } from '@app/modules/operative/prod-order-sheduler-ng/prod-order-sheduler-ng.component';
import { ProdOrderComponent } from '@app/modules/operative/prod-order/prod-order.component';
import { ScheduleComponent } from '@app/modules/operative/schedule/schedule.component';
import { ShiftComponent } from '@app/modules/operative/shift/shift.component';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';

const routes: Routes = [
  {
    path: 'prod-order',
    component: ProdOrderComponent,
    canActivate: [AuthGuard],
    data: {
      [ApplicationKey.ExpectedRoles]: [
        UserRoleEnum.Operator
      ]
    }
  },
  {
    path: 'prod-order-schedule-ng',
    component: ProdOrderShedulerNgComponent,
    canActivate: [AuthGuard],
    data: {
      [ApplicationKey.ExpectedRoles]: [
        UserRoleEnum.Operator
      ]
    }
  },
  {
    path: 'prod-order-calendar-ng',
    component: ProdOrderCalendarNgComponent,
    canActivate: [AuthGuard],
    data: {
      [ApplicationKey.ExpectedRoles]: [
        UserRoleEnum.Operator
      ]
    }
  },
  {
    path: 'prod-order/:id',
    component: ProdOrderDetailsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      isNew: false,
      [ApplicationKey.ExpectedRoles]: [
        UserRoleEnum.Operator
      ]
    }
  },
  { path: 'prod-order-copy/:id', component: ProdOrderDetailsComponent, canActivate: [AuthGuard], canDeactivate: [DirtyCheckGuard], data: { isCopy: true } },
  {
    path: 'prod-order-new',
    component: ProdOrderDetailsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      isNew: true
    }
  },
  { path: 'machine', component: MachineComponent, canActivate: [AuthGuard] },
  { path: 'machine-group', component: MachineGroupComponent, canActivate: [AuthGuard] },
  { path: 'instruction', component: InstructionComponent, canActivate: [AuthGuard] },
  { path: 'instruction-line', component: InstructionLineComponent, canActivate: [AuthGuard] },
  { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard] },
  { path: 'schedule-ng', component: ProdOrderShedulerNgComponent, canActivate: [AuthGuard] },
  { path: 'shift', component: ShiftComponent, canActivate: [AuthGuard] },
  { path: 'deviation', component: DeviationComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperativeRoutingModule { }
