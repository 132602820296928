<section>
    <kendo-grid [data]="prodOrderLines" [loading]="(prodOrderLines.length === 0)"     [size]="'small'">
        <kendo-grid-column  title="{{ 'Operative.Line' | translate }}"  [width]="15" [editable]= "false">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-isNew="isNew" >
              {{ isNew ? (prodOrderLines?.length + 1) : (rowIndex + 1) }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="mainReportingLine" title="{{ 'Operative.Main' | translate }}"  [width]="20">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.mainReportingLine ? ('App.Yes' | translate) : ""}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="material.description" title="{{ 'Operative.Material' | translate }}" [width]="50">
        </kendo-grid-column>

        <kendo-grid-column field="activityCode" title="{{ 'Operative.Activity' | translate }}"  [width]="50">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{getActivityText(dataItem)}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="materialRelationSum" title="{{ 'Operative.Relation' | translate }}" [width]="30">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{  dataItem.materialRelationSum == 0  ? ('App.NA' | translate) :  dataItem.materialRelationSum }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="planedQtyUOM" title="{{ 'Operative.UnitCode' | translate }}"  [width]="40">
        </kendo-grid-column>

        <kendo-grid-column field="directionText" title="{{ 'Operative.Direction' | translate }}"  [width]="40">
        </kendo-grid-column>

        <kendo-grid-column field="stockLocationName" title="{{ 'App.StockLocation' | translate }}"  [width]="40">
        </kendo-grid-column>

        <kendo-grid-column field="planedQty" title="{{ 'App.Quantity' | translate }}"  [width]="20" [style]="{'text-align': 'right'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.planedQty | number : '1.3'}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="totalReportedQty" title="{{ 'Operative.ReportedQty' | translate }}" [width]="20" [style]="{'text-align': 'right'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.totalReportedQty | number : '1.3'}}
            </ng-template>
        </kendo-grid-column>

    </kendo-grid>
  </section>
