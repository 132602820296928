import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor
} from '@angular/common/http';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Observable } from 'rxjs';

export class OverrideTenantInterceptor implements HttpInterceptor {
  private tennatHeader:string = 'optinex-tenant-override';

  constructor(private localStorage:LocalStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const o = this.localStorage.getItem(LocalStorageService.tennatKey);

    if (o !== null && o !== '') {
      const authReq = request.clone({
        headers: request.headers.set(this.tennatHeader, o)
      });
      return next.handle(authReq);
    }

    return next.handle(request);
  }
}
