import { Component, Input } from '@angular/core';
import { IProdOrderLinkedToGluesetDTO } from '@app/core/models/prod-order';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';

@Component({
  selector: 'app-glueset-sequence-display',
  templateUrl: './glueset-sequence-display.component.html',
  styleUrls: ['./glueset-sequence-display.component.css']
})
export class GluesetSequenceDisplayComponent {
  public showPopup = false;

  @Input() gluesetID: number;
  @Input() badge: boolean = false;

  loading:boolean = false;

  public gluesetSequenceReturn: IProdOrderLinkedToGluesetDTO[] = [];

  constructor(private readonly poService: ProdOrderService) { }

  public onToggle(): void {
    this.loading = true;
    if (this.gluesetID > 0) {
      this.poService.getProdOrdersLinkedToGlueset(this.gluesetID).subscribe((gluesetSequenceReturn) => {
        if (gluesetSequenceReturn) {
          this.gluesetSequenceReturn = gluesetSequenceReturn;
          this.showPopup = !this.showPopup;
        }
        this.loading = false;
      });
    }
  }
}
