import { Component, Output, EventEmitter, Inject, AfterViewInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { Observable, timer, Subscription, Subject, takeUntil } from 'rxjs';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepType } from '@progress/kendo-angular-layout';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IOptimizerCustomSetting, OptiInputModel, OptimizationOrder, OptimizationResponce, OptimizationType } from '@app/core/models/optimization-order.model';
import { WatsonDeployedModel } from '@app/core/models/watson-deployed.model';
import { WatsonRunStatus, WatsonSolveStatus, WatsonStatus } from '@app/core/models/watson-status';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { IUserSettingDTO } from '@app/core/models/userSettingDTO';
import { OptimazationService } from '@app/core/services/http-services/gluelam/optimazation.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { AdminService } from '@app/core/services/http-services/admin/admin.service';
import { RunJobService } from '@app/core/services/http-services/run-job/run-job.service';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { WatsonDataSetEnum } from '@app/core/models/watson-dataset.enum';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import {  DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { CreateOptimizationOrderForm } from '@app/core/models/forms/gluelam/optimization-order/create-optimization-order-form.model';

marker('App.SaveSettingMsg');

@Component({
  selector: 'optimazation-order',
  templateUrl: './optimazation-order.component.html',
  styleUrls: ['./optimazation-order.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class OptimazationOrderEditor extends DialogContentBase implements AfterViewInit, OnDestroy  {

  public form: FormGroup = new FormGroup<CreateOptimizationOrderForm>({
    beamLamminaDimensionID: new FormControl<number>(0, [Validators.required]),
    maxLength: new FormControl<number>(0, [Validators.required]),
    minLenght: new FormControl<number>(0, [Validators.required]),
    minHight: new FormControl<number>(0, [Validators.required]),
    maxHight: new FormControl<number>(0, [Validators.required]),
    weightFactorValue: new FormControl<number>(0, [Validators.required]),
    weightFactorAreaWaste: new FormControl<number>(0, [Validators.required]),
    weightFactorHeightWaste: new FormControl<number>(0, [Validators.required]),
    weightFactorPerformedTimes: new FormControl<number>(0, [Validators.required]),
    weightFactorFewOrdersPerBatch: new FormControl<number>(0, [Validators.required]),
    numberOfGlueSetBatches: new FormControl<number>(0, [Validators.required]),
    maxOrdersPerGlueSetBatch: new FormControl<number>(0, [Validators.required]),
    maxBatchesPerOrder: new FormControl<number>(0, [Validators.required]),
    deployedModelFile: new FormControl<string>('', []),
    deployedModelId: new FormControl<string>('', [Validators.required]),
    numberOfGlusets: new FormControl<number>(0, [Validators.required]),
    useTwoLayers: new FormControl<number>(0, [Validators.required]),
    maxSurgestions: new FormControl<number>(0, [Validators.required]),
    maxSolveTime: new FormControl<number>(0, [Validators.required])
  });

   optimizationTypes: OptimizationType[] = [];
   watsonDeployedModels: WatsonDeployedModel[] = [];
   preActiveCounter = 0;
   optiActiveCounter = 0;
   getResultCounter = 0;
   elapsedSec: number;
   jobState: WatsonStatus;
   JobUrl: string;
   NumberOfSurgestins: number[] = [1, 2, 5, 10, 100];
    min = 0;
    max = 10;
    step = 1;
     title = (value: number): string => {
      return `${this.numbers[value]}`;
    };
     stepType: StepType = 'full';
     current = 0;
     paramStepLable = 'Set Params';
     createStepLable = 'Crate Job';
     optiStepLable = 'Run Optimization';
     resultStepLable = 'Get Result';
     noResStepLable = 'No Res';

  private numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private pollCounter: Observable<number>;
  private timeCounter: Observable<number>;
  private counterSub: Subscription;
  private timeCounterSub: Subscription;
  private optimizerSetting: IOptimizerCustomSetting[] = [];
  private settingsId = UserSettingkeyEnum.inputForm_glulam_glulamOptimizer;
  private settings: IUserSettingDTO;
  private optimizerCustomSetting: IOptimizerCustomSetting[] = [];
  private destroy$: Subject<void> = new Subject<void>();

  @Output() optimizationResult = new EventEmitter<OptimizationResponce>();

  constructor(
    private configService: ConfigurationService,
    private optimazationService: OptimazationService,
    private gluingPlanService: GluePlanService,
    private dialogRef: DialogRef,
    private adminService: AdminService,
    private notificationService: AppNotificationService,
    @Inject(DialogRef) public input: OptiInputModel,
    private runJobService: RunJobService,
    private userSettingService: UserSettingService,
    private appNotificationService: AppNotificationService
  ) {
    super(dialogRef)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  override ngAfterViewInit(): void {
    this.pollCounter = timer(0, 3000); // polling
    this.timeCounter = timer(0, 100); // progres
    this.getUserSettings();
  }

  setDefaultValues() {
    this.getDeployedModels(null);
    this.configService.load().then(() => {
      let useTwoLayers = false;

      if (
        this.input.BeamLamminaDimension &&
        this.input.BeamLamminaDimension.width < this.configService.BEAM_MAX_WIDTH_FOR_TWO_LAYERS
      ) {
        useTwoLayers = true;
      }

      this.form.patchValue({
        beamLamminaDimensionID: this.input.BeamLamminaDimension.beamLamminaDimensionID,
        maxLength: this.configService.PRESS_LENGTH_MAX,
        minLenght: this.configService.PRESS_LENGTH_MIN,
        minHight: this.configService.PRESS_HIGHT_MIN,
        maxHight: this.configService.PRESS_HIGHT_MAX,
        weightFactorValue: this.configService.BEAM_WEIGHT_FACTOR_VALUE * 10,
        weightFactorAreaWaste: this.configService.BEAM_WEIGHT_FACTOR_AREA * 10,
        weightFactorHeightWaste: this.configService.BEAM_WEIGHT_FACTOR_HIGHT * 10,
        weightFactorPerformedTimes: this.configService.BEAM_WEIGHT_FACTOR_PT * 10,
        weightFactorFewOrdersPerBatch: this.configService.BEAM_WEIGHT_FACTOR_ORDERS_BATCH * 10,
        numberOfGlueSetBatches: this.configService.BEAM_MAX_NUMBER_OF_GLUSET_BATCHES,
        maxOrdersPerGlueSetBatch: this.configService.BEAM_MAX_ORDER_PER_GLUSET_BATCHE,
        maxBatchesPerOrder: this.configService.BEAM_MAX_GLUSET_BATCH_PER_ORDER,
        deployedModelFile: this.configService.WATSON_MODEL_FILE,
        deployedModelId: this.configService.WATSON_MODEL_FILE_ID,
        numberOfGlusets: this.configService.BEAM_MAX_GLUESETS_SURGESTIONS,
        useTwoLayers,
        maxSurgestions: this.configService.BEAM_MAX_GLUESETS_SURGESTIONS,
        maxSolveTime: this.configService.BEAM_MAX_SOLVE_TIME
      });
    });
  }

  runOptimazation() {
    this.form.disable();

    const selectedLamina = this.gluingPlanService._selectedPlan.dimension;
    if (this.input.BeamOrderLines.some((x) => x.BeamLamminaDimensionID !== selectedLamina.beamLamminaDimensionID)) {
      const error = { text: 'GlueSet.BM_OF_DIFFERENT_DIM', values: { bm: selectedLamina.width } };
      this.notificationService.notifyErrorAppChanel(error.text, 'GlueSet.BMGS_Edit_Error', error.values);
      return;
    }

    console.log('Run Oprimazation Clicked');

    this.current = 1;

    this.StartTimeCounter();

    const oo = this.getOpt();

    if (oo.demands.length > 0) {
      const toLongRows: string[] = [];

      if (toLongRows.length > 0) {
        this.showWarning(`Dessa rader är kortare än max längd${toLongRows.join(',')}`);
      }

      this.ShowInfo('Optimization started');

      this.optimazationService.newOptimazationStart(oo, null).pipe(takeUntil(this.destroy$)).subscribe((ret) => {
        this.JobUrl = ret.jobId;
        this.jobState = ret;
        this.StartJobPole();
        this.PollState();
      });
    } else {
      this.showError('Inga Rader Valda, Välj Rader!');
    }
  }

  saveSettings() {
    const settings = this.setInputSettings();
    this.convertSettingsToDTO(settings);
    this.saveOrUpdateUserSetting();
  }

  close() {
    this.StopCounters();
    this.dialogRef.close();
  }

  canRemoveOrder(): boolean {
    // dont allow to remove last order
    return this.input.BeamOrderLines.length > 1;
  }

  removeOrder(order: any) {
    const thisPointer = this;
    thisPointer.input.BeamOrderLines = thisPointer.input.BeamOrderLines.filter(
      (bol) => bol.BeamOrderRowID !== order.BeamOrderRowID
    );
  }

  optiSteps = [
    { label: this.paramStepLable, icon: '', isValid: true, time: 10, state: null, solveStatus: null },
    { label: this.createStepLable, icon: '', isValid: true },
    { label: this.optiStepLable, isValid: true },
    { label: this.noResStepLable, isValid: false, optional: true },
    { label: this.resultStepLable, isValid: true }
  ];

  onDataLoadFailed(error) {
    console.log(error);
  }

 private setCustomUserSettings() {
    this.configService.load().then(() => {
      let useTwoLayers = false;

      if (
        this.input.BeamLamminaDimension &&
        this.input.BeamLamminaDimension.width < this.configService.BEAM_MAX_WIDTH_FOR_TWO_LAYERS
      ) {
        useTwoLayers = true;
      }

      const deployedModelId = this.optimizerCustomSetting.find((x) => x.InputName === 'deployedModelId');
      this.getDeployedModels(deployedModelId !== undefined ? deployedModelId.InputValue : null);

      this.form.patchValue({
        beamLamminaDimensionID: this.input.BeamLamminaDimension.beamLamminaDimensionID,
        maxLength: this.getInputValueFromKey('maxLength', this.configService.PRESS_LENGTH_MAX),
        minLenght: this.getInputValueFromKey('minLenght', this.configService.PRESS_LENGTH_MIN),
        minHight: this.getInputValueFromKey('minHight', this.configService.PRESS_HIGHT_MIN),
        maxHight: this.getInputValueFromKey('maxHight', this.configService.PRESS_HIGHT_MAX),
        weightFactorValue: this.getInputValueFromKey('weightFactorValue', (this.configService.BEAM_WEIGHT_FACTOR_VALUE * 10)),
        weightFactorAreaWaste: this.getInputValueFromKey('weightFactorAreaWaste', (this.configService.BEAM_WEIGHT_FACTOR_AREA * 10)),
        weightFactorHeightWaste: this.getInputValueFromKey('weightFactorHeightWaste', (this.configService.BEAM_WEIGHT_FACTOR_HIGHT * 10)),
        weightFactorPerformedTimes: this.getInputValueFromKey('weightFactorPerformedTimes', (this.configService.BEAM_WEIGHT_FACTOR_PT * 10)),
        weightFactorFewOrdersPerBatch: this.getInputValueFromKey('weightFactorFewOrdersPerBatch', (this.configService.BEAM_WEIGHT_FACTOR_ORDERS_BATCH * 10)),
        numberOfGlueSetBatches: this.getInputValueFromKey('numberOfGlueSetBatches', this.configService.BEAM_MAX_NUMBER_OF_GLUSET_BATCHES),
        maxOrdersPerGlueSetBatch: this.getInputValueFromKey('maxOrdersPerGlueSetBatch', this.configService.BEAM_MAX_ORDER_PER_GLUSET_BATCHE),
        maxBatchesPerOrder: this.getInputValueFromKey('maxBatchesPerOrder', this.configService.BEAM_MAX_GLUSET_BATCH_PER_ORDER),
        numberOfGlusets: this.getInputValueFromKey('numberOfGlusets', this.configService.BEAM_MAX_GLUESETS_SURGESTIONS),
        useTwoLayers,
        maxSurgestions: this.getInputValueFromKey('maxSurgestions', this.configService.BEAM_MAX_GLUESETS_SURGESTIONS),
        maxSolveTime: this.getInputValueFromKey('maxSolveTime', this.configService.BEAM_MAX_SOLVE_TIME)
      });
    });
  }

 private getOpt(): OptimizationOrder {
    const o = new OptimizationOrder(
      this.form.value.beamLamminaDimensionID,
      this.form.value.maxLength,
      this.form.value.minLenght,
      this.form.value.minHight,
      this.form.value.maxHight,
      this.form.value.weightFactorValue / 10,
      this.form.value.weightFactorAreaWaste / 10,
      this.form.value.weightFactorHeightWaste / 10,
      this.form.value.weightFactorPerformedTimes / 10,
      this.form.value.weightFactorFewOrdersPerBatch / 10,
      this.form.value.numberOfGlueSetBatches,
      this.form.value.maxOrdersPerGlueSetBatch,
      this.form.value.maxBatchesPerOrder,
      this.form.value.deployedModelFile,
      this.form.value.deployedModelId,
      this.form.value.numberOfGlusets,
      this.form.value.useTwoLayers,
      this.form.value.maxSurgestions,
      this.form.value.maxSolveTime
    );

    o.demands = this.input.BeamOrderLines;
    o.demands.forEach((x) => {
      x.qty =
        x.transportPackageAllocation?.qty + x.transportPackageAllocation?.unitsExtra - (x.QtySumUI + x.QtyPlanned);
    });

    return o;
  }

  private StartTimeCounter() {
    this.timeCounterSub = this.timeCounter.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      this.elapsedSec = t / 10;

      if (this.current === 1) {
        this.preActiveCounter = this.elapsedSec;
        this.optiSteps[1].time = Math.floor(this.preActiveCounter);
      }
      if (this.current === 2) {
        this.optiActiveCounter = this.elapsedSec - this.preActiveCounter;
        this.optiSteps[2].time = Math.floor(this.optiActiveCounter);
      }
      if (this.current === 4) {
        this.getResultCounter = this.elapsedSec - this.preActiveCounter - this.optiActiveCounter;
        this.optiSteps[2].time = Math.floor(this.getResultCounter);
      }
    });
  }

 private StartJobPole() {
    this.counterSub = this.pollCounter.pipe(takeUntil(this.destroy$)).subscribe((n) => {
      if (this.JobUrl) {
        this.PollState();
      }
    });
  }

 private getResult() {
    this.optimazationService.getOptimazationResult(this.getOpt(), this.JobUrl).pipe(takeUntil(this.destroy$)).subscribe((r) => {
      if (r.errorMessage !== '') {
        this.ShowInfo(`Job Completed, total runtime ${this.elapsedSec}`);
        this.dialogRef.close(r);
      } else {
        this.showError(r.errorMessage);
      }
    });
  }

  private PollState() {
    this.runJobService.PollState(this.JobUrl).pipe(takeUntil(this.destroy$)).subscribe(
      (d) => {
        const [s, state] = d;
        this.jobState = s;
        console.warn(s.status);
        switch (state) {
          case -1:
            break;
          case 1:
            this.current = 1;
            break;
          case 2:
            this.current = 2;
            break;
          case 3:
            this.StopCounters();
            this.current = 3;
            this.optiSteps[3].state = `${s.status} ${s.solveStatus}`;
            break;
          case 4:
            this.current = 4;
            this.optiSteps[4].state = s.status + s.solveStatus;
            this.getResult();
            this.StopCounters();
            break;
          case 5:
            this.current = 3;
            this.optiSteps[3].state = s.status + s.solveStatus;
            this.StopCounters();
            break;
        }
      },
      (e) => {
        this.StopCounters();
        this.current = 3;
        this.jobState.status = WatsonRunStatus.appError;
        this.jobState.solveStatus = WatsonSolveStatus.appError;
      }
    );
  }


  private StopCounters() {
    if (this.counterSub)
    this.counterSub.unsubscribe();
    if (this.timeCounterSub)
    this.timeCounterSub.unsubscribe();
  }



  private showWarning(msg: string) {
    // this._snackBar.open(msg);
  }

  private ShowInfo(msg: string) {
    // this._snackBar.open(msg);
  }

  private showError(msg: string) {
    // this._snackBar.open(msg);
  }



  private getDeployedModels(customDeployedModelId: string) {
    this.adminService.getWatsonDeployments(WatsonDataSetEnum.Glulam).pipe(takeUntil(this.destroy$)).subscribe((d) => {
      this.watsonDeployedModels = d;
      const deployedModelId = customDeployedModelId ?? this.watsonDeployedModels[0].Id;
      this.form.get('deployedModelId').setValue(deployedModelId);
    });
  }

  private setInputSettings() {
    const settings = this.optimizerSetting
      .map((item) => item)
      .map((item) => ({
        InputName: item.InputName,
        InputValue: item.InputValue
      }));
    Object.entries(this.form.value).forEach(element => {
      if (element[0] !== 'beamLamminaDimensionID') {
        settings.push({ InputName: element[0], InputValue: CommonHelper.isNumber(element[1]) ? Number(element[1]) : element[1] });
      }
    });
    return settings;
  }

  private convertSettingsToDTO(newSettings: IOptimizerCustomSetting[]): void {
    this.settings = {
      key: this.settingsId,
      value: JSON.stringify(newSettings)
    };
  }

  private saveOrUpdateUserSetting(): void {
    this.userSettingService.saveUserSetting(this.settings).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.appNotificationService.notifySucsessAppChanel('App.SaveSettingMsg');
      }
    });
  }

 private getUserSettings(): void {
    this.userSettingService.getUserSetting(this.settingsId).pipe(takeUntil(this.destroy$)).subscribe((settings) => {
      if (settings) {
        this.optimizerCustomSetting = settings;
        this.setCustomUserSettings();
      } else {
        this.setDefaultValues();
      }
    });
  }

 private getInputValueFromKey(key :string, originalValue:any) {
    const optimizerSetting = this.optimizerCustomSetting.find(x => x.InputName === key);
    return (optimizerSetting !== undefined) ? optimizerSetting.InputValue : originalValue;
  }
}
