import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderStateDTO } from '@app/core/models/prod-order';
import { IProdOrderBatchDTO } from '@app/core/models/prod-order-batch.model';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { ProdOrderCustomService } from '@app/core/services/custom-services/prod-order-custom.service';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { CreateProdOrderBulkEditInstructionForm, CreateProdOrderBulkEditStartDateForm, CreateProdOrderBulkEditStateForm } from '@app/core/models/forms/operative/prod-order/prod-order-bulk-edit/create-prod-order-bulk-edit-form.model';

marker('Operative.SelectState');
marker('Operative.UpdateSuccessMessage');
marker('Operative.ProdOrderStateUpdateErrorMsg');
marker('Operative.ProdOrdersStateUpdate');
marker('Operative.UpdateProdOrderErrMsg');
marker('Operative.ProdOrdersDeletedSuccessfully');
marker('Operative.ErrorWhileDeletingProdOrder');
marker('Operative.ProdOrderBulkDeleteErrorMsg');

@Component({
  selector: 'app-prodorder-bulk-edit',
  templateUrl: './prodorder-bulk-edit.component.html',
  styleUrls: ['./prodorder-bulk-edit.component.css']
})
export class ProdorderBulkEditComponent extends DialogContentBase implements OnInit {
  enableSubmitButton: boolean = true;
  defaultSelectedStateIds: number[];
  prodOrderStatesData: IProdOrderStateDTO[] = [];
  runnign: boolean = false;
  changeStateBackToReleaseMsg:string;
  confirmStateUpdate = false;
  oldPOState:number;

  conditionalRequired: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const change = control.get('change');
    const val = control.get('val');

    if (change.value === true && (val.value == null || val.value === '')) {
      return { required: true };
    }

    return null;
  };

  anyChange: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const stateChange = control.get('state').get('change');
    const instructionChange = control.get('instruction').get('change');
    const startdate = control.get('startdate').get('change');

    if (stateChange.value === false && instructionChange.value === false && startdate.value === false) {
      return { required: true };
    }

    return null;
  };

  updateProdOrderForm: FormGroup = new FormGroup({
    state: new FormGroup<CreateProdOrderBulkEditStateForm>({
      change: new FormControl<boolean>(false),
      val: new FormControl<number|null>(null)
    }, this.conditionalRequired),

    instruction: new FormGroup<CreateProdOrderBulkEditInstructionForm>({
      change: new FormControl<boolean>(false),
      val: new FormControl<string|null>(null)
    }, this.conditionalRequired),

    startdate: new FormGroup<CreateProdOrderBulkEditStartDateForm>({
      change: new FormControl<boolean>(false),
      val: new FormControl<Date|null>(null)
    }, this.conditionalRequired)

  }, this.anyChange);

  constructor(
    @Inject(DialogRef) public data: { prodOrderIds: number[] },
    public prodOrderService: ProdOrderService,
    public beamOrderRowService: DeamndService,
    public spinner: NgxSpinnerService,
    private dialogRef: DialogRef,
    private appNotificationService: AppNotificationService,
    private prodOrderCustomService: ProdOrderCustomService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.updateProdOrderForm.get('state').get('val').valueChanges.subscribe(poStateId => {
      if (poStateId) {
        this.checkProdorderState(poStateId);
        this.updateProdOrderForm.get('state').get('change').setValue(true);
      } else {
        this.updateProdOrderForm.get('state').get('change').setValue(false);
      }
    });

    this.updateProdOrderForm.get('instruction').get('val').valueChanges.subscribe(s => {
      if (s != null && s !== '') {
        this.updateProdOrderForm.get('instruction').get('change').setValue(true);
      } else {
        this.updateProdOrderForm.get('instruction').get('change').setValue(false);
      }
    });

    this.updateProdOrderForm.get('startdate').get('val').valueChanges.subscribe(s => {
      if (s) {
        this.updateProdOrderForm.get('startdate').get('change').setValue(true);
      } else {
        this.updateProdOrderForm.get('startdate').get('change').setValue(false);
      }
    });
  }

  save() {
    this.runnign = true;

    const dto = <IProdOrderBatchDTO> {
      prodOrderIds: this.data.prodOrderIds
    };

    const stateGroup = this.updateProdOrderForm.get('state');
    if (stateGroup.get('change').value) {
      dto.stateId = stateGroup.get('val').value;
    }

    const instructionGroup = this.updateProdOrderForm.get('instruction');
    if (instructionGroup.get('change').value) {
      dto.instruction = instructionGroup.get('val').value;
    }

    const startdateGroup = this.updateProdOrderForm.get('startdate');
    if (startdateGroup.get('change').value) {
      dto.plannedExecutionStartDate = startdateGroup.get('val').value;
    }

    this.prodOrderService.bulkUpdate(dto)
      .subscribe(
        res => this.updateProdOrderResponse(res)
        , () => this.handleResponseError('Operative.UpdateProdOrderErrMsg')
      );
  }

  close(message: boolean) {
    this.dialogRef.close(message);
  }

  private handleResponseError(_error: any) {
    this.appNotificationService.notifyErrorAppChanel(_error);
  }

  updateProdOrderResponse(res) {
    this.runnign = true;
    this.close(true);
    if (res > 0) {
      this.appNotificationService.notifyInfoAppChanel('Operative.ProdOrdersStateUpdate', 'Operative.UpdateSuccessMessage', { noOfRecords: res, noOfSelectedRecords: this.data.prodOrderIds.length });
    } else {
      this.appNotificationService.notifyErrorAppChanel('Operative.ProdOrderStateUpdateErrorMsg');
    }
  }

  confirmUpdate(isUpdate: boolean) {
    this.confirmStateUpdate = false;
    if (!isUpdate) {
      this.updateProdOrderForm.get('state').get('val').setValue(this.oldPOState);
    }
  }

  private checkProdorderState(poStateId: number) {
    this.data.prodOrderIds.forEach(prodOrderId => {
      this.prodOrderService.getProdOrder(prodOrderId).subscribe(prodOrder => {
        if (prodOrder.stateId > ProdOrderState.Released && poStateId === ProdOrderState.Released) {
          this.changeStateBackToReleaseMsg = this.prodOrderCustomService.getPOBackToReleaseMsg(prodOrder.stateId);
          this.confirmStateUpdate = true;
          this.oldPOState = prodOrder.stateId;
        }
      });
    });
  }
}
