import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { BeamMaterialGlueSet } from '../../../core/models/beam-material-glue-set.model';
import { GluingPlan } from '../../../core/models/gluing-plans.model';
import { BeamLamminaDimension } from '../../../core/models/beam-lammina-dimension.model';

@Component({
  selector: 'app-gluesets-view',
  templateUrl: './gluesets-view.component.html',
  styleUrls: ['./gluesets-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GluesetsViewUrlComponent implements OnInit {
  public glueSets: BeamMaterialGlueSet[];
  loading = true;
  glueplan: GluingPlan;
  dim:BeamLamminaDimension;

  constructor(
    private gsService: GlueSetService,
    private dimService: DimensionService,
    private route: ActivatedRoute,
    private gpService: GluePlanService,
    private conf: ConfigurationService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      console.log(p);
      this.conf.load().then(() => {
        this.gpService.getGluingPlan(p['id']).subscribe(gp => {
          this.glueplan = gp;
          this.dimService.getBeamLamminaDimention(gp.beamLaminaDimentionId).subscribe(d => {
            this.dim = d;
            this.GetSavedGlueSetsForGluingPlan(gp.gluingPlanID);
          });
        });
      });
    });

    this.conf.load();
  }

  GetSavedGlueSetsForGluingPlan(id: number) {
    this.loading = true;
    this.gsService.getGlueSetByGluingPlanId(id).subscribe((glueSets) => {
      this.glueSets = glueSets;
      this.loading = false;
    });
  }

  onBack() {
    this.router.navigate(['glulam/glueplans']);
  }
}
