import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { EntityPropertyService } from '@app/core/services/http-services/model/entity-property.service';
import { EntityRelationDirection, EntityService, IEntity, IEntityRelation, IGlulamSpec, IPropertyValue } from '@app/core/services/http-services/model/entity.service';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { FileDownloadMode } from '@app/core/models/file-download-mode-enum';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { IUnit } from '@app/core/models/unit.model';
import { IUnitConversion } from '@app/core/models/unitConversion.model';
import { GlulamSpecData } from '@app/core/models/gulam-spec.model';
import { CreateEntityForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-form.model';
import { CreatePropertyValueEntityForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-property-value-form.model';
import { CreateEntityRelationForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-relation-form.model';
import { CreateEntityUnitConversionForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-unit-conversion-form.model';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';
import { DialogRef, DialogContentBase, DialogCloseResult } from '@progress/kendo-angular-dialog';

marker('EntityTranslation.MinStockShouldbeGreaterThanZero');
marker('EntityTranslation.MaxStockShouldbeGreaterThanZero');
marker('EntityTranslation.MaxStockShouldbeGreater');
marker('EntityTranslation.MinStockNotEqualToMax');
marker('EntityTranslation.EntityRelationInvalidRelationForConsume');
marker('EntityTranslation.InstructionFileDeleted');
marker('EntityTranslation.EntityCodeLengthError');
marker('EntityTranslation.InvalidDataType');

const createFormGroup = (e: IEntity, isActivity: boolean) => new FormGroup<CreateEntityForm>({
  index: new FormControl<number>(e.index, [Validators.required]),
  code: new FormControl<string>(e.code, [Validators.required]),
  baseUnitCode: new FormControl<string>(e.baseUnitCode ?? '', [Validators.required]),
  changeDate: new FormControl<string>(e.changeDate),
  commentId: new FormControl<number>(e.commentId),
  description: new FormControl<string>(e.description, [Validators.required]),
  entityGroupCode: new FormControl<string>(e.entityGroupCode),
  isDynamicActivity: new FormControl<boolean>(e.isDynamicActivity),
  sortOrderIndex: new FormControl<number>(e.sortOrderIndex),
  status: new FormControl<number>(e.status),
  superiorEntityIndex: new FormControl<number>(e.superiorEntityIndex),
  userCode: new FormControl<string>(e.userCode),
  machineId: new FormControl<number>(e.machineId),
  propertyValues: new FormArray(e.propertyValues.map<FormGroup<CreatePropertyValueEntityForm>>(pv => createFormGroupP(pv))),
  entityRelations: new FormArray(e.entityRelations.map<FormGroup<CreateEntityRelationForm>>(enr => createEntityRelationFormGroup(enr, isActivity, isActivity ? enr.productIndex : enr.activityIndex, false))),
  unitConversions: new FormArray(e.unitConversions.map<FormGroup<CreateEntityUnitConversionForm>>(uc => createFormGroupUC(uc, false, e.unitConversions, isActivity)), validateUnitConversionArray()),
  targetMinStock: new FormControl<number>(e.targetMinStock, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
  targetMaxStock: new FormControl<number>(e.targetMaxStock, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
  batchSize: new FormControl<number>(e.batchSize, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
  guid: new FormControl<string>(e.guid)
});

const createFormGroupP = (pv: IPropertyValue) => new FormGroup<CreatePropertyValueEntityForm>({
  id: new FormControl<number>(pv.id),
  entityIndex: new FormControl<number>(pv.entityIndex),
  propertyCode: new FormControl<string>(pv.propertyCode),
  dataType: new FormControl<number>(pv.dataType),
  value: new FormControl<string>(getStringValue(pv)),
  userCode: new FormControl<string>(pv.userCode),
  changeDate: new FormControl<string>(pv.changeDate)
});

const createEntityRelationFormGroup = (enr: IEntityRelation, isActivity: boolean, entityIndex: number, isNew: boolean) => new FormGroup<CreateEntityRelationForm>({
  entityIndex: new FormControl<number>(entityIndex),
  relation: new FormControl<number>(enr.relation, [Validators.required, Validators.pattern(RegexHelper.positiveNegativeWithDotCommaWithNoScale)]),
  direction: new FormControl<EntityRelationDirection>(enr.direction),
  unitCode: new FormControl<string>(enr.unitCode),
  userCode: new FormControl<string>(enr.userCode),
  changeDate: new FormControl<string>(enr.ChangeDate),
  productCode: new FormControl<string>(enr.productCode),
  activityCode: new FormControl<string>(enr.activityCode),
  productIndex: new FormControl<number>(enr.productIndex),
  activityIndex: new FormControl<number>(enr.activityIndex),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false),
  inTactical: new FormControl<boolean>(enr.inTactical),
  inOperative: new FormControl<boolean>(enr.inOperative),
  isChaining: new FormControl<boolean>(enr.isChaining)
});

const createFormGroupUC = (uc: IUnitConversion, isNew: boolean, UnitConversionData: IUnitConversion[], isActivity: boolean) => new FormGroup<CreateEntityUnitConversionForm>({
  index: new FormControl<number>(uc.index),
  entityIndex: new FormControl<number>(uc.entityIndex),
  unitCode: new FormControl<string>(uc.unitCode, isActivity ? null : [Validators.required]),
  convFactor: new FormControl<number>(uc.convFactor, isActivity ? null : [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotCommaWithNoScaleNoZero)]),
  userCode: new FormControl<string>(uc.userCode),
  changeDate: new FormControl<string>(uc.changeDate),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false),
  id: new FormControl<number>(uc.id)
});

export const getStringValue = (pv: IPropertyValue): string => {
  if (pv.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT) {
    return pv?.valueInt?.toString();
  } else if (pv.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE) {
    return pv?.valueDouble?.toString();
  } else {
    return pv?.valueString;
  }
};

export function validateUnitConversionArray(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const notRemoved = formArray.value.filter(i => i.isRemoved === false);

    const uniqueValues = new Set(notRemoved.map(v => v.unitCode));

    if (uniqueValues.size < notRemoved.length) {
      return { error: 'Unit code already exist!!!' };
    }
    return null;
  };
};

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./../entity-editor.component.css']
})
export class EntityFormComponent extends DialogContentBase implements OnInit, OnDestroy {
  _entity: IEntity;
  _entityGroup: IEntityGroup;
  _entityRelation: IEntityRelation;
  units: IUnit[];
  entityRelationForm: FormGroup;
  disableSubmit: boolean = false;
  fileDownloadMode = FileDownloadMode;
  glulamSpecButtonDisabled: boolean = false;
  entityForm: FormGroup;
  isNew: boolean;
  isChange: boolean;
  isRemoved: boolean;
  isActivity = false;
  isEntityTypeCodeResource = false;
  isGlulamSpecEnabled = false;

  private selectedMaterialCode: string;
  private selectedDirectionText: string;
  private glulamSpecForm: FormGroup;
  private readonly destroy = new Subject<void>();

  entityRelations(): FormArray {
    return this.entityForm.get('entityRelations') as FormArray;
  }

  glulamSpecs(): FormArray {
    return this.glulamSpecForm?.get('specrows') as FormArray;
  }

  newEntityRelation(entityTypeCode): FormGroup {
    return createEntityRelationFormGroup(<IEntityRelation>{
      relation: null,
      direction: EntityRelationDirection.CONSUMES,
      unitCode: 'M3',
      userCode: null,
      inTactical: true,
      inOperative: true,
      isChaining: false
    }, entityTypeCode === 'ACTIVITY', 0, true);
  }

  newUnitConversion(): FormGroup {
    return createFormGroupUC(<IUnitConversion>{
      unitCode: 'SELECT',
      convFactor: null,
      entityIndex: this.data.entity.index,
      index: 0,
      id: 0
    }, true, this.data.entity.unitConversions, false);
  }

  addNewunitConversion() {
    this.unitConversions.push(this.newUnitConversion());
  }

  addNewEntityRelation(entityTypeCode) {
    this.entityRelations().push(this.newEntityRelation(entityTypeCode));
  }

  removeEntityRelation(entityIndex: number) {
    this.entityRelations().at(entityIndex).get('isRemoved').patchValue(true);
    if (this.entityRelations().at(entityIndex).get('isNew').value) {
      this.entityRelations().removeAt(entityIndex);
    }
  }

  removeUnitConversion(Index: number) {
    if (this.unitConversions.at(Index).get('isNew').value) {
      this.unitConversions.removeAt(Index);
    } else {
      this.unitConversions.at(Index).get('isRemoved').patchValue(true);
    }
  }

  setSelectedMaterial(material: IEntity) {
    this.selectedMaterialCode = material.code;
  }

  onSelectDirection(direction: IEnumKeyValue) {
    this.selectedDirectionText = direction.key;
  }

  buildForm() {
    this.entityForm = createFormGroup(this._entity, this.isActivity);
  }

  constructor(@Inject(DialogRef)
    public data: { entity: IEntity, entityGroup: IEntityGroup, isNew: boolean, entityTypeCode: string },
    private readonly unitService: UnitService,
    private readonly entityService: EntityService,
    private readonly poService: ProdOrderService,
    private readonly entityPropertyService: EntityPropertyService,
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: DialogRef,
    private readonly appNotificationService: AppNotificationService
  ) {
    super(dialogRef);
    this.entityRelationForm = this.formBuilder.group({
      entityRelations: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.unitService.query({}).pipe(takeUntil(this.destroy)).subscribe(u => {
      this.units = u;
    });

    this._entity = this.data.entity;
    this._entityGroup = this.data.entityGroup;
    this.isNew = this.data.isNew;
    this.isActivity = this.data.entityTypeCode.toLocaleLowerCase() === 'ACTIVITY'.toLocaleLowerCase();
    this.isGlulamSpecEnabled = this.data.entityGroup.isGlulamGroup;
    this.buildForm();
  }

  show(c: AbstractControl): boolean {
    if (this.entityForm.get('baseUnitCode').value === c.value.unitCode) {
      c.get('convFactor').patchValue(1);
    }
    return this.entityForm.get('baseUnitCode').value !== c.value.unitCode;
  }

  onSubmit() {
    if (this.validate()) {
      const e: IEntity = <IEntity>{
        index: this.entityForm.get('index').value ? this.entityForm.get('index').value : 0,
        code: this.entityForm.get('code').value,
        baseUnitCode: this.entityForm.get('baseUnitCode').value,
        changeDate: this.entityForm.get('changeDate').value,
        commentId: this.entityForm.get('commentId').value,
        description: this.entityForm.get('description').value,
        entityGroupCode: this.entityForm.get('entityGroupCode').value,
        // isDynamicActivity : this.entityForm.get('isDynamicActivity').value,
        sortOrderIndex : this.entityForm.get('sortOrderIndex').value,
        status: this.entityForm.get('status').value,
        // superiorEntityIndex = this.entityForm.get('superiorEntityIndex').value,
        userCode: this.entityForm.get('userCode').value,
        machineId: this.isActivity ? this.entityForm.get('machineId').value : 0,
        targetMinStock: CommonHelper.convertStringQuantityToNumber(this.entityForm.get('targetMinStock').value) ?? null,
        targetMaxStock: CommonHelper.convertStringQuantityToNumber(this.entityForm.get('targetMaxStock').value) ?? null,
        batchSize: CommonHelper.convertStringQuantityToNumber(this.entityForm.get('batchSize').value) ?? null,
        beamHightMin: this.glulamSpecForm?.get('hightMin').value,
        beamHightMax: this.glulamSpecForm?.get('hightMax').value,
        propertyValues: this.propertyValues.controls.filter(c => {
          let propertyValue = c.get('value').value;
          return propertyValue !== null && propertyValue !== 'null';
        })
        .map<IPropertyValue>(c => {
          return <IPropertyValue>{
            id: c.get('id').value,
            entityIndex: this.entityForm.get('index').value ?? 0,
            propertyCode: c.get('propertyCode').value,
            valueInt: c.get('dataType').value === EntityPropertyDataTypeEnum.DATA_TYPE_INT ? 1 * c.get('value').value : 0,
            valueDouble: c.get('dataType').value === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE ? 1 * CommonHelper.replaceCommawithDot(c.get('value').value) : 0,
            valueString: c.get('dataType').value === EntityPropertyDataTypeEnum.DATA_TYPE_STRING ? c.get('value').value : '',
            userCode: 'API',
            changeDate: new Date().toISOString(),
            dataType: c.get('dataType').value
          };
        }),
        entityRelations: this.entityRelations().controls.map<IEntityRelation>(erc => {
          return <IEntityRelation>{
            productIndex: this._entityGroup.entityTypeCode === 'RESOURCE' ? this._entity.index : erc.get('entityIndex').value,
            activityIndex: this._entityGroup.entityTypeCode === 'ACTIVITY' ? this._entity.index : erc.get('entityIndex').value,
            relation: this.poService.validateQuantityAsPerDirection(Number(CommonHelper.replaceCommawithDot(erc.get('relation').value)), Number(erc.get('direction').value)),
            direction: erc.get('direction').value,
            unitCode: erc.get('unitCode').value,
            userCode: erc.get('userCode').value,
            ChangeDate: new Date().toISOString(),
            productCode: erc.get('productCode').value,
            activityCode: erc.get('activityCode').value,
            isNew: erc.get('isNew').value,
            isChange: this.isEntityRelationsUpdated(erc as FormGroup),
            isRemoved: erc.get('isRemoved').value,
            inTactical: erc.get('inTactical').value,
            inOperative: erc.get('inOperative').value,
            isChaining: erc.get('isChaining').value,
            glulamSpec: this.glulamSpecs()?.controls.filter(f => f.get('productIndex').value === erc.get('entityIndex').value).map<IGlulamSpec>(gs => {
              return this.createGlulamSpec(gs);
            })
          };
        }),
        unitConversions: this.unitConversions.controls.map<IUnitConversion>(c => {
          return <IUnitConversion>{
            index: c.get('index').value ? c.get('index').value : 0,
            entityIndex: this._entity.index ? this._entity.index : 0,
            unitCode: c.get('unitCode').value,
            convFactor: CommonHelper.convertStringQuantityToNumber(c.get('convFactor').value),
            userCode: 'API',
            changeDate: new Date().toISOString(),
            isNew: this.isNew,
            isChange: this.isUnitConversionsUpdated(c as FormGroup),
            isRemoved: c.get('isRemoved').value,
            id: c.get('id').value ? c.get('id').value : 0
          };
        })
      };

      this.disableSubmit = true;
      if (e.index === -1) {
        this.entityService.insert(e).pipe(takeUntil(this.destroy)).subscribe({
          next: () => {
            this.disableSubmit = false;
            this.dialogRef.close(true);
          },
          error: () => {
            this.disableSubmit = false;
          }
        });
      } else {
        this.entityService.update(e).pipe(takeUntil(this.destroy)).subscribe({
          next: () => {
            this.disableSubmit = false;
            this.dialogRef.close(true);
          },
          error: () => {
            this.disableSubmit = false;
          }
        });
      }
    }
  }

  get unitConversions() {
    return this.entityForm.get('unitConversions') as FormArray;
  };

  get propertyValues() {
    return this.entityForm.get('propertyValues') as FormArray;
  };

  onCancel() {
    this.dialogRef.close(false);
  }

  openAddEditGlulamSpecModal() {
    if (this.glulamSpecButtonDisabled) {
      return;
    }

    this.glulamSpecButtonDisabled = true;

    const dialogdata: GlulamSpecData = {
      activityIndex: this.data.entity.index,
      glulamSpecs: [],
      productList: [],
      hightMin: this.data.entity.beamHightMin,
      hightMax: this.data.entity.beamHightMax
    };

    const er = this.data.entity.entityRelations.filter(er => er.direction === EntityRelationDirection.CONSUMES && er.unitCode !== 'HOUR');
    er.forEach(e => {
      e.glulamSpec.forEach(g => {
        dialogdata.glulamSpecs.push(g);
      });
    });

    const lst: number[] = [];
    const rows = this.entityRelations();
    rows.controls.forEach(erc => {
      if ((erc.get('direction').value === EntityRelationDirection.CONSUMES) && (erc.get('unitCode').value !== 'HOUR')) {
        const id = erc.get('entityIndex').value;
        if (id !== null) {
          lst.push(id);
        }
      }
    });

    const tasks: Observable<IEntity>[] = [];
    lst.forEach(e => {
      tasks.push(this.entityService.get(e));
    });
    forkJoin(tasks).pipe(takeUntil(this.destroy)).subscribe(totres => {
      for (const e of totres) {
        dialogdata.productList.push({ key: e.index, value: e.description, width: e.standardProperties?.width, thickness: e.standardProperties?.thickness });
      }
      const dr = this.entityService.openDialogForGlulamSpec(dialogdata);
      dr.result.pipe(takeUntil(this.destroy)).subscribe((d: any) => {
        if (d instanceof DialogCloseResult) {
          dr.close();
        } else if (d.success) {
          this.glulamSpecForm = d.glulamspec;
        }
          this.glulamSpecButtonDisabled = false;
      });
    });
  }

  private validate(): boolean {
    //Code length validation
    if (this.entityForm.get('code').value.length > 100) {
        this.showMessage('EntityTranslation.EntityCodeLengthError');
        return false;
    }

    // Entity min max stock validation
    const minStock = CommonHelper.convertStringQuantityToNumber(this.entityForm.get('targetMinStock').value) ?? null;
    const maxStock = CommonHelper.convertStringQuantityToNumber(this.entityForm.get('targetMaxStock').value) ?? null;

    if (minStock != null && maxStock != null) {
      if (minStock <= 0) {
        this.showMessage('EntityTranslation.MinStockShouldbeGreaterThanZero');
        return false;
      } else if (maxStock <= 0) {
        this.showMessage('EntityTranslation.MaxStockShouldbeGreaterThanZero');
        return false;
      } else if (maxStock < minStock) {
        this.showMessage('EntityTranslation.MaxStockShouldbeGreater');
        return false;
      } else if (maxStock === minStock) {
        this.showMessage('EntityTranslation.MinStockNotEqualToMax');
        return false;
      }
    }
    // Entity relation validation with direction
    const invalidEntityRelationIndex: number[] = [];
    this.entityRelations().controls.forEach((erc, index) => {
      if ((erc.get('direction').value === EntityRelationDirection.CONSUMES) && (Number(CommonHelper.replaceCommawithDot(erc.get('relation').value)) === 0)) {
        invalidEntityRelationIndex.push(index + 1);
      }
    });

    if (invalidEntityRelationIndex.length > 0) {
      this.appNotificationService
        .notifyErrorAppChanel('EntityTranslation.EntityRelationInvalidRelationForConsume'
          , 'Error'
          , { lineNo: invalidEntityRelationIndex.join(', ') });
      return false;
    }

    // Property value data type validation
    const propsWithoutAreValuesValidated = this._entityGroup.entityGroupProperties.filter(x => !x.property.areValuesValidated);
    if (propsWithoutAreValuesValidated.length > 0) {
      let dataTypes = this.entityPropertyService.getDataTypeMap();

      for (const element of propsWithoutAreValuesValidated) {
        const prop = this.propertyValues.controls.find(control => control.get('propertyCode').value === element.propertyCode);
        const dataType = prop.value.dataType;

        if (prop && this.isInvalidProperty(prop, dataType)) {
          this.appNotificationService.notifyErrorAppChanel('EntityTranslation.InvalidDataType' , 'Error', { propertyCode: prop.value.propertyCode, dataType: dataTypes.find(x => x.value == dataType).text});
          return false;
        }
      };
    }
    return true;
  }

  private isInvalidProperty(prop: any, dataType: any): boolean {
    if (prop.value.value !== null && prop.value.value !== 'null' && prop.value.value.trim() !== '') {
      const valueAsNumber = Number(CommonHelper.replaceCommawithDot(prop.value.value));

      if (dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE && isNaN(valueAsNumber) || dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT && !Number.isInteger(valueAsNumber))
        return true;
    }
    return false;
  }

  private isUnitConversionsUpdated(uc: FormGroup): boolean {
    const old = this.data.entity.unitConversions.find(x => x.id === uc.get('id').value);
    if (old?.convFactor && old?.unitCode) {
      if (old.convFactor !== uc.get('convFactor').value || old.unitCode !== uc.get('unitCode').value) {
        return true;
      } else {
        return false;
      }
    }
  }

  private isEntityRelationsUpdated(enr: FormGroup): boolean {
    const old = this.data.entity.entityRelations.find(x => x.activityIndex === enr.get('activityIndex').value && x.productIndex === enr.get('productIndex').value);
    if (old != null) {
      if (this.isActivity && old.productIndex !== enr.get('entityIndex').value) {
        return true;
      } else if (this.isEntityTypeCodeResource && old.activityIndex !== enr.get('entityIndex').value) {
        return true;
      } else if (old.relation !== enr.get('relation').value || old.direction !== enr.get('direction').value || old.unitCode !== enr.get('unitCode').value ||
        old.inTactical !== enr.get('inTactical').value || old.inOperative !== enr.get('inOperative').value || old.isChaining !== enr.get('isChaining').value
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  private createGlulamSpec(gs: AbstractControl): IGlulamSpec {
    return <IGlulamSpec>{
      activityIndex: gs.get('activityIndex').value,
      heightDependant: gs.get('heightDependant').value,
      dryJoint: gs.get('dryJoint').value,
      turn: gs.get('turn').value,
      numberOfLammellas: gs.get('numberOfLammellas').value,
      planingThickness: gs.get('planingThickness').value,
      productIndex: gs.get('productIndex').value,
      sequence: gs.get('sequence').value,
      isNew: gs.get('isNew').value,
      isChanged: gs.get('isChanged').value,
      isRemoved: gs.get('isRemoved').value
    };
  }

  private showMessage(message: string) {
    this.appNotificationService.notifyErrorAppChanel(message);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
