<kendo-dialog-titlebar kendo-dialog-title>
  {{"Beams.ManualBeamMaterial" | translate}}
</kendo-dialog-titlebar>

<div class="container">
  <kendo-grid [data]="manulaBeamMaterial.demands"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
>
    <kendo-grid-column field="orderId" title="OrderID" [editable]="false"></kendo-grid-column>
    <kendo-grid-column field="length" title="Length" [editable]="false"></kendo-grid-column>
    <kendo-grid-column field="qty" title="Qty" [editable]="true" editor="numeric"></kendo-grid-column>
  </kendo-grid>
</div>

<kendo-dialog-actions>
  <div class="k-ml-xs">
    <kendo-numerictextbox
        [(ngModel)]="manulaBeamMaterial.length"  placeholder="Lenght">
    </kendo-numerictextbox>
  </div>
  <div display="flex-1"></div>
  <button kendoButton type="submit" themeColor="primary" (click)="addManualBeamMaterial()">
    {{'App.Add' | translate}}
  </button>
  <button kendoButton themeColor="error" type="button" (click)="closeDialog()">
    {{'App.Close' | translate}}
  </button>
</kendo-dialog-actions>
