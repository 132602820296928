import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { Directive, Injectable, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../custom-services/localstorage.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { SiteService } from '@app/core/services/http-services/app/site.service';

@Injectable({
  providedIn: 'root'
})

export class StockLocationService extends BehaviorSubject<AppGridDataResult<IStockLocation>> {
  constructor(
    public http: HttpClient,
    public localStorageService:LocalStorageService
  ) {
    super(<AppGridDataResult<IStockLocation>> {
      data: [],
      total: 0
    });
  }

  public loading = false;

  private stockUri = `${environment.apiUrl}StockLocation`;

  public query(query:IstockQuery = <IstockQuery>{}): void {
    this.loading = true;

    this.queryData(query).subscribe((x) => {
      this.loading = false;
      super.next(x);
    });
  }

  public queryData(query:IstockQuery = <IstockQuery>{}): Observable<AppGridDataResult<IStockLocation>> {
    let params = new HttpParams();

    if (query.siteId) {
      params = params.set('siteId', query.siteId);
    }

    if (query.code) {
      params = params.set('code', query.code);
    }

    if (query.name) {
      params = params.set('name', query.name);
    }

    if (query.searchString) {
      params = params.set('searchString', query.searchString);
    }

    if (query.isRoot !== null && query.isRoot !== undefined) {
      params = params.set('isRoot', query.isRoot);
    }

    if (query.isEdge !== null && query.isEdge !== undefined) {
      params = params.set('isEdge', query.isEdge);
    }

    if (query.stockLocationType !== null && query.stockLocationType !== undefined) {
      params = params.set('stockLocationType', query.stockLocationType);
    }

    if (query.parentStockLocationId) {
      params = params.set('parentStockLocationId', query.parentStockLocationId);
    }

    return this.http.get<AppGridDataResult<IStockLocation>>(this.stockUri, {
      params: params.set('skip', query.skip ?? 0).set('take', query.take ?? 30)
    });
  }

  update(StockLocation: IStockLocation): Observable<IStockLocation> {
    return this.http.put<IStockLocation>(`${this.stockUri}/${StockLocation.stockLocationId}`, JSON.stringify(StockLocation), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(entity: IStockLocation): Observable<IStockLocation> {
    return this.http.post<IStockLocation>(this.stockUri, JSON.stringify(entity), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  autoCompleteSearch(entity: IStockLocation): Observable<IStockLocation> {
    return this.http.post<IStockLocation>(this.stockUri, JSON.stringify(entity), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public remove(stockLocationId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.stockUri}/${stockLocationId}`);
  }

  public get(stockLocationId: number): Observable<IStockLocation> {
    return this.http.get<IStockLocation>(`${this.stockUri}/${stockLocationId}`);
  }
}

@Directive({
  selector: '[stockLocationBinding]'
})
export class StockLocationBindingDirective
  extends DataBindingDirective
  implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;

  constructor(private sockLocationService: StockLocationService, private siteService:SiteService, grid: GridComponent) {
    super(grid);
  }

  public override ngOnInit(): void {
    this.serviceSubscription = this.sockLocationService.subscribe(async (result) => {
      this.grid.loading = false;
      this.grid.data = await this.siteService.mapSiteIdsToNames(result);
      this.notifyDataChange();
    });

    super.ngOnInit();

    this.rebind();
  }

  public override ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  public override rebind(): void {
    this.grid.loading = true;

    if (this.state.filter?.filters) {
      const fiters: FilterDescriptor[] = this.state.filter.filters as FilterDescriptor[];
      this.sockLocationService.query(<IstockQuery> {
        siteId: fiters.find((f:FilterDescriptor) => f.field === 'siteId')?.value,
        code: fiters.find((f:FilterDescriptor) => f.field === 'code')?.value,
        name: fiters.find((f:FilterDescriptor) => f.field === 'name')?.value,
        isRoot: fiters.find((f:FilterDescriptor) => f.field === 'isRoot')?.value,
        isEdge: fiters.find((f:FilterDescriptor) => f.field === 'isEdge')?.value,
        stockLocationType: fiters.find((f:FilterDescriptor) => f.field === 'stockLocationType')?.value,
        parentStockLocationId: fiters.find((f:FilterDescriptor) => f.field === 'parentStockLocationId')?.value,
        skip: this.state.skip,
        take: this.state.take
      });
    } else {
      this.sockLocationService.query(<IstockQuery> { skip: this.state.skip, take: this.state.take });
    }
  }
}

export interface IStockLocation {
  stockLocationId: number;
  code:string;
  name:string;
  isRoot:boolean;
  isEdge:boolean;
  stockLocationType:number;
  parentStockLocationId:number;
  parent:IStockLocation;
  siteId:string;
}

export interface AppGridDataResult<T> extends GridDataResult {
  data:T[];
}

export enum StockLocationType
    {
        Batch = 0,
        Quantity = 1
    }

export interface IstockQuery {
  siteId:string,
  code:string,
  name:string,
  isRoot:boolean,
  isEdge:boolean,
  stockLocationType:boolean,
  parentStockLocationId: number,
  searchString:string,
  skip:number,
  take:number
}
