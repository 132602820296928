  <kendo-dialog-titlebar kendo-dialog-title>
    <div class="primaryColor section-header-operative" translate>{{selectedMachine.name}} - {{ 'Machine.RamConfigurationDialogHeader' | translate}}</div>
  </kendo-dialog-titlebar>
  <div class="container">
    <div class="k-mb-4">
      <button kendoButton [primary]="true" (click)="add()">
        {{ 'App.AddNew' | translate }}
      </button>
    </div>
      <div>
        <kendo-grid
            #ramConfigurationEditorGrid
            [columnMenu]="false"
            [data]="ramConfigurationsGridData"
            [groupable]="false"
            [resizable]="true"
            [sortable]="true"
            [scrollable]="'none'"
            (edit)="editHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            [size]="'small'">
          <kendo-grid-column
            title="{{ 'EntityTranslation.Index' | translate }}"
            field="index"
            [editable]="false"
            class="align-right">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.index }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            title="{{ 'App.Position' | translate }}"
            field="position"
            class="align-right">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.position }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
              <kendo-numerictextbox
                id="position"
                format="n"
                [decimals]="0"
                [formControl]="formGroup.get('position')"
                [min]="0">
              </kendo-numerictextbox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            title="{{ 'Machine.Ram' | translate }}"
            field="ram"
            class="align-right">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.width }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
              <kendo-numerictextbox
                id="width"
                format="n"
                [decimals]="0"
                [formControl]="formGroup.get('width')"
                [min]="1">
              </kendo-numerictextbox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
              <button kendoGridEditCommand id="edit-ram" [primary]="true">
                {{ 'App.Edit' | translate }}
              </button>
              <button kendoGridSaveCommand id="add-ram" [primary]="true">
                {{ isNew ? ('App.Add' | translate) : ('App.Update' | translate) }}
              </button>
              <button kendoGridRemoveCommand id="remove-ram" themeColor="error" translate>
                {{ 'App.Remove' | translate }}
              </button>
              <button kendoGridCancelCommand id="cancel-ram" themeColor="error">
                {{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}
              </button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
        <div class="k-i-loading" *ngIf="loading"></div>
      </div>
  </div>
  <kendo-dialog-actions>
    <div class="k-flex-1"></div>
    <button kendoButton (click)="close()">
      {{ 'App.Close' | translate }}
    </button>
    <button kendoButton [primary]="true" (click)="save()" [disabled]="disableSaveBtn">
      {{ 'App.Save' | translate }}
    </button>
  </kendo-dialog-actions>
