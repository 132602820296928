import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IHandelingUnit, HandelingUnitService, IHandlingUnitSearchCriteriaDTO } from '@app/core/services/http-services/stock/handeling-unit-report.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.SelectEntity');

@Component({
  selector: 'app-select-package',
  template: `
   <kendo-multicolumncombobox
                        [data]="handelingUnitReport"
                        textField="handelingUnitIdentifier"
                        valueField= "handelingUnitId"
                        placeholder="{{'Operative.SearchIdentifier' | translate}}"
                        [loading]="loading"
                        [filterable]="true"
                        (filterChange)="handleFilterChange($event)"
                        (valueChange)="onSelectionChange($event)"
                        [disabled]="disabled"
                      >
                        <kendo-combobox-column field="handelingUnitIdentifier" title="ID" [width]="80">
                        </kendo-combobox-column>
                        <kendo-combobox-column field="qty" title="qty" [width]="80">
                          <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                            {{ dataItem.qty | number }}
                          </ng-template>
                        </kendo-combobox-column>
                        <kendo-combobox-column field="qtyUnitCode" title="qtyunitcode" [width]="120">
                        </kendo-combobox-column>
                        <kendo-combobox-column field="stockLocationName" title="stockLocationName" [width]="200">
                        </kendo-combobox-column>
                      </kendo-multicolumncombobox>
  `,
  styleUrls: ['./select-package.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectPackageComponent
    }
  ]
})
export class SelectPackageComponent implements ControlValueAccessor {
  selectedEntityIndex: number = null;

  loading: boolean = false;

  @Input() label: string = 'App.SelectEntity';
  @Input() showLable: boolean = false;
  @Input() materialIndex:number;
  @Input() stockLocationId:number;
  @Input() isProdOrderReport:boolean = false;

  @Output() handelingUnit:EventEmitter<IHandelingUnit> = new EventEmitter<IHandelingUnit>();

  private onChanged!: Function;
  private onTouched!: Function;

  public disabled = false;

  handelingUnitReport:IHandelingUnit[] = [];

  constructor(private readonly handelingUnitService: HandelingUnitService) { }

  onSelectionChange(hu: IHandelingUnit) {
    this.onChanged(hu.handelingUnitId);
    this.handelingUnit.emit(hu);
  }

  writeValue(value: number): void {
    this.selectedEntityIndex = value;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public handleFilterChange(searchTerm: string): void {
    if (searchTerm.length > 2) {
      this.loading = true;
      this.handelingUnitService.gethandelingunits(<IHandlingUnitSearchCriteriaDTO>{
        materialIndex: this.materialIndex,
        stockLocationId: this.stockLocationId,
        searchValue: searchTerm,
        isRecursive: true,
        inStock: true
      }).subscribe(g => {
        this.handelingUnitReport = g.data;
        if (this.isProdOrderReport) {
          this.handelingUnitReport = this.handelingUnitReport.filter(x => x.currentStockLocationId !== null && x.qty !== 0);
        }
        this.loading = false;
      });
    };
  }
}
