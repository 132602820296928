import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserService } from '@app/core/services/http-services/common/user.service';
import { ITenantInformationDTO } from '@app/core/models/tenantInformationDTO.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.Tenant');
marker('App.ChangeTenant');

@Component({
  selector: 'app-select-tenant',
  template: `
<kendo-floatinglabel
  [text]="label | translate" *ngIf="showLable" style="width:100%">
  <kendo-dropdownlist
    [data]="tenants"
    textField="id"
    valueField="id"
    [loading]="loading"
    [valuePrimitive]="true"
    [(value)]="selectedTenant"
    style="width:100%"
    (selectionChange)="onChangedVal()"
  >
  </kendo-dropdownlist>
</kendo-floatinglabel>

<app-confirmation-dialog *ngIf="tennatChanged"
                         [msg] = "confirmationMsg"
                         [title]="confirmationMsgTitle"
                         (conformationDialogCloseEvent) = "confirmChangeTenant($event)">
</app-confirmation-dialog>
  `,
  styles: [
    'host: { width: 100%; }'
  ]
})
export class SelectTenantComponent implements OnInit {
  tenants: ITenantInformationDTO[] = [];
  loading:boolean = true;
  tennatChanged: boolean = false;
  defaultTenant:string = null;

  @Input() label:string = 'App.Tenant';
  @Input() showLable:boolean = false;
  @Input() selectedTenant:string = 'ForestX';

  // confirmation dialog
  confirmationMsg: string = 'App.ChangeTenant';
  confirmationMsgTitle: string = 'App.RemoveConfirmTitle';

  constructor(private userService: UserService
    , private localStorage:LocalStorageService) {
  }

  ngOnInit(): void {
    this.loadTenant();
  }

  private loadTenant() {
    this.userService.getTenants().subscribe(t => {
      this.tenants = t;
      this.selectedTenant = this.localStorage.getItem(LocalStorageService.tennatKey) ?? this.selectedTenant;
      this.defaultTenant = this.selectedTenant;
      this.loading = false;
    });
  }

  onChangedVal() {
    this.tennatChanged = true;
  }

  confirmChangeTenant(confirmChange:boolean) {
    this.tennatChanged = false;
    if (confirmChange) {
      this.localStorage.setItem(LocalStorageService.tennatKey, this.selectedTenant);
      window.location.href = window.location.origin;
    } else {
      this.selectedTenant = this.defaultTenant;
    }
  }
}
