import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public static readonly tennatKey:string = 'optinex-tenant-override';
  public static readonly selectedMachines: string = 'selectedMachines';
  public static readonly resourceKey: string = 'groupEntityRecuorceIndex';
  public static readonly activityKey: string = 'groupEntityActivityIndex';
  public static readonly sheduleDurationKey: string = 'sheduleDuration';
  public static readonly selectedMachineGroups: string = 'selectedMachineGroups';
  public static readonly eventsheduleSelectedType:string = 'eventsheduleSelectedType';
  public static readonly userGroups:string = 'userGroups';
  public static readonly newPODetails = 'newPODetails';
  public static readonly newPODetailLine = 'newPODetailLine';
  public static readonly selectedLocale = 'selectedLocale';
  public static readonly selectedAutoScheduler = 'selectedAutoScheduler';
  public static readonly userSites = 'userSites';
  public static readonly selectedSiteId = 'selectedSiteId';
  public static readonly userRoles = 'userRoles';

  public localeChangedSub = new Subject();

  constructor() { }

  setItem(key:string, value:string) : boolean {
    localStorage.setItem(key, value);
    return true;
  }

  getItem(key:string) : any {
    return localStorage.getItem(key);
  }

  removeItem(key:string) : boolean {
    localStorage.removeItem(key);
    return true;
  }

  localeChanged() {
    this.localeChangedSub.next(null);
  }
}
