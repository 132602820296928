import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ReplaceDotToCommaPipe'
})
export class ReplaceDotToCommaPipe implements PipeTransform {
  transform(value: any): string {
    if (value !== null) {
      let dummyQtyValue = value + '';
      if (dummyQtyValue.match(/\s/g)) {
        dummyQtyValue = value.replace(/\s/g, '');
      }
      if (dummyQtyValue.match(/\./g)) {
        dummyQtyValue = dummyQtyValue.replace(/\./, ',');
      }
      return dummyQtyValue !== undefined ? dummyQtyValue : value;
    } else {
      return '';
    }
  }
}
