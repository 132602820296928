import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Demandline } from '@app/core/models/demand-line.model';
import { CreateManualBeamMaterialDemandForm } from '@app/core/models/forms/gluelam/gluelam-planning-statical/manual-beam-material-dialog/create-manual-beam-material-demand.model';
import { IManualBeamMaterialDemandLinesDTO } from '@app/core/models/manual-beam-material-demand-lines-dto';
import { IManualBeamMaterialDTO, IManualBeamMaterialResult } from '@app/core/models/manual-beam-material.dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { CellClickEvent, CellCloseEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

marker('App.Cancel');
marker('Beams.ManualBeamMaterial');
marker('App.Add');

@Component({
  selector: 'app-manual-beam-material-dialog',
  templateUrl: './manual-beam-material-dialog.component.html',
  styleUrls: ['./manual-beam-material-dialog.component.css']
})
export class ManualBeamMaterialDialogComponent extends DialogContentBase implements OnInit {
  manulaBeamMaterial : IManualBeamMaterialDTO;
  view: GridDataResult| undefined;
  editService = { mode: 'incell', allowAdding: true, allowDeleting: true, allowUpdating: true };
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(
    private readonly dialogRef: DialogRef,
    public notificationService: AppNotificationService,
    @Inject(DialogRef)
    public data:any) {
      super(dialogRef);
  }

  ngOnInit(): void {
    this.manulaBeamMaterial = this.setManulaBeamMaterial(this.data);
  }

  cellClickHandler(args: CellClickEvent): void {
    if (!args.isEdited) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args.dataItem)
      );
    }
  }

  createFormGroup(dataItem: Demandline): FormGroup {
    return new FormGroup<CreateManualBeamMaterialDemandForm>({
      orderID: new FormControl<string>(dataItem.orderID),
      length: new FormControl<number>(dataItem.length),
      qty: new FormControl<number>(dataItem.qty, [Validators.required, Validators.min(0), Validators.pattern('^[0-9]+$')])
    });
  }

  cellCloseHandler(args: CellCloseEvent) {
    this.updateManualBeamMaterialQty(args);
  }

  updateManualBeamMaterialQty(args: CellCloseEvent) {
    if (args.formGroup.valid) {
      this.manulaBeamMaterial.demands[args.rowIndex].qty = args.formGroup.controls['qty'].value;
      this.manulaBeamMaterial.length = this.calculateLength(this.manulaBeamMaterial.demands);
    }
  }

  setManulaBeamMaterial(data: Demandline[]): IManualBeamMaterialDTO {
    if (data) {
      const result : IManualBeamMaterialDTO = {
        length: 0,
        demands: data.map((demand: any) => ({
          orderId: demand.orderID,
          beamOrderRowID: demand.BeamOrderRowID,
          length: demand.length,
          height: demand.hight,
          width: demand.width,
          numberOfLaminas: demand.numberOfLaminas,
          deafultLamminaPlaneThicknes: demand.deafultLamminaPlaneThicknes,
          qty: 1
        }))
      };
      result.length = this.calculateLength(result.demands);
      return result;
    } else {
      return null;
    }
  }

  calculateLength(data:IManualBeamMaterialDemandLinesDTO[]):number {
    return data.reduce((accumulator, currentValue) => accumulator + (currentValue.length * currentValue.qty), 0);
  }

  onQtyChange(newQty: number, dataItem: any) {
    dataItem.Qty = newQty;
  }

  closeDialog() {
    this.dialogRef.close(<IManualBeamMaterialResult> { success: false });
  }

  addManualBeamMaterial() {
    this.dialogRef.close(<IManualBeamMaterialResult> { success: true, manulaBeamMaterial: this.manulaBeamMaterial });
  }
}
