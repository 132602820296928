<h1 translate>Operative.Deviation</h1>
<section class="app-button-row">
  <div style="flex-grow: 1;"></div>
  <button kendoButton class="k-mb-1" themeColor="primary" (click)="addDeviationRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

  <kendo-grid #deviationEditorGrid
          [data]="gridDeviationData"
          class="crud-grid"
          [filter]="gridState.filter"
          [filterable]="true"
          [skip]="gridState.skip"
          [pageSize]="gridState.take"
          [pageable]="true"
          [loading]="loader"
          [resizable]="true"
          [size]="'small'"
          (edit)="editHandler($event)"
          (cancel)="cancelHandler($event)"
          (save)="saveHandler($event)"
          (remove)="removeHandler($event)"
          (dataStateChange)="dataStateChange($event)"
          [size]="'small'"
  >
  <kendo-grid-column
        field="siteId"
        title="{{ 'App.Site' | translate  }}">
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <app-site-selector-v2 (onSiteSelected)="onSiteSelected()" [formControl]="formGroup.get('siteId')"></app-site-selector-v2>
        </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <span>{{ dataItem.siteName }}</span>
        </ng-template>
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <app-site-filter [filter]="filter"></app-site-filter>
        </ng-template>

    </kendo-grid-column>
    <kendo-grid-column field="code" title="{{ 'App.Code' | translate }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox [formControl]="formGroup.get('code')">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="description" title="{{ 'Operative.description' | translate }}" [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox [formControl]="formGroup.get('description')">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="startDate" title="{{ 'Operative.deviationStartDate' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.startDate | siteDateFormat: true }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datetimepicker [formControl]="formGroup.get('startDate')"
                              (valueChange)="onDateTimeChange($event, 'startDate')">
        </kendo-datetimepicker>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell
                                      [showOperators]="false"
                                      [column]="column"
                                      [filter]="filter">
        </kendo-grid-date-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="startDate" title="{{ 'Operative.deviationStartDateInGMT' | translate }}" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem> {{  dataItem.startDate | siteDateFormat : true:'GMT'  }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"> {{  formGroup.get('startDate').value | siteDateFormat : true:'GMT' }} </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="endDate" title="{{ 'Operative.deviationEndDate' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.endDate | siteDateFormat: true }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datetimepicker [formControl]="formGroup.get('endDate')"
                              (valueChange)="onDateTimeChange($event, 'endDate')">
        </kendo-datetimepicker>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell  [showOperators]="false"
                                      [column]="column"
                                      [filter]="filter">
        </kendo-grid-date-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="endDate" title="{{ 'Operative.deviationEndDateInGMT' | translate }}" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem > {{ dataItem.endDate | siteDateFormat : true:'GMT' }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"> {{  formGroup.get('endDate').value | siteDateFormat : true:'GMT' }} </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="duration" title="{{ 'Operative.deviationDuration' | translate }}">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-label class="textCenter"> {{ formGroup.get('duration').value }}</kendo-label>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shift" title="{{ 'Operative.DeviationShift' | translate  }}">
      <ng-template kendoGridCellTemplate let-dataItem> {{ getShiftsName(dataItem.shiftDeviation) }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" >
          <kendo-multiselect [formControl]="formGroup.get('shifts')" [data]="shiftData"
                              textField="name" valueField="shiftId">
          </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-grid-multiselect-drop-down-filter [filter]="filter"
                                            [data]="shiftData"
                                            textField="name"
                                            valueField="shiftId">
        </app-kendo-grid-multiselect-drop-down-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
        <button kendoGridEditCommand [primary]="true"> {{ 'App.Edit'| translate }} </button>
        <button kendoGridRemoveCommand themeColor="error"> {{ 'App.Remove'|translate }} </button>
        <button kendoGridSaveCommand [disabled]="deviationForm?.invalid"  themeColor="primary"> {{ isNew ? ("App.Add"| translate) : ("App.Update"|translate) }} </button>
        <button kendoGridCancelCommand themeColor="error"> {{ 'App.Cancel' | translate }} </button>
      </ng-template>
    </kendo-grid-command-column>

  </kendo-grid>

  <app-confirmation-dialog *ngIf="deleteDeviationId"
                        (conformationDialogCloseEvent) = "confirmRemove($event)">
  </app-confirmation-dialog>
