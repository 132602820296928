import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RoleBasedAccessHelper, UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit {
  restrictedRoles: UserRoleEnum[];
  @Input() public set deniedRoles(roles: any) {
    this.restrictedRoles = roles.split(',');
  }

  constructor(private elementRef: ElementRef, private roleBasedAccessHelper: RoleBasedAccessHelper) { }

  ngOnInit() {
    this.checkAccess();
  }

  checkAccess() {
    const isRolesMatched = this.roleBasedAccessHelper.hasOnlyInputRole(this.restrictedRoles);
    const styles = 'pointer-events : none;border-color: #e0e0e0;color : rgba(0, 0, 0, 0.38)';
    const tagename = this.elementRef.nativeElement.tagName.toLowerCase();
    if (isRolesMatched) {
      tagename === 'button'
        ? this.elementRef.nativeElement.setAttribute('style', styles + ';background-color: #e0e0e0;')
        : this.elementRef.nativeElement.setAttribute('style', styles);
    }
  }
}
