import { Component, Input } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { TranslateService } from '@ngx-translate/core';
import { BeamMaterial } from '../../../../core/models/beam-material.model';

@Component({
  selector: 'app-bm-print',
  templateUrl: './bm-print.component.html',
  styleUrls: ['./bmgs-print.component.css']
})
export class BMprintComponent {
  @Input() bm: BeamMaterial;
  @Input() index: number;

  constructor(public translate: TranslateService) {}

  orderdLines(): BeamMaterialLine[] {
    return this.bm.beamMaterialLines.sort((a, b) => {
      if (a.Cut > b.Cut) {
        return -1;
      }
      if (a.Cut < b.Cut) {
        return 1;
      }

      // If the votes number is the same between both items, sort alphabetically
      // If the first item comes first in the alphabet, move it up
      // Otherwise move it down
      if (a.IndexInCut > b.IndexInCut) {
        return 1;
      }
      if (a.IndexInCut < b.IndexInCut) {
        return -1;
      }
      return 0;
    });
  }
}
