import { GridDataResult } from '@progress/kendo-angular-grid';
import { UserService, UserSiteDTO } from '../common/user.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(private userService: UserService) {}

  private userSites:UserSiteDTO[] = null;

  /* Bind Site Name to Grid data */
  public async mapSiteIdsToNames(gridData: GridDataResult) : Promise<GridDataResult> {
    gridData.data = await Promise.all(gridData.data.map(async (row) => {
      row.siteName = await this.query(row.siteId);
      return row;
    }));

    return gridData;
  }

  public async query(siteId:String):Promise<string> {
    if (!this.userSites) {
      this.userSites = (await this.userService.getUser())?.userSites;
    }
    return this.userSites.find(s => s.siteId === siteId)?.siteName;
  }
}


