import { Component, Input } from '@angular/core';

@Component({
  selector: 'json-formatter',
  template: '<ngx-json-viewer [json]="json"></ngx-json-viewer>'
})
export class JsonFormatterDirectiveComponent {
  @Input() json: any;
  @Input() history: boolean = false;

  constructor() { }
}
