import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../custom-services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AppNotificationService } from '../../custom-services/notification.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { environment } from '../../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { IUnit } from '@app/core/models/unit.model';
import { IUnitConversion } from '@app/core/models/unitConversion.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BehaviorSubject<GridDataResult> {
  public _units : IUnit[] = null;
  public _unitConversions : IUnitConversion[] = null;

  constructor(
    conf: ConfigurationService,
    private http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(null);
  }

  public query(state: State): Observable<Array<IUnit>> {
    if (this._units) {
      return of(this._units);
    } else {
      const endpointUrl = `${environment.apiUrl}Unit`;
      return this.http.get<IUnit[]>(endpointUrl)
        .pipe(tap(u => {
          this._units = u;
        }));
    }
  }

  public getUnitConversions(entityIndex: number): Observable<Array<IUnitConversion>> {
    if (this._unitConversions) {
      return of(this._unitConversions);
    } else {
      const endpointUrl = `${environment.apiUrl}Entity/${entityIndex}`;
      return this.http.get<IUnitConversion[]>(endpointUrl)
        .pipe(tap(u => {
          this._unitConversions = u;
        }));
    }
  }
}
