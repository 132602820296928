import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';

@Injectable()
export class DimensionService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(http, conf, notificationService);
  }

  private _beamLamminaDimensions: BeamLamminaDimension[] = null;
  private _beamLamminaDimensionsAll: BeamLamminaDimension[] = null;

  getBeamLamminaDimentions(all: boolean = false): Observable<BeamLamminaDimension[]> {
    if (all && this._beamLamminaDimensionsAll) {
      return of(this._beamLamminaDimensionsAll);
    } else if (!all && this._beamLamminaDimensions) {
      return of(this._beamLamminaDimensions);
    } else {
      return this.getFromServer(all);
    }
  }

  private getFromServer(all: boolean = false): Observable<BeamLamminaDimension[]> {
    return this.http
      .get<BeamLamminaDimension[]>(this.configurations.baseApiUrl + 'dimensions?all=' + all)
      .pipe(
        tap((response: BeamLamminaDimension[]) => {
          if (all) {
            this._beamLamminaDimensionsAll = response;
          } else {
            this._beamLamminaDimensions = response;
          }
        })
      );
  }

  public getBeamLamminaDimention(id: number): Observable<BeamLamminaDimension> {
    let d: BeamLamminaDimension;

    if (this._beamLamminaDimensionsAll) {
      d = this._beamLamminaDimensionsAll.find(d => d.beamLamminaDimensionID === id);
    }

    if (d) {
      return of(d);
    } else {
      return this.getDimentionFromServer(id);
    }
  }

  private getDimentionFromServer(id: number): Observable<BeamLamminaDimension> {
    return this.http
      .get<BeamLamminaDimension>(this.configurations.baseApiUrl + 'dimensions/' + id);
  }
}
