import { Demandline } from './demand-line.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { IOptimizationResponse } from '@app/core/services/http-services/gluelam/optimazation.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { BeamMaterialGlueSet, BeamMaterialGlueSetCreator } from '@app/core/models/beam-material-glue-set.model';

export class OptimizationOrder {
  constructor(
    public beamLamminaDimensionID: number,
    public maxLength: number,
    public minLenght: number,
    public minHight: number,
    public maxHight: number,
    public weightFactorValue: number,
    public weightFactorAreaWaste: number,
    public weightFactorHeightWaste: number,
    public weightFactorPerformedTimes: number,
    public weightFactorFewOrdersPerBatch: number,
    public numberOfGlueSetBatches: number,
    public maxOrdersPerGlueSetBatch: number,
    public maxBatchesPerOrder: number,
    // public CplexModelFile: string,
    public deployedModelFile: string,
    public deployedModelId: string,
    public numberOfGlusets: number,
    public useTwoLayers: boolean,
    public maxSurgestions: number,
    public maxSolveTime: number
  ) {
    this.maxSurgestions = 1;
  }

  private _BeamLamminaDimension: BeamLamminaDimension;
  get beamLamminaDimension(): BeamLamminaDimension {
    return this._BeamLamminaDimension;
  }

  set beamLamminaDimension(bld: BeamLamminaDimension) {
    this.beamLamminaDimensionID = bld.beamLamminaDimensionID;
    this._BeamLamminaDimension = bld;
  }

  public demands: Demandline[];
}
export class OptimizationType {
  constructor(key: string, value: string) {
    this.Key = key;
    this.Value = value;
  }

  Key: string;
  Value: string;
}
export class OptimizationResponceCreator {
  static FromJson(
    object: IOptimizationResponse,
    conf: ConfigurationService
  ): OptimizationResponce {
    const beamMaterialGlueSets = object.beamMaterialGlueSets.map((obj) =>
      BeamMaterialGlueSetCreator.FromJson(
        obj,
        conf,
        true
      )
    );

    return new OptimizationResponce(
      object.beamLamminaDimensionID,
      0,
      object.maxLength,
      object.maxHight,
      object.maxHight,
      null,
      beamMaterialGlueSets,
      object.optiApiJson,
      object.optiApiURL,
      object.numberOfDemands,
      object.numberOfGluestest,
      object.numberOfAllocations,
      object.numberOfBeamMaterials,
      object.solveState,
      object.errorMessage
    );
  }
}

export class OptimizationResponce {
  public static readonly INFEASIBLE_SOLUTION: string = 'INFEASIBLE_SOLUTION';
  public static readonly FEASIBLE_SOLUTION: string = 'FEASIBLE_SOLUTION';
  public static readonly OPTIMAL_SOLUTION: string = 'OPTIMAL_SOLUTION';

  constructor(
    public beamLamminaDimensionID: number,
    public minLenght: number,
    public maxLength: number,
    public minHight: number,
    public maxHight: number,
    public beamLamminaDimension: BeamLamminaDimension,
    public beamMaterialGlueSets: BeamMaterialGlueSet[],
    public optiApiJson: string,
    public optiApiURL: string,
    public numberOfDemands: number,
    public numberOfGluestest: number,
    public numberOfAllocations: number,
    public numberOfBeamMaterials: number,
    public solveState: string,
    public errorMessage: string
  ) { }
}

export class OptiInputModel {
  public BeamLamminaDimension: BeamLamminaDimension;
  public BeamOrderLines: Demandline[];
}

export interface IOptimizerCustomSetting {
  InputName:string
  InputValue:any
}
