<div class="machine-view-container">
  <div #machineview class="machine-view">
    <svg xmlns="http://www.w3.org/2000/svg" *ngIf="machine?.machineId > 0" overflow="auto">
      <rect *ngFor="let ram of ramConfiguration; let i = index" [attr.width]="getWidth(ram.width)"
        [attr.x]="getWidth(ram.position)" height="100%"></rect>

      <defs>
        <clipPath *ngFor="let item of dimensions; let i = index" [attr.id]="'clip_' + item.glueplanId">
          <rect [attr.id]="'clip_' + item.glueplanId + '_rect'" [attr.width]="item.width - 10" [attr.height]="item.height" [attr.x]="item.x" [attr.y]="item.y + 10">
          </rect>
        </clipPath>
      </defs>

      <rect *ngFor="let item of dimensions; let i = index" [draggable]="true" [attr.width]="item.width"
        [attr.height]="item.height" [attr.x]="item.x" [attr.y]="item.y + 10" [attr.id]="item.glueplanId"
        [attr.fill]="getColor(item.state)" (xAttributeChange)="onChangeOfset($event)" rx="5px" ry="10px">
        <title>
          {{item.name}} - {{item.glueplanId}}
        </title>
      </rect>

      <g *ngFor="let item of dimensions; let i = index">
        <text [attr.id]="'name_' + item.glueplanId" cursor="pointer" fill="white" font-size="1em" [attr.x]="item.x + 10"
          [attr.y]="item.y + 25" alignment-baseline="middle" [attr.clip-path]="'url(#clip_' + item.glueplanId + ')'">
          <title>
            {{item.name}} - {{item.glueplanId}}
          </title>
          {{item.name}} - {{item.glueplanId}} - {{item.date | date:'yyyy-MM-dd'}}
        </text>
      </g>

      <g *ngFor="let rel of release; let x = index">
        <clipPath [attr.id]="'clip_rel_' + x">
          <rect width="40" height="30" [attr.x]="this.containerWidth - 60" [attr.y]="rel + 10"></rect>
        </clipPath>
        <rect class="pointer" [attr.id]="'name_relases_' + x" fill="black" [attr.x]="this.containerWidth - 60"
          overflow="hidden" [attr.y]="rel + 10" width="40" height="30" fill="orange" rx="5px" ry="10px"
          (click)="relese(rel)" [attr.clip-path]="'url(#clip_rel_' + x + ')'">
        </rect>
        <text class="pointer" [attr.id]="'name_relases_' + x" fill="black" font-size="1em"
          [attr.x]="this.containerWidth - 50" [attr.y]="rel + 25" alignment-baseline="middle" (click)="relese(rel)"
          [attr.clip-path]="'url(#clip_rel_' + x + ')'">
          Rel
        </text>
      </g>
    </svg>
  </div>
</div>
