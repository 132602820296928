<kendo-formfield *ngIf="showLable">
  <kendo-label translate>{{label}}</kendo-label>
  <kendo-dropdownlist
    [data]="sites"
    textField="siteName"
    valueField="siteId"
    [loading]="loading"
    [valuePrimitive]="true"
    [(value)]="selectedSiteId"
    (selectionChange)="onChangedVal($event)"
    class="width"
  >
  </kendo-dropdownlist>
</kendo-formfield>

<kendo-dropdownlist *ngIf="!showLable"
  [data]="sites"
  textField="siteName"
  valueField="siteId"
  [loading]="loading"
  [valuePrimitive]="true"
  [(value)]="selectedSiteId"
  (selectionChange)="onChangedVal($event)"
  class="width"
>
</kendo-dropdownlist>
