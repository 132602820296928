import { Directive, OnDestroy, OnInit } from '@angular/core';
import { EntityGroupService, IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { UserSiteDTO } from '@app/core/services/http-services/common/user.service';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Guid } from 'guid-typescript';

@Directive({
  selector: '[entityGroupBinding]'
})
export class EntityGroupBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;
  result: IEntityGroup[] = [];
  public override state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };

  constructor(private entityGroupService: EntityGroupService, grid: GridComponent, private localStorageService: LocalStorageService) {
    super(grid);
  }

  public override ngOnInit(): void {
    this.serviceSubscription = this.entityGroupService.query().subscribe((d) => {
      this.result = d;
      this.result.forEach(entityGroup => {
         entityGroup.selectedEntityGroupSites = this.getSelectedEntityGroupSites(entityGroup.entityGroupSites.map(x => x.siteId));
      });
      this.loadData(this.state);
    });
    super.ngOnInit();
    this.rebind();
  }

  public override ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  public override rebind(): void {
    this.grid.loading = true;
    this.entityGroupService.query();
    this.grid.loading = false;
  }

  public override onStateChange(state: State) {
    this.loadData(state);
  }

  private loadData(state: State) {
    const d = process(this.result, state);
    this.grid.loading = false;
    this.grid.data = d;
    this.notifyDataChange();
  }

  private getSelectedEntityGroupSites(entityGroupSiteIds: any[]): string[] {
    const userSites = JSON.parse(this.localStorageService.getItem(LocalStorageService.userSites)) as UserSiteDTO[];
    return userSites.filter(x => entityGroupSiteIds.includes(x.siteId)).map(s => s.siteName)
  }
}
