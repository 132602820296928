import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { EntityStatusEnum } from '@app/core/models/entity-status.enum';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('EntityTranslation.EntityStatus');

@Component({
  selector: 'app-select-entity-status',
  templateUrl: './select-entity-status.component.html',
  styleUrls: ['./select-entity-status.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityStatusComponent
    },
    EnumToObject
  ]
})
export class SelectEntityStatusComponent implements OnInit {
  entityStatus: IEnumKeyValue[];
  selectedStatus:string = 'Active';

  @Input() label:string = 'EntityTranslation.EntityStatus';
  @Input() showLable:boolean = false;

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private enumToObject: EnumToObject) {

  }

  ngOnInit(): void {
    this.entityStatus = this.enumToObject.transform(EntityStatusEnum);
  }

  ngOnDestroy() {
  }

  onChangedVal(status: any) {
    this.selectedStatus = status != null ? status.value : 0;
    this.onTouched();
    this.onChanged(this.selectedStatus);
  }

  writeValue(status: string): void {
    this.selectedStatus = status;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
