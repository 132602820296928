import {
  BaseFilterCellComponent,
  FilterService
} from '@progress/kendo-angular-grid';
import { Component, Input, OnInit } from '@angular/core';
import { UserService, UserSiteDTO } from '@app/core/services/http-services/common/user.service';

/**
 * NOTE: Interface declaration here is for demo compilation purposes only!
 * In the usual case include it as an import from the data query package:
 *
 * import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
 */
interface CompositeFilterDescriptor {
  logic: 'or' | 'and';
  filters: Array<any>;
}

@Component({
  selector: 'app-site-filter',
  template: `
    <kendo-dropdownlist
      [data]="userSites"
      (valueChange)="onChange($event)"
      [defaultItem]="defaultItem"
      [value]="selectedValue"
      [valuePrimitive]="true"
      [textField]="'siteName'"
      [valueField]="'siteId'"
    >
    </kendo-dropdownlist>
  `
})
export class SiteFilterComponent extends BaseFilterCellComponent implements OnInit {
  public get selectedValue(): unknown {
    const filter = this.filterByField(this.filterField);
    return filter ? filter.value : null;
  }

  @Input() public override filter: CompositeFilterDescriptor = null;
  @Input() public filterField: string = 'siteId';

  public userSites:UserSiteDTO[] = [];

  public get defaultItem(): { [Key: string]: unknown } {
    return {
      siteName: 'Select item...',
      siteId: null
    };
  }

  constructor(filterService: FilterService, public userService:UserService) {
    super(filterService);
  }

  public onChange(value: unknown): void {
    this.applyFilter(
      value === null // value of the default item
        ? this.removeFilter(this.filterField) // remove the filter
        : this.updateFilter({
          // add a filter for the field with the value
          field: this.filterField,
          operator: 'eq',
          value
        })
    ); // update the root filter
  }

  public async ngOnInit() {
    const user = await this.userService.getUser();

    this.userSites = user.userSites;
  }
}
