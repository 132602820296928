import { AppNotification, NotificationLevel } from '@app/core/models/app-notification';
import { Component, Input, OnInit } from '@angular/core';
import { SVGIcon, infoSolidIcon } from '@progress/kendo-svg-icons';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DashboardCardComponent } from '../dashboard-layout/dashboard-card-component';

@Component({
  selector: 'app-notification-display',
  template: `
  <div class="notification-container">
    <app-notification *ngFor="let n of notifications" [notification]="n"></app-notification>
  </div>`,
  styleUrls: ['./notification.component.css']
})
export class NotificationDisplayComponent implements OnInit, DashboardCardComponent {
  data: number;
  showTitle: boolean = true;
  notifications: AppNotification[] = [];
  chanel: number;

  constructor(public notificationService: AppNotificationService) { }

  ngOnInit() {
    this.chanel = this.data;

    /*    this.notificationService.getNotificationsByChanel(this.chanel).subscribe(not => {
      this.notifications = not;
    }) */
  }
}

@Component({
  selector: 'app-notification',
  template: `
  <div [ngClass]="getNotificationClass()">
    <kendo-svg-icon [icon]="infoSolidIcon"></kendo-svg-icon>
    <div>
    <span>{{notification.title}}</span>
    <span>{{notification.message}}</span>
    </div>

  </div>
  `,
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements DashboardCardComponent {
  data: any;
  showTitle: boolean = true;
  @Input() notification: AppNotification;

  public infoSolidIcon:SVGIcon = infoSolidIcon;

  constructor() { }

  public getNotificationClass(): string {
    let classString = 'notification ';

    if (this.notification.level === NotificationLevel.INFO) {
      classString += 'notification-info';
    } else {
      classString += 'notification-info';
    }

    return classString;
  }
}

export class NotificationDisplayData {

}
