<h1 class="header">
  {{'Operative.ProdOrder' | translate}}
</h1>
<div class="icon-container">
  <kendo-icon
    name="reload"
    (click)="reloadProdOrderGrid()">
  </kendo-icon>
</div>
<section>
  <form
    [formGroup]="prodorderFilterForm"
    class="form">
    <app-site-selector  formControlName="siteId"
                        [showLable]="false"
                        (onSiteSelected)="reloadProdOrderGrid()">
    </app-site-selector>
  </form>
  <div style="flex-grow: 1;"></div>
  <form class="form"
    [formGroup]="selectEntityForm">
    <app-select-entity
    entitycombobox_width_class = "entitycombobox-800"
    formControlName="materialIndex"
    [entityTypeCode]="'RESOURCE'"
    [showLable]="true"
    [label]="'Operative.SelectMaterial'">
  </app-select-entity>
      <button
        kendoButton
        (click)="openProdOrderMaterialDialog()"
        [disabled]="!selectEntityForm.valid"
        themeColor="primary"
        appAccessControl deniedRoles="{{deniedRole}}">
        {{ 'Operative.NewProdOrder' | translate }}
      </button>
      <button
            kendoButton
            (click)="openAutoSchedulerPopup()"
            appAccessControl deniedRoles="{{deniedRole}}"
            themeColor="primary">
            {{'Operative.AutoScheduler' | translate}}
        </button>
  </form>
</section>

<app-prod-order-grid>
</app-prod-order-grid>
