import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppControlsModule } from '../app-controls/app-controls.module';
import { CommonModule } from '@angular/common';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OptimizationRoutingModule } from './optimization-routing.module';
import { OptimizationRunAdminComponent } from './optimization-run-admin/optimization-run-admin.component';
import { OptimizationRunDetailComponent } from './optimization-run-detail/optimization-run-detail.component';
import { SequencerComponent } from './sequencer/sequencer.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SequencerComponent,
    OptimizationRunAdminComponent,
    OptimizationRunDetailComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    KendoImportModule,
    TranslateModule,
    OptimizationRoutingModule,
    AppControlsModule,
    SharedModule

  ]
})
export class OptimizationModule { }
