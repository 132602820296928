import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { CreateGlulamSpecForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-form.model';
import { CreateGlulamSpecRowForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-row-form.model';
import {
  GlulamSpecFormModel,
  IGlulamSpecFormData,
  IProductListItem,
  GlulamSpecData
} from '@app/core/models/gulam-spec.model';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntityService } from '@app/core/services/http-services/model/entity.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

marker('EntityTranslation.MinStockShouldbeGreaterThanZero');
marker('GluePlan.EntityMandatory');
marker('GluePlan.NrOfLamellasShouldbeGreaterThanZero');
marker('GluePlan.ThicknessShouldbeGreaterThanZero');
marker('GluePlan.EntityHasNoThickness');
marker('GluePlan.EntityThicknessExceeded');
marker('GluePlan.BeamHightMandatory');
marker('GluePlan.MoreThanOneHeightDependant');

const createFormGroup = (e: GlulamSpecFormModel) =>
  new FormGroup<CreateGlulamSpecForm>(
    {
      specrows: new FormArray(
        e.specrows.sort((a, b) => a.sequence - b.sequence).map<FormGroup>((pv) => createFormGroupGS(pv)),
        []
      ),
      hightMin: new FormControl<number>(e.hightMin, [Validators.required, Validators.min(1)]),
      hightMax: new FormControl<number>(e.hightMax, [Validators.required, Validators.min(1), maxLargerMin('hightMin')])
    },
    [sumHightBelowMax, onlyOneHightDep]
  );

const createFormGroupGS = (gs: IGlulamSpecFormData) =>
  new FormGroup<CreateGlulamSpecRowForm>({
    activityIndex: new FormControl<number>(gs.activityIndex),
    productIndex: new FormControl<number>(gs.productIndex, [Validators.required]),
    sequence: new FormControl<number>(gs.sequence),
    numberOfLammellas: new FormControl<number>(gs.numberOfLammellas, [Validators.required, Validators.min(1)]),
    planingThickness: new FormControl<number>(gs.planingThickness, [Validators.required, Validators.min(1)]),
    maxThickness: new FormControl<number>(gs.maxThickness),
    heightDependant: new FormControl<boolean>(gs.heightDependant),
    dryJoint: new FormControl<boolean>(gs.dryJoint),
    turn: new FormControl<boolean>(gs.turn),
    isNew: new FormControl<boolean>(gs.isNew),
    isChanged: new FormControl<boolean>(gs.isChanged),
    isRemoved: new FormControl<boolean>(gs.isRemoved)
  });

export function maxLargerMin(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const c2 = control?.parent?.get(controlName);

    if (control?.value <= c2?.value) {
      return {
        maxLargerMin: true
      };
    }
  };
}

export const sumHightBelowMax: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formVal = (control as FormGroup)?.value;
  let sum = 0;
  const rows = formVal.specrows;
  for (const i in rows) {
    sum += rows[i]?.numberOfLammellas * rows[i]?.planingThickness;
  }

  if (sum < formVal.hightMin) {
    return {
      sumSmallerThanMin: true
    };
  } else if (sum > formVal.hightMax) {
    return {
      sumSmallerThanMin: true
    };
  }

  return null;
};

export const onlyOneHightDep: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formVal = (control as FormGroup)?.value;
  const rows = formVal.specrows as any[];

  if (rows.filter((i) => i.heightDependant === true).length > 1) {
    return {
      onlyOneHightDep: true
    };
  }

  return null;
};

@Component({
  selector: 'app-glulam-spec',
  templateUrl: './glulam-spec.component.html',
  styleUrls: ['./glulam-spec.component.css']
})
export class GlulamSpecComponent extends DialogContentBase implements OnInit {
  public loading = false;
  public glulamspecForm: FormGroup;
  public formdata: GlulamSpecFormModel;
  public productList: IProductListItem[] = [];
  status = [
    { text: 'Yes', value: true },
    { text: 'No', value: false }
  ];

  public get specRows() {
    return this.glulamspecForm.get('specrows') as FormArray;
  }

  buildForm() {
    this.glulamspecForm = createFormGroup(this.formdata);
  }

  constructor(
    @Inject(DialogRef) public data: GlulamSpecData,
    public entityService: EntityService,
    private dialogRef: DialogRef,
    private appNotificationService: AppNotificationService
  ) { super(dialogRef); }

  ngOnInit(): void {
    this.loading = true;
    this.productList = this.data.productList;
    this.formdata = { specrows: [], hightMin: this.data.hightMin, hightMax: this.data.hightMax };
    this.data.glulamSpecs.forEach((gs) => {
      this.formdata.specrows.push(<IGlulamSpecFormData>{
        activityIndex: gs.activityIndex,
        heightDependant: gs.heightDependant,
        dryJoint: gs.dryJoint,
        turn: gs.turn,
        numberOfLammellas: gs.numberOfLammellas,
        planingThickness: gs.planingThickness,
        maxThickness: this.getMaxThickness(gs.productIndex),
        productIndex: gs.productIndex,
        sequence: gs.sequence,
        isNew: false,
        isChanged: false,
        isRemoved: false
      });
    });

    this.buildForm();
    this.loading = false;
  }

  getMaxThickness(productIndex: number): number {
    const entity = this.productList.find((f) => f.key === productIndex);
    if (entity !== null) {
      return entity.thickness;
    }
    return 0;
  }

  onTurnChange(c: AbstractControl) {
    c.get('isChanged').patchValue(true);
  }

  onProductChange(c: AbstractControl) {
    const pi = c.get('productIndex').value;
    const mv = this.getMaxThickness(pi);
    c.get('maxThickness').patchValue(mv);
  }

  calculateRelation(): Map<number, number> {
    const relations = new Map<number, number>();

    const rows = this.specRows;
    rows.controls.forEach((r) => {
      const pi = r.get('productIndex').value;
      const nl = r.get('numberOfLammellas').value;
      const removed = r.get('isRemoved').value;

      const entity = this.productList.find((f) => f.key === pi);
      const relation = (((entity.width / 1000) * entity.thickness) / 1000) * nl;
      if (relations.has(pi)) {
        if (!removed) {
          relations.set(pi, relations.get(pi) + relation);
        }
      } else {
        if (removed) {
          relations.set(pi, 0);
        } else {
          relations.set(pi, relation);
        }
      }
    });

    return relations;
  }

  onSubmit() {
    this.loading = true;
    if (this.validate()) {
      this.dialogRef.close({ success: true, relations: this.calculateRelation(), glulamspec: this.glulamspecForm });
    } else {
      this.loading = false;
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  validate(): boolean {
    let valid = true;
    if (!this.glulamspecForm.valid) {
      const messages = this.glulamspecForm?.errors?.['errormessages'];
      messages.forEach((e) => {
        this.showMessage(e);
      });
      valid = false;
    }

    return valid;
  }

  showMessage(message: string) {
    this.appNotificationService.notifyErrorAppChanel(message);
  }

  removeRow(specIndex: number) {
    const rows = this.specRows;
    rows.at(specIndex).get('isRemoved').patchValue(true);
    if (rows.at(specIndex).get('isNew').value) {
      rows.removeAt(specIndex);
    }
  }

  addRow() {
    const rows = this.specRows;
    const maxSequenseValue = Math.max(...rows.controls.map((o) => o.get('sequence').value), 0);

    rows.insert(
      rows.length,
      createFormGroupGS({
        activityIndex: this.data.activityIndex,
        heightDependant: false,
        numberOfLammellas: null,
        planingThickness: null,
        maxThickness: 0,
        productIndex: null,
        sequence: maxSequenseValue + 1,
        dryJoint: false,
        turn: false,
        isNew: true,
        isChanged: false,
        isRemoved: false
      })
    );
  }
}
