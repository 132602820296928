export class BeamOrderLineActionCreator {
  static FromJson(lines: any): Array<BeamOrderLineAction> {
    return lines.json().map(obj => new BeamOrderLineAction(obj.OrderRowID, obj.Action, obj.Sucsess, obj.Message));
  }
}

export class BeamOrderLineAction {
  public constructor(
    public OrderRowID: number,
    public Action: number,
    public Sucsess: boolean,
    public Message: string) {

  }
}
