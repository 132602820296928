import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class GraphService extends BaseService {
  constructor(
    http: HttpClient,
    config: ConfigurationService,
    notificationService: AppNotificationService
  ) {
    super(http, config, notificationService);
  }

  // /get resource by activity details
  // http://localhost:58022/getresourcebyactivitydetails?planIndex=1

  /// get activity by resource details
  // http://localhost:58022/getactivitybyresourcedetails?planIndex=1

  //   getGraph(): Observable<Graph> {
  //     return this.http.get<Graph>(this.configurations.baseApiUrl + "Graph");
  //   }

  getLogClasses(): Observable<Entity[]> {
    return this.http.get<Entity[]>(this.configurations.baseApiUrl + 'Graph');
  }

  /** entity information in different format. It is shown in node-detail dialog */
  getEntityInformation(entityIndex: number): Observable<EntityInformation[]> {
    return this.http.get<EntityInformation[]>(
      this.configurations.baseUrl +
        'getentityinformation?entityId=' +
        entityIndex
    );
  }

  /** all resources to show in autocomplete */
  getAllResources(planIndex: number): Observable<ActionResource[]> {
    return this.http.get<ActionResource[]>(
      this.configurations.baseUrl + 'getallresources?planIndex=' + planIndex
    );
  }

  /** all activities to show in autocomplete */
  getAllActivities(planIndex: number): Observable<ActionResource[]> {
    return this.http.get<ActionResource[]>(
      this.configurations.baseUrl + 'getallactivities?planIndex=' + planIndex
    );
  }

  getActivityByResourceDetails(
    parameter: GraphActionResourceParameter
  ): Observable<ActionResource[]> {
    return this.http.put<ActionResource[]>(
      this.configurations.baseUrl + 'getactivitybyresourcedetails',
      JSON.stringify(parameter)
    );
  }

  getResourceByActivityDetails(
    parameter: GraphActionResourceParameter
  ): Observable<ActionResource[]> {
    return this.http.put<ActionResource[]>(
      this.configurations.baseUrl + 'getresourcebyactivitydetails',
      JSON.stringify(parameter)
    );
  }

  save(saveParameter: GraphParam) {
    return this.http.put<ActionResource[]>(
      this.configurations.baseUrl + 'save',
      JSON.stringify(saveParameter)
    );
  }
}

export class Group {
  constructor() {}

  private _ActionResourceNodes: ActionResource[] = [];
  public Index: number;
  public GroupsEntitiesType: string;
  public SelectedEntityId: any;
  /**
   * selected Value from drop down either entity (logclass) or action resource.
   * */
  public SelectedEntity: any;
  // public SelectedEntityList: FormControl;
  public SelectedGroupCode: any;

  public set ActionResourceNodes(value: ActionResource[]) {
    this._ActionResourceNodes = value;
  }

  public get ActionResourceNodes() {
    if (!this.SelectedGroupCode) {
      return [];
    }

    return this.Index === 0
      ? this._ActionResourceNodes
      : this._ActionResourceNodes.filter(
        x => x.EntityGroupCode === this.SelectedGroupCode
      );
  }

  public get GroupCodes(): string[] {
    if (this.Index === 0) {
      setTimeout(() => {
        this.SelectedGroupCode = 'LogClass';
      }, 50);
      return ['LogClass'];
    }

    const result: string[] = [];
    if (
      this._ActionResourceNodes &&
      this._ActionResourceNodes.some(x => (!!x))
    ) {
      this._ActionResourceNodes.forEach(x => {
        if (!(result.indexOf(x.EntityGroupCode) >= 0)) {
          result.push(x.EntityGroupCode);
        }
      });
    }

    return result;
  }

  public get VisibleNodes(): ActionResource[] {
    let result: ActionResource[] = [];
    if (!this.SelectedGroupCode) {
      return result;
    }

    if (this.Index === 0) {
      if (this.SelectedEntity && Object.prototype.hasOwnProperty.call(this.SelectedEntity, 'Code')) {
        // first group should be always logclass
        //
        this.SelectedEntity.Color = 'Green';
        result =
          this.GroupsEntitiesType.toLowerCase() === 'LogClass'.toLowerCase()
            ? [this.SelectedEntity]
            : [];
      } else {
        return result;
      }
    } else if (
      this.SelectedEntity &&
      Object.prototype.hasOwnProperty.call(this.SelectedEntity, 'Code')
    ) {
      // selected object's circle should be hidden on UI
      //
      result = this._ActionResourceNodes
        .filter(x => x.Code === this.SelectedEntity.Code)
        .sort((a, b) => {
          if (a.Index < b.Index) return -1;
          if (a.Index > b.Index) return 1;
          if (a.Index === b.Index) return 0;
          return 0;
        });
    } else {
      result = this._ActionResourceNodes
        .filter(x => x.EntityGroupCode === this.SelectedGroupCode)
        .sort((a, b) => {
          if (a.Index < b.Index) return -1;
          if (a.Index > b.Index) return 1;
          if (a.Index === b.Index) return 0;
          return 0;
        });
    }

    return this.RemoveDuplicate(result);
  }

  public get NumberOfEntities(): number {
    return this.VisibleNodes.length;
  }

  public getIndexesOfGroupCode(groupCode: string) {
    const result = [];
    this.RemoveDuplicate(
      this._ActionResourceNodes.filter(x => x.EntityGroupCode === groupCode)
    ).forEach(y => {
      result.push(y.Index);
    });

    return result;
  }

  private RemoveDuplicate(list: ActionResource[]) {
    const result: ActionResource[] = [];
    list.forEach(y => {
      if (result.findIndex(z => z.Index === y.Index) < 0) {
        result.push(y);
      }
    });

    return result;
  }
}

export interface GraphActionResourceParameter {
  PlanIndex: number;
  EntityIndexes: number[];
  EntityGroupCodes: string[];
}

export interface Entity {
  BaseUnitCode: string;
  Code: string;
  CommentId: number;
  Description: string;
  EntityGroupCode: string;
  Index: number;
  IsDynamicActivity: boolean;
  SortOrderIndex: number;
  Status: number;
  SuperiorEntityIndex: number;
  UserCode: string;
}

export class EntityInformation {
  AreValuesValidated: boolean;
  Code: string;
  DataType: number;
  Description: string;
  Index: number;
  PropertyDescription: string;
  ValueChar: string;
  ValueDouble: number;
  valueInt: number;
}

export class ActionResource {
  constructor() {
    this.BackwordRelations = [];
    this.ForwardRelations = [];
  }

  public NodeIndex: number;

  public Index: number;
  public ParentIndex: number;
  public Code: string;
  public Description: string;
  public EntityGroupCode: string;
  public Rel: number;
  public Quantity: number;
  public LowBound: number;
  public UpBound: number;
  public Type: string;
  public BaseUnitCode: string;
  // Represent index to which group this object belongs
  public GroupIndex: number;
  public X: number;
  public Y: number;
  public Color: string;
  public BackwordRelations: Relation[];
  public ForwardRelations: Relation[];
}

export interface Node {
  X: number;
  Y: number;
  Index: number;
  Color: string;
}

export class Relation {
  Index: number;
  X: number;
  Y: number;
  FromIndex: number;
  ToIndex: number;
  FromNode: ActionResource;
  ToNode: ActionResource;
}

// Parameter Interfaces for saving graph
export class GraphParam {
  GraphCode: string;
  Levels: Level[];
  public get LevelJson(): string {
    return JSON.stringify(this.Levels);
  }
}

export class Level {
  LevelIndex: number;
  Groups: GroupParam[];
}

export class GroupParam {
  GroupIndex: number;
  EntityGroupCode: string;
  RemovedEntityCode: string[];
  selectEntity: string[];
}
