import { Component, ViewChild, Input, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DashbordCardDirective } from './dashbord-card.directive';
import { CardItem } from '../models/CardItem.model';
import { CardItemService } from '../services/dashbord-card-item.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ngxLoadingAnimationTypes } from 'ngx-spinner';
@Component({
  selector: 'app-dashboard-layout-card',
  template: `
  <kendo-card class="dashboard-card" width="100%" [style.background-color]="card.backgroundColor">
  <kendo-card-header *ngIf="card.showTitle">
    <h1 kendoCardTitle >
      {{card.title}}
      <!--<button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <kendo-svg-icon>more_vert</kendo-svg-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item>Expand</button>
        <button mat-menu-item>Remove</button>
      </mat-menu>-->
    </h1>
  </kendo-card-header>
  <kendo-card-body class="dashboard-card-content">

    <ng-template ad-host></ng-template>
    <span *ngIf="!card.component">Can't Load</span>
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  </kendo-card-body>
</kendo-card>`,
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutCardComponent implements OnInit {
  @Input() card: CardItem;

  @ViewChild(DashbordCardDirective, { static: true }) adHost: DashbordCardDirective;

  // public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public cardService: CardItemService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.loadComponent();
  }

  loadComponent() {
    if (this.card.component != null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.card.component);

      const viewContainerRef = this.adHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<DashboardCardComponent>componentRef.instance).data = this.card.data;

      this.card.showTitle = (<DashboardCardComponent>componentRef.instance).showTitle;

      this.spinner.hide();
    }
  }
}

export interface DashboardCardComponent {
  data: any;
  showTitle: boolean;
  // loaded: Observable<any>;
}
