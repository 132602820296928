import { Component, Directive, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { GluingPlan } from '../../../core/models/gluing-plans.model';
import { BeamLamminaDimension } from '../../../core/models/beam-lammina-dimension.model';
import { Subscription } from 'rxjs';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { Router } from '@angular/router';
import { GlusetStateHelper } from './gluplan-state-helper';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetStateService, IBeamMaterialGlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { SVGIcon, eyeIcon, downloadIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-glue-plans-history',
  templateUrl: './glue-plans-history.component.html',
  styleUrls: ['./glue-plans-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GluePlansHistoryComponent implements OnInit {
  state: State = {
    skip: 0,
    take: 40,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [
        { field: 'gluingPlanID', operator: 'contains', value: '' },
        { field: 'name', operator: 'contains', value: '' },
        { field: 'instruction', operator: 'contains', value: '' },
        { field: 'stateID', operator: 'contains', value: 2 },
        { field: 'dimentions', operator: 'contains', value: '' }
      ]
    }
  };

  states:IBeamMaterialGlueSetState[];
  beamLamminaDimensions: BeamLamminaDimension[];
  filterObj: any = {};

  public eyeIcon: SVGIcon = eyeIcon;
  public downloadIcon:SVGIcon = downloadIcon;

  constructor( private productionFileService: ProductionFileService,
    private dimensionService: DimensionService,
    private router:Router,
    private stateService: GlueSetStateService) { }

  ngOnInit(): void {
    this.dimensionService.getBeamLamminaDimentions().subscribe(blds => {
      this.beamLamminaDimensions = blds;
    });

    this.stateService.getGlusetStates().subscribe(s => {
      this.states = s;

      this.states.forEach(x => {
        x.name = x.name.toUpperCase();
      });
    });
  }

  async download(gluingPlan: GluingPlan) {
    const blob = await this.productionFileService.productionFileForGlueplan(gluingPlan.gluingPlanID);
    DownloadFileHelper.downloadFile(blob, `GluePlan-${gluingPlan.gluingPlanID}-${new Date(Date.now()).toISOString().split('T')[0]}.zip`);
  }

  onStateChange(state: DataStateChangeEvent) {
    this.state = state;
  }

  viewGlueplan(item: GluingPlan) {
    this.router.navigate(['glulam/gluplam-view', item.gluingPlanID]);
  }

  getStateColor(code: number) {
    return gluplanColorMap(code);
  }

  getStateText(code:number):string {
    return GlusetStateHelper.getStateText(code);
  }

}

export const gluplanColorMap = (code: number) => {
  switch (code) {
    case 1: return '#6c757d';
    case 2: return '#007bff';
    case 5: return '#28a745';
    case 6: return '#dc3545';
    case 7: return '#007bff';
    case 8: return '#007bff';
    case 9: return '#dc3545';
  }
};

@Directive({
  selector: '[gluePlanBinding]'
})
export class GluePlanBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;

  constructor(private gluePlanService: GluePlanService, grid: GridComponent) {
    super(grid);
  }

  override ngOnInit(): void {
    this.serviceSubscription = this.gluePlanService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
    });

    super.ngOnInit();
    this.rebind();
  }

  override ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  override rebind(): void {
    this.grid.loading = true;
    this.gluePlanService.query(this.state);
  }

  override onStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.rebind();
  }
}
