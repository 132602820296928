import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

import { DimensionService } from './dimension.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductionFileService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    public dimensioService: DimensionService
  ) {
    super(http, conf, notificationService);
  }

  public async productionFileForGlueset(id: number): Promise<Blob> {
    return this.downloadResource(`${this.configurations.baseApiUrl}ProductionFile/gluset/` + id);
  }

  public async productionFileForGlueplan(id: number): Promise<Blob> {
    return this.downloadResource(`${this.configurations.baseApiUrl}ProductionFile/glungPlan/` + id);
  }

  private async downloadResource(url:string): Promise<Blob> {
    const file = await firstValueFrom(this.http.get<Blob>(url, { responseType: 'blob' as 'json' }));
    return file;
  }
}
