<h1>
    {{'Optimization.Run' | translate }} {{ logItem.cplexJobID }}
</h1>
<section class="row">
    <div style="flex-grow: 1;">
        <h2 translate>Optimization.Kpis</h2>
        <kendo-grid [data]="logItem.kpis" [size]="'small'">
            <kendo-grid-column field="code"></kendo-grid-column>
            <kendo-grid-column field="value"></kendo-grid-column>
        </kendo-grid>
    </div>
</section>
<section class="row">
    <div style="flex-grow: 1;">
        <h2 translate>Optimization.Output</h2>
        <json-formatter [json]="outputData"></json-formatter>
    </div>
    <div style="flex-grow: 1;">
        <h2 translate>Optimization.Input</h2>
        <json-formatter
            [json]="inputData"
            style="flex-grow: 1;">
        </json-formatter>
    </div>
</section>
