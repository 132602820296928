import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('AoT.PlanningType.Normal');
marker('AoT.PlanningType.GlulamStatical');
marker('AoT.PlanningType.GlulamContinous');
marker('AoT.PlanningType.CLT');

export enum PlanningTypeEnum {
        Normal = 0,
        GlulamStatical = 1,
        GlulamContinous = 2,
        CLT = 3
}

export function getPlanningTypeValues() {
  return [
    {
      value: PlanningTypeEnum.Normal,
      text: 'AoT.PlanningType.Normal'
    },
    {
      value: PlanningTypeEnum.GlulamStatical,
      text: 'AoT.PlanningType.GlulamStatical'
    },
    {
      value: PlanningTypeEnum.GlulamContinous,
      text: 'AoT.PlanningType.GlulamContinous'
    },
    {
      value: PlanningTypeEnum.CLT,
      text: 'AoT.PlanningType.CLT'
    }
  ];
}
