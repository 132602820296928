<h1>{{'Operative.Instruction' | translate}}</h1>

<section class="app-button-row">
  <div style="flex-grow: 1;"></div>
  <button kendoButton class="k-mb-1" themeColor="primary" (click)="addInstructionRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

<kendo-grid
    [data]="instructions"
    class="crud-grid"
    [pageSize]="state.take"
    [skip]="state.skip"
    [pageable]="true"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    [filterable]="true"
    [filter]="state.filter"
    [loading]="loadInstructionData"
    (dataStateChange)="dataStateChange($event)"
    [size]="'small'">

    <kendo-grid-column
        field="name"
        title="{{ 'Shift.Name'|translate  }}">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
            kendoGridEditTemplate
            let-formGroup="formGroup">
            <kendo-textbox [formControl]="formGroup.get('name')"> </kendo-textbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="code"
                       title="{{ 'App.Code'|translate  }}">
    </kendo-grid-column>
    <kendo-grid-column  field="materialPropertyCode"
                        title="{{ 'Operative.MaterialPropertyCode'|translate  }}">
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'App.Action' | translate}}">
        <ng-template
            kendoGridCellTemplate
            let-isNew="isNew"
            let-dataItem>
            <button
                kendoGridEditCommand
                [primary]="true">
                {{ 'App.Edit' | translate }}
            </button>
            <button
                kendoGridRemoveCommand
                themeColor="error">
                {{ 'App.Remove' | translate }}
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<app-confirmation-dialog
    *ngIf="itemToRemove"
    (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>
