<kendo-dialog-titlebar kendo-dialog-title>
  <div translate>App.Update</div>
</kendo-dialog-titlebar>

<div class="container">
  <form [formGroup]="updateProdOrderForm" style="height: 98%">
    <div class="col">
      <div class="row control-group" formGroupName="state">
        <kendo-formfield>
          <kendo-switch formControlName="change" color="primary"> </kendo-switch>
        </kendo-formfield>
        <kendo-label translate>App.Change</kendo-label>
        <div style="flex-grow: 1"></div>
        <app-select-prod-order-state
          style="flex-grow: 1; width: 200px"
          formControlName="val"
          [getAllStates]="true"
        ></app-select-prod-order-state>
      </div>
      <div class="row control-group" formGroupName="instruction">
        <kendo-formfield>
          <kendo-switch formControlName="change" color="primary"> </kendo-switch>
        </kendo-formfield>
        <kendo-label translate>App.Change</kendo-label>
        <div style="flex-grow: 1"></div>
        <kendo-formfield style="flex-grow: 1">
          <kendo-label translate>Operative.Instruction</kendo-label>
          <kendo-textarea formControlName="val" color="primary" [rows]="5"> </kendo-textarea>
        </kendo-formfield>
      </div>
      <div class="row control-group" formGroupName="startdate">
        <kendo-formfield>
          <kendo-switch formControlName="change" color="primary"> </kendo-switch>
        </kendo-formfield>
        <kendo-label translate>App.Change</kendo-label>
        <div style="flex-grow: 1"></div>
        <kendo-formfield style="flex-grow: 1">
          <kendo-label translate>App.StartDate</kendo-label>
          <kendo-datetimepicker formControlName="val"></kendo-datetimepicker>
        </kendo-formfield>
      </div>
      <div class="row control-group" *ngIf="runnign">
        <div style="flex-grow: 1"></div>
        <kendo-loader> </kendo-loader>
        <div style="flex-grow: 1"></div>
      </div>
    </div>
  </form>
</div>

<kendo-dialog-actions kendo-dialog-action>
  <div class="flex-grow-2"></div>
  <div class="btn-holder">
    <button kendoButton type="button" class="closebtn" (click)="close(false)">
      {{ 'App.Close' | translate }}
    </button>
    <button kendoButton themeColor="primary" type="submit" (click)="save()" [disabled]="!updateProdOrderForm.valid">
      {{ 'App.Submit' | translate }}
    </button>
  </div>
</kendo-dialog-actions>

<app-confirmation-dialog
*ngIf="confirmStateUpdate"
(conformationDialogCloseEvent)="confirmUpdate($event)" [msg]="changeStateBackToReleaseMsg">
</app-confirmation-dialog>
