import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Component, Input } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.SelectItem');

@Component({
  selector: 'app-kendo-grid-drop-down-filter',
  template: `
  <kendo-dropdownlist
      [data]="data"
      (valueChange)="onChange($event)"
      [defaultItem]="defaultItem"
      [value]="defaultSelectedValue ?? selectedValue"
      [valuePrimitive]="true"
      [textField]="textField"
      [valueField]="valueField"
    >
      <ng-template kendoDropDownListValueTemplate  let-dataItem>
        {{ getSelectedText(dataItem)  | translate }}
      </ng-template>

      <ng-template  kendoDropDownListItemTemplate let-dataItem>
          {{  getItemText(dataItem) | translate}}
      </ng-template>

    </kendo-dropdownlist>
  `,
  styles: [
  ]
})
export class KendoGridDropDownFilterComponent extends BaseFilterCellComponent {
  public get selectedValue(): any {
    const filter = this.filterByField(this.filterCode ?? this.valueField);
    return filter ? filter.value : null;
  }

  getSelectedText(dataItem:any) {
    if (dataItem === undefined) {
      return '';
    } else if (dataItem[this.textField] === this.selectItemText || this.trasPrefix === null) {
      return dataItem[this.textField];
    } else {
      return `${this.trasPrefix}.${dataItem[this.textField]}`;
    }
  }

  public getItemText(dataItem:any):string {
    if (dataItem[this.textField] === this.selectItemText || this.trasPrefix === null) {
      return dataItem[this.textField];
    } else {
      return `${this.trasPrefix}.${dataItem[this.textField]}`;
    }
  }

  @Input() public override filter: any = null;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public filterCode: string;
  @Input() public trasPrefix:string = null;
  @Input() public isValueTypeString:boolean = false;
  @Input() set defaultSelectedValue(value: any) {
    this.onChange(value);
  };

  public selectItemText = 'App.SelectItem';

  public get defaultItem(): any {
    return {
      [this.textField]: this.selectItemText,
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: any): void {
    this.applyFilter(
      this.isNullOrUndefined(value) // value of the default item
        ? this.removeFilter(this.filterCode ?? this.valueField) // remove the filter
        : this.updateFilter({
          // add a filter for the field with the value
          field: this.filterCode ?? this.valueField,
          operator: 'eq',
          value: this.isValueTypeString ? value.toString() : value
        })
    ); // update the root filter
  }

  private isNullOrUndefined(value: any) {
    return value === undefined || value === null;
  }
}
