<h1 translate>Operative.ScheduleTitle</h1>

<section>
  <div style="flex-grow: 1;"></div>
    <button kendoButton
            class="k-mb-1"
            themeColor="primary"
            (click)="addSchedule()">
            {{ 'App.AddNew' | translate }}
      </button>
</section>

<app-schedule-grid [refreshScheduleGrid]="refreshScheduleGrid"></app-schedule-grid>

<!-- Do not remove needed for delete dialog-->
<div kendoDialogContainer></div>
