import { BehaviorSubject, Observable } from 'rxjs';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { IEntityGroup } from '../../custom-services/entity-group.service';
import { IStandardProperties } from './entity.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntitySimpleService extends BehaviorSubject<GridDataResult> {
  loading: boolean = false;

  private contUrl = `${environment.apiUrl}EntitySimple`;

  constructor(
    public http: HttpClient
  ) {
    super(null);
  }

  /*
  query() {
    this.loading = true;
    this.http.post<GridDataResult>(this.contUrl, JSON.stringify(filter), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe(r => {
      super.next(r);
      this.loading = false;
    });
  }
*/
  get(entityIndex: number): Observable<IEntitySimpleDTO> {
    return this.http.get<IEntitySimpleDTO>(`${this.contUrl}/${entityIndex}`);
  }
}

export interface IEntitySimpleDTO {
  baseUnitCode: string;
  changeDate: string | null;
  code: string;
  commentId: number | null;
  description: string;
  entityGroupCode: string;
  index: number;
  isDynamicActivity: boolean | null;
  sortOrderIndex: number | null;
  status: number | null;
  superiorEntityIndex: number | null;
  userCode: string;
  machineId: number;
  entityGroup?: IEntityGroup;
  targetMinStock: number | null;
  targetMaxStock: number | null;
  batchSize: number | null;
  guid:string;
  beamHightMin: number | null;
  beamHightMax: number | null;
  standardProperties?: IStandardProperties
}
