import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from '@app/core/services/custom-services/application-insights.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, PopupRequest } from '@azure/msal-browser';
import { LocalStorageService } from '../custom-services/localstorage.service';
import { RoleBasedAccessHelper, UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userName: string;
  token: string;
  user: any = null;
  apiTooken: string;
  apiAzureTooken: string;
  deniedRoles = [UserRoleEnum.Operator];

  isIframe = false;
  loggedIn:boolean = null;

  public static apiScope:string = 'https://saas.forestx.se/optinex-api/.default';

  constructor(
      public msalService: MsalService,
      public broadcastService:MsalBroadcastService,
      public router: Router,
      public applicationInsightsService: ApplicationInsightsService,
      public localStorageService:LocalStorageService,
      private roleBasedAccessHelper:RoleBasedAccessHelper,
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
  }
  // handleLoginFlow():boolean {
  //   return true;
  /*
    this.msalService.ssoSilent({ });

    this.checkAccount();

    this.broadcastService.msalSubject$.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });
/*
    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        this.monitorService.logException(authError);
        return;
      }
    });

    this.broadcastService.subscribe("msal:loginFailure", payload => {
      this.monitorService.logException(payload);
    });

    this.msalService.setLogger(new Logger((logLevel, message) => {

      if(logLevel == LogLevel.Warning)
      this.monitorService.logTrace(message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));

    return this.loggedIn;
    */
  // }

  isLoggedIn() {
    const allAccounts = this.msalService.instance.getAllAccounts();
    const isLoggedIn = allAccounts.length > 0;
    if (isLoggedIn) {
      this.setActiveAccount(allAccounts[0]);
    }
    return isLoggedIn;
  }

  logout() {
    this.applicationInsightsService.clearUserId();
    this.msalService.logoutRedirect();
  }

  setActiveAccountCurrent() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
    }
  }

  setActiveAccount(account: AccountInfo) {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      this.msalService.instance.setActiveAccount(account);
    }
    this.applicationInsightsService.setUserId(account.username, account.tenantId);
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe({
          next: (result) => {
            this.setActiveAccount(result.account);
            this.redirectBasedOnRole();
          },
          error: (error) => console.log(error)
        });
    } else {
      this.msalService.loginPopup()
        .subscribe({
          next: (result) => {
            this.setActiveAccount(result.account);
            this.redirectBasedOnRole();
          },
          error: (error) => console.log(error)
        });
    }
  }

  getAccount():any {
    return this.msalService.instance.getActiveAccount();
  }

  redirectBasedOnRole() {
    if (this.roleBasedAccessHelper.hasOnlyInputRole(this.deniedRoles)) {
      this.router.navigate(['/prod-order']);
    } else {
      this.router.navigate(['dashboard']);
    }
  }
}

export interface UserClaim {
  name?: string;
  // eslint-disable-next-line camelcase
  preferred_name?: string;
  oid?: string;
}
