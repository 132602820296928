import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderStateDTO } from '@app/core/models/prod-order';

@Component({
  selector: 'app-select-prod-order-state',
  template: `
        <kendo-formfield>
          <kendo-label *ngIf="showTitel">{{"Operative.State" | translate}}</kendo-label>
            <kendo-dropdownlist [ngModel]="selectedStateId"
                                (valueChange)="valueChanged($event)"
                                [data]="prodOrderStates"
                                textField="name"
                                valueField="stateId"
                                [valuePrimitive]="true"
                                [loading]="loadProdOrderStates"
                                [itemDisabled]="isProdOrderStateDisabled"
                                [disabled]="disabled"
                                [defaultItem]="default"
                              >
            </kendo-dropdownlist>
        </kendo-formfield>
  `,
  styleUrls: ['./select-prod-order-state.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectProdOrderSateComponent
    }
  ]
})
export class SelectProdOrderSateComponent implements ControlValueAccessor {
  private _prodOrderId = null;
  private _isNewProdOrder: boolean = false;

  @Input() set prodOrderId(val: number) {
    this._prodOrderId = val;
    if (this._prodOrderId) {
      this.getProdOrderStates();
    }
  };

  @Input() set isNewProdOrder(val: boolean) {
    this._isNewProdOrder = val;
    if (this._isNewProdOrder) {
      this.getProdOrderStates();
    }
  };

  @Input() set getAllStates(val: boolean) {
    if (val) {
      this.getProdOrderStates();
    }
  };

  @Input() showTitel: boolean = true;

  selectedStateId: number = null;
  prodOrderStates: IProdOrderStateDTO[] = [];
  loadProdOrderStates: boolean = false;
  disabled = false;
  default: IProdOrderStateDTO = <IProdOrderStateDTO>{ stateId: null, name: '-', canChangeToState: true };

  constructor(public poService: ProdOrderService) { }

  public writeValue(obj: any): void {
    this.selectedStateId = obj;
  }

  public valueChanged($event) {
    this.selectedStateId = $event;
    this.onChanged($event);
  }

  private onTouched!: Function;
  private onChanged!: Function;

  public registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getProdOrderStates() {
    this.loadProdOrderStates = true;
    this.poService.getProdOrderStates(this._prodOrderId, this._isNewProdOrder).subscribe((pos) => {
      this.prodOrderStates = pos;
      this.loadProdOrderStates = false;
    });
  }

  isProdOrderStateDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.canChangeToState;
  }
}
