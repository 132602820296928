import { Component, Inject, OnInit } from '@angular/core';
import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-beam-material-gluset-statical-dialog',
  templateUrl: './beam-material-gluset-statical-dialog.component.html',
  styleUrls: ['./beam-material-gluset-statical-dialog.component.css']
})
export class BeamMaterialGlusetStaticalDialogComponent extends DialogContentBase implements OnInit {
  constructor(
    @Inject(DialogRef)
    public data: { bmgs: BeamMaterialGlueSet, plan: GluingPlan, machine: IMachineDTO },
    private gluelamStaticalMediatorService: GluelamStaticalMediatorService,
    private dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    if (this.data) {
      this.gluelamStaticalMediatorService.notifyGlueplanRamConfigurationChange(this.data.machine.pressbedRamConfigurations);
    }
  }
}
