import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { LogLevel, Configuration, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azureADauthClient,
    authority: environment.azureADauthAuthority,
    redirectUri: environment.authRedirectUrl,
    postLogoutRedirectUri: environment.authLogoutRedirectUrl,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: false
    }
  }
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  protectedResourceMap.set('https://optinex-dev.forestx.se/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://optinex-dev-auto.forestx.se/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://optinex-test.forestx.se/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://optinex-stage.forestx.se/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://optinex.forestx.se/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://localhost:44343/api/', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://localhost:4200/api/entity', [AuthService.apiScope]); // api you host
  protectedResourceMap.set('https://localhost:4200/api/', [AuthService.apiScope]); // api you host
  //  protectedResourceMap.set('https://localhost:4200',[""]); // api you host

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
