import { Component, OnInit } from '@angular/core';

import { DiagramComponent } from '../diagram/diagram.component';

@Component({
  selector: 'app-pie-diagram',
  template: `
  <kendo-chart>
    <kendo-chart-legend position="top"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item type="pie"
        [data]="pieData"
        field="value"
        categoryField="category">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  `,
  styleUrls: ['./pie-diagram.component.css']
})
export class PieDiagramComponent implements OnInit, DiagramComponent {
  showTitle: boolean;
  data: any;
  public pieData: any;

  constructor() { }

  ngOnInit() {
    this.pieData = this.data;
  }
}
