import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog'
import { environment } from 'src/environments/environment';
import { IProdOrderLineDTO, ICreateProdOrderReportDTO, IProdOrderReportStateDTO, IProdOrderReportDTO, IHandelingUnitReportDTO } from '@app/core/models/prod-order';
import { ProdOrderReportComponent } from '@app/modules/operative/controls/prod-order-report/prod-order-report.component';

@Injectable({
  providedIn: 'root'
})
export class ProdOrderReportService {
  constructor(private http: HttpClient
    , private dialogService: DialogService) {
  }

  openDialog(prodOrderLines:IProdOrderLineDTO[], prodOrderId: number) : DialogRef {
    const dialog = this.dialogService.open({
      content: ProdOrderReportComponent
    });

    const content = dialog.content.instance as ProdOrderReportComponent;
    content.data.prodOrderId = prodOrderId;
    content.data.prodOrderLines = prodOrderLines;

    return dialog;
  }

  addUpdateProdOrderLineReport(prodOrderReport:ICreateProdOrderReportDTO) : Observable<IProdOrderReportStateDTO> {
    const url = `${environment.apiUrl}ProdOrder/addUpdateProdOrderLineReport`;
    return this.http.post<IProdOrderReportStateDTO>(url, JSON.stringify(prodOrderReport), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getHandelingUnitIdentifer(prodOrderReports:IProdOrderReportDTO):Observable<IHandelingUnitReportDTO[]> {
    return this.http.post<IHandelingUnitReportDTO[]>(`${environment.apiUrl}ProdOrder/GetHandelingUnitIdentifier`, prodOrderReports)
    ;
  }
}
