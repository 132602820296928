import { Component, Inject, OnInit } from '@angular/core';
import { BeamMaterialGlueSet, BeamMaterialGlueSetLayer } from '@app/core/models/beam-material-glue-set.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { CordHelper } from '@app/modules/gluelam/controls/beam-material-gluset/CordHelper';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-glue-plan-layerd-view-model',
  templateUrl: './glue-plan-layerd-view-model.component.html',
  styleUrls: ['./glue-plan-layerd-view-model.component.css']
})
export class GluePLanLayerdViewModelComponent extends DialogContentBase implements OnInit {  //
  containerWidth: number = 500;
  containerHeight: number = 500;
  defaultLayerPlacementffset:number = 50;
  gsOffsets:number[][] = [];
  gluesets : BeamMaterialGlueSet[];
  ch: CordHelper;

  constructor(
    @Inject(DialogRef)
    public data: { glueSets: BeamMaterialGlueSet[], machine: IMachineDTO | null },
    private dialogRef: DialogRef,
  ) {
      super(dialogRef);
  }

  ngOnInit(): void {
    let offset = this.data.machine.maxPressWidth;
      this.gsOffsets = [];
      this.gluesets = this.data.glueSets;

      // Generate the array of offsets to place the beams
      this.gluesets?.forEach((gs) => {
        const layerOffsets:number[] = [];
        gs.layers.forEach((layer) => {
          offset -= (this.calcMaxWidth(layer) + this.defaultLayerPlacementffset);
          layerOffsets.push(offset);
        });

        this.gsOffsets.push(layerOffsets);
      });

    this.setupCordSystem();
  }

  private setupCordSystem() {
    if (this.data.glueSets?.length > 0 && this.data.machine) {
      this.ch = new CordHelper(
        this.containerHeight, // height of the coniainer
        this.data.machine.maxPressWidth ?? 1200,
        this.containerWidth, // width of the coniainer
        this.data.machine.maxPressHight ?? 1200// width of the gluepress machine
      );
    }
  }

  calcMaxWidth(layer:BeamMaterialGlueSetLayer) {
    const max = layer.BeamMaterials.length > 0 ? Math.max(...layer.BeamMaterials?.map(bm => bm.width)) : 0;
    return max;
  }

  calcBMSideX(bmgsl: BeamMaterialGlueSetLayer, index: number): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsl.BeamMaterials[i].getHight();
    }

    return x;
  }

  getOffset(layerIndex: number, gsIndex : number) {
    return this.gsOffsets[gsIndex][layerIndex];
  }

  getLinesToDraw(bm:BeamMaterial): BeamMaterialLine[] {
    // we need to display only 1st beam material line in the glue plan statical side view
    return bm.beamMaterialLines.filter(x => x.IndexInCut === 0);
  }

  getLamminas(count:number) {
    return new Array(count);
  }
}
