import { Component, OnInit } from '@angular/core';
import { DashboardCardComponent } from '../dashboard-layout/dashboard-card-component';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css']
})
export class DiagramComponent implements OnInit, DashboardCardComponent {
  showTitle: boolean = true;
  data: any;

  constructor() { }

  ngOnInit() {
  }
}
