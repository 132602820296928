<kendo-dialog-titlebar kendo-dialog-title>
</kendo-dialog-titlebar>
<div class="container">
  <div class="layerdChart" [attr.width]="containerWidth" [attr.height]="containerHeight">
    <svg [attr.height]="containerHeight" [attr.width]="containerWidth">

      <svg class="gluSet" *ngFor="let bmgs of data.glueSets; let gs_index = index">

        <svg *ngFor="let layer of bmgs.layers; let l_index = index"
          [attr.width]="ch.getWidth(this.data.machine.maxPressHight)" [attr.height]="ch.getHight(calcMaxWidth(layer))"
          [attr.y]="getOffset(l_index,gs_index) > 0 ? ch.getHight(getOffset(l_index,gs_index)) : 0">
          <svg class="bm" *ngFor="let bm of layer.BeamMaterials; let bm_index = index"
            [attr.height]="ch.getHight(bm.width)" [attr.width]="ch.getWidth(bm.getHight())"
            [attr.x]="ch.getWidth(calcBMSideX(layer, bm_index))">
            <svg [attr.height]="ch.getHight(bm.width)" *ngFor="let line of getLinesToDraw(bm);">>
              <rect class="lamminaBeam"
                *ngFor="let lammina of getLamminas(line.transportPackageDemand.NumberOfLaminas); let i = index"
                [attr.x]="i*(ch.getWidth(bm.getHight())/line.transportPackageDemand.NumberOfLaminas)" [attr.y]="0"
                [attr.width]="ch.getWidth(bm.getHight()/line.transportPackageDemand.NumberOfLaminas)"
                [attr.height]="ch.getHight(line.transportPackageDemand.LamminaPlaneWidth)"
                [ngStyle]="{fill: line.transportPackageDemand.Color}">
              </rect>
              <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="ch.getWidth(bm.getHight())" [attr.y2]="0" class="line" />
              <line [attr.x1]="0" [attr.y1]="ch.getHight(line.transportPackageDemand.LamminaPlaneWidth)"
                [attr.x2]="ch.getWidth(bm.getHight())"
                [attr.y2]="ch.getHight(line.transportPackageDemand.LamminaPlaneWidth)" class="line" />
              <line [attr.x1]="ch.getWidth(bm.getHight())" [attr.y1]="0" [attr.x2]="ch.getWidth(bm.getHight())"
                [attr.y2]="ch.getHight(line.transportPackageDemand.LamminaPlaneWidth)" class="line" />
              <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0"
                [attr.y2]="ch.getHight(line.transportPackageDemand.LamminaPlaneWidth)" class="line" />
            </svg>
          </svg>
        </svg>
      </svg>
    </svg>
  </div>
</div>
