import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

export class ErrorIntercept implements HttpInterceptor {
  constructor(public notificationService:AppNotificationService) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          let errorTitle = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorTitle = 'Client Error';
            errorMessage = `${error.error.message}`;
          } else if (error.status === 403) {
            errorTitle = 'User Not Authorized';
            errorMessage = `${error?.error ?? 'error'}`;
          } else if (typeof error.error === 'string') {
            errorTitle = `Server error - Http  ${error.status}`;
            errorMessage = `${error.error}`;
          } else {
            const pd = error.error as IProblemDetails;
            errorTitle = `${pd.title} ${pd.status}`;
            if (pd?.exceptionDetails?.length > 0) {
              errorTitle = pd.exceptionDetails[0].type.split('.').slice(-1)[0];
            }
            errorMessage = pd.detail;
          }
          this.notificationService.notifyErrorAppChanel(errorMessage, errorTitle);
          console.error(error.error);
          return throwError(error);
        })

      );
  }
}

export interface IProblemDetails {
  type: string | null;
  title: string | null;
  status: number | null;
  detail: string | null;
  instance: string | null;
  extensions: { [key: string]: any | null; };
  exceptionDetails: IExceptionDetails[]
}
export interface IExceptionDetails {
  type:string;
};
