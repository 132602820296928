import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AddEditInstructionDialogComponent } from './add-edit-instruction-dialog/add-edit-instruction-dialog.component';
import { AppControlsModule } from '../app-controls/app-controls.module';
import { AutoSchedulerInputPopupComponent } from './controls/auto-scheduler-input-popup/auto-scheduler-input-popup.component';
import { CommonModule } from '@angular/common';
import { CreateMultiStepProdOrderComponent } from './controls/create-multi-step-prod-order/create-multi-step-prod-order.component';
import { DeviationComponent } from './deviation/deviation.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EventCalenderPopupComponent } from './controls/event-calender-popup/event-calender-popup.component';
import { GluelamModule } from '../gluelam/gluelam.module';
import { GluesetSequenceDisplayComponent } from '@app-module-operative/controls/glueset-sequence-display/glueset-sequence-display.component';
import { InstructionComponent } from './instruction/instruction.component';
import { InstructionLineComponent } from './instruction-line/instruction-line.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LabelModule } from '@progress/kendo-angular-label';
import { MachineComponent } from './machine/machine.component';
import { MachineGroupComponent } from './machine-group/machine-group.component';
import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OperativeRoutingModule } from './operative-routing.module';
import { ProdOrderCalendarNgComponent } from './prod-order-calendar-ng/prod-order-calendar-ng.component';
import { ProdOrderCalendarService } from './services/prod-order-calendar.service';
import { ProdOrderComponent } from './prod-order/prod-order.component';
import { ProdOrderCreateV2Component } from './prod-order-create-v2/prod-order-create-v2.component';
import { ProdOrderCustomService } from '@app/core/services/custom-services/prod-order-custom.service';
import { ProdOrderDetailsComponent } from './prod-order-details/prod-order-details.component';
import { ProdOrderEventComponent } from './controls/prod-order-event/prod-order-event.component';
import { ProdOrderGridComponent } from './controls/prod-order-grid/prod-order-grid.component';
import { ProdOrderLineGridComponent } from './controls/prod-order-line-grid/prod-order-line-grid.component';
import { ProdOrderReportComponent } from './controls/prod-order-report/prod-order-report.component';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { ProdOrderShedulerNgComponent } from './prod-order-sheduler-ng/prod-order-sheduler-ng.component';
import { ProdorderBulkEditComponent } from './controls/prodorder-bulk-edit/prodorder-bulk-edit.component';
import { RamConfigurationDialogComponent } from './ram-configuration-dialog/ram-configuration-dialog.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleEditorComponent } from './controls/schedule-editor/schedule-editor.component';
import { ScheduleGridComponent } from './controls/schedule-grid/schedule-grid.component';
import { SelectProdOrderDemandComponent } from './controls/sequence-display/sequence-display.component';
import { SelectProdOrderSateComponent } from './controls/select-prod-order-state/select-prod-order-state.component';
import { SharedModule } from '@app/shared/shared.module';
import { ShiftCalendarComponent } from './shift-calendar/shift-calendar.component';
import { ShiftComponent } from './shift/shift.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ProdOrderComponent,
    ProdOrderDetailsComponent,
    ProdOrderGridComponent,
    MachineComponent,
    MachineGroupComponent,
    ProdOrderLineGridComponent,
    ProdOrderReportComponent,
    ProdOrderEventComponent,
    ScheduleComponent,
    ShiftComponent,
    ScheduleGridComponent,
    ScheduleEditorComponent,
    ShiftCalendarComponent,
    DeviationComponent,
    SelectProdOrderDemandComponent,
    SelectProdOrderSateComponent,
    ProdOrderShedulerNgComponent,
    ProdOrderCalendarNgComponent,
    EventCalenderPopupComponent,
    ProdorderBulkEditComponent,
    CreateMultiStepProdOrderComponent,
    InstructionLineComponent,
    InstructionComponent,
    AutoSchedulerInputPopupComponent,
    ProdOrderCreateV2Component,
    RamConfigurationDialogComponent,
    AddEditInstructionDialogComponent,
    GluesetSequenceDisplayComponent
  ],
  providers: [
    ProdOrderService,
    ProdOrderCalendarService,
    ProdOrderCustomService
  ],
  imports: [
    CommonModule,
    SharedModule,
    OperativeRoutingModule,
    DragDropModule,
    KendoImportModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    GluelamModule,
    AppControlsModule,
    ReactiveFormsModule,
    LabelModule,
    NgxSpinnerModule,
    MbscModule
  ]
})
export class OperativeModule { }
