<h1 translate>GluePlan.GluePlans</h1>
<kendo-grid
    gluePlanBinding
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="true"
    [sortable]="true"
    [pageable]="true"
    [filter]="state.filter"
    (dataStateChange)="onStateChange($event)"
    [style]="{'height': 'calc(100vh - 10em)'}"
    [size]="'small'">

    <kendo-grid-column
        field="gluingPlanID"
        title="{{ 'GluePlan.Id' | translate }}"
        [width]="130">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <kendo-grid-string-filter-cell
                [showOperators]="false"
                [column]="column"
                [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="name"
        title="{{ 'GluePlan.Name' | translate }}"
        [width]="200">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <kendo-grid-string-filter-cell
                [showOperators]="false"
                [column]="column"
                [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="instruction"
        title="{{ 'GluePlan.Instruction' | translate }}"
        [width]="200">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <kendo-grid-string-filter-cell
                [showOperators]="false"
                [column]="column"
                [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="stateID"
        title="{{ 'GluePlan.GlueSetState' | translate }}"
        [width]="200">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <app-kendo-grid-drop-down-filter
                [data]="states"
                [filter]="filter"
                textField="name"
                valueField="glueSetStateId"
                [filterCode]="'stateID'"
                [trasPrefix]="'GluePlan'">
            </app-kendo-grid-drop-down-filter>
        </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <span [style.color]="getStateColor(dataItem.glueSetStateId)">
                {{ getStateText(dataItem.glueSetStateId) | translate}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="dimension"
        title="{{ 'GluePlan.Dimension' | translate }}"
        [width]="200">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter>
            <app-kendo-grid-drop-down-filter
                [data]="beamLamminaDimensions"
                [filter]="filter"
                textField="width"
                valueField="beamLamminaDimensionID"
                [filterCode]="'dimension'">
            </app-kendo-grid-drop-down-filter>
        </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <span>{{dataItem?.dimension?.width }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="plannedExecutionStartDate"
        title="{{ 'GluePlan.StartDate' | translate }}"
        filter="date"
        format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.plannedExecutionStartDate | siteDateFormat}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="plannedExecutionEndDate"
        title="{{ 'GluePlan.EndDate' | translate }}"
        filter="date"
        format="{0:d}">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.plannedExecutionEndDate | siteDateFormat}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="{{ 'GluePlan.Action' | translate }}">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <button
                kendoButton
                themeColor="primary"
                [svgIcon]="eyeIcon"
                (click)="viewGlueplan(dataItem)"
                title="{{ 'GluePlan.GluePlan' | translate }}">
            </button>
            <button
                kendoButton
                themeColor="primary"
                [svgIcon]="downloadIcon"
                (click)="download(dataItem)">
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
