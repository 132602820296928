import { Pipe, PipeTransform } from '@angular/core';
import { IEnumKeyValue } from '../../core/models/enumKeyValue.model';

@Pipe({ name: 'EnumToObject' })
export class EnumToObject implements PipeTransform {
  transform(value): IEnumKeyValue[] {
    const data: IEnumKeyValue[] = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        data.push({ key: value[enumMember], value: parseInt(enumMember), disable: false });
      }
    }
    return data;
  }
}
