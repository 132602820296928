<div style="display: flex;flex-direction: row;">
<h3>{{"GluePlan.GluePlan" | translate}} - {{glueplan.gluingPlanID}}</h3>
<div style="flex-grow: 1;"></div>
<div>
  <button kendoButton themeColor="primary" (click)="onBack()">Back</button>
</div>
</div>
<app-glue-set-view
  [gluesets]="glueSets"
  [cols]="6"
  [dimension]="dim"
  ></app-glue-set-view>
<h4 *ngIf="!glueSets">No records found</h4>
