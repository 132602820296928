<kendo-formfield>
  <kendo-label translate>App.Site</kendo-label>
  <kendo-multiselect
    [data]="sites"
    textField="siteName"
    valueField="siteId"
    [(value)]="selectedSiteIds"
    [valuePrimitive]="true"
    (valueChange)="onChangedVal($event)">
  </kendo-multiselect>
</kendo-formfield>
