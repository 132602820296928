import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Guid } from '@app/core/models/Guid';
import { EntityGroupService, IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

@Component({
  selector: 'app-select-entity-group',
  template: `
<div class="select-entity-group">
<kendo-formfield appearance="fill">
<kendo-label translate>{{label}}</kendo-label>
  <kendo-dropdownlist [data]= "groups"
                      [valueField]="'description'"
                      [textField]="'description'"
                      (selectionChange)="onSelectionChange($event)"
                      class="width"
                      [(value)]="selectedEntityGroup">
  </kendo-dropdownlist>
</kendo-formfield>
</div>`,
  styleUrls: ['./select-entity-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityGroupComponent
    }
  ]
})
export class SelectEntityGroupComponent {
  groups: IEntityGroup[] = [];
  selectedEntityGroup: IEntityGroup;
  disabled: boolean = false;
  activityTypeCode: string = 'ACTIVITY';
  private _selectedSiteId: Guid;

  @Input() entityTypeCode: string;
  @Input() noCalcTime: boolean = false;
  @Input() set selectedSiteId(siteId: Guid) {
    this._selectedSiteId = siteId;
    if (this.groups.length > 0) {
    this.filterGroupUsingSite(siteId);
    }
  };

  filteredGroups: IEntityGroup[] = [];
  label: string = 'EntityTranslation.EntityGroup';
  @Input() inOperative: boolean = false;

  @Output() entityGroup: EventEmitter<IEntityGroup> = new EventEmitter<IEntityGroup>();

  onTouched: Function;
  onChanged: Function;
  selectedIndex: number = -1;

  constructor(private readonly entityGroupService: EntityGroupService
    , private readonly localstorageServie: LocalStorageService) { }

  ngOnInit(): void {
    this.entityGroupService.query(this.inOperative).subscribe(data => {
      if (data) {
        this.filterAndSortEntityGroups(data);
        this.filteredGroups = this.groups;
        this.filterGroupUsingSite(this._selectedSiteId);
      }
    });
  }

  private getKey(): string {
    return this.entityTypeCode.toLowerCase() === this.activityTypeCode.toLowerCase()
                                               ? LocalStorageService.activityKey
                                               : LocalStorageService.resourceKey;
  }

  private filterAndSortEntityGroups(data: IEntityGroup[]) {
    if (this.entityTypeCode) {
      this.groups = data.filter(d => d.entityTypeCode === this.entityTypeCode);
    } else {
      this.groups = data;
    }

    if (this.noCalcTime) {
      this.groups = this.groups.filter(d => d.resourceTypeCode !== 'CALTIME');
    }

    this.groups = this.groups.sort((a, b) => a.sortOrderIndex - b.sortOrderIndex);
  }

  public onSelectionChange(g: IEntityGroup) {
    this.localstorageServie.setItem(g.entityTypeCode.toLowerCase() === this.activityTypeCode.toLowerCase() ? LocalStorageService.activityKey : LocalStorageService.resourceKey, g.index.toString());
    this.selectGroup(g);
  }

  public selectGroup(group: IEntityGroup) {
    if (group) {
      this.selectedEntityGroup = group;
      this.setGroupSelected(group.index);
      this.entityGroup.emit(group);
      this.onChanged(group.index);
      this.onTouched();
    }
  }

  private setGroupSelected(index: number) {
    this.groups.forEach(x => {
      x.isSelected = x.index === index;
    });
  }

  private filterGroupUsingSite(siteId:Guid) {
    const selectedIndex = Number.parseInt(this.localstorageServie.getItem(this.getKey()));
    if (siteId) {
      this.groups = this.filteredGroups.filter(d => d.entityGroupSites.find(s => s.siteId === siteId));
    }
    this.selectGroup(this.groups.find(g => g.index === selectedIndex) ?? this.groups[0]);
  }

  writeValue(value: IEntityGroup): void {}

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
