<h1 translate>Operative.MachineGroup</h1>

<section class="app-button-row">

  <form [formGroup]="machineGroupFilterForm"
        class="form">
    <app-site-selector formControlName="siteId"
                       [showLable]="false"
                       (onSiteSelected)="onSiteSelected()">
    </app-site-selector>
  </form>

  <div style="flex-grow: 1;"></div>

  <button kendoButton
          themeColor="primary"
          class="k-mb-1"
          (click)="addMachineGroupRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

 <kendo-grid #MachineGroupEditorGrid
          [kendoGridBinding]="machineGroups"
          class="crud-grid"
          [pageSize]="state.take"
          [skip]="state.skip"
          [pageable]="true"
          [size]="'small'"
          (edit)="editHandler($event)"
          (cancel)="cancelHandler($event)"
          (save)="saveHandler($event)"
          (remove)="removeHandler($event)"
          [loading]="machinegrouploader"
          [size]="'small'"
  >
    <kendo-grid-column field="name"
                       title="{{ 'App.Name' | translate }}">
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup" >
        <kendo-textbox [formControl]="formGroup.get('name')">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shifts"
                       title="{{ 'Operative.ShiftName' | translate  }}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getShiftsName(dataItem.shiftAllocations) }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-multiselect [formControl]="formGroup.get('shifts')"
                             [data]="shifts"
                             textField="name"
                             valueField="shiftId">
          </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'Operative.AutoSchedulerDisable' | translate }}">
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <input  kendoCheckBox
                type="checkbox"
                class="k-checkbox group-check"
                [checked]="dataItem.isDisabledForAutoSheduler"
                [disabled]="true">
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <input kendoCheckBox
              type="checkbox"
              class="k-checkbox group-check"
              [formControl]="formGroup.get('isDisabledForAutoSheduler')"/>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'Operative.AutoSchedulerPreserveOrder' | translate }}">
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <input kendoCheckBox
               type="checkbox"
               class="k-checkbox group-check"
               [checked]="dataItem.preserveOrderAutoSheduler"
               [disabled]="true">
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <input kendoCheckBox
               type="checkbox"
               class="k-checkbox group-check"
               [formControl]="formGroup.get('preserveOrderAutoSheduler')" />
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'Operative.ContinuousPressGroup' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem>
          <input class="k-checkbox group-check" [checked]="dataItem.isContinuousPressGroup" type="checkbox" [disabled]="true" kendoCheckBox>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input class="k-checkbox group-check" (click)="toggleIsContinuousPressGroupCheckbox()" [checked]="dataItem.isContinuousPressGroup" type="checkbox" kendoCheckBox>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.StaticalPressGroup' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem>
          <input class="k-checkbox group-check" [checked]="dataItem.isStaticalPressGroup" type="checkbox" [disabled]="true" kendoCheckBox>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input class="k-checkbox group-check" (click)="toggleIsStaticalPressGroupCheckbox()" [checked]="dataItem.isStaticalPressGroup" type="checkbox" kendoCheckBox>
      </ng-template>
  </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}" [width]="320">
      <ng-template kendoGridCellTemplate
                   let-isNew="isNew"
                   let-dataItem>
        <button kendoGridEditCommand [primary]="true">
          {{ 'App.Edit' | translate}}
        </button>
        <button kendoGridRemoveCommand themeColor="error">
          {{ 'App.Remove' | translate}}
        </button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid"  [primary]="true">
          {{ (isNew ? "App.Add" : "App.Update" ) | translate }}
        </button>
        <button kendoGridCancelCommand themeColor="error">
          {{ 'App.Cancel' | translate }}
        </button>
        <button *ngIf="dataItem.machineGroupId > 0"
                kendoButton
                themeColor="primary"
                (click)="openCalender(dataItem.machineGroupId)" >
          {{ ('App.View' | translate)}}
        </button>
      </ng-template>
    </kendo-grid-command-column>

 </kendo-grid>

  <app-confirmation-dialog *ngIf="itemToRemove"
                        (conformationDialogCloseEvent) = "confirmRemove($event)">
  </app-confirmation-dialog>
  <div kendoDialogContainer></div>
