<kendo-dialog>

<kendo-dialog-titlebar kendo-dialog-title>
  <div translate>GluePlan.GlulamSpec</div>
</kendo-dialog-titlebar>

<form
    [formGroup]="glulamspecForm"
    style="display: flex; flex-direction: column;"
    (ngSubmit)="onSubmit()">
  <div>
    <div id="rows">
    <table>
      <thead>
        <th translate>GluePlan.Entity</th>
        <th translate>GluePlan.NumberOfLams</th>
        <th translate>GluePlan.PlaningThickness</th>
        <th translate>GluePlan.NoGlue</th>
        <th translate>GluePlan.HightDependent</th>
        <th translate>GluePlan.Turn</th>
        <th translate>App.Action</th>
      </thead>
      <tbody formArrayName="specrows">
        <ng-container *ngFor="let r of specRows.controls let i = index">
        <tr [formGroupName]="i" *ngIf="r.get('isRemoved').value != true">
          <td>
              <kendo-formfield>
                <kendo-dropdownlist
                  id="prodList"
                  [data]="productList"
                  [textField]="'value'"
                  [valueField]="'key'"
                  [loading]="loading"
                  [valuePrimitive]="true"
                  formControlName="productIndex"
                  (valueChange)="onProductChange(r)">
                </kendo-dropdownlist>
              </kendo-formfield>
            </td>
            <td>
              <kendo-formfield>
                <kendo-numerictextbox
                  formControlName="numberOfLammellas"
                  [decimals]="0"
                  format="n0">
                </kendo-numerictextbox>
              </kendo-formfield>
            </td>
            <td>
              <kendo-formfield>
                <kendo-numerictextbox
                  formControlName="planingThickness"
                  [decimals]="2"
                  format="n2"
                  >
                </kendo-numerictextbox>
              </kendo-formfield>
            </td>
            <td>
              <kendo-formfield>
                <kendo-dropdownlist
                  [data]="status"
                  [textField]="'text'"
                  [valueField]="'value'"
                  [loading]="loading"
                  [valuePrimitive]="true"
                  formControlName="dryJoint">
                </kendo-dropdownlist>
              </kendo-formfield>
            </td>
            <td>
              <kendo-formfield>
                <kendo-switch formControlName="heightDependant" ></kendo-switch>
              </kendo-formfield>
            </td>
            <td>
              <kendo-formfield>
                <kendo-switch formControlName="turn" (valueChange)="onTurnChange(r)"></kendo-switch>
              </kendo-formfield>
            </td>
            <td style="white-space: nowrap;">
              <button kendoButton
                (click)="removeRow(i)"
                [primary]="true">
                {{ 'App.Remove' | translate }}
            </button>
            </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <kendo-formerror *ngIf="glulamspecForm.errors?.sumSmallerThanMin" translate>GlulamSpec.SumNotInRange</kendo-formerror>
    <kendo-formerror *ngIf="glulamspecForm.errors?.onlyOneHightDep" translate>GlulamSpec.MoreThanOneHeightDependant</kendo-formerror>
    </div>
    <p>
      <button kendoButton
        (click)="addRow()"
        [primary]="true">
        {{ 'App.Add' | translate }}</button>
    </p>
    <div class="row" style="margin-bottom: 20px;">
      <kendo-formfield>
        <kendo-label
          [for]="HightMin"
          text="{{ 'GluePlan.HightMin' | translate }}"></kendo-label>
          <kendo-numerictextbox
          #HightMin
          formControlName="hightMin"
          [decimals]="2"
          format="n2"
          >
        </kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label
          [for]="HightMax"
          text="{{ 'GluePlan.HightMax' | translate }}"></kendo-label>
          <kendo-numerictextbox
          #HightMax
          formControlName="hightMax"
          [decimals]="2"
          format="n2"
          >
        </kendo-numerictextbox>
        <kendo-formerror *ngIf="glulamspecForm.controls.hightMax.errors?.maxLargerMin" translate>GlulamSpec.MinLargerThenMax</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <kendo-dialog-actions kendo-dialog-action>
    <div style="flex-grow:1"></div>
      <button
          kendoButton
          type="submit"
          [disabled]="(!glulamspecForm.valid)"
          [primary]="true">
          {{ 'App.Ok'| translate }}
      </button>
      <button
          kendoButton
          (click)="onCancel()"
          [themeColor]="'error'">
          {{ 'App.Cancel' | translate }}
      </button>
    <div style="align-items: center;">
      <kendo-loader *ngIf="loading"></kendo-loader>
    </div>
  </kendo-dialog-actions>
</form>
</kendo-dialog>
