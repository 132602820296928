import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { OptimizationRunAdminComponent } from './optimization-run-admin/optimization-run-admin.component';
import { OptimizationRunDetailComponent } from './optimization-run-detail/optimization-run-detail.component';
import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { SequencerComponent } from './sequencer/sequencer.component';

const routes: Routes = [
  {
    path: 'optimization',
    children: [
      { path: 'sequencer', component: SequencerComponent, canActivate: [AuthGuard] },
      { path: 'runs', component: OptimizationRunAdminComponent, canActivate: [AuthGuard] },
      { path: 'run/:id', component: OptimizationRunDetailComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OptimizationRoutingModule { }
