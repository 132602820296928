import { DateFormatEnum } from '@app/core/models/date-format.enum';

export class DateHelper {
  // date formats used in whole application
  public static dateFormat: string = 'dd-MM-yyyy';
  public static dateFormatWithTime: string = DateHelper.dateFormat + ' HH:mm';
  public static locale = 'en-EU';

  public static fromDateISOFormat(date: Date): string {
    return new Date(date.setHours(0, 0, 0)).toISOString();
  }

  public static toDateISOFormat(date: Date): string {
    return new Date(date.setHours(23, 59, 59)).toISOString();
  }

  public static getTime(date: Date): string {
    const hr = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return hr + ':' + min;
  }

  public static getweekdaysfromselecteddate(selectedDate: Date): [Date, Date] {
    const curr = new Date(selectedDate); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    const firstday = new Date(curr.setDate(first + 1)); // 06-Jul-2014
    const lastday = new Date(curr.setDate(last + 1)); // 12-Jul-2014
    return [firstday, lastday];
  }

  public static formatDate(date: Date, format: DateFormatEnum): Date {
    switch (format) {
      case DateFormatEnum.yyyy_MM_dd:
        return new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    }
    return date;
  }

  /**
 * This method accepts date string in the form of 'yyyyMMdd'
 * For example, 20210510 gets converted into 2021-05-10
 * @param dateString
 * @returns Date or null
 */
  public static convertStringToDate(dateString: string) {
    if (dateString.length !== 8) {
      return null;
    }

    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const date = year + '-' + month + '-' + day;
    return new Date(date);
  }

  public static convertDateToString(date: Date) {
    return (date !== null ? date.toDateString() : date);
  }

  public static stripTime(date: Date): Date {
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 and milliseconds to 0
    return newDate;
  }
}
