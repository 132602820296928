<div class="row">
         <h5>{{ "GluePlanPrint.Beam_Material" | translate  }}: {{ index + 1 }}</h5>
      <h5>{{ "GluePlanPrint.No_Of_Lamina" | translate }}:</h5>
      <span>{{ bm.numberOfLamminas }}</span>
      <h5>{{ "GluePlanPrint.Comment" | translate }}:</h5>
      <span>{{ bm.productionNote }}</span>
  <div class="row">
    <div class="col-md-1">
      &nbsp;
    </div>
    <div class="col-md-1 print-bml-head">
      {{ "GluePlanPrint.Index" | translate }}:
    </div>
    <div class="col-md-1 print-bml-head">
      {{ "GluePlanPrint.Gap" | translate }}:
    </div>
    <div class="col-md-1 print-bml-head">
      {{ "GluePlanPrint.Beams" | translate }}:
    </div>
    <div class="col-md-1 print-bml-head">
      {{ "GluePlanPrint.Cross_Section" | translate }}:
    </div>

    <div class="col-md-1 print-bml-head">
      {{ "GluePlanPrint.Length" | translate }}:
    </div>
    <div class="col-md-2 print-bml-head">
      {{ "GluePlanPrint.OrderNumber" | translate }}:
    </div>
    <div class="col-md-4 print-bml-head">
      {{ "GluePlanPrint.Customer" | translate }}:
    </div>
  </div>
  <div class="row" *ngFor="let bml of bm.beamMaterialLines; let g = index">
    <div class="col-md-1">
      &nbsp;
    </div>
    <div class="col-md-1 print-bml-cell">#{{ g + 1 }}</div>
    <div class="col-md-1 print-bml-cell">
      {{ bml.Cut }}
    </div>
    <div class="col-md-1 print-bml-cell">
      {{ bml.NumberOfBeams }}
    </div>
    <div class="col-md-1 print-bml-cell">
      {{ bml.transportPackageDemand.width }}*{{ bml.transportPackageDemand.hight }}
    </div>
    <div class="col-md-1 print-bml-cell">
      {{ bml.transportPackageDemand.length }}
    </div>
    <div class="col-md-2 print-bml-cell">
      {{ bml.transportPackageDemand.OrderText }}
    </div>
    <div class="col-md-4 print-bml-cell">
      {{ bml.transportPackageDemand.customerDesc }}
    </div>
  </div>
