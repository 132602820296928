import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { ErrorIntercept } from '@app/shared/helpers/error.interceptor';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { OverrideTenantInterceptor } from '@app/shared/helpers/override-tenant.interceptor';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '@app/core/services/auth-service/auth-config';
import { UserService } from '@app/core/services/http-services/common/user.service';
import { UploadInterceptor } from '@app/shared/helpers/upload.interceptor';
import { ErrorHandlerService } from '@app/core/services/custom-services/error-handler.service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      deps: [AppNotificationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OverrideTenantInterceptor,
      deps: [LocalStorageService],
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: LOCALE_ID,
      deps: [UserService],
      useFactory: (settingsService: UserService) => settingsService.getLocaleStr()
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class CoreModule { }
