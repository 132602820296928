
    <div class="btn-row">
      <button kendoButton
      [svgIcon]="plusCircleIcon"
      themeColor="primary"
      [disabled]="!plan ||plan?.glueSetStateId >= 2"
      (click)="addEmptyGlueSet()">
      </button>
      <h3 class="fw-bold"
         [ngClass]="{'margin0': isDeleteAllowed, 'margin15': !isDeleteAllowed}"
         [translate]="'GlueSet.Glueing'"
         [translateParams]="{no: bmgs.beamMaterialGlueSetID > 0 ? bmgs.beamMaterialGlueSetID : 'NEW'}">
      </h3>
      &nbsp; - &nbsp;
      <span [translate]="'GlueSet.Length'"
      [translateParams]="{lenght: bmgs.length }">
      </span>
      <div style="flex-grow: 1;"></div>
        <div kendoTooltip>
        <kendo-svg-icon themeColor="error" size="large" [icon]="warningCircleIcon" title="{{bmgs.errorMessageText | translate}}" *ngIf="bmgs.errorMessageText">
        </kendo-svg-icon>
        <kendo-svg-icon themeColor="warning" size="large" [icon]="pencilIcon" *ngIf="bmgs.save && !bmgs.errorMessageText">
        </kendo-svg-icon>
        </div>
        <kendo-svg-icon themeColor="success" size="large" [icon]="checkCircleIcon" *ngIf="!bmgs.save">
        </kendo-svg-icon>
      <div>
        <kendo-dropdownbutton
        [data]="rowActions"
        themeColor="primary"
        [svgIcon]="menuIcon">
          <ng-template kendoDropDownButtonItemTemplate let-dataItem>
            <kendo-svg-icon [icon]="dataItem.svgIcon"></kendo-svg-icon> {{ dataItem.name | translate }}
          </ng-template>
        </kendo-dropdownbutton>
      </div>

    </div>
    <div style="display: flex; flex-direction: row;">
      <div class="info-pill info-pill-accsent"
         *ngIf="bmgs.glusetBatch > 0">
        {{ "GlueSet.Batch" | translate }}: {{bmgs.glusetBatch}}
      </div>
    </div>
    <div #bmgsContainer *ngFor="let layer of bmgs.layers; let i_layer = index">
      <div class="btn-row">
        <button kendoButton [svgIcon]="plusCircleIcon" themeColor="primary"[disabled]="!plan || plan?.glueSetStateId >= glueSetStateEnum.PLANNED" (click)="onAddNewLayer()">
        </button>
          <h3 class="fw-bold margin0">
            {{ "GlueSet.Layer" | translate }} : {{bmgs.layers.length - i_layer}}
          </h3>
          <div style="flex-grow: 1;"></div>
        <button kendoButton [svgIcon]="trashIcon" themeColor="error" [disabled]="!isDeleteAllowed" (click)="onDeleteLayer(i_layer)">
        </button>
      </div>
      <div>
        <svg
          [attr.height]="containerHight"
          [attr.width]="gsWidth"
          #layer
          [attr.y]="calcY(i_layer)"
          x="0">
          <rect class="pressbed" *ngFor="let ram of ramConfiguration; let i = index"
            [attr.width]="getWidth(ram.width)"
            [attr.x]="getWidth(ram.position)"
            height="100%"
            >
          </rect>
            <rect x="0"
              y="0"
              [attr.width]="gsWidth"
              [attr.height]="containerHight"
              style="fill: #ffffff00; stroke-width: 1; stroke: rgb(0, 0, 0);"
              (contextmenu)="openBMGSContextMenu(layer, $event,i_layer)">
            </rect>
          <svg app-beam-material
              *ngFor="let bm of layer.BeamMaterials; let i = index"
              [bm]="bm" [ch]="ch"
              [attr.y]="calcYForLayer(i, layer)"
              [isSplitView]="false"
              (openBeamMaterialLineMenu)="openContextMenu($event,i_layer)">
          </svg>
        </svg>
      </div>
    </div>
