<kendo-appbar themeColor="primary">

  <div class="custom-toolbar">
    <button *ngIf="!locked"
        kendoButton
        [svgIcon]="menuSvg"
        fillMode="flat"
        (click)="toggleExpanded()"
    ></button>
  </div>

  <kendo-appbar-section>
    <a class="toolbar-logo">
      <img alt="Optinex logo" [attr.src]="'assets/img/fx_logo.png'">
    </a>
  </kendo-appbar-section>

  <kendo-appbar-spacer>
  </kendo-appbar-spacer>

  <kendo-appbar-section>
    {{ configuration.environment }}
  </kendo-appbar-section>

  <kendo-appbar-spacer>
  </kendo-appbar-spacer>

  <kendo-appbar-section>
    <button kendoButton fillMode="flat" themeColor="base" *ngIf="loggedIn" (click)="logout()">
      <kendo-svgicon [icon]="logoutIcon"></kendo-svgicon>
    </button>
  </kendo-appbar-section>
</kendo-appbar>
  <div kendoDialogContainer></div>
  <kendo-drawer-container>
      <kendo-drawer
          #drawer
          [width]="300"
          [items]="authenicatedNodes"
          [mode]="mode"
          [mini]="mini"
          [expanded]="expanded"
          (select)="onSelect($event)"
          [autoCollapse]="false"
          [isItemExpanded]="isItemExpanded">
          <ng-template kendoDrawerHeaderTemplate *ngIf="expanded">
            <app-user-settings [ngClass]="{'fixed-drawer': locked, 'unfix-drawer': !locked}"
              (lockedMenu)="confLockState($event)"
              (toggleExpanded)="drawer.toggle()"
              *ngIf="loggedIn && expanded">
            </app-user-settings>
          </ng-template>
          <ng-template kendoDrawerItemTemplate let-item let-hasChildren="hasChildren" isItemExpanded="isItemExpanded">
            <kendo-svgicon [icon]="item.svgIcon" themeColor="primary" size="large"></kendo-svgicon>
            <div (contextmenu)="onRightClick(item)">{{ item.text | translate}}</div>
            <div style="flex-grow: 1;"></div>
            <kendo-svg-icon *ngIf="hasChildren"  [icon]="isItemExpanded ? chevronDownIcon : chevronUpIcon" themeColor="primary" ></kendo-svg-icon>
          </ng-template>
      </kendo-drawer>
      <kendo-drawer-content>
        <router-outlet></router-outlet>
      </kendo-drawer-content>
  </kendo-drawer-container>
