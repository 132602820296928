import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IDeviationDTO } from '@app/core/models/deviation.model';

marker('Operative.DeviationEndDateCanNotLessThenStartDate');
marker('Operative.DeviationDurationCanNotBeLessThenZero');
marker('Operative.ShiftDeviationCanNotBeNull');
marker('Operative.DeviationOverlapping');
marker('Operative.ErrorWhileDeletingDeviation');
marker('Operative.DeviationDeletedSuccessfully');
marker('Operative.DeviationCreatedSuccessfully');
marker('Operative.DeviationUpdatedSuccessfully');
marker('Operative.DeviationCodeCanNotBeBlank');
marker('Operative.DeviationCodeAlreadyExists');

@Injectable({
  providedIn: 'root'
})
export class DeviationService {
  constructor(private http: HttpClient
    , private kendoGridService: KendoGridService) {
  }

  public getDeviation(state: State): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      if (element.code === 'shiftId') {
        const shiftIds = element.value.split(',');
        shiftIds.forEach(shiftIds => {
          params = params.append('shiftId', shiftIds);
        });
      } else if (element.code === 'startDate') {
        params = params.append(element.code, DateHelper.fromDateISOFormat(new Date(element.value)));
      } else if (element.code === 'endDate') {
        params = params.append(element.code, DateHelper.toDateISOFormat(new Date(element.value)));
      } else {
        params = params.append(element.code, element.value);
      }
    });

    return this.http.get<GridDataResult>(`${environment.apiUrl}Deviation/get`, { params }).pipe(map(s => {
      s.data.forEach(i => {
        i.startDate = new Date(i.startDate);
        i.endDate = new Date(i.endDate);
      });
      return s;
    }));
  }

  public query(
    startDate: Date,
    endDate: Date,
    duration: number,
    shiftId: number[],
    skip: number,
    take: number): Observable<GridDataResult> {
    let params = new HttpParams()
      .set('skip', skip)
      .set('take', take);

    if (startDate) {
      params = params.set('startDate', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('endDate', endDate.toISOString());
    }

    if (duration) {
      params = params.set('duration', duration);
    }

    for (const s of shiftId) {
      params = params.append('shiftId', s);
    }

    return this.http.get<GridDataResult>(`${environment.apiUrl}Deviation/get`, { params }).pipe(map(s => {
      s.data.forEach(i => {
        i.startDate = new Date(i.startDate);
        i.endDate = new Date(i.endDate);
      });
      return s;
    }));
  }

  public getDeviationsForMg(
    startDate: Date,
    endDate: Date,
    mashineGroupIds: number[]
  ): Observable<DeviationMashineDTO[]> {
    let params = new HttpParams()
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());

    for (const id of mashineGroupIds) {
      params = params.append('mashineGroupId', id);
    }

    return this.http.get<DeviationMashineDTO[]>(`${environment.apiUrl}Deviation`, { params }).pipe(map(ret => {
      ret.forEach(i => {
        i.startDate = new Date(i.startDate);
        i.endDate = new Date(i.endDate);
      });
      return ret;
    }));
  }

  public save(deviation: IDeviationDTO): Observable<IDeviationDTO> {
    return this.http.post<IDeviationDTO>(`${environment.apiUrl}Deviation`, JSON.stringify(deviation), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public update(deviation: IDeviationDTO): Observable<IDeviationDTO> {
    return this.http.put<IDeviationDTO>(`${environment.apiUrl}Deviation`, JSON.stringify(deviation), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public delete(deviationId: number) {
    return this.http.delete<boolean>(`${environment.apiUrl}Deviation/${deviationId}`);
  }
}

export interface DeviationMashineDTO {
  mashineGroupId: number;
  description: string;
  startDate: Date;
  endDate: Date;
  duration: number;
}
