import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { GridComponent, ScrollMode } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { DayEnum } from '@app/core/models/day.enum';
import { ObjectState } from '@app/core/models/object-state.enum';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Subject, takeUntil } from 'rxjs';
import { CreateScheduleEditorForm, CreateScheduleLineEditorForm } from '@app/core/models/forms/operative/schedule/schedule-editor/create-schedule-editor-form.model';

marker('Operative.ScheduleLineInvalidDuration');

const createScheduleForm = (e: IScheduleDTO) => new FormGroup<CreateScheduleEditorForm>({
  scheduleId: new FormControl<number>(e.scheduleId),
  code: new FormControl<string>(e.code, [Validators.required]),
  name: new FormControl<string>(e.name, [Validators.required]),
  scheduleLines: new FormControl<IScheduleLineDTO[]>(e.scheduleLines)
});

const createScheduleLineForm = (p: IScheduleLineDTO, defaultDate: Date, isAddRequest: boolean) => {
  const start = (p.start !== null ? new Date(defaultDate.setHours(Number.parseInt(p.start.substring(0, 2)), Number.parseInt(p.start.substring(3, 5)), 0, 0)) : p.start);
  const end = (p.end !== null ? new Date(defaultDate.setHours(Number.parseInt(p.end.substring(0, 2)), Number.parseInt(p.end.substring(3, 5)), 0, 0)) : p.end);

  return new FormGroup<CreateScheduleLineEditorForm>({
    scheduleLineId: new FormControl<number>(p.scheduleLineId),
    dayOfWeek: new FormControl<number>(p.dayOfWeek, Validators.required),
    start: new FormControl<string|Date>(start, Validators.required),
    end: new FormControl<string|Date>(end, Validators.required),
    duration: new FormControl<number>(p.duration, Validators.required),
    lineNo: new FormControl<number>(p.lineNo, Validators.required),
    isAddRequest: new FormControl<boolean>(isAddRequest)
  });
};

@Component({
  selector: 'app-schedule-editor',
  templateUrl: './schedule-editor.component.html',
  styleUrls: ['./schedule-editor.component.css'],
  providers: [EnumToObject]
})
export class ScheduleEditorComponent extends DialogContentBase implements OnInit,OnDestroy {
  scheduleForm: FormGroup;
  scheduleLineForm: FormGroup;
  scheduleLinesGrid: IScheduleLineDTO[];
  itemToRemoveLineNo: number = undefined;
  days: IEnumKeyValue[] = [];
  dafaultDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
  scrollMode: ScrollMode = 'scrollable';

  private scheduleFormModel = <IScheduleDTO>{};
  private editedRowIndex: number;
  private destroy$ = new Subject<void>();

  @ViewChild('scheduleEditorGrid')
  private scheduleEditorGrid: GridComponent;

  constructor(
    @Inject(DialogRef)
    public data: { scheduleId: number }
    , private readonly spinner: NgxSpinnerService
    , private readonly scheduleService: ScheduleService
    , private readonly router: Router
    , private readonly enumToObject: EnumToObject
    , private readonly appNotificationService: AppNotificationService
    , public dialogRef: DialogRef) {
    super(dialogRef);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.scheduleForm = createScheduleForm(this.scheduleFormModel);
    this.days = this.enumToObject.transform(DayEnum);
    this.loadForm();
  }

  loadForm() {
    if (this.data.scheduleId === undefined) {
      this.create();
    } else {
      this.edit();
    }
  }

  create() {
    this.setUpScheduleForm(<IScheduleDTO>{ code: '', name: '', scheduleId: undefined, scheduleLines: [] });
  }

  edit() {
    this.spinner.show('scheduleLoader');
    this.scheduleService.getSchedule(this.data.scheduleId)
    .pipe(takeUntil(this.destroy$))
      .subscribe((d) => {
        this.scheduleResponse(d);
      },
        err => {
          this.appNotificationService.notifyErrorAppChanel(err.message);
        });
    this.hideLoader();
  }

  setScheduleLineAdditionalProperty(scheduleLine: IScheduleLineDTO[]) {
    let lineNo = 0;
    scheduleLine.forEach(d => {
      lineNo += 1;
      d.lineNo = lineNo;
      d.dayOfWeekText = this.getDayOfWeekText(d.dayOfWeek);
    });
    return scheduleLine;
  }

  private getDayOfWeekText(dayOfWeek: number): string {
    return this.days.find(x => x.value === dayOfWeek).key;
  }

  bindScheuleLinesToGrid() {
    this.scheduleLinesGrid = this.getActiveScheduleLines();
  }

  getActiveScheduleLines() {
    return this.scheduleForm.get('scheduleLines').value.filter(d => d.state !== ObjectState.Delete);
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    const schedule: IScheduleDTO = {} as IScheduleDTO;
    schedule.code = this.scheduleForm.get('code').value;
    schedule.name = this.scheduleForm.get('name').value;
    schedule.scheduleId = this.scheduleForm.get('scheduleId').value;
    schedule.scheduleLines = this.scheduleForm.get('scheduleLines').value;

    this.spinner.show('scheduleLoader');
    this.scheduleService.saveSchedule(schedule)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        id => this.successResponse(id, 'schedule create successfully')
        , () => this.hideLoader()
      );
  }

  onDayChange(e: IEnumKeyValue) {
    this.scheduleLineForm.patchValue({
      start: new Date(this.dafaultDate),
      end: new Date(this.dafaultDate),
      duration: 0
    });
  }

  addScheduleLineRow() {
    this.closeEditor(this.scheduleEditorGrid);
    const addScheduleLine = <IScheduleLineDTO>{
      lineNo: (this.scheduleForm.get('scheduleLines').value.length + 1),
      scheduleLineId: 0,
      scheduleId: this.data?.scheduleId ?? 0,
      dayOfWeek: null,
      start: '00:00',
      end: '00:00',
      duration: 0
    };
    this.scheduleLineForm = createScheduleLineForm(addScheduleLine, this.dafaultDate, true);
    this.scheduleEditorGrid.addRow(this.scheduleLineForm);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    const scheduleLine = this.createObject(<IScheduleLineDTO>{}, dataItem);
    this.scheduleLineForm = createScheduleLineForm(scheduleLine, this.dafaultDate, false);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.scheduleLineForm);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    if (this.validation(formGroup.value)) {
      const scheduleLine = formGroup.value;
      scheduleLine.start = DateHelper.getTime(scheduleLine.start);
      scheduleLine.end = DateHelper.getTime(scheduleLine.end);

      if (isNew) {
        const data = this.createObject(<IScheduleLineDTO>{}, scheduleLine);
        data.state = ObjectState.Add;
        this.scheduleForm.get('scheduleLines').value.push(data);
      } else {
        const existingScheduleLine = this.getScheduleLine(scheduleLine.lineNo);
        existingScheduleLine.state = ObjectState.Update;
        this.createObject(existingScheduleLine, scheduleLine);
      }
      sender.closeRow(rowIndex);
      this.bindScheuleLinesToGrid();
    }
  };

  createObject(scheduleLine: IScheduleLineDTO, inputData: IScheduleLineDTO): IScheduleLineDTO {
    scheduleLine.scheduleId = inputData.scheduleId;
    scheduleLine.scheduleLineId = inputData.scheduleLineId;
    scheduleLine.dayOfWeek = inputData.dayOfWeek;
    scheduleLine.start = inputData.start;
    scheduleLine.end = inputData.end;
    scheduleLine.duration = inputData.duration;
    scheduleLine.dayOfWeekText = this.getDayOfWeekText(inputData.dayOfWeek);
    scheduleLine.lineNo = inputData.lineNo;
    return scheduleLine;
  }

  removeHandler({ dataItem }): void {
    this.itemToRemoveLineNo = dataItem.lineNo;
  }

  closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.scheduleLineForm = undefined;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      const selectedScheudleLine = this.getScheduleLine(this.itemToRemoveLineNo);

      if (selectedScheudleLine !== undefined) {
        selectedScheudleLine.state = ObjectState.Delete;
      }
      this.bindScheuleLinesToGrid();
    }
    this.itemToRemoveLineNo = undefined;
  }

  getScheduleLine(lineNo: number) {
    return this.scheduleForm.get('scheduleLines').value.find(d => d.lineNo === lineNo);
  }

  onTimeChange() {
    const timeStart = this.scheduleLineForm.get('start').value;
    const timeEnd = this.scheduleLineForm.get('end').value;
    if (timeStart !== null && timeEnd !== null) {
      this.setDuration(new Date(timeStart), new Date(timeEnd));
    } else {
      this.setDuratonToForm(0);
    }
  }

  setDuration(startDateTime: Date, endDateTime: Date) {
    const difference = endDateTime.getTime() - startDateTime.getTime(); // This will give difference in milliseconds
    const resultInMinutes = Math.round(difference / 60000);
    this.setDuratonToForm(resultInMinutes);
  }

  setDuratonToForm(duration: number) {
    this.scheduleLineForm.patchValue({
      duration
    });
  }

  validation(scheduleLine: IScheduleLineDTO) {
    if (scheduleLine.duration < 5) {
      this.appNotificationService.notifyErrorAppChanel('Operative.ScheduleLineInvalidDuration');
      return false;
    }
    return true;
  }

  private successResponse(scheduleId: number, successMsgKey: string) {
    this.hideLoader();
    if (scheduleId !== -1) {
      this.appNotificationService.notifySucsessAppChanel(successMsgKey);
      this.dialogRef.close(true);
    } else {
      this.appNotificationService.notifyErrorAppChanel('Error while creating new schedule');
    }
  }

  private scheduleResponse(schedule: IScheduleDTO) {
    if (schedule) {
      this.setUpScheduleForm(schedule);
    } else {
      this.pageNotFound();
    }
  }

  private setUpScheduleForm(schedule: IScheduleDTO) {
    schedule.scheduleLines = this.setScheduleLineAdditionalProperty(schedule.scheduleLines);
    this.scheduleForm.patchValue({
      scheduleId: schedule.scheduleId,
      code: schedule.code,
      name: schedule.name,
      scheduleLines: schedule.scheduleLines
    });
    this.bindScheuleLinesToGrid();
  }


  private pageNotFound() {
    this.router.navigate(['/page-not-found']);
  }

  private hideLoader() {
    this.spinner.hide('scheduleLoader');
  }
}
