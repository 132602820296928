<h1 translate>Optimization.Runs</h1>
<kendo-grid
    [data]="gridData | async"
    [pageable]="true"
    [skip]="state.skip"
    [pageSize]="state.take"
    (dataStateChange)="filter($event)"
    [size]="'small'">
    <kendo-grid-column field="cplexJobID"></kendo-grid-column>
    <kendo-grid-column field="watsonDataSetType">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            {{getDataSetText(dataItem.watsonDataSetType)}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="state"></kendo-grid-column>
    <kendo-grid-column field="solveState"></kendo-grid-column>
    <kendo-grid-column
        field="start">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{  dataItem.start | siteDateFormat:true }}</div>
        </ng-template>
      </kendo-grid-column>
    <kendo-grid-column
        field="ended">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{  dataItem.ended | siteDateFormat:true }}</div>
        </ng-template>
      </kendo-grid-column>
    <kendo-grid-command-column title="command">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <button
                kendoButton
                [themeColor]="'primary'"
                [routerLink]="['/optimization/run', dataItem.cplexRunLogID]">
                Details
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
