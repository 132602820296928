import { Component, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'date-range-filter-cell',
  styles: [`
        kendo-daterange > kendo-dateinput.range-filter {
            display: inline-block;
        }
        .k-button {
            margin-left: 5px;
        }
    `],
  template: `
        <kendo-daterange>
           <kendo-dateinput
                class="range-filter"
                kendoDateRangeStartInput
                [value]="start"
                (valueChange)="filterRange($event, end)"
                style="width:120px">
            </kendo-dateinput>
            -
            <kendo-dateinput
                class="range-filter"
                kendoDateRangeEndInput
                [value]="end"
                (valueChange)="filterRange(start, $event)"
                style="width:120px">
            </kendo-dateinput>
        </kendo-daterange>
        <button
            class="k-button k-button-icon"
            title="Clear"
            (click)="clearFilter()">
            <span class="k-icon k-i-filter-clear"></span>
        </button>
    `
})
export class DateRangeFilterCellComponent extends BaseFilterCellComponent {
  @Input() public override filter: any = null;

  @Input()
  public field: string;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get start(): Date {
    const first = this.findByOperator('gte');

    return (first || <FilterDescriptor>{}).value;
  }

  public startDate;
  public endDate;

  public get end(): Date {
    const end = this.findByOperator('lte');
    return (end || <FilterDescriptor>{}).value;
  }

  public get hasFilter(): boolean {
    return this.filtersByField(this.field).length > 0;
  }

  public clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.field)
    );
  }

  public filterRange(start: Date, end: Date): void {
    this.filter = this.removeFilter(this.field);

    if (start) {
      this.startDate = start;
    }

    if (end) {
      this.endDate = end;
    }

    if (this.startDate && this.endDate) {
      const dt = this.getDateString();
      this.applyFilter(
        this.updateFilter({ // otherwise add/modify the filter for the field with the value
          field: this.field,
          operator: 'eq',
          value: dt
        })
      ); // and update the root filtery
    }
  }

  getDateString(): string {
    let dt;
    if (this.startDate) {
      dt = this.getDateFormat(this.startDate);
    }
    if (this.endDate) {
      dt = dt + '^' + this.getDateFormat(this.endDate);
    }
    return dt;
  }

  getDateFormat(date) {
    let month = (date.getMonth() + 1).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return day + '/' + month + '/' + date.getFullYear();
  }

  private findByOperator(op: string): FilterDescriptor {
    return this.filtersByField(this.field)
      .filter(({ operator }) => operator === op)[0];
  }
}
