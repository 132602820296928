import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EntityGroupService, IEntityGroup, IEntityGroupProperty } from '@app/core/services/custom-services/entity-group.service';
import { EntityPropertyService, IEntityProperty, IEntityPropertyValueValid } from '@app/core/services/http-services/model/entity-property.service';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IUnit } from '@app/core/models/unit.model';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';
import { CreateEntityPropertyForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-property-form.model';
import { CreateValidValuesForm } from '@app/core/models/forms/entity-admin/entity-form/create-valid-values-form.model';
import { CreateEntityGroupPropertyForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-group-property-form.model';
import { SVGIcon, trashIcon, plusIcon } from '@progress/kendo-svg-icons';

marker('EntityTranslation.NewEntityProperty');
marker('EntityTranslation.UpdateEntityProperty');

export function validType(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const validDataTypes = [EntityPropertyDataTypeEnum.DATA_TYPE_INT, EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE, EntityPropertyDataTypeEnum.DATA_TYPE_STRING];

    return validDataTypes.indexOf(control.value) < 0
      ? null
      : { InvalidDataType: control.value };
  };
}

export function customValidateArrayGroup(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const ide: Set<number> = new Set(formArray.value.map(v => v.entityGroupIndex));
    return ide.size === formArray.controls.length ? null : { error: 'Entity group code already exist!!!' };
  };
};

export const createFormGroup = (e: IEntityProperty, isNew: boolean) => new FormGroup<CreateEntityPropertyForm>({
  code: new FormControl<string>(e.code, [Validators.required]),
  description: new FormControl<string>(e.description),
  propertyGroupCode: new FormControl<string>(e.propertyGroupCode),
  dataType: new FormControl<number>(e.datatype, [Validators.required]),
  areValuesValidated: new FormControl<boolean>(e.areValuesValidated, [Validators.required]),
  sortOrderIndex: new FormControl<number>(e.sortOrderIndex),
  entityGroupProperties: new FormArray(e.entityGroupProperties.map<FormGroup>(k => CreateEntityGroupPropertyFormGroup(k, false, e.datatype)), customValidateArrayGroup()),
  validValues: new FormArray(e.validValues.map<FormGroup>(k => createValidValuesFormGroup(k, false))),
  selectedGroup: new FormControl<number>(null),
  userCode: new FormControl<string>(e.userCode)
});

export const createValidValuesFormGroup = (egp: IEntityPropertyValueValid, isNew: boolean) => new FormGroup<CreateValidValuesForm>({
  id: new FormControl<number>(egp.id),
  value: new FormControl<number | string>(egp.valueString !== null ? egp.valueString : egp.valueInt !== null ? egp.valueInt : egp.valueDouble !== null ? egp.valueDouble : null),
  userCode: new FormControl<string>(egp.userCode),
  changeDate: new FormControl<string>(egp.changeDate),
  propertyCode: new FormControl<string>(egp.propertyCode),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false)
});

export const CreateEntityGroupPropertyFormGroup = (egp: IEntityGroupProperty, isNew: boolean, dataType: number) => new FormGroup<CreateEntityGroupPropertyForm>({
  index: new FormControl<number>(egp.index),
  entityGroupIndex: new FormControl<number>(egp.entityGroupIndex),
  propertyCode: new FormControl<string>(egp.propertyCode),
  defaultValue: new FormControl<number | string>(dataType === 1 ? egp.defaultValueInt : dataType === 4 ? egp.defaultValueDouble : dataType === 6 ? egp.defaultValueString : ''),
  isMandatory: new FormControl<boolean>(egp.isMandatory),
  isLenght: new FormControl<boolean>(egp.isLength),
  isThickness: new FormControl<boolean>(egp.isThickness),
  isWidth: new FormControl<boolean>(egp.isWidth),
  isSpecies: new FormControl<boolean>(egp.isSpecies),
  isQuality: new FormControl<boolean>(egp.isQuality),
  userCode: new FormControl<string>(egp.userCode),
  changeDate: new FormControl<string>(egp.changeDate),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false)
});

@Component({
  selector: 'app-entity-property-form',
  templateUrl: './entity-property-form.component.html',
  styleUrls: ['./../entity-editor.component.css']
})
export class EntityPropertyFormComponent extends DialogContentBase implements OnInit {
  entityPropertyForm: FormGroup = null;
  entityProperty: IEntityProperty = <IEntityProperty>{ entityGroupProperties: [] };
  groups: IEntityGroup[];
  units: IUnit[];
  areValuesValidatedAvailable = false;
  isNew: boolean;
  currentCode: string;
  defaultItem: { text: string; value: number } = {
    text: 'Select',
    value: null
  };
  dataTypes = null;
  areValuesValidated: Array<{ text: string; value: boolean }> = [
    { value: true, text: 'YES' },
    { value: false, text: 'NO' }
  ];

  buildForm() {
    this.entityPropertyForm = createFormGroup(this.entityProperty, this.isNew);

    this.entityPropertyForm.controls.areValuesValidated.valueChanges.subscribe((result) => {
      this.areValuesValidatedAvailable = result;
      this.entityProperty.areValuesValidated = result;
    });
  }

  public trashIcon:SVGIcon = trashIcon;
  public plusIcon:SVGIcon = plusIcon;

  constructor(@Inject(DialogRef) public data: { entityProperty: IEntityProperty, isNew: boolean },
    private readonly propService: EntityPropertyService,
    private readonly entityGroupService: EntityGroupService,
    private readonly dialogRef: DialogRef) { super(dialogRef); }

  ngOnInit(): void {
    this.dataTypes = this.propService.getDataTypeMap();

    this.entityProperty = this.data.entityProperty;
    this.isNew = this.data.isNew;

    this.buildForm();

    this.entityGroupService.query().subscribe(gl => {
      this.groups = gl;
    });
  }

  get entityGroupProperties(): FormArray {
    return this.entityPropertyForm.get('entityGroupProperties') as FormArray;
  }

  getAsFormGroup(a: AbstractControl): FormGroup {
    return a as FormGroup;
  }

  gc(a: AbstractControl, n: string): FormControl {
    const k = a as FormGroup;
    return k.get(n) as FormControl;
  }

  getEG(id: number): IEntityGroup {
    return this.groups.find(e => e.index === id);
  }

  get validValues(): FormArray {
    return this.entityPropertyForm.get('validValues') as FormArray;
  }

  addProperty() {
    const index = this.entityPropertyForm.value.selectedGroup;
    const dt = this.entityPropertyForm.value.dataType;
    const g = this.groups.find(x => x.index === index);
    this.entityGroupProperties.push(CreateEntityGroupPropertyFormGroup(
      <IEntityGroupProperty>{
        propertyCode: this.entityProperty.code,
        entityGroupIndex: g.index,
        isLength: false,
        isQuality: false,
        isWidth: false,
        isThickness: false,
        isSpecies: false
      }, true, dt));
  }

  addValidatedValue() {
    this.validValues.push(createValidValuesFormGroup(<IEntityPropertyValueValid>{ valueString: '' }, true));
  }

  removeValidValue(index) {
    this.validValues.at(index).get('isRemoved').patchValue(true);
    if (this.validValues.at(index).get('isNew').value) {
      this.validValues.removeAt(index);
    }
  }

  removeProperty(index) {
    this.entityGroupProperties.at(index).get('isRemoved').patchValue(true);
    if (this.entityGroupProperties.at(index).get('isNew').value) {
      this.entityGroupProperties.removeAt(index);
    }
  }

  onSubmit() {
    const formValue = this.entityPropertyForm.value;
    const dataType = formValue.dataType;

    this.entityProperty.code = formValue.code;
    this.entityProperty.description = formValue.description;
    this.entityProperty.datatype = formValue.dataType;
    this.entityProperty.areValuesValidated = formValue.areValuesValidated;
    this.entityProperty.userCode = formValue.userCode;
    this.entityProperty.validValues = formValue.validValues.map(c => {
      return <IEntityPropertyValueValid>{
        id: c.id ?? 0,
        userCode: this.entityProperty.userCode,
        propertyCode: this.entityProperty.code,
        valueDouble: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE ? parseFloat(c.value) : null,
        valueString: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_STRING ? String(c.value) : null,
        valueInt: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT ? parseInt(c.value) : null,
        isNew: c.isNew,
        isChange: c.isChange,
        isRemoved: c.isRemoved
      };
    });

    this.entityProperty.entityGroupProperties = this.entityGroupProperties.value.map(c => {
      return <IEntityGroupProperty>{
        index: c.index ?? 0,
        entityGroupIndex: c.entityGroupIndex,
        entityGroupCode: c.entityGroupCode,
        propertyCode: c.propertyCode,
        isMandatory: c.isMandatory ?? false,
        userCode: this.entityProperty.userCode,
        defaultValueDouble: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE ? parseFloat(c.defaultValue) : null,
        defaultValueString: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_STRING ? String(c.defaultValue) : null,
        defaultValueInt: dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT ? parseInt(c.defaultValue) : null,
        isLength: c.isLenght ?? false,
        isWidth: c.isWidth ?? false,
        isQuality: c.isQuality ?? false,
        isSpecies: c.isSpecies ?? false,
        isThickness: c.isThickness ?? false,
        isNew: c.isNew,
        isChange: c.isChange,
        isRemoved: c.isRemoved
      };
    });

    if (this.isNew) {
      this.propService.insert(this.entityProperty).subscribe(() => {
        this.dialogRef.close(this.entityProperty);
      });
    } else {
      this.propService.update(this.entityProperty).subscribe(() => {
        this.dialogRef.close(this.entityProperty);
      });
    }
  }

  canclePropertyUpdate() {
    this.dialogRef.close();
  }
}
