import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {
  constructor(
    public http: HttpClient
  ) {}

  createUpploadUrl(container:string): string {
    return `${environment.apiUrl}FileStorage/${container}`;
  }

  public removeUploadedFile(container:string, folder: string, fileName: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}FileStorage/${container}/${folder}/${fileName}`);
  }

  public downloadUploadedFile(container:string, folder: string, fileName: string) {
    return this.http.get(`${environment.apiUrl}FileStorage/${container}/${folder}/${fileName}`);
  }

  public getUploadedFileNames(container:string, folder: string): Observable<Array<IFileDetails>> {
    return this.http.get<IFileDetails[]>(`${environment.apiUrl}FileStorage/${container}/${folder}`);
  }

  public downloadPDF(url: string): Observable<Blob> {
    const options = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Blob>(url, options).pipe(map(result => new Blob([result], { type: 'application/pdf' })));
  }
}

export interface IFileDetails {
  id: number;
  fileName: string;
  fileURL: string;
}
