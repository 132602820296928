import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { IInstructionLineDTO } from '@app/core/models/instruction-line.model';

@Injectable({
  providedIn: 'root'
})
export class InstructionLineService {
  public _instructionLines : IInstructionLineDTO[] = null;

  constructor(public http: HttpClient
    , private kendoGridService: KendoGridService) {
  }

  getInstructionsLines(state: State) : Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}InstructionLine`, { params });
  }

  update(instructionLine: IInstructionLineDTO): Observable<IInstructionLineDTO> {
    const endpointUrl = `${environment.apiUrl}InstructionLine/${instructionLine.instructionLineId}`;
    return this.http.put<IInstructionLineDTO>(endpointUrl, JSON.stringify(instructionLine), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(instructionLine: IInstructionLineDTO): Observable<IInstructionLineDTO> {
    const endpointUrl = `${environment.apiUrl}InstructionLine`;
    return this.http.post<IInstructionLineDTO>(endpointUrl, JSON.stringify(instructionLine), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public remove(data: IInstructionLineDTO): Observable<boolean> {
    const ShiftUri = `${environment.apiUrl}InstructionLine`;
    return this.http.delete<boolean>(`${ShiftUri}/${data.instructionLineId}`);
  }

  getInstructionForMachine(machineId: number = null): Observable<IInstructionLineDTO[]> {
    let params = new HttpParams();

    if (machineId) {
      params = params.append('machineId', machineId);
    }

    return this.http.get<IInstructionLineDTO[]>(`${environment.apiUrl}InstructionLine/getInstructionForMachine`, { params });
  }
}
