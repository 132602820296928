import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { MbscCalendarEvent } from '@mobiscroll/angular';
import { ProdOrderCalendarService } from '@app/modules/operative/services/prod-order-calendar.service';

@Component({
  selector: 'app-event-calender-popup',
  templateUrl: './event-calender-popup.component.html',
  styleUrls: ['./event-calender-popup.component.css']
})
export class EventCalenderPopupComponent implements OnInit {
  @Input() selectedEvent!: MbscCalendarEvent;
  @Input() isDeleteDisabled : boolean = false;
  @Output() popupCloseEvent = new EventEmitter<boolean>();
  @Output() deleteEvent = new EventEmitter<number>();
  deniedRoles = [UserRoleEnum.Operator];
  @Input() isEditDisabled:boolean = false;

  constructor(private prodOrderCalendarService: ProdOrderCalendarService) { }

  ngOnInit(): void {
  }

  onEditClick(): void {
    if (!this.isEditDisabled) {
      this.prodOrderCalendarService.editPO(this.selectedEvent.id);
      this.popupCloseEvent.emit(true);
    }
  }

  onCopyClick(): void {
    this.prodOrderCalendarService.copyPO(this.selectedEvent.id);
    this.popupCloseEvent.emit(true);
  }

  onDeleteClick(): void {
    if (!this.isDeleteDisabled) {
      this.deleteEvent.emit(Number(this.selectedEvent.id));
    }
  }
}
