import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Deployment } from '../../../models/Deployment.model';
import { environment as configuration } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { WatsonDataSetEnum } from '../../../models/watson-dataset.enum';
import { WatsonDeployedModel } from '../../../models/watson-deployed.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  // private menuSubject: ReplaySubject<MenuItem[]>;

  constructor(private http: HttpClient) {}

  createDeployModel(modelData: any): Observable<any> {
    return this.http.post<any>(`${configuration.apiUrl}JobAdministration/CreateDeployModel`, modelData, {
      headers: {
        'Content-Disposition': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      },
      reportProgress: true
    });
  }

  getDeployments(watsonDataSet: WatsonDataSetEnum): Observable<Deployment[]> {
    return this.http.get<Deployment[]>(`${configuration.apiUrl}JobAdministration/deployments/` + watsonDataSet);
  }

  getWatsonDeployments(watsonDataSet: WatsonDataSetEnum): Observable<WatsonDeployedModel[]> {
    return this.getDeployments(watsonDataSet)
      .pipe(
        map((response: Deployment[]) => {
          return response.map((x) => <WatsonDeployedModel>{
            Description: x.description,
            Id: x.id,
            Name: x.name
          });
        })
      );
  }

  deleteDeployment(id: string, date: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${configuration.apiUrl}JobAdministration/deleteDeployment` + '/' + id + '/' + date
    );
  }
}
