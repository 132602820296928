import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppControlsModule } from '../app-controls/app-controls.module';
import { CommonModule } from '@angular/common';
import { EntityEditorComponent } from './entity-editor/entity-editor.component';
import { EntityFormComponent } from './entity-form/entity-form.component';
import { EntityGroupBindingDirective } from './entity-group-editor/entity-group-binding.directive';
import { EntityGroupEditorComponent } from './entity-group-editor/entity-group-editor.component';
import { EntityGroupFormComponent } from './entity-group-form/entity-group-form.component';
import { EntityPropertyEditorComponent } from './entity-property-editor/entity-property-editor.component';
import { EntityPropertyFormComponent } from './entity-property-form/entity-property-form.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { ModelRoutingModule } from '../model/model-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    EntityFormComponent,
    EntityEditorComponent,
    EntityGroupFormComponent,
    EntityPropertyFormComponent,
    EntityPropertyEditorComponent,
    EntityGroupEditorComponent,
    EntityGroupBindingDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    KendoImportModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ModelRoutingModule,
    AppControlsModule
  ]
})
export class EntityAdminModule { }
