import { Component, Inject, OnInit } from '@angular/core';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderEventSearchCriteriaDTO } from '@app/core/models/prod-order-event-search-criteria';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-prod-order-event',
  templateUrl: './prod-order-event.component.html',
  styleUrls: ['./prod-order-event.component.css']
})
export class ProdOrderEventComponent extends DialogContentBase implements OnInit {
  loading:boolean = true;
  prodOrderEvents: GridDataResult;
  filter: CompositeFilterDescriptor;
  state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: [
      ]
    }
  };

  constructor(
    @Inject(DialogRef)
    public data: { input: IProdOrderEventSearchCriteriaDTO },
    private prodOrderService:ProdOrderService,
    private dialogRef: DialogRef) {
      super(dialogRef);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadProdOrderevents();
  }

  ngOnInit(): void {
    this.loadProdOrderevents();
  }

  loadProdOrderevents() {
    this.prodOrderService.getProdOrderEventDetails(this.state, this.data.input.prodOrderID)
      .subscribe(r => {
        r.data.forEach(item => {
          item.eventDate = new Date(item.eventDate);
        });

        this.prodOrderEvents = r;
        this.loading = false;
      });
  }

  close() {
    this.dialogRef.close(false);
  }
}
