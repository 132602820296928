import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';
import { UserService } from '../../services/http-services/common/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isUserLoggedIn = false;

  constructor(public authService: AuthService, public router: Router, public userService: UserService) { }

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.isLoggedIn();
  }

  login() {
    this.authService.login();
  }
}
