import { Component, OnInit } from '@angular/core';
import { IEntity } from '@app/core/services/http-services/model/entity.service';

@Component({
  selector: 'app-graph-explorer',
  templateUrl: './graph-explorer.component.html',
  styleUrls: ['./graph-explorer.component.css']
})
export class GraphExplorerComponent implements OnInit {
  public selectedRootEntity:IEntity;

  constructor() { }

  ngOnInit(): void {
  }

  onS(any) {
    console.log(any);
  }
}
