import { Injectable } from '@angular/core';
import { IEntity } from '@app/core/services/http-services/model/entity.service';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {
  defaultVolumeUnitCode: string = 'M3';

  qtyConversionByUOM(entity:IEntity, fromUOM: string, toUOM: string, qty:number, defaultQty: number = 0): number {
    let conversionQty: number;
    if (fromUOM === toUOM) {
      conversionQty = qty;
    }

    try {
      const fromCF = (entity.baseUnitCode === fromUOM) ? 1 : entity.unitConversions.find(x => x.unitCode === fromUOM).convFactor;
      const toCF = (entity.baseUnitCode === toUOM) ? 1 : entity.unitConversions.find(x => x.unitCode === toUOM).convFactor;
      conversionQty = (qty * fromCF) / toCF;
    } catch (e) {
      conversionQty = defaultQty;
    }

    conversionQty = (conversionQty < 0 ? conversionQty * -1 : conversionQty);

    return conversionQty;
  }
}
