import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { environment } from 'src/environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Guid } from '@app/core/models/Guid';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { AddLineParameter, Demandline } from '@app/core/models/demand-line.model';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { BeamMaterialGlueSet, BeamMaterialGlueSetLayer } from '../../../../core/models/beam-material-glue-set.model';
import { CordHelper } from './CordHelper';
import { BeamMaterialLineEditorService } from './beam-material-line-menu.service';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { CopyService, DataForCutBM, DataForCutBML } from '../../../../core/services/custom-services/copy.service';
import { BMGSprintComponent } from '../print/bmgs-print.component';
import { ValidataionResult } from '../../../../core/models/validation-result.model';
import { BeamSplitViewModalComponent } from '../beam-split-view-modal/beam-split-view-modal.component';
import { BeamMaterialService } from '@app/modules/gluelam/services/beam-material.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { GlusetStateHelper } from '@app/modules/gluelam/glue-plans-history/gluplan-state-helper';
import { ProductionStateGroupCode } from '@app/core/models/production-state-group-code-enum';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Subject, takeUntil } from 'rxjs';
import {
  SVGIcon,
  trashIcon,
  downloadIcon,
  displayFlexIcon,
  menuIcon,
  checkCircleIcon,
  pencilIcon,
  warningCircleIcon
} from "@progress/kendo-svg-icons";
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';

marker(['GlueSet.Height',
  'GlueSet.Length',
  'GlueSet.ALL_UNITS_ROW_PLANNED',
  'GlueSet.BMGS_CAN_NOT_ADD_MANY_TEMPLATES',
  'GlueSet.BMGS_Edit_Error',
  'GlueSet.BM_OF_DIFFERENT_DIM',
  'GlueSet.BOR_LAMMINAS_DIFFERNET_FROM_BM',
  'GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM',
  'GlueSet.Total_Glue_Set_Hight_Exided',
  'GlueSet.Total_Glue_Set_Length_Exided',
  'GlueSet.BMGS_Removed',
  'GlueSet.NOT_ALLOWED_TO_COMBINE_PLANING',
  'GlueSet.UNDER_MIN_HIGHT',
  'GlueSet.NOT_ALLOWED_TO_COMBINE_FINAL_HIGHT',
  'GlueSet.NOT_ALLOWED_TO_COMBINE_WIDTH',
  'GlueSet.NOT_ALLOWED_TO_COMBINE_SPLIT_AND_NOSPLIT_BM',
  'GlueSet.BOR_CERT_DIFFERNET_FROM_BM',
  'GlueSet.REJECTED_BEAMS_TOOLTIP',
  'GlueSet.Download',
  'GlueSet.SplitView',
  'App.Delete'
]);

export interface GlusetMenuEvent {
  bm: BeamMaterial;
  bml: BeamMaterialLine;
  layer: BeamMaterialGlueSetLayer;
  mouseEvent: MouseEvent;
}

@Component({
  selector: 'app-beam-material-glue-set',
  templateUrl: './beam-material-glueset.component.html',
  styleUrls: ['./beam-material-glueset.component.css']
})

export class BeamMaterialGlueSetEditor implements OnInit, OnChanges, OnDestroy {
  bmgs: BeamMaterialGlueSet = null;
  machine: IMachineDTO = null;
  containerHight = 300;
  hightSideLayerPX = 25;
  widthSideMM = environment.maxHight;
  ch: CordHelper;
  isSplit = false;

  //Icons
  trashIcon: SVGIcon = trashIcon;
  menuIcon: SVGIcon = menuIcon;
  checkCircleIcon: SVGIcon = checkCircleIcon;
  warningCircleIcon: SVGIcon = warningCircleIcon;
  pencilIcon: SVGIcon = pencilIcon;
  rowActions = [];

  private destroy = new Subject<void>();

  getHeightAllocation(totalHeight: number) {
    return totalHeight;
  }

  @Input() set glueSet(value: BeamMaterialGlueSet) {
    this.bmgs = value;
    if (value) {
      this.rowActions = [
        {
          name: "GlueSet.SplitView",
          svgIcon: displayFlexIcon,
          click: (): void => { this.splitView(); },
        },
        {
          name: "GlueSet.Download",
          svgIcon: downloadIcon,
          disabled: this.bmgs?.beamMaterialGlueSetState?.glueSetStateId < GlueSetState.PLANNED,
          click: (): void => { this.download(); },
        },
        {
          name: "App.Delete",
          svgIcon: trashIcon,
          disabled: !this.isOperator && this.bmgs?.beamMaterialGlueSetState?.glueSetStateId > GlueSetState.GENERATED,
          click: (): void => { this.onDelete(); },
        }
      ];
    }
  };

  @Input() plan: GluingPlan;
  @Input() gsWidth = 300;
  @Input() editable: boolean = true;
  @Input() isLeftMajor: string;
  @Input() lamminaDim: BeamLamminaDimension;
  @Input() isOperator: boolean = false;

  @Output() deleteBeamMaterialGlueSet = new EventEmitter<BeamMaterialGlueSet>();

  @Output() editBeamMaterial: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
    bm: BeamMaterial;
  }> = new EventEmitter();

  @Output() removeBeamMaterialGlueSet: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
  }> = new EventEmitter();

  @Output() updateBeamMaterialGlueSet: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
  }> = new EventEmitter();

  @Output() CalculateUnits: EventEmitter<{}> = new EventEmitter();

  @Output('openBeamMaterialLineMenu')
  // eslint-disable-next-line indent
  openBeamMaterialLineMenuInt: EventEmitter<{
    bm: BeamMaterial;
    bml: BeamMaterialLine;
    x: number;
    y: number;
  }> = new EventEmitter();

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly dialog: DialogService,
    private readonly notificationService: AppNotificationService,
    private readonly bmlDialogService: BeamMaterialLineEditorService,
    private readonly translate: TranslateService,
    private readonly productionFileService: ProductionFileService,
    private readonly copyService: CopyService,
    private readonly bmgsService: GlueSetService,
    private readonly beamMaterialService: BeamMaterialService,
    private readonly machineService: MachineService
  ) {
  }

  ngOnInit(): void {
    this.machineService.getMachinesByID(this.plan.machineId).pipe(takeUntil(this.destroy)).subscribe((machine) => {
      this.machine = machine;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bmgs && this.gsWidth) {
      this.setupCordSystem();
    }
  }

  calcY(i: number): number {
    return i * (this.containerHight + 10);
  }

  calcYForLayer(index: number, bmgsLayer: BeamMaterialGlueSetLayer): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsLayer.BeamMaterials[i].getHight();
    }

    return this.ch.getHight(x);
  }

  setupCordSystem() {
    if (this.bmgs) {
      this.ch = new CordHelper(
        this.containerHight, environment.maxHight,
        this.gsWidth, this.bmgs.length
      );
    }
  }

  adjustBMLAndSetCords() {
    if (this.bmgs) {
      this.bmgs.AdjustBeamMaterialLines();
      this.setupCordSystem();
    }
  }

  calcBMSideX(bmgsl: BeamMaterialGlueSetLayer, index: number): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsl.BeamMaterials[i].getHight();
    }

    return this.ch.getHight(x);
  }

  getContainerHight() {
    return this.bmgs.layers.length * (this.containerHight + 20);
  }

  onSave() {
    this.notificationService.notifySucsessAppChanel(
      'GlueSet.BMGS_ProductionOrder_Created'
    );
    // Save=true, is added since somewhere it is lost. It is required with Save=true, Send will not be performed.
    this.bmgs.save = true;

    this.updateBeamMaterialGlueSet.emit({ bmgs: this.bmgs });
  }

  onDelete() {
    this.deleteBeamMaterialGlueSet.emit(this.bmgs);
  }

  onPrint() {

    const width = 1300;
    this.dialog.open({
      content: BMGSprintComponent,
      width: `${width}px`,
      height: 'auto',
    });
  }

  onRepeatsChanged(number: string) {
    const rep = Number.parseInt(number);
    this.bmgs.performedTimes = Number.isInteger(rep)
      ? rep
      : this.bmgs.performedTimes;
    this.CalculateUnits.emit({});
  }

  openBMGSContextMenu(layer: BeamMaterialGlueSetLayer, mouseEvent: MouseEvent) {
    this.openContextMenu({
      bm: null,
      bml: null,
      layer,
      mouseEvent
    });

    return false;
  }

  openContextMenu(event: GlusetMenuEvent) {
    if (this.bmgs.beamMaterialGlueSetState.glueSetStateId > 1) {
      return;
    }

    const bmlDialog = this.bmlDialogService.open(event.mouseEvent, {
      bm: event.bm,
      bml: event.bml,
      height: '400px',
      width: '600px',
      machine: this.machine,
      isStaticalGluPlan: false
    });

    bmlDialog.onCutBeamMaterial.pipe(takeUntil(this.destroy)).subscribe((data: DataForCutBM) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      data.sourceGS = this.bmgs;
      this.CalculateUnits.emit({});
      bmlDialog.close();
    });

    bmlDialog.onCutBeamMaterialLine.pipe(takeUntil(this.destroy)).subscribe((data: DataForCutBML) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      data.sourceGS = this.bmgs;
      this.CalculateUnits.emit({});
      bmlDialog.close();
    });

    bmlDialog.onPasteCutBeamMaterial.pipe(takeUntil(this.destroy)).subscribe((data: DataForCutBM) => {
      // add new bm and bml
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      let couldAdd = true;

      this.loading.emit(true);

      data.copiedBMs.forEach((copyBm) => {
        if (event.bm) {
          couldAdd = this.AddLineToBM(copyBm.beamMaterialLines, event.bm);
        } else {
          couldAdd = this.AddLineToNewBM(copyBm.beamMaterialLines, copyBm.height);
        }
      });

      if (couldAdd) {
        this.copyService.reset();
      }

      this.loading.emit(false);

      bmlDialog.close();
    });

    bmlDialog.onPasteCutBeamMaterialLine.pipe(takeUntil(this.destroy)).subscribe((data: DataForCutBML) => {
      let couldAdd = true;
      this.bmgs.save = true;
      if (event.bm) {
        couldAdd = this.AddLineToBM(data.copiedBMLs, event.bm);
      } else {
        couldAdd = this.AddLineToNewBM(data.copiedBMLs, event.bm?.height ?? data.sourceBM.height);
      }

      if (couldAdd) {
        this.copyService.reset();
      }

      bmlDialog.close();
    });

    bmlDialog.onRemoveBeamMaterial.pipe(takeUntil(this.destroy)).subscribe((bm) => {
      this.RemoveBeamMaterial(bm);
      this.bmgs.save = true;
      // TODO: check if BM is last BM associated with template, if yes, remove template as well
    });

    bmlDialog.onRemoveBeamMaterialLine.pipe(takeUntil(this.destroy)).subscribe((bml: BeamMaterialLine) => {
      this.RemoveBeamMaterialLine(bml, event.bm);
      this.bmgs.save = true;
    });

    bmlDialog.onMoveUppBeamMaterial.pipe(takeUntil(this.destroy)).subscribe((bm) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      this.reorderBeamMaterialUpp(bm, this.bmgs);
    });

    bmlDialog.onAddLineToBM.pipe(takeUntil(this.destroy)).subscribe((param: AddLineParameter) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      if (event.bm) {
        const newLine = this.createBMLFromBOL(param.demand);
        this.AddLineToBM([newLine], event.bm);
      } else {
        const newLine = this.createBMLFromBOL(param.demand);
        const height = this.beamMaterialService.calculateHeight(param.demand.DeafultLamminaPlaneThicknes, param.demand.NumberOfLaminas);

        this.AddLineToNewBM([newLine], height);
      }
    });

    bmlDialog.onAddLineToBMGS.pipe(takeUntil(this.destroy)).subscribe((param: AddLineParameter) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;

      const newLine = this.createBMLFromBOL(param.demand);
      const height = this.beamMaterialService.calculateHeight(param.demand.DeafultLamminaPlaneThicknes, param.demand.NumberOfLaminas);

      this.AddLineToNewBM([newLine], height);
    });

    bmlDialog.onMoveDownBeamMaterial.pipe(takeUntil(this.destroy)).subscribe((bm) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.reorderBeamMaterialDown(bm, this.bmgs);
    });

    bmlDialog.onPasteBM.pipe(takeUntil(this.destroy)).subscribe((bm) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.bmgs.layers[0].BeamMaterials.push(bm);
      this.CalculateUnits.emit({});
    });

    bmlDialog.onAdjustBMGS.pipe(takeUntil(this.destroy)).subscribe(x => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.adjustBMLAndSetCords();
    });

    bmlDialog.onAddTestPsc.pipe(takeUntil(this.destroy)).subscribe(bm => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;

      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
      });
    });

    bmlDialog.onRemoveTestPsc.pipe(takeUntil(this.destroy)).subscribe(bm => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;

      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
        this.copyService.activeSave = false;
      });
    });
  }

  async download() {
    const blob = await this.productionFileService.productionFileForGlueset(this.bmgs.beamMaterialGlueSetID);
    DownloadFileHelper.downloadFile(blob, `GlueSet-${this.bmgs.beamMaterialGlueSetID}-${new Date(Date.now()).toISOString().split('T')[0]}`);
  }

  splitView() {
    const dialogRef = this.dialog.open({
      content: BeamSplitViewModalComponent,
      height: '50%',
      width: '50%'
    });

    const content = dialogRef.content.instance as BeamSplitViewModalComponent;
    content.data = {
      beamMaterials: this.bmgs.beammaterials,
      bmgsLength: this.bmgs.length
    }

  }

  getStateText(code: number): string {
    return GlusetStateHelper.getStateText(code);
  }

  isAnyErrorOrWarning(bmgs: BeamMaterialGlueSet) {
    return bmgs.errorMessageText || this.isAnyBeamRejected(bmgs)
  }

  isAnyBeamRejected(bmgs: BeamMaterialGlueSet): boolean {
    return bmgs.layers.some(layer =>
      layer.BeamMaterials.some(beamMaterial =>
        beamMaterial.beamMaterialLines.some(line => line.ProductionStateGroupCode === ProductionStateGroupCode.REJECTED)
      )
    );
  }

  getTooltip(bmgs: BeamMaterialGlueSet): string {
    let errorMessage = bmgs.errorMessageText ? this.translate.instant(bmgs.errorMessageText) : '';
    let rejectedMessage = this.isAnyBeamRejected(bmgs) ? this.translate.instant('GlueSet.REJECTED_BEAMS_TOOLTIP') : '';
    return [errorMessage, rejectedMessage].filter(Boolean).join('\n');
  }

  private RemoveBeamMaterialLine(bml: BeamMaterialLine, bm: BeamMaterial) {
    this.bmgsService.isDirty = true;
    bml.transportPackageDemand.QtySumUI = bml.transportPackageDemand.QtySumUI - 1;
    bm.beamMaterialLines = bm.beamMaterialLines.filter((obj) => obj !== bml);
    this.bmgs.layers.forEach((l) => {
      l.BeamMaterials = l.BeamMaterials.filter(
        (bm) => bm.beamMaterialLines.length > 0
      );
    });

    this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((calcLenght: number) => {
      bm.length = calcLenght;
      this.adjustBMLAndSetCords();
      this.CalculateUnits.emit({});
      this.copyService.activeSave = false;
    });
  }

  private getLayerIndex(bm: BeamMaterial): number {
    return bm.layer ? bm.layer - 1 : 0;
    // consider two level layering, below code needs to be modified.
    // return this.layers.length >= 1 ? layerValue - 1 : 0;
  }

  private RemoveBeamMaterial(bm: any) {
    this.bmgsService.isDirty = true;
    const layerValue = this.getLayerIndex(bm);
    // find BOL and reduce manualUnit
    this.bmgs.layers[layerValue].BeamMaterials = this.bmgs.layers[
      layerValue
    ].BeamMaterials.filter((obj) => obj !== bm); // TODO: filter is not working, object comparison is failing.
    // this.ReduceManualUnit(bm);
    if (this.bmgs.Template != null) {
      let hasTemplateBm = false;
      for (const element of this.bmgs.layers[bm.layer].BeamMaterials) {
        const xbm = element;
        for (const element of xbm.beamMaterialLines) {
          const xbml = element;
          if (
            xbml.transportPackageDemand.BeamMaterialType !== null &&
            xbml.transportPackageDemand.BeamMaterialType.Name ===
            this.bmgs.Template.Name
          ) {
            hasTemplateBm = true;
          }
        }
      }
      if (!hasTemplateBm) {
        this.bmgs.RemoveTemplate(this.bmgs.Template);
      }
    }

    this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((x: number) => {
      bm.calculatedLength = x;
      this.adjustBMLAndSetCords();
      this.CalculateUnits.emit({});
      this.copyService.activeSave = false;
    });
  }

  private createBMLFromBOL(bol: Demandline): BeamMaterialLine {
    return new BeamMaterialLine(
      bol,
      bol.length,
      bol.BeamOrderRowID,
      bol.deafultNumberOfBeamsPerBeamMaterial,
      1,
      0,
      null,
      null,
      bol.transportPackageId,
      null,
      null,
      bol.transportPackageAllocation,
      null
    );
  }

  private createNewBM(demand: Demandline, width: number, height: number): BeamMaterial {
    return new BeamMaterial(
      -1,
      0,
      -1,
      demand.NumberOfLaminas,
      false,
      undefined,
      undefined,
      demand.DeafultLamminaPlaneThicknes,
      demand.BeamMaterialType,
      demand.materialIndex,
      width,
      height,
      null,
      Guid.newGuid(),
      false,
      0,
      demand.deafultNumberOfBeamsPerBeamMaterial > 1,
      this.machine?.defaultCutOffLength,
      this.machine?.cutWidth
    );
  }

  private AddLineToNewBM(
    newBmls: BeamMaterialLine[],
    height: number
  ): boolean {
    const newBm = this.createNewBM(newBmls[0].transportPackageDemand, 100, height);
    let canAdd = true;

    newBmls.forEach(newBml => {
      const vr: ValidataionResult = this.Validate(
        this.bmgs,
        null,
        newBm,
        newBml,
        0
      );

      if (vr.valid) {
        newBm.AddNewLine(newBml);
      } else {
        canAdd = false;
        this.copyService.activeSave = false;
      }
    });

    if (canAdd) {
      this.beamMaterialService.getBeamMaterialLength(newBm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((calcLenght: number) => {
        newBm.length = calcLenght;
        this.bmgs.layers[0].BeamMaterials.push(newBm);
        this.adjustBMLAndSetCords();
        this.CalculateUnits.emit({});
        this.copyService.activeSave = false;
      });
    } else {
      this.CalculateUnits.emit({});
    }

    return canAdd;
  }

  private AddLineToBM(
    newBmls: BeamMaterialLine[],
    bm: BeamMaterial
  ): boolean {
    let couldAdd = true;

    newBmls.forEach((newBML: BeamMaterialLine) => {
      newBML.BeamMaterialID = bm.beamMaterialID;
      newBML.IndexInCut = -1;

      const vr: ValidataionResult = this.Validate(
        this.bmgs,
        null,
        bm,
        newBML,
        0
      );

      if (vr.valid) {
        // if any BML is affected by template in current BM, then all BML in BM are affected by template.
        bm.AddNewLine(newBML);
      } else {
        couldAdd = false;
        this.copyService.activeSave = false;
      }
    });

    if (couldAdd) {
      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset ?? 0).pipe(takeUntil(this.destroy)).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
        this.copyService.activeSave = false;
      });
    }

    this.CalculateUnits.emit({});

    return couldAdd;
  }

  private reorderBeamMaterialUpp(bm: BeamMaterial, bmgs: BeamMaterialGlueSet) {
    const layerValue = this.getLayerIndex(bm);

    const bmIndex = this.bmgs.layers[layerValue].BeamMaterials.indexOf(bm);
    if (bmIndex > 0) {
      const selectedBm = this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex,
        1
      );

      this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex - 1,
        0,
        selectedBm[0]
      );

      this.adjustBMLAndSetCords();
    }
  }

  private reorderBeamMaterialDown(bm: BeamMaterial, bmgs: BeamMaterialGlueSet) {
    const layerValue = this.getLayerIndex(bm);

    const bmIndex = this.bmgs.layers[layerValue].BeamMaterials.indexOf(bm);

    if (bmIndex < this.bmgs.layers[layerValue].BeamMaterials.length - 1) {
      const selectedBm = this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex,
        1
      );

      this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex + 1,
        0,
        selectedBm[0]
      );
    }

    this.adjustBMLAndSetCords();
  }

  private Validate(
    newBMGS: BeamMaterialGlueSet,
    suorceBM: BeamMaterial,
    newBM: BeamMaterial,
    newBML: BeamMaterialLine,
    toLayer: number
  ): ValidataionResult {
    const ret: Array<{ text: string, values: any }> = new Array<{ text: string, values: any }>();
    let heightLayer = 0;
    let numberOfLamminas = 0;
    let bmlInSameBM: boolean = false;
    let newHeight = 0;
    const bol = newBML.transportPackageDemand;

    if (bol.BeamLamminaDimensionID !== this.lamminaDim.beamLamminaDimensionID) {
      ret.push({ text: 'GlueSet.BM_OF_DIFFERENT_DIM', values: { bm: this.lamminaDim.width } });
    }
    // Validate template

    if (bol.BeamMaterialType != null && newBMGS.Template != null) {
      if (bol.BeamMaterialType.Name !== newBMGS.Template.Name) {
        ret.push({ text: 'GlueSet.BMGS_CAN_NOT_ADD_MANY_TEMPLATES', values: {} });
      }
    }

    if (
      suorceBM === null &&
      bol.QtyPlanned + bol.QtySumUI >= (bol.transportPackageAllocation ? bol.transportPackageAllocation.qty : bol.qty) + (bol.transportPackageAllocation ? bol.transportPackageAllocation.unitsExtra : bol.QtyReproduce)
    ) {
      ret.push({ text: 'GlueSet.ALL_UNITS_ROW_PLANNED', values: {} }); //    6                0                   1             >   7           0
    }

    if (suorceBM !== null) {
      numberOfLamminas = suorceBM.numberOfLamminas;
    } else {
      numberOfLamminas = bol.NumberOfLaminas;
    }

    const layerMaterials = newBMGS.layers[0].BeamMaterials;
    layerMaterials.forEach((bm) => {
      heightLayer += bm.getHight();
      if (bm === newBM) {
        bmlInSameBM = true;
      }
    });

    if (!bmlInSameBM) {
      newHeight = heightLayer + (bol.NumberOfLaminas * bol.DeafultLamminaPlaneThicknes) > 0 ?
        heightLayer + (bol.NumberOfLaminas * bol.DeafultLamminaPlaneThicknes) :
        bol.hight;
    }

    if (bol.DeafultLamminaPlaneThicknes !== newBM.planedThickness) {
      ret.push({ text: 'GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM', values: { bol: bol.DeafultLamminaPlaneThicknes, bm: newBM.planedThickness } });
    }

    if (newBMGS.beammaterials && newBMGS.beammaterials.length > 0 && newBMGS.beammaterials[0].planedThickness !== bol.DeafultLamminaPlaneThicknes) {
      ret.push({ text: 'GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM', values: { bol: bol.DeafultLamminaPlaneThicknes, bm: newBMGS.beammaterials[0].planedThickness } });
    }

    if (numberOfLamminas !== newBM.numberOfLamminas) {
      ret.push({ text: 'GlueSet.BOR_LAMMINAS_DIFFERNET_FROM_BM', values: {} });
    } else if (
      newBM.getLength() + newBML.length >
      environment.maxLenght
    ) {
      const lenghtCalc = newBMGS.calcMaxLength() + newBML.length;

      ret.push(
        {
          text: 'GlueSet.Total_Glue_Set_Length_Exided',
          values: <any>{ lenght: lenghtCalc, maxLenght: environment.maxLenght }
        });
    } else if (newHeight > environment.maxHight) {
      ret.push(
        {
          text: 'GlueSet.Total_Glue_Set_Hight_Exided',
          values: <any>{ height: newHeight, maxHeight: environment.maxHight }
        }
      );
    }

    ret.forEach((error) => {
      this.notificationService.notifyErrorAppChanel(
        error.text,
        'GlueSet.BMGS_Edit_Error',
        error.values
      );
    });

    const vr = new ValidataionResult();

    if (ret.length > 0) {
      vr.valid = false;
      vr.message = ret[0].text;
    } else {
      vr.valid = true;
    }
    return vr;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

@Component({
  selector: 'key-value-display',
  styleUrls: ['./beam-material-glueset.component.css'],
  template: `
    <span [ngClass]="{ 'info-pill': true, 'info-pill-prim': useBackground }">
      <b>{{ lable | translate }}:</b> {{ value | number }} <b>{{ unit }}</b>
    </span>
  `
})
export class KeyValueDisplayComponent {
  @Input() lable: string;
  @Input() value: number;
  @Input() unit: string;
  @Input() useBackground: boolean = false;

  constructor() { }
}
