import { Component } from '@angular/core';
import { ColumnReorderEvent, ColumnResizeArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EntityPropertyService, IEntityProperty } from '@app/core/services/http-services/model/entity-property.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { State } from '@progress/kendo-data-query';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-entity-property-editor',
  templateUrl: './entity-property-editor.component.html',
  styleUrls: ['./../entity-editor.component.css']
})
export class EntityPropertyEditorComponent {
  state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public entityProperty: IEntityProperty = null;
  public isCollapsed: boolean = true;
  public isNew = false;
  public result: IEntityProperty[] = [];

  public dataTypes: { text: string; value: number }[] = [];

  constructor(private entityPropertyService: EntityPropertyService,
    private notificationService: AppNotificationService) {
    this.dataTypes = this.entityPropertyService.getDataTypeMap();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.entityPropertyService.get().subscribe((d) => {
      this.result = d;
    });
  }

  onAddEntity() {
    this.isNew = true;
    this.isCollapsed = false;
  }

  onNewEntity() {
    this.isNew = true;
    this.entityProperty = null;
    this.entityProperty = <IEntityProperty>{
      description: null,
      name: null,
      code: null,
      propertyGroupCode: null,
      datatype: 1,
      areValuesValidated: false,
      entityGroupProperties: [],
      validValues: []
    };
    this.isCollapsed = false;
    const dialogOpened = this.entityPropertyService.openDialog(this.entityProperty, true);
    dialogOpened.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        dialogOpened.close();
      } else if (result) {
        this.loadData();
        this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityAdded');
      }
      this.isCollapsed = true;
    });
  }

  onEditEntity({ dataItem }) {
    this.entityProperty = dataItem;
    const dialogOpened = this.entityPropertyService.openDialog(this.entityProperty, false);
    dialogOpened.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        dialogOpened.close();
      } else if (result) {
        this.loadData();
        this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityUpdated');
      }
    });
  }

  public columnReorder(args: ColumnReorderEvent): void {
    console.log(`column ${args.column['field']} moved from index ${args.oldIndex} to index ${args.newIndex}`);
  }

  public columnResize(args: ColumnResizeArgs[]): void {
    const arg = args[0][0];
    console.log(`column ${arg.column['field']} width changed from ${arg.oldWidth} to ${arg.newWidth} pixels`);
  }

  pageChange(e: PageChangeEvent) {
    this.state = {
      skip: e.skip,
      take: e.take
    };
  }

  public getDataTypeString(dataType: number): string {
    return this.dataTypes.find(d => d.value === dataType)?.text ?? 'INVALID';
  }
}
