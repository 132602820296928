import { Injectable } from '@angular/core';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { RoleBasedAccessHelper, UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

marker('Operative.ChangeStateBackToReleaseMsg');

@Injectable()
export class ProdOrderCustomService {
  deniedRoles = [UserRoleEnum.Operator];

  constructor(private translateService: TranslateService, private roleBasedAccessHelper:RoleBasedAccessHelper) { }

  getPOBackToReleaseMsg(oldStateId:number) :string {
    let msg = '';
    this.translateService.get('Operative.ChangeStateBackToReleaseMsg', { State: ProdOrderState[oldStateId] })
      .subscribe(x => {
        msg = x;
      });
    return msg;
  }

  checkAccessForClosedPO(stateId:number) {
    return stateId === ProdOrderState.Closed && this.roleBasedAccessHelper.hasOnlyInputRole(this.deniedRoles);
  }
}
