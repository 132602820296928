export enum UserSettingkeyEnum {
  grid_prodOrder_prodOrderList = 'grid/prod-order/prod-order-list',
  availability_aot_balance = 'availability/aot-balance',
  grid_prodOrder_prodOrderdetailList = 'grid/prod-order/prod-order-detail-list',
  inputForm_glulam_glulamOptimizer = 'inputForm/glulam/glulamOptimizer',
  glulam_sequence_optimizer='optimization/optimize-sequencer',
  grid_gluelam_demandList = 'grid/gluelam/demand-list',
  grid_gluelam_demandList_exp = 'grid/gluelam/demand-list-exp',
  inputForm_glulam_glulamStaticalOptimizer = 'inputForm/glulam/glulam-statical-optimizer',
  order_view = 'gluelam/order_view',
}
