import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { AppNotificationService } from './notification.service';

export class BaseService {
  static readonly apiVersion: string = '1';
  private readonly _loginUrl: string = '/connect/token';
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(public http: HttpClient, public configurations: ConfigurationService, public notificationService: AppNotificationService) {
  }
}
