export class GraphConfig {
  public egRubner: EG[];
  public egOther: EG[];
  constructor() {
    this.egOther = <EG[]>[
      { entityGroupIndex: 89, level: 1, color: 'lightgreen' },
      { entityGroupIndex: 90, level: 2, color: '#2B7CE9' },
      { entityGroupIndex: 88, level: 3, color: 'red' },
      { entityGroupIndex: 87, level: 4, color: '#cf75a4' }
    ];
    this.egRubner = <EG[]>[
      { entityGroupIndex: 6, level: 1, color: '#ffb3ba' },
      { entityGroupIndex: 7, level: 1, color: '#ffdfba' },
      { entityGroupIndex: 8, level: 1, color: '#ffffba' },
      { entityGroupIndex: 14, level: 1, color: '#baffc9' },
      { entityGroupIndex: 16, level: 1, color: '#bae1ff' },
      { entityGroupIndex: 31, level: 1, color: '#e1f7d5' },
      { entityGroupIndex: 33, level: 1, color: '#ffbdbd' },
      { entityGroupIndex: 35, level: 1, color: '#c9c9ff' },
      { entityGroupIndex: 36, level: 1, color: '#f1cbff' },
      { entityGroupIndex: 37, level: 1, color: '#66b2b2' },
      { entityGroupIndex: 38, level: 1, color: '#006666' },
      { entityGroupIndex: 39, level: 1, color: '#005b96' },
      { entityGroupIndex: 41, level: 1, color: '#b3cde0' },
      { entityGroupIndex: 52, level: 1, color: '#ff6f69' },
      { entityGroupIndex: 59, level: 1, color: '#ffcc5c' },
      { entityGroupIndex: 63, level: 1, color: '#88d8b0' },
      { entityGroupIndex: 64, level: 1, color: '#efbbff' },
      { entityGroupIndex: 66, level: 1, color: '#be29ec' },
      { entityGroupIndex: 67, level: 1, color: '#660066' },
      { entityGroupIndex: 71, level: 1, color: '#b3ecec' },
      { entityGroupIndex: 76, level: 1, color: '#ffbaba' },
      { entityGroupIndex: 77, level: 1, color: '#a70000' },
      { entityGroupIndex: 82, level: 1, color: '#fc3468' },
      { entityGroupIndex: 89, level: 1, color: '#ff0065' },
      { entityGroupIndex: 92, level: 1, color: '#ffbfd3' },
      { entityGroupIndex: 95, level: 1, color: '#0086ad' },
      { entityGroupIndex: 98, level: 1, color: '#090088' },
      { entityGroupIndex: 101, level: 1, color: '#ff6666' },
      { entityGroupIndex: 103, level: 1, color: '#525266' },
      { entityGroupIndex: 107, level: 1, color: '#7df9ff' },
      { entityGroupIndex: 111, level: 1, color: '#e76a6a' },
      { entityGroupIndex: 115, level: 1, color: 'lightgreen' },
      { entityGroupIndex: 118, level: 2, color: 'red' }
    ];
  }
}

export class EG {
  public entityGroupIndex:number;
  public level:number;
  public color:string;
}
