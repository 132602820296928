<svg *ngIf="!bm.BeamMaterialType.IsManual">
  <svg:rect
    *ngFor="let bml of getLinesToDraw(); let i = index"
    x="0"
    [attr.width]="ch.getWidth(bm.getLength())"
    [attr.height]="ch.getHight(bm.getHight())"
    [ngStyle]="bm.getColor()"
    (contextmenu)="grab($event)"
    [attr.y]="0">
  </svg:rect>

  <svg:text
    *ngIf="bm.IsTemplate" class="small" y="13" x="13">
     {{ bm.BeamMaterialType ? bm.BeamMaterialType.Name : "Template" }}
  </svg:text>

  <svg
    *ngFor="let bml of getLinesToDraw(); let i = index">
    <svg
        app-beam-material-line
        [bml]="bml"
        [ch]="ch"
        [attr.x]="ch.getWidth(getXforBML(bml,i))"
        [planedThickness]="bm.planedThickness"
        [defaultNoOfBeams]="getDefaultNoOfBeams(bml)"
        [colorLight]="bm.BeamMaterialType.ColorLight"
        (openBeamMaterialLineMenu)="openBeamMaterialLineMenu($event)"
        [attr.y]="0">
    </svg>
  </svg>

  <svg:rect
    *ngIf="bm.takeTestPsc"
    y="0"
    [attr.width]="ch.getWidth(bm.testPscLenght)"
    [attr.height]="ch.getHight(bm.getHight())"
    [attr.x]="ch.getWidth(xOffsetTestPSC)"
    class="test-pcs"
  ></svg:rect>
</svg>
<svg *ngIf="bm.BeamMaterialType.IsManual">
  <svg>
    <defs>
      <clipPath *ngFor="let bml of manualBeamMaterialLinesToDisplay; let i = index" [attr.id]="'clip_' + bm.getLength()">
        <rect [attr.width]="ch.getWidth(bm.getLength()) - 5" [attr.height]="ch.getHight(bm.getHight())" [attr.x]="0" [attr.y]="0">
        </rect>
      </clipPath>
    </defs>
      <rect
        *ngFor="let bml of manualBeamMaterialLinesToDisplay; let i = index"
        x="0"
        [attr.width]="ch.getWidth(bm.getLength())"
        [attr.height]="ch.getHight(bm.getHight())"
        [ngStyle]="bm.getColor()"
        (contextmenu)="grab($event)"
        [attr.y]="0">
      </rect>
      <text alignment-baseline="middle" [attr.clip-path]="'url(#clip_' + bm.getLength() + ')'" [attr.y]="12" [attr.x]="5 + getXforManualBML(i)" *ngFor="let bml of manualBeamMaterialLinesToDisplay; let i = index" class="small no-pointer white-stroke">
        <tspan>
          {{getTextForManualBeamMaterial(i)}}
        </tspan>
      </text>
      <svg:rect
      *ngIf="bm.takeTestPsc"
      y="0"
      [attr.width]="ch.getWidth(bm.testPscLenght)"
      [attr.height]="ch.getHight(bm.getHight())"
      [attr.x]="ch.getWidth(xOffsetTestPSC)"
      class="test-pcs"
    ></svg:rect>
  </svg>
</svg>
