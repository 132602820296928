import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Demandline } from '@app/core/models/demand-line.model';
import { IDemandLamellaSpecificationDTO } from '@app/core/models/demand-lamella-specification.model';
import { DemandSpecificationDialogComponent } from '@app/modules/aot/demand-specification-dialog/demand-specification-dialog.component';
import { IDemandDTO } from '@app-core-httpservice/gluelam/demand.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('AoT.DemandSpecificationDialogHeader');

@Injectable({
  providedIn: 'root'
})

export class DemandSpecificationService {
  constructor(private readonly http: HttpClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService
  ) { }

  get(beamOrderRowId: number): Observable<IDemandLamellaSpecificationDTO[]> {
    return this.http.get<IDemandLamellaSpecificationDTO[]>(`${environment.apiUrl}DemandSpecification/${beamOrderRowId}`);
  }

  save(demandSpecifications: IDemandLamellaSpecificationDTO[]): Observable<IDemandDTO> {
    return this.http.post<IDemandDTO>(`${environment.apiUrl}DemandSpecification`, demandSpecifications);
  }

  openDialog(demand: Demandline): DialogRef {
    const dialogRef = this.dialogService.open({
      content: DemandSpecificationDialogComponent,
      title: this.translateService.instant('AoT.DemandSpecificationDialogHeader'),
      height: 'auto',
      width: '1000px'
    });

    const content = dialogRef.content.instance as DemandSpecificationDialogComponent;
    content.data = demand;
    return dialogRef;
  }
}
