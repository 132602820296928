<div class="container">
  <form
      [formGroup]="entityForm"
      style="display: flex; flex-direction: column;"
      (ngSubmit)="onSubmit()">
      <div>
          <h3 translate>EntityTranslation.BaseData</h3>
          <div class="row" style="margin-bottom: 20px;">
              <kendo-formfield style="width: 300px;">
                  <kendo-label translate>EntityTranslation.Code</kendo-label>
                  <kendo-textbox formControlName="code"></kendo-textbox>
              </kendo-formfield>
              <kendo-formfield style="width: 400px;">
                  <kendo-label translate>EntityTranslation.Description</kendo-label>
                  <kendo-textbox formControlName="description"></kendo-textbox>
              </kendo-formfield>
              <app-select-unit
                  formControlName="baseUnitCode"
                  [showLable]="true"
                  [label]="'EntityTranslation.BaseUnitCode'"
                  class="width-dropdown">
              </app-select-unit>
              <app-select-entity-status
                  formControlName="status"
                  [showLable]="true"
                  [label]="'EntityTranslation.EntityStatus'"
                  class="width-dropdown">
              </app-select-entity-status>
              <span
                  *ngIf="isActivity"
                  class="row">
                  <app-machine-selector-dropdown formControlName="machineId" [machineGroupId]="_entityGroup?.machineGroupId"></app-machine-selector-dropdown>
                  <kendo-formfield>
                      <kendo-label translate>EntityTranslation.BatchSize</kendo-label>
                      <kendo-textbox
                          type="text"
                          formControlName="batchSize">
                          <ng-template kendoTextBoxSuffixTemplate>
                              {{entityForm.get('baseUnitCode').value }}
                          </ng-template>
                      </kendo-textbox>
                  </kendo-formfield>
              </span>
              <span
                  *ngIf="!isActivity"
                  class="row">
                  <kendo-formfield>
                      <kendo-label translate>EntityTranslation.MinStock</kendo-label>
                      <kendo-numerictextbox
                          formControlName="targetMinStock"
                          [ngModel]="entityForm.get('targetMinStock').value | ReplaceDotToCommaPipe">
                          <ng-template kendoTextBoxSuffixTemplate>
                              {{entityForm.get('baseUnitCode').value }}
                          </ng-template>
                      </kendo-numerictextbox>
                  </kendo-formfield>
                  <kendo-formfield>
                      <kendo-label translate>EntityTranslation.MaxStock</kendo-label>
                      <kendo-numerictextbox
                          formControlName="targetMaxStock"
                          [ngModel]="entityForm.get('targetMaxStock').value | ReplaceDotToCommaPipe">
                          <ng-template kendoTextBoxSuffixTemplate>
                              {{entityForm.get('baseUnitCode').value }}
                          </ng-template>
                      </kendo-numerictextbox>
                  </kendo-formfield>
              </span>
          </div>
          <div
              class="row"
              style="max-height: 50vh;">
              <div
                  class="col">
                  <h3 translate>
                    EntityTranslation.PropertyValues
                  </h3>
                  <div
                      formArrayName="propertyValues"
                      style="overflow: auto;">
                      <div *ngFor="let v of propertyValues.controls; let i = index">
                          <div
                              [formGroupName]="i"
                              class="row">
                              <app-select-valid-value
                                  formControlName="value"
                                  [showLable]="true"
                                  [label]="v.value['propertyCode']"
                                  [propertyCode]="v.value['propertyCode']">
                                </app-select-valid-value>
                          </div>
                      </div>
                  </div>
                  <div *ngIf="!isActivity">
                  <div class="row">
                      <h3>Unit Conversion</h3>
                      <div style="flex-grow: 1;"></div>
                      <div class="col">
                          <a
                              kendoButton
                              (click)="addNewunitConversion()"
                              primary="true">
                              {{ 'App.Add' | translate }}
                          </a>
                      </div>
                  </div>
                  <div
                      formArrayName="unitConversions"
                      style="overflow: auto;">
                      <span
                          *ngIf="unitConversions.errors != null"
                          class="validation-text">
                          Unit code already exist!!!
                      </span>
                      <div *ngFor="let v of unitConversions.controls; let i = index">
                          <div
                              [formGroupName]="i"
                              *ngIf="v.get('isRemoved').value != true && show(v)"
                              class="row">
                              <kendo-formfield>
                                  <kendo-label translate>EntityTranslation.ConversionFactor</kendo-label>
                                  <kendo-numerictextbox formControlName="convFactor"></kendo-numerictextbox>
                              </kendo-formfield>
                              <app-select-unit formControlName="unitCode" [showLable]="true"></app-select-unit>
                              <div class="remove-conversion-button">
                                  <button
                                      *ngIf="!(v.get('unitCode').value == 'M3' || v.get('unitCode').value == 'LPM' || v.get('unitCode').value == 'PCS')"
                                      kendoButton
                                      (click)="removeUnitConversion(i)"
                                      [primary]="true">
                                      {{ 'App.Remove' | translate }}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div
              class="col"
              *ngIf="isActivity">
              <h3>
                  {{ 'EntityTranslation.Files' | translate }}
              </h3>
              <app-file-upload
                      *ngIf="!isNew"
                      [container]="'activity-attachments'"
                      [folder]="entityForm.get('guid').value"
                      [downloadMode]="fileDownloadMode.SingleFileUsingChip">
              </app-file-upload>
              </div>
              <div
                  class="col"
                  style="flex-grow: 1; ">
                  <div class="row">
                      <h3 *ngIf="data.entityTypeCode == 'RESOURCE'">
                          {{ 'EntityTranslation.RelationMaterial' | translate }}
                      </h3>
                      <h3 *ngIf="data.entityTypeCode == 'ACTIVITY'">
                          {{ 'EntityTranslation.RelationActivity' | translate }}
                      </h3>
                      <div style="flex-grow: 1;"></div>
                      <div class="col">
                        <button *ngIf="isActivity && isGlulamSpecEnabled"
                          kendoButton
                          (click)="openAddEditGlulamSpecModal()"
                          [primary]="true"
                          [disabled]="glulamSpecButtonDisabled">
                          {{ 'GluePlan.GlulamSpec' | translate }}
                        </button>
                      </div>
                      <div class="col">
                        <button
                          kendoButton
                          (click)="addNewEntityRelation(data.entityTypeCode)"
                          [primary]="true">
                          {{ 'App.Add' | translate }}
                        </button>
                      </div>
                  </div>
                  <div
                      formArrayName="entityRelations"
                      style="overflow: auto;">
                      <div *ngFor="let r of entityRelations().controls let i = index">
                          <div
                              [formGroupName]="i"
                              *ngIf="r.get('isRemoved').value != true"
                              class="row"
                              style="align-items: flex-end;">
                              <app-select-entity
                                  formControlName="entityIndex"
                                  [entityTypeCode]="data.entityTypeCode == 'RESOURCE'? 'ACTIVITY' : 'RESOURCE'"
                                  (selectedEntity)="setSelectedMaterial($event)"
                                  [showLable]="true"
                                  [label]="data.entityTypeCode == 'RESOURCE'? 'ACTIVITY' : 'MATERIAL'">
                              </app-select-entity>
                              <kendo-formfield>
                                  <kendo-label
                                      translate
                                      text="{{ 'EntityTranslation.Relation' | translate }}"></kendo-label>
                                  <kendo-numerictextbox
                                      placeholder="relation"
                                      formControlName="relation"
                                      [ngModel]="r.get('relation').value | ReplaceDotToCommaPipe">
                                  </kendo-numerictextbox>
                              </kendo-formfield>
                              <app-select-unit
                                  formControlName="unitCode"
                                  [showLable]="true"
                                  style="width:100px"></app-select-unit>
                              <app-select-entity-relation-direction
                                  formControlName="direction"
                                  style="width:100px"
                                  (onSelectDirection)=onSelectDirection($event)
                                  [showLable]="false">
                              </app-select-entity-relation-direction>
                              <kendo-formfield>
                                  <kendo-label
                                      [for]="Tactical"
                                      text="{{ 'EntityTranslation.Tactical' | translate }}"></kendo-label>
                                  <kendo-switch
                                      #Tactical
                                      formControlName="inTactical"></kendo-switch>
                              </kendo-formfield>
                              <kendo-formfield>
                                  <kendo-label
                                      [for]="Operative"
                                      text="{{ 'EntityTranslation.Operative' | translate }}"></kendo-label>
                                  <kendo-switch
                                      #Operative
                                      formControlName="inOperative"></kendo-switch>
                              </kendo-formfield>
                              <kendo-formfield *ngIf="isActivity">
                                  <kendo-label
                                      [for]="Chaining"
                                      text="{{ 'EntityTranslation.Chaining' | translate }}"></kendo-label>
                                  <kendo-switch
                                      #Chaining
                                      formControlName="isChaining"></kendo-switch>
                              </kendo-formfield>
                              <div class="remove-relation-button">
                                  <button
                                      kendoButton
                                      themeColor="error"
                                      type="button"
                                      (click)="removeEntityRelation(i)">
                                      {{ 'App.Remove' | translate }}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </form>
  <kendo-dialog-actions align="end">
    <div class="flex-grow:1"></div>
    <button
        kendoButton
        type="submit"
        (click)="onSubmit()"
        [disabled]="(!entityForm.valid || disableSubmit)"
        *ngIf="isNew"
        [primary]="true">
        {{ 'App.Add' | translate }}
    </button>
    <button
        kendoButton
        type="submit"
        [disabled]="(!entityForm.valid || disableSubmit)"
        *ngIf="!isNew"
        (click)="onSubmit()"
        [primary]="true">
        {{ 'App.Update'| translate }}
    </button>
    <button
        kendoButton
        (click)="onCancel()"
        [themeColor]="'error'">
        {{ 'EntityTranslation.Cancle' | translate }}
    </button>
  </kendo-dialog-actions>
  </div>
