export class CordHelper {
  private pxPerMMWidth = null;
  private pxPerMMHight = null;

  public constructor(containerHightPX: number, hightMaxMM: number, containerWidthPX: number, widthMaxMM: number) {
    if (containerWidthPX && widthMaxMM && containerHightPX && hightMaxMM) {
      this.pxPerMMWidth = containerWidthPX / widthMaxMM;
      this.pxPerMMHight = containerHightPX / hightMaxMM;
    } else {
      console.error(`Wrong input to Cord Helper containerHightPX = ${containerHightPX} hightMaxMM  = ${hightMaxMM} ContainerWidthPx = ${containerWidthPX} widthMaxMM = ${widthMaxMM}`);
    }
  }

  public getHight(mm: number): number {
    return mm * this.pxPerMMHight;
  }

  public getWidth(mm: number): number {
    return mm * this.pxPerMMWidth;
  }
}
