import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplaceDotToCommaPipe } from '@app/shared/helpers/replace-dot-to-comma.pipe';
import { SiteDateFormatPipe } from './helpers/date-format.pipe';
import { AccessControlDirective } from './helpers/access-control.directive';

@NgModule({
  declarations: [
    ReplaceDotToCommaPipe,
    SiteDateFormatPipe,
    AccessControlDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ReplaceDotToCommaPipe,
    SiteDateFormatPipe,
    AccessControlDirective
  ],
  providers: [
    SiteDateFormatPipe
  ]
})
export class SharedModule { }
