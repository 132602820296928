import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { IEntityGroupProperty } from '../../custom-services/entity-group.service';
import { Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { environment } from 'src/environments/environment';
import { EntityPropertyFormComponent } from '@app/modules/entity-admin/entity-property-form/entity-property-form.component';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('EntityTranslation.NewEntityProperty');
marker('EntityTranslation.UpdateEntityProperty');

@Injectable({
  providedIn: 'root'
})

export class EntityPropertyService extends BehaviorSubject<GridDataResult> {
  public validValues : IEntityPropertyValueValid[] = null;

  constructor(
    private readonly http: HttpClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService
  ) { super(null); }

  get(): Observable<Array<IEntityProperty>> {
    return this.http.get<IEntityProperty[]>( `${environment.apiUrl}EntityProperty`).pipe(map(l => {
      l.forEach(k => {
        k.changeDate = new Date(k.changeDate);
      });
      return l;
    }));
  }

  update(entityProperty: IEntityProperty): Observable<any> {
    return this.http.put<IEntityProperty[]>(`${environment.apiUrl}EntityProperty/${entityProperty.code}`, JSON.stringify(entityProperty), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(entityProperty: IEntityProperty): Observable<any> {
    return this.http.post<IEntityProperty>(`${environment.apiUrl}EntityProperty`, JSON.stringify(entityProperty), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  openDialog(entityProperty:IEntityProperty, isNew:boolean): DialogRef {
    const dialogRef = this.dialogService.open({
      content: EntityPropertyFormComponent,
      title: isNew ? this.translateService.instant('EntityTranslation.NewEntityProperty') : this.translateService.instant('EntityTranslation.UpdateEntityProperty'),
      maxWidth: '1200px',
      maxHeight: '1000px'
    });

    const content = dialogRef.content.instance as EntityPropertyFormComponent;
    content.data = { entityProperty, isNew  };

    return dialogRef;
  }

  getValidValues(propertyCode: string): Observable<Array<IEntityPropertyValueValid>> {
    return this.http.get<IEntityPropertyValueValid[]>(`${environment.apiUrl}EntityProperty/getValidValues?propertyCode=${propertyCode}`)
      .pipe(tap(v => {
        this.validValues = v;
      }));
  }

  getDataTypeMap(): Array<{ text: string; value: number }> {
    return [
      { value: EntityPropertyDataTypeEnum.DATA_TYPE_INT, text: 'INT' },
      { value: EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE, text: 'Double' },
      { value: EntityPropertyDataTypeEnum.DATA_TYPE_STRING, text: 'String' }
    ];
  }

  searchProperties(code:string, take:number) : Observable<IEntityProperty[]> {
    return this.http.get<IEntityProperty[]>(`${environment.apiUrl}EntityProperty/searchProperties`, {
      params: new HttpParams()
        .set('code', code)
        .set('take', take.toString())
    });
  }

  getPropertyByCode(code: string): Observable<IEntityProperty> {
    return this.http.get<IEntityProperty>(`${environment.apiUrl}EntityProperty/${code}`);
  }

  getEntityProperties(): Observable<Array<IEntityProperty>> {
    return this.http.get<IEntityProperty[]>(`${environment.apiUrl}EntityProperty/getEntityProperties`)
  }
}

export interface IEntityProperty {
  description: string;
  name: string;
  code: string;
  propertyGroupCode: string;
  datatype: number;
  areValuesValidated: boolean | null;
  sortOrderIndex: number;
  userCode: string;
  changeDate: Date | null;
  entityGroupProperties: IEntityGroupProperty[];
  validValues: IEntityPropertyValueValid[];
}

export interface IEntityPropertyValueValid {
  id: number;
  propertyCode: string;
  valueInt: number | null;
  valueDouble: number | null;
  valueString: string;
  description: string;
  sortOrderIndex: number | null;
  userCode: string;
  changeDate: string | null;
  isNew: boolean;
  isChange: boolean;
  isRemoved: boolean;
}
