import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.css']
})
export class LoginRedirectComponent implements OnInit {
  account;
  to;
  constructor(public router:Router, public msalService:MsalService) { }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe({
      next: (response:AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(response.account);
        this.router.navigate(['dashboard']);
      },
      error: e => console.error(e),
      complete: () => true
    });
  }
}
