export class BeamMaterialType {
  constructor(
    public BeamMaterialTypeID: number,
    public TypeGroupCode: number,
    public Name: string,
    public Desctiption: string,
    public Color: string,
    public ColorLight: string,
    public FixedHight: number,
    public Radius: number,
    public IsNormal: boolean,
    public IsSpecialForm: boolean,
    public IsManual: boolean
  ) {}

  getHight(lenght:number):number {
    return 200;
  }
}
