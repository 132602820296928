<kendo-loader
    *ngIf="loading"
    size="small">
</kendo-loader>

<ng-template [ngIf]="downloadMode === fileDownloadMode.SingleFileUsingDropdown">
    <kendo-dropdownbutton
        [disabled]="files.length === 0"
        [data]="files"
        (itemClick)="downloadFile($event.fileURL)">
        {{"Operative.InstructionFiles" | translate}} ({{files.length ?? 0}})
        <ng-template kendoDropDownButtonItemTemplate let-dataItem>
          <span class="k-icon k-i-file-pdf"></span>
          <span>{{ dataItem.fileName }}</span>
        </ng-template>
    </kendo-dropdownbutton>
</ng-template>

<ng-template [ngIf]="downloadMode === fileDownloadMode.SingleFileUsingChip">
    <kendo-chip
        *ngFor="let f of files"
        [label]="f.fileName"
        [removable]="true"
        (contentClick)="downloadFile(f.fileURL)"
        (remove)="tryRemoveFile(f.fileName)">
    </kendo-chip>
</ng-template>

<ng-template [ngIf]="downloadMode === fileDownloadMode.AllFiles">
  <button
    kendoButton
    themeColor="info"
    type="button"
    [disabled]="files.length === 0"
    (click)="downloadAndPreviewPDF()">
    {{'App.View' | translate}} ({{files.length ?? 0}})
  </button>
</ng-template>

<kendo-upload
    *ngIf="canEdit == true"
    [saveUrl]="uploadSaveUrl"
    [autoUpload]="false"
    [restrictions]="myRestrictions"
    [disabled]="disabled"
    (upload)="uploadEventHandler($event)"
    (success)="getFiles()">
</kendo-upload>

<app-confirmation-dialog
    (conformationDialogCloseEvent)="confirmRemoveUploadedFile($event)"
    *ngIf="removeUploadedFile">
</app-confirmation-dialog>
