import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IMachineDTO, IMachineSearchCriteriaDTO } from '@app/core/models/machineDTO';
import { shareReplay, tap } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MachineService {
  conf: any;

  private readonly _mashhineUrl = `${environment.apiUrl}Machine`;
  private mashineGetMap = new Map<number, Observable<IMachineDTO>>();

  constructor(
    public http: HttpClient
    , private readonly kendoGridService: KendoGridService) { }

  get(state: State, criteria: IMachineSearchCriteriaDTO): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    criteria.machines.forEach(m => {
      params = params.append('machineIds', m.machineId?.toString());
    });

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http.get<GridDataResult>(this._mashhineUrl, { params });
  }

  public data: any[] = [];
  _machinesDataResult: GridDataResult = null;

  getmachines(): Observable<GridDataResult> {
    return this.http.get<GridDataResult>(this._mashhineUrl)
      .pipe(tap(m => {
        this._machinesDataResult = m;
      }));
  }

  query(machineGroupId: number | null = null, isStaticalPressGroup: boolean | null = null, isContinuousPressGroup: boolean | null = null): Observable<GridDataResult> {
    let params = new HttpParams();

    if (machineGroupId) {
      params = params.append('machineGroupId', machineGroupId.toString());
    }

    if (isStaticalPressGroup) {
      params = params.append('isStaticalPressGroup', isStaticalPressGroup.toString());
    }

    if (isContinuousPressGroup) {
      params = params.append('isContinuousPressGroup', isContinuousPressGroup.toString());
    }

    return this.http.get<GridDataResult>(`${this._mashhineUrl}/query`, { params })
      .pipe(tap(m => {
        this._machinesDataResult = m;
      }));
  }

  getMachinesByID(id: number): Observable<IMachineDTO> {
    if(this.mashineGetMap.has(id)){
      return this.mashineGetMap.get(id);
    }else{
      const sub = this.http.get<IMachineDTO>(`${this._mashhineUrl}/${id}`).pipe(shareReplay());
      this.mashineGetMap.set(id, sub);
    }
  }

  update(machine: IMachineDTO): Observable<any> {
    return this.http.put<IMachineDTO>(`${this._mashhineUrl}/${machine.machineId}`, JSON.stringify(machine), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(entity: IMachineDTO): Observable<IMachineDTO> {
    return this.http.post<IMachineDTO>(this._mashhineUrl, JSON.stringify(entity), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  remove(data: IMachineDTO): Observable<boolean> {
    return this.http.delete<boolean>(`${this._mashhineUrl}/${data.machineId}`);
  }
}
