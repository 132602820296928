import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '@progress/kendo-data-query';
import { SiteDateFormatPipe } from '@app/shared/helpers/date-format.pipe';

@Injectable({
  providedIn: 'root'
})
export abstract class QuantityReportService extends BehaviorSubject<GridDataResult> {
  loading: boolean;
  quantityReports : GridDataResult = null;
  HandelingUnitId: any = null;
  listdata:IQuantityReport[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly siteDateFormatPipe:SiteDateFormatPipe
  ) {
    super(<GridDataResult>{ data: [], total: 0 });
  }

  queryQuantity(state: State, HandelingUnitId): void {
    this.loading = true;

    let q = new HttpParams();

    const stocklocation: any = state.filter.filters.find(x => x['field'] === 'stockLocationName');
    const qty: any = state.filter.filters.find(x => x['field'] === 'qty');
    const qtyUnit: any = state.filter.filters.find(x => x['field'] === 'qtyUnit');
    const isActive: any = state.filter.filters.find(x => x['field'] === 'isActive');
    const isPartioal: any = state.filter.filters.find(x => x['field'] === 'isPartioal');
    const stockTransTypeName: any = state.filter.filters.find(x => x['field'] === 'stockTransTypeName');
    const eventDate: any = state.filter.filters.find(x => x['field'] === 'eventDate');
    const reportDate:any = state.filter.filters.find(x => x['field'] === 'reportDate');
    const changeUser: any = state.filter.filters.find(x => x['field'] === 'changeUser');

    if (stocklocation) {
      q = q.set('stockLocationNameq', stocklocation.value);
    }
    if (HandelingUnitId) {
      q = q.set('handelingUnitId', HandelingUnitId);
    }
    if (qty) {
      q = q.set('qty', qty.value);
    }
    if (qtyUnit) {
      q = q.set('qtyUnit', qtyUnit.value);
    }
    if (isActive) {
      q = q.set('isActive', isActive.value);
    }
    if (isPartioal) {
      q = q.set('isPartioal', isPartioal.value);
    }
    if (stockTransTypeName) {
      q = q.set('stockTransTypeName', stockTransTypeName.value);
    }
    if (eventDate) {
      q = q.set('eventDate', this.siteDateFormatPipe.transform(eventDate.value));
    }
    if (reportDate) {
      q = q.set('reportDate', this.siteDateFormatPipe.transform(reportDate.value));
    }
    if (changeUser) {
      q = q.set('changeUser', changeUser.value);
    }

    q = q.set('skip', state.skip.toString()).set('take', state.take.toString());

    this.http.get<GridDataResult>(`${environment.apiUrl}HandelingUnit/GetQuantityReportAsync/`, { params: q }).pipe(map(response => (<GridDataResult>{

      data: this.loadgrid(response.data),

      total: response.total
    }))).subscribe(result => {
      super.next(result);
    });
  }

  loadgrid(data:any):IQuantityReport[] {
    this.listdata = data;
    this.listdata.forEach((element, index) => {
      element.reportDate = new Date(element.reportDate);
      element.eventDate = new Date(element.eventDate);
    });

    return this.listdata;
  }

  loadHandelingUnitProp():any {
    const endpointUrl = `${environment.apiUrl}HandelingUnit/Props`;
    return this.http.get<IHandelingUnitProp[]>(endpointUrl).pipe();
  }

  gethandelingunitprop(): Observable<IHandelingUnitProp[]> {
    const endpointUrl = `${environment.apiUrl}HandelingUnit/Props`;
    return this.http.get<IHandelingUnitProp[]>(endpointUrl)
      .pipe();
  }
}

export interface IHandelingUnitProp {
  propCode: string;
  Description:string
  Datatype:HandelingUnitPropType;
}

export interface IQuantityReport {
  handelingUnitId : number;
  StockLocationId:number | null;
  stockLocationName:string
  stockTransTypeId : number;
  qty : number;
  qtyUnit : string;
  isPartioal : boolean;
  isActive : boolean;
  stockTransTypeName:string;
  eventDate:Date;
  reportDate:Date;
  changeUser:string
  handelingUnitReportId:number
  handelingUnitPropValues: IHandelingUnitProperty[];
}

export interface IHandelingUnitProperty {
  handelingUnitId : number;
  propCode:string;
  valueInt:number;
  valueDecimal:number;
  valueString:string;
  valueDateTime:Date;
  handelingReportId:number
  handelingUnitProp:IHandelingUnitProp[];
}

export enum HandelingUnitPropType{
  Int,
  Decimal,
  String,
  Date
}
