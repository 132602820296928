import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { IshiftSearchCriteriaDTO, IShiftDTO } from '@app/core/models/shift';

marker('Operative.ShiftNameAlreadyExists');
marker('Operative.ShiftCodeCanNotBeBlank');
marker('Operative.ShiftCodeAlreadyExists');
marker('Operative.ShiftValidFromCannotHigherThanValidTo');

@Injectable({
  providedIn: 'root'
})

export class ShiftService {
  conf: any;

  constructor(
    public http: HttpClient,
    private readonly kendoGridService: KendoGridService
  ) { }

  public data: any[] = [];

  getshifts(state: State, criteria: IshiftSearchCriteriaDTO): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    criteria.machines.forEach(m => {
      params = params.append('machineIds', m.machineId?.toString());
    });

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}Shift`, { params });
  }

  query(skip: number = 0, take: number = 100): Observable<GridDataResult> {
    const params = new HttpParams()
      .set('skip', skip.toString())
      .set('take', take.toString());

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}Shift`, { params });
  }

  update(Shift: IShiftDTO): Observable<any> {
    const endpointUrl = `${environment.apiUrl}Shift/${Shift.shiftId}`;

    return this.http.put<IShiftDTO>(endpointUrl, JSON.stringify(Shift), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  insert(entity: IShiftDTO): Observable<IShiftDTO> {
    const endpointUrl = `${environment.apiUrl}Shift`;
    return this.http.post<IShiftDTO>(endpointUrl, JSON.stringify(entity), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public remove(data: IShiftDTO): Observable<boolean> {
    const ShiftUri = `${environment.apiUrl}Shift`;
    return this.http.delete<boolean>(`${ShiftUri}/${data.shiftId}`);
  }

  public getshchedules(): Observable<IScheduleDTO[]> {
    const shiftUri = `${environment.apiUrl}/Shift/getSchedules`;
    return this.http.get<IScheduleDTO[]>(`${shiftUri}`);
  }
}
