<div class="main">
  <kendo-card width="500px" >
    <kendo-card-header class="k-hstack">
      <kendo-avatar imageSrc="../../assets/img/fx_x.svg"></kendo-avatar>
      <div>
        <h1 kendo-card-title>{{ 'AppComponent.PageNotFound' | translate }}</h1>
      </div>
    </kendo-card-header>
    <kendo-card-body>
      <p>{{ 'AppComponent.PageNotFound' | translate }}</p>
    </kendo-card-body>
  </kendo-card>
</div>

