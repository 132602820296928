<kendo-dialog-titlebar kendo-dialog-title>
  <div class="primaryColor section-header-operative" translate>
    <span [translate]="'GluePlan.GlueSetNo'"
          [translateParams]="{no: bmgs.beamMaterialGlueSetID}">
    </span>
  </div>
</kendo-dialog-titlebar>

<div class="container">
  <div class="gluesetContainer">
    <app-beam-material-glue-set
      [glueSet]="bmgs"
      [plan] = "plan"
    >
    </app-beam-material-glue-set>
    <h4 *ngIf="!bmgs">No records found</h4>
  </div>
</div>

<kendo-dialog-actions>
  <button
    kendoButton
    type="button"
    themeColor="error"
    (click)="close()"
    class="float-right"
  >
    {{ "App.Close" | translate }}
  </button>
</kendo-dialog-actions>
