import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights, IExceptionTelemetry, PageView, PageViewPerformance } from '@microsoft/applicationinsights-web';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true
      }
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
  }

  setUserId(authenticatedUserId: string, accountId?: string) {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name ? : string, uri ? : string) {
    this.appInsights.trackPageView({
      name,
      uri
    });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error
    };
    this.appInsights.trackException(exception);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
      envelope.tags['ai.cloud.role'] = 'OptinexWeb';
      if (envelope.name === PageView.envelopeType || envelope.name === PageViewPerformance.envelopeType) {
        envelope.baseData.name = this.router.url;
      }
    });
  }
}
