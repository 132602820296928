import { HttpClient, HttpParams } from '@angular/common/http';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { IOptimizationSequencer } from '@app/core/models/optimization-sequencer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAutoSchedulerStartDTO } from '@app/core/models/autoSchedulerStartDTO';
import { WatsonStatus, WatsonRunStatus, WatsonSolveStatus } from '@app/core/models/watson-status';

@Injectable({
  providedIn: 'root'
})
export class RunJobService extends BaseService {
  constructor(conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService) {
    super(http, conf, notificationService);
  }

  public PollState(jobId: string, onlyDB: boolean = false, typeOfSequencer: string = null): Observable<[WatsonStatus, number]> {
    return this.getOptimazationState(jobId, onlyDB)
      .pipe(
        map((jobState: WatsonStatus) => {
          switch (jobState.status) {
            case (WatsonRunStatus.notFound):
              return [jobState, -1];
            case (WatsonRunStatus.queued):
              return [jobState, 1];
            case (WatsonRunStatus.running):
              return [jobState, 2];
            case (WatsonRunStatus.failed):
            case (WatsonRunStatus.canceled):
              return [jobState, 3];
            case (WatsonRunStatus.completed):
              switch (jobState.solveStatus) {
                case WatsonSolveStatus.feasible_solution:
                case WatsonSolveStatus.optimal_solution:
                case WatsonSolveStatus.unbounded_solution:
                  return jobState.isResultProcessed ? [jobState, 6] : [jobState, 4];
                default:
                  return [jobState, 5];
              }
            default:
              return [jobState, -1];
          }
        }));
  }

  private getOptimazationState(jobID: string, onlyDB: boolean): Observable<WatsonStatus> {
    console.log('Optimazation Order:');
    return this.http
      .get<WatsonStatus>(
        `${this.configurations.baseApiUrl}RunJob/jobstatus/${jobID}`
        , { params: new HttpParams().set('onlyDB', onlyDB) }
      );
  }

  startOptimization(OptimizationSequencer: IOptimizationSequencer): Observable<WatsonStatus> {
    return this.http
      .post<WatsonStatus>(this.configurations.baseApiUrl + 'RunJob/optimize/sequencer',
        JSON.stringify(OptimizationSequencer),
        this.httpOptions
      );
  }

  public passInputToAutoAcheduler(auutoSchedulerStartDTO: IAutoSchedulerStartDTO): Observable<WatsonStatus> {
    return this.http
      .post<WatsonStatus>(this.configurations.baseApiUrl + 'RunJob/optimize/autoScheduler',
        JSON.stringify(auutoSchedulerStartDTO),
        this.httpOptions
      );
  }
}
