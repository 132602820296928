import { Demandline } from './demand-line.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { IStaticalOptimizationResponse } from '@app/core/services/http-services/gluelam/optimazation.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { BeamMaterialGlueSet, BeamMaterialGlueSetCreator } from '@app/core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';

export class StaticalOptimizationOrder {
  constructor(
    public beamLamminaDimensionID: number,
    public maxPressLength: number,
    public maxPressHight: number,
    public minPressHight: number,
    public maxPressWidth: number,
    public maxGluePlanLength: number,
    public maxBeamInBeamMaterial: number,
    public maxGlueplans: number,
    public maxGluesets: number,
    public maxLayers:number,
    public maxSolveTime: number,
    public machineId:number,
    public deployedModelFile: string,
    public deployedModelId: string
  ) {}

  private _BeamLamminaDimension: BeamLamminaDimension;
  get beamLamminaDimension(): BeamLamminaDimension {
    return this._BeamLamminaDimension;
  }

  set beamLamminaDimension(bld: BeamLamminaDimension) {
    this.beamLamminaDimensionID = bld.beamLamminaDimensionID;
    this._BeamLamminaDimension = bld;
  }

  public demands: Demandline[];
}
export class OptimizationType {
  constructor(key: string, value: string) {
    this.Key = key;
    this.Value = value;
  }

  Key: string;
  Value: string;
}
export class StaticalOptimizationResponceCreator {
  static FromJson(
    object: IStaticalOptimizationResponse,
    conf: ConfigurationService
  ): StaticalOptimizationResponce {
    const beamMaterialGlueSets = object.beamMaterialGlueSets.map((obj) =>
      BeamMaterialGlueSetCreator.FromJson(
        obj,
        conf,
        true
      )
    );

    return new StaticalOptimizationResponce(
      object.beamLamminaDimensionID,
      0,
      object.maxPressLength,
      object.maxPressHight,
      object.minPressHight,
      object.maxPressWidth,
      object.maxBeamInBeamMaterial,
      object.maxGlueplans,
      object.maxGluesets,
      null,
      beamMaterialGlueSets,
      object.gluePlans,
      object.optiApiJson,
      object.optiApiURL,
      object.numberOfDemands,
      object.numberOfGluestest,
      object.numberOfAllocations,
      object.numberOfBeamMaterials,
      object.solveState,
      object.errorMessage
    );
  }
}

export class StaticalOptimizationResponce {
  public static INFEASIBLE_SOLUTION: string = 'INFEASIBLE_SOLUTION';
  public static FEASIBLE_SOLUTION: string = 'FEASIBLE_SOLUTION';
  public static OPTIMAL_SOLUTION: string = 'OPTIMAL_SOLUTION';

  constructor(
    public beamLamminaDimensionID: number,
    public maxPressLength: number,
    public maxPressHight: number,
    public minPressHight:number,
    public maxPressWidth:number,
    public maxGluePlanLength: number,
    public maxBeamInBeamMaterial: number,
    public maxGlueplans: number,
    public maxGluesets: number,
    public beamLamminaDimension: BeamLamminaDimension,
    public beamMaterialGlueSets: BeamMaterialGlueSet[],
    public gluePlans:GluingPlan[],
    public optiApiJson: string,
    public optiApiURL: string,
    public numberOfDemands: number,
    public numberOfGluestest: number,
    public numberOfAllocations: number,
    public numberOfBeamMaterials: number,
    public solveState: string,
    public errorMessage: string
  ) { }
}

export class OptiInputModel {
  public BeamLamminaDimension: BeamLamminaDimension;
  public BeamOrderLines: Demandline[];
  public machine:IMachineDTO;
}

export interface IOptimizerCustomSetting {
  InputName:string
  InputValue:any
}
