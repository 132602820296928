import { Component, OnInit } from '@angular/core';
import { DashboardCardComponent } from '../dashboard-layout/dashboard-card-component';

@Component({
  selector: 'app-simple-metric',
  templateUrl: './simple-metric.component.html',
  styleUrls: ['./simple-metric.component.css']
})

export class SimpleMetricComponent implements OnInit, DashboardCardComponent {
  showTitle: boolean = false;
  data: any;
  name:string;
  value:number;
  uom:string;

  constructor() { }

  ngOnInit() {
    this.name = this.data.name;
    this.value = this.data.value;
    this.uom = this.data.uom;
  }
}
