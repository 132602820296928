import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@app/shared/helpers/date-helper';

@Pipe({
  name: 'siteDateFormat'
})
export class SiteDateFormatPipe implements PipeTransform {
  // constructor for localStorage
  // constructor(private localStorage:LocalStorageService) {}
  transform(inputDate: any, showTime?:boolean, timezone?: string): any {
    if (inputDate !== null && inputDate !== undefined && inputDate !== '') {
      const dateFormat = showTime ? DateHelper.dateFormatWithTime : DateHelper.dateFormat;
      const date = new Date(inputDate);

      // when implementing sitetime we take format from localstorage
      // dateFormat = this.localStorage.getItem(LocalStorageService.dateFormat) ?? dateFormat;
      return formatDate(date, dateFormat, DateHelper.locale, timezone);
      // if localstorage format is not in switch case then show dates in given localstorage format
      // return formatDate(formattedDate, dateFormat, locale, timezone);
    }
  }
}
