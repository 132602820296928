import { Component, Input } from '@angular/core';

import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderLineDTO } from '@app/core/models/prod-order';

@Component({
  selector: 'app-prod-order-line-grid',
  templateUrl: './prod-order-line-grid.component.html',
  styleUrls: ['./prod-order-line-grid.component.css']
})
export class ProdOrderLineGridComponent {
  @Input() public prodOrderLines: IProdOrderLineDTO[] = [];

  constructor() { }

  public getActivityText(line:IProdOrderLineDTO):string {
    return ProdOrderService.GetActivityText(line);
  }
}
