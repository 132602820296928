import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VmService {
  constructor(private http: HttpClient) { }

  private subsctiption = 'dc67d5da-7865-4d6f-b6d8-bfe7911d64f0';
  private endpoint = 'management.azure.com';
  private resourceGroup = 'optinex';
  private vm = 'OptinexWM';

  private getPath(action: string): string {
    return `https://${this.endpoint}/subscriptions/${this.subsctiption}/resourceGroups/${this.resourceGroup}/providers/Microsoft.Compute/virtualMachines/${this.vm}/${action}?api-version=2019-03-01`;
  }

  public getVMPowerState(): Observable<string> {
    return this.getVMStatus().pipe(map(i => {
      if (i.statuses.find(s => s.code === 'PowerState/running')) {
        return 'Running';
      } else if (i.statuses.find(s => s.code === 'PowerState/deallocated') || i.statuses.find(s => s.code === 'PowerState/stopped')) {
        return 'Offline';
      } else if (i.statuses.find(s => s.code === 'PowerState/starting')) {
        return 'Starting';
      } else if (i.statuses.find(s => s.code === 'PowerState/stopping')) {
        return 'Stopping';
      }
    }
    ));
  }

  public getVMStatus(): Observable<VmInfo> {
    return this.http.get<VmInfo>(this.getPath('instanceView'));
  }

  public startVM(): Observable<any> {
    return this.http.post<any>(this.getPath('start'), {});
  }

  public stopVM(): Observable<any> {
    return this.http.post<any>(this.getPath('powerOff'), {});
  }
}

export class VmInfo {
  computerName: string;
  statuses: VmState[];
}

export class VmState {
  code: string;
  displayStatus: string;
}
