import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { IRunLogDTO } from '@app/core/models/run-log-dto';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RunLogService {
  public querySubject: BehaviorSubject<TypedGridResult<IRunLogDTO>> = new BehaviorSubject<TypedGridResult<IRunLogDTO>>(null);

  constructor(private conf: ConfigurationService,
    private http: HttpClient,
    private notificationService: AppNotificationService) {
  }

  public query(skip:number = 0, take:number = 50) {
    let params = new HttpParams();

    params = params.set('take', take).set('skip', skip);

    return this.http.get<GridDataResult>(`${this.conf.baseApiUrl}/RunLog`, { params }).subscribe(x => {
      x.data.forEach(e => {
        e.start = new Date(e.start);
        e.ended = new Date(e.ended);
      });
      this.querySubject.next(new TypedGridResult<IRunLogDTO>(x.data, x.total));
    });
  }

  public get(id:number):Observable<IRunLogDTO> {
    return this.http.get<IRunLogDTO>(`${this.conf.baseApiUrl}/RunLog/${id}`);
  }
}

export class TypedGridResult<T> implements GridDataResult {
  constructor(
  public data: T[],
  public total: number
  ) {}
}
