import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { CordHelper } from './CordHelper';
import { GlusetMenuEvent } from './beam-material-glueset.component';

@Component({
  selector: '[app-beam-material]',
  templateUrl: './beam-material.component.html',
  styleUrls: ['./beam-material-glueset.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeamMaterialEditor implements OnInit {
  @Input() containerWidth: number;
  @Input() bm: BeamMaterial;
  @Input() ch: CordHelper;
  @Input() cut: number = 1;
  @Input() isSplitView: boolean;
  @Output('openBeamMaterialLineMenu') openBeamMaterialLineMenuInt: EventEmitter<GlusetMenuEvent> = new EventEmitter();

  manualBeamMaterialLinesToDisplay: {manualBml: BeamMaterialLine, qty:number}[] = [];

  public get xOffsetTestPSC() {
    if (this.bm) {
      return this.bm.GetTotalAssignedLength() + this.bm.overlength;
    } else {
      return 0;
    }
  };

  constructor(public conf: ConfigurationService) {
    if (this.bm && this.bm.BeamMaterialType.IsManual) {
      this.getLinesToDrawForManualBm();
    }
  }

  ngOnInit(): void {
    if (this.bm && this.bm.BeamMaterialType.IsManual) {
      this.getLinesToDrawForManualBm();
    }
  }

  openBeamMaterialLineMenu(event:GlusetMenuEvent) {
    event.bm = this.bm;
    this.openBeamMaterialLineMenuInt.emit(event);
  }

  public grab(event: any) {
    this.openBeamMaterialLineMenuInt.emit(<GlusetMenuEvent>{ bm: this.bm, bml: null, mouseEvent: event });
    return false;
  }

  public getLinesToDraw(): BeamMaterialLine[] {
    return this.bm.beamMaterialLines.filter(bml => bml.Cut === this.cut).sort((a, b) => a.IndexInCut - b.IndexInCut);
  }

  public getLinesToDrawForManualBm() {
    this.bm.beamMaterialLines.forEach((bml, index) => {
      if (this.manualBeamMaterialLinesToDisplay.length === 0) {
        this.manualBeamMaterialLinesToDisplay.push({ manualBml: bml, qty: 1 });
      } else {
        const ifDuplicateBML = this.manualBeamMaterialLinesToDisplay.find(x => x.manualBml.transportPackageDemand.orderID === this.bm.beamMaterialLines[index].transportPackageDemand.orderID &&
          x.manualBml.transportPackageDemand.orderRowID === this.bm.beamMaterialLines[index].transportPackageDemand.orderRowID);

        if (!ifDuplicateBML) {
          this.manualBeamMaterialLinesToDisplay.push({ manualBml: bml, qty: 1 });
        } else {
          ifDuplicateBML.qty = ifDuplicateBML.qty + 1;
        }
      }
    });
  }

  getXforManualBML(i) {
    if (i === 0) {
      return 0;
    } else {
      return i > 0 ? this.ch.getWidth(this.manualBeamMaterialLinesToDisplay[i - 1].manualBml.length * this.manualBeamMaterialLinesToDisplay[i - 1].qty) : 0;
    }
  }

  getTextForManualBeamMaterial(index : number): string {
    return (
      this.manualBeamMaterialLinesToDisplay[index].manualBml.transportPackageDemand.orderID +
      ' / ' +
      this.manualBeamMaterialLinesToDisplay[index].manualBml.transportPackageDemand.orderRowID +
      ' / ' +
      this.manualBeamMaterialLinesToDisplay[index].qty
    );
  }

  public getXforBML(bml: BeamMaterialLine, index: number) {
    if (bml.IndexInCut === 0) {
      return this.bm.overlength;
    }

    let x = this.bm.overlength;
    const lines = this.getLinesToDraw();
    for (let i = 0; i < index; i++) {
      x += lines[i].length + this.bm.cutWidth; // get this 60 from machine config
    }
    return x;
  }

  calcX(index: number) {
    let x = this.conf.BEAM_DEFAULT_OFF_CUT_BMGS;

    const lines = this.getLinesToDraw();

    for (let i = 0; i < index; i++) {
      x += lines[i].length;
    }

    return this.ch.getWidth(x);
  }

  public split = (bm: BeamMaterial) => {
    const isSplit = bm.beamMaterialLines.map(x => {
      const isSplit = x.transportPackageDemand?.deafultNumberOfBeamsPerBeamMaterial > 1;
      return isSplit;
    }).some(x => x === true);
    return isSplit;
  };

  getDefaultNoOfBeams(bml: BeamMaterialLine) {
    if (!this.isSplitView) {
      return this.bm.isSplit ? 2 : 1;
    } else {
      return bml.NumberOfBeams > 1 ? 2 : 1;
    }
  }
}
